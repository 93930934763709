// UserPending.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { 
  colors,
  logoSrc,
  iconSrc,
  northsideLogoSrc,
  overviewSrc,
  athletesSrc,
  groupsSrc,
  programsSrc,
  exercisesSrc,
  movementsSrc
 } from '../../constants';
 import { ExploreIcon, ExploreBarOvalButton, ShellOvalBtn, UserNameDiv } from './title';
 import { acceptUserToGroup, submitUserEmailList } from '../../services';

const tabSectionItemsWidth = "90%";
const tabSectionItemsVerticalSpacing = "10px";


const FakeUserAcceptWrapper = styled.div`
  /* cursor: pointer; */
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  white-space: nowrap;
  width: 100%;
  height: 200px;
  padding: 0px;
  padding-top: 0px;
  margin: auto;
  margin-top: 0px;
  text-align: center;
  vertical-align: middle;
  /* background: url("Shell_assets/fakes/user_accept.png");
  background-size: cover; */
  /* background: red; */
`;

/*
background: url("Shell_assets/fakes/group_create.png");     //
background: url("Shell_assets/fakes/group_edit.png");       //
background: url("Shell_assets/fakes/injury_bodymap.png");   //
background: url("Shell_assets/fakes/readiness.png");        //
background: url("Shell_assets/fakes/soreness_bodymap.png"); //
background: url("Shell_assets/fakes/user.png");             //
background: url("Shell_assets/fakes/user_accept.png");      // fake <UserAccept> implemented in <UserSection> & looks good
background: url("Shell_assets/fakes/user_invite.png");      // fake <UserInvite> implemented in <Shell> & looks good
*/


const UserPending = ({
  // do we need any input props here, for UserAccept context? a group_id? a user_id? anything?
  state, groupId, onInviteSubmit, onAcceptUser
}) => {
  console.log('~~~~~ UserPending(), staff is ' + state.user.staff_boolean);

  // const userType = staff ? "Staff" : "Athlete";
  const userType = state.user.staff_boolean ? "Staff" : "Athlete";
  const pendingType = state.user.group_approval_boolean ? "User" : "Group";

  const userDisplayName = state.user &&
  state.user.last_name_string && state.user.last_name_string.length > 0 && 
  state.user.first_name_string && state.user.first_name_string.length > 0 ?
  state.user.last_name_string + ', ' + state.user.first_name_string : 
  state.user && state.user.email_string && state.user.email_string.length > 0 ?
  state.user.email_string : "SomeLastName, SomeFirstName";

  console.log('~~~~~ UserPending(), userDisplayName is ' + userDisplayName + ', and userType is ' + userType);


  function acceptUser(user_id, group_id){
    console.log('~~~~~ UserPending(), acceptUser() - user_id is ' + user_id + ', and group_id is ' + group_id);

    if (pendingType === "Group"){
      // hit the API and accept this user on behalf of the Group they were invited to
      acceptUserToGroup(groupId, state.user.id_guid, state.user.staff_boolean)
      .then(item => { // item is going to be what is returned from the service method... 
        console.log('~~~~~ UserPending(), acceptUser() - item: ' + item);
        const obj_str = JSON.stringify(item);
        console.log('~~~~~ UserPending(), acceptUser() - obj_str: ' + obj_str);
        onAcceptUser(groupId, state.user.id_guid, state.user.staff_boolean);
        // do we need to 
      })
    } else {
      // hit the API to send them another Invite email use the current group and
      // what if we clicked the user from the general Explore search, we would have no group to refderence
      // tell Nathan not to do that in the demo ;-)
      submitUserEmailList(state.user.email_string, groupId, state.user.staff_boolean)
      .then(item => { // item is going to be what is returned from the service method... 
        console.log('~~~~~ UserPending(), acceptUser() - item: ' + item);
        const obj_str = JSON.stringify(item);
        console.log('~~~~~ UserPending(), acceptUser() - obj_str: ' + obj_str);
        onInviteSubmit(state.user.email_string, groupId, state.user.staff_boolean, true);
      })
    }
  }

  return (
    <>
      <FakeUserAcceptWrapper>
        <UserNameDiv>
          {userDisplayName}
        </UserNameDiv>
        <div style={{ textAlign: "center", paddingTop: "20px", color: "#000000", opacity: "1.0", fontSize: "14px" }}>
          <div style={{ paddingTop: "50px" }}>
            <div style={{ paddingBottom: "10px", color: colors.exploreBlueBtn }}>Pending {pendingType} Acceptance</div>
            <ShellOvalBtn style={{ display: "block", width: "200px", margin: "auto" }} 
              onClick={(userType) => acceptUser(userType)} 
              title={pendingType === "Group" ? "Accept this " + userType + " user" : "Resend Invite Email"}
            >
              {pendingType === "Group" && <span>Accept as {userType}</span>}
              {pendingType === "User" && <span>Resend Invite Email</span>}
            </ShellOvalBtn>
          </div> 
        </div>
      </FakeUserAcceptWrapper>

    </>
    

  );

}

export default UserPending;