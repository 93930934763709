// UserAssessmentHistory.js

import React, { useState } from 'react';

import UserAssessmentHistoryChart from './UserAssessmentHistoryChart';
import AssessmentsTable from './AssessmentsTable';
import LoadingDivLayer  from './LoadingDivLayer';
import { Title, EZSpacer, ContentWrapper } from './title';

function UserAssessmentHistory({
  user, historyLoaded, assessments, onAssessmentClick, isMobile
}) {
  // section below needs re-thought, so that user with n0 assessments does not cause a crash...?
  console.log('~~~~~ UserAssessmentHistory() - assessments.length is *' + assessments.length + '*');
  // console.log('~~~~~ UserAssessmentHistory() - assessments[0].memberid is *' + assessments[0].memberid + '*, and user.id_guid is *' + user.id_guid + '*');
  // const assessmentUser = !assessments ? '1234567890' : assessments[0].memberid;
  const assessmentUser = !assessments ? '1234567890' : assessments.length === 0 || !assessments[0].memberid ? '0' : assessments[0].memberid;
  const viewedUser = !user || !user.id_guid ? '0987654321' : user.id_guid;
 
  // const [loaded, setLoaded] = useState(assessmentUser === viewedUser); // TODO: pass back a historyLoaded bool from Shell userState?
  // const mounted = useRef(true);

  // if (assessmentUser === viewedUser && !loaded) {
  //   setLoaded(true);
  // } else if (assessmentUser !== viewedUser && loaded) {
  //   setLoaded(false);
  // }

  return (
    <div style={{ width: "100%", padding: "0px", margin: "0px", marginTop: "20px" }}>
      {historyLoaded ? '' : <LoadingDivLayer style={{ marginTop: "100px" }} />}
      <ContentWrapper className="wrapper" loaded={historyLoaded} style={{ width: "100%", paddingTop: "0px" }}>
        <EZSpacer height={"0vh"} />
        <Title style={{ marginTop: "30px", marginBottom: "-30px" }}>Recent Assessment Scores</Title>
        <UserAssessmentHistoryChart isMobile={isMobile} data={assessments} />
        <EZSpacer height={"6vh"} />
        <Title>Assessment History</Title>
        <EZSpacer height={"2vh"} />
        <AssessmentsTable isMobile={isMobile} assessments={assessments} handleClick={onAssessmentClick} />
      </ContentWrapper>
    </div>
  );
}

export default UserAssessmentHistory;