import styled from 'styled-components'

import React from 'react';
// import { propTypes } from 'react-bootstrap/esm/Image';

const ScoreText = styled.text`
  text-anchor: middle;
  color: #ffffff;
  font-weight: 400;
`

function ScoreCircle(props) {
  const {
    size,     // (circle diameter)
    score,    // (integer between 1 and 99 inclusive)
    trend,    // ('up' or 'dn')
    bigNum,   // (true | false) - default if not specified is small numbers
    simple    // (true | false) - default if not specified is false (not simple)
  } = props;
  const strokeWidth = size / 11;
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius
  
  // TODO: figure out best sizes & css for subdomain scores with trend arrows
  // const fontSize =  simple ? radius * 0.9 : bigNum ? radius * 0.85 : radius * 0.85;
  // const textY = simple ? center * 1.25 : bigNum ? center * 1.19 : center * 1.19;
  const fontSize = bigNum ? radius * 0.85 : radius * 0.6;
  const textY = center * 1.19;
  const svgStyle = simple ? { margin: 0 } : { margin: "20px auto" };
  // const svgStyle = { margin: 0 };

  // calculate triangle dimensions based on props.size (circle outer diameter)
  // the fill color and the orientation will be swapped by the trend direction
  let triangleBase = props.size / 6; /* triangle side is 1/3 circle radius */
  let triangleY = 2 * props.size / 3; /* triangle located 67% down from top */
  let triangleX = 5 * props.size / 12;
  let triangleHeight = triangleBase / 1.414;
  // these next numbers are for the triangle pointing UP
  let UpTrianglePoint1x = triangleX;
  let UpTrianglePoint1y = triangleY + triangleHeight;
  let UpTrianglePoint2x = triangleX + triangleBase/2;
  let UpTrianglePoint2y = triangleY;
  let UpTrianglePoint3x = triangleX + triangleBase;
  let UpTrianglePoint3y = triangleY + triangleHeight;
  let triangleUpPoint1 = UpTrianglePoint1x + "," + UpTrianglePoint1y;
  let triangleUpPoint2 = UpTrianglePoint2x + "," + UpTrianglePoint2y;
  let triangleUpPoint3 = UpTrianglePoint3x + "," + UpTrianglePoint3y;
  let triangleUpPoints = triangleUpPoint1 + " "
    + triangleUpPoint2 + " " + triangleUpPoint3;

  let trianglePoints = triangleUpPoints; // default orientation is UP
  let triangleColor = "green"; // default color is green
  // these next numbers are for the triangle pointing DOWN
  let DnTrianglePoint1x = triangleX;
  let DnTrianglePoint1y = triangleY;
  let DnTrianglePoint2x = triangleX + triangleBase/2;
  let DnTrianglePoint2y = triangleY + triangleHeight;
  let DnTrianglePoint3x = triangleX + triangleBase;
  let DnTrianglePoint3y = triangleY;
  let triangleDnPoint1 = DnTrianglePoint1x + "," + DnTrianglePoint1y;
  let triangleDnPoint2 = DnTrianglePoint2x + "," + DnTrianglePoint2y;
  let triangleDnPoint3 = DnTrianglePoint3x + "," + DnTrianglePoint3y;
  let triangleDnPoints = triangleDnPoint1 + " "
    + triangleDnPoint2 + " " + triangleDnPoint3;

  // set the triangle to be pointed down if the trend is down,
  // and set the color to be red, too
  if (trend === "dn" || trend === "down") {
    trianglePoints = triangleDnPoints;
    triangleColor = "red";
  }

  let offset = ((100 - score) / 100) * circumference;

  let redStroke = "#ff2957";
  let yellowStroke = "#f4f53d";
  let greenStroke = "#7dea62";
  let circleBgStroke = "#eeeeee";

  let minYellowScore = 40; // eventually inport something like { minYellowScore } from './config'
  let maxYellowScore = 59; // eventually inport something like { maxYellowScore } from './config'
  
  let circleTwoStroke = redStroke; // default stroke color is red
  if (score >= minYellowScore) { circleTwoStroke = yellowStroke; }
  if (score > maxYellowScore) { circleTwoStroke = greenStroke; }

  let circleTransform = "rotate(-89.9, " + center + ", " + center + ")";

  return (
    <>
      <svg className="svg" width={size} height={size} style={svgStyle}>
        <circle
          className="svg-circle-bg"
          stroke={circleBgStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-circle"
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={circleTransform}
        />
        {/* {!simple ?         */}
          <polygon /* points are x,y with origin upper left */
            points={trianglePoints} 
            className="svg-triangle"
            strokeWidth={0}
            fill={triangleColor}
          /> : <></>
        {/* } */}
        <ScoreText x={center} y={textY} style={{ fontSize: fontSize, color: "#888888" }}>
          {score}
        </ScoreText>
        Sorry, your browser does not support inline SVG.
      </svg>
    </>
  );
}

export default ScoreCircle;
