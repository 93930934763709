// components/styled/MyReadiness.js

import React, { useEffect, useRef, useState } from 'react';
import packageJson from '../../../package.json';

import { getUserReadiness, setUserReadiness } from '../../services';

import { Icon, MobileVersionDiv } from './title';

import { iconSrc } from '../../constants';
import MobileLoadingDivLayer  from './MobileLoadingDivLayer';
import MyBodymap from './MyBodymap';
import PickOneOfFive from './PickOneOfFive';
import { AlertLink } from 'react-bootstrap';

const fakeSorenessArray = [
  {
    "body_map_id_integer": 12
    , "soreness_integer": 0 // was 2
  }
  , {
    "body_map_id_integer": 13
    , "soreness_integer": 1
  }
];

const fakeInjuryArray = [
  {
    "body_map_id_integer": 14
    , "injury_integer": 0 // was 1
  }
  , {
    "body_map_id_integer": 12
    , "injury_integer": 2
  }
];


const fakeReadiness = {
	"user_id_guid": "ce8228fd-a3ab-4ef5-8f11-8ee469095b1b"
	, "readiness_overall_integer": 5 // was 2

	, "readiness_sleep_integer": 5 // was 3

	, "readiness_hydration_integer": 5 // was 4

	, "readiness_stress_integer": 5 // was 5

	, "readiness_soreness_integer": 5 // was 4

	, "readiness_soreness_body_map_array" : fakeSorenessArray
	
	, "readiness_injury_integer": 4
	
	, "readiness_injury_body_map_array" : fakeInjuryArray

	, "readiness_injury_explanation_string" : "Skydiving Accident" // was "Motorcyle Accident"
}


const MyReadiness = ({
  athleteId, isMobile 
}) => {
  console.log('~~~~~ MyReadiness.js, Readiness() - isMobile is *' + isMobile + '*');

  const [readinessResponse, setReadinessResponse] = useState([]);
  const [recentReadiness, setRecentReadiness] = useState(fakeReadiness);

  const fakeInjuryExplanation = "Crashed my motorcycle jumping the creek...";
  const [soreBodyAreas, setSoreBodyAreas] = useState([]); // TODO: have this pulled from API
  const [hurtBodyAreas, setHurtBodyAreas] = useState([]); // TODO: have this pulled from API
  const [injuryExplanation, setInjuryExplanation] = useState(fakeInjuryExplanation); // TODO: have this pulled from API
  const [refreshSoreAreas, setRefreshSoreAreas] = useState(uuidv4());
  const [refreshHurtAreas, setRefreshHurtAreas] = useState(uuidv4());

  const [readinessName, setReadinessName] = useState('');
  const [itemNotes, setItemNotes] = useState([]);
  const [formItem, setFormItem] = useState({});

  const [loaded, setLoaded ] = useState(true); // TODO: set back to false when there is a useEffect() that will set it to true after fetching
  // const [loading, setLoading] = useState(false);
  
  // const mounted = useRef(true);
  
  // const urlPieces = window.location.toString().split("/");
  // const lastPiece = urlPieces[urlPieces.length - 1];  // the last piece might have a queryString e.g groups?email=greg@physmodo

  // console.log('~~~~~ MyReadiness.js - searchParams.get("athleteEmail"): ' + searchParams.get("athleteEmail"));

  // let updatingReadinessName = 'none_yet';
  // let fetchingReadinessNow = false;

  // let fetchingNow = false;

  // function readinessClickHandler(event, id) {
  //   alert('~~~~~ MyReadiness.js, Readiness() - readinessClickHandler() event.type is *' + event.type + '*, and event.target.id is *' + event.target.id + '*');
  //   alert('~~~~~ MyReadiness.js, Readiness() - readinessClickHandler() event.type is *' + event.type + '*, and event.currentTarget.id is *' + event.currentTarget.id + '*');
  //   const key = event.currentTarget.id;
  //   const readinessId = key.split('_')[0];
  //   const readinessName = event.currentTarget.className;

  //   console.log('~~~~~ MyReadiness() - readinessClickHandler() readinessId is *' + readinessId + '*, and readinessName is *' + readinessName + '*');
  //   setReadinessName(event.currentTarget.dataset["name"]);
  //   updatingReadinessName = event.currentTarget.dataset["name"];


  //   if (isMobile) {
  //     alert('readinessClickHandler - inside isMobile');
  //     setTimeout(() => {

  //       const element = document.getElementById("readiness_component"); // top of the Readiness component
  //       if (element !== undefined && element !== null) {
  //         alert('readinessClickHandler - inside element exists *' + element.id + '*');
  //         element.scrollIntoView();
  //       } else {
  //         alert('readinessClickHandler - readiness_component was not found');
  //       }
  //     }, 3500)
  //   }

  // }

  function updateUserReadiness(ref, value) {
    console.log('~~~~~ MyReadiness() - updateUserReadiness()');
    console.log('~~~~~ MyReadiness()(), updateUserReadiness() - isInjuryMap2 - ref is ' + ref + ', and value is ' + value);
    
    let readinessObject = recentReadiness;
    if (readinessObject['user_id_guid'] !== athleteId) { readinessObject['user_id_guid'] = athleteId }
    readinessObject[ref] = value;

    setRecentReadiness(readinessObject);
  }

  function submitUserReadiness() {
    console.log('~~~~~ MyReadiness() - submitUserReadiness()');

    setUserReadiness(athleteId, recentReadiness) // should return item.status where 201 = create worked, 422 = bad request
    .then(thisItem => { // item is going to be what is returned from the service method... 

      let alertMsg = 'Updated readiness for user with id_guid ' + athleteId;

      setTimeout(function() {
        // window.location.reload();
        // alert(alertMsg);
        console.log(alertMsg);
      }, 500);
    })
  }

  // function bodymapClickHandler(event, id) { // click on bodymap to change body area state

  //   console.log('~~~~~ MyReadiness() - bodymapClickHandler()');

  //   const key = event.currentTarget.id;
  //   // const itemId = key.split('_')[0];

  //   console.log('~~~~~ MyReadiness() - bodymapClickHandler() - key is *' + key + '*');

  //   // updateUserReadiness(ref, value);

  //   // if (isMobile) {
  //   //   alert('handleExerciseClick for mobile case');
  //   // } else {
  //   //   alert('handleExerciseClick for NOT mobile case');
  //   // }
    
  //   // window.scrollTo({
  //   //   top: 1000, // find vh in px and scroll to there?  or scroll to an element found at the top of Exercise component?
  //   //   left: 0,
  //   //   behavior: "smooth",
  //   // });

  //   // setTimeout(() => {
  //   //   // alert('In setTimeout for scrollIntoView()');
  //   //   // const element = document.getElementById("exercise_component");
  //   //   // console.log('~~~~~ MyReadiness.js, Readiness() - element is *' + element + '*');
  //   //   // window.scrollTo(0, document.body.scrollHeight);
  //   //   const scrollingElement = (document.scrollingElement || document.body);
  //   //   scrollingElement.scrollTop = scrollingElement.scrollHeight + 3000;
  //   //   // element.scrollIntoView();
  //   // },2500);

  //   // selectedItemId = itemId;
  //   // setItemId(itemId);
  //   // getItemObj(itemId);
  // }

  function handleNoteChange(text){
    console.log('~~~~~ MyReadiness()(), handleNoteChange() - Explanation text is ' + text);
    // code here to submit the change via the API - *IF* we were allowing changes here...

    const ref = "readiness_injury_explanation_string";
    const value = text;

    updateUserReadiness(ref, value);
  }

  function handleHurtAreaClick(areaRef){
    handleMapAreaClick('hurt', areaRef);
  }

  function handleSoreAreaClick(areaRef){
    handleMapAreaClick('sore', areaRef);
  }

  function handleMapAreaClick(mapRef, areaRef){
    console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - mapRef is ' + mapRef + ', and areaRef is ' + areaRef);
    const isInjuryMap = mapRef.includes('sore') ? false : true;
    let updatedAreasArray = isInjuryMap ? hurtBodyAreas : soreBodyAreas;
    let objIndex = 0;
    // parse the areaRef to find the integer id of the area
    const area_id = parseInt(areaRef.split('_')[1]);
    // const area.body_map_id_integer = areaRef.split('_')[1];
    console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - area_id is ' + area_id);
    // find out if this area is already in the areas array
    if (updatedAreasArray.some(area => area.body_map_id_integer === area_id)){
      console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - area_id ' + area_id + ' was found in bodyAreas');
      // if so, find current color of this area in the areas array
      const currentLevel = updatedAreasArray.find(area => area.body_map_id_integer === area_id).level_integer;
      if (currentLevel === 2){
        console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - area_id ' + area_id + ' was level 2');
        // if the color was red (or integer 2), delete the element from the array
        updatedAreasArray = updatedAreasArray.filter(e => e.body_map_id_integer !== area_id);
      } else { // the only other choice is the yellow color, since grey would not appear in the array at all
        console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - area_id ' + area_id + ' was yellow');
        // modify the color to be red, and save the element in the areas array, locally and via the API
        objIndex = updatedAreasArray.findIndex((obj => obj.body_map_id_integer === area_id));
        updatedAreasArray[objIndex].level_integer = 2;
        console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - updatedAreasArray[objIndex].level_integer is now ' + updatedAreasArray[objIndex].level_integer);
      }
    } else {
      console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - area_id ' + area_id + ' was NOT found in bodyAreas');
      // if the area is not already in the array, then add it to the array with the yellow color, then save
      updatedAreasArray.push({ body_map_id_integer: area_id, level_integer: 1 });
      objIndex = updatedAreasArray.findIndex((obj => obj.body_map_id_integer === area_id));
      console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - updatedAreasArray[objIndex].level_integer is now ' + updatedAreasArray[objIndex].level_integer);
    }
    if (isInjuryMap) {
      console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - isInjuryMap1');
      setHurtBodyAreas(updatedAreasArray);
      setRefreshHurtAreas(uuidv4());
      const ref = "readiness_injury_body_map_array";
      const value = updatedAreasArray;
  
      updateUserReadiness(ref, value);
    } else {
      console.log('~~~~~ MyReadiness()(), handleMapAreaClick() - isSoreMap');
      setSoreBodyAreas(updatedAreasArray);
      setRefreshSoreAreas(uuidv4());
      const ref = "readiness_soreness_body_map_array"; // "readiness_soreness_body_map_array"
      const value = updatedAreasArray;
  
      updateUserReadiness(ref, value);
    }
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  function handleReadinessChange(ref, intValue){
    console.log('~~~~~ MyReadiness(), handleReadinessChange() - ref is *' + ref + '*, and intValue is *' + intValue + '*');
    // const intValue = parseInt(value);
    // code here to update db via API...
    updateUserReadiness(ref, intValue);
  }

  return ( 
    <div>
      {loaded ? '' : <MobileLoadingDivLayer />} 
      <div className="SomeWrapper">
        <MobileVersionDiv>v{packageJson.version}</MobileVersionDiv>
        <div className="IconAndTitle" style={{ width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center", paddingBottom: "5vw", paddingTop: "0vw" }}>
          <div className="HeaderIconDiv" style={{ width: "100vw", display: "flex", justifyContent: "center", paddingTop: "3vw", paddingBottom: "3vw" }}>
            <Icon style={{ width: "6vw", height: "6vw", margin: "auto" }} src={iconSrc.rocket_black} />
          </div>
          <h4 style={{ width: "100vw", fontSize: "5vw", fontWeight: "bold", textAlign: "center" }}>READINESS</h4>
          <div className="Subtitle" style={{ width: "100vw", fontSize: "2.5vw", textAlign: "center", color: "#777" }}>
            Describe how you feel today
          </div>
        </div>
        <div className="ReadinessInputSection" style={{ cursor: "auto", paddingBottom: "15vw" }}>
          <PickOneOfFive 
            key="readiness_readiness" 
            title="readiness" 
            subtitle_left="not ready" 
            subtitle_right="ready" 
            onChange={handleReadinessChange}
          />
          <PickOneOfFive 
            key="readiness_sleep" 
            title="sleep" 
            subtitle_left="not well" 
            subtitle_right="very well"
            onChange={handleReadinessChange} 
          />
          <PickOneOfFive 
            key="readiness_hydration" 
            title="hydration" 
            subtitle_left="not hydrated" 
            subtitle_right="very hydrated"
            onChange={handleReadinessChange} 
          />
          <PickOneOfFive 
            key="readiness_stress" 
            title="stress" 
            subtitle_left="not stressed" 
            subtitle_right="very stressed"
            onChange={handleReadinessChange} 
          />
          <PickOneOfFive 
            key="readiness_soreness" 
            title="soreness" 
            subtitle_left="not sore" 
            subtitle_right="very sore"
            onChange={handleReadinessChange} 
          />
        </div>

        <div className="BodyMapSection" style={{ cursor: "auto", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <MyBodymap                // only title and areas required for display of Soreness Bodymap
            key={refreshSoreAreas}
            title="Soreness Body Map" 
            areas={soreBodyAreas}
            readOnly={true}
            // content=""
            // inputName=""
            onMapAreaClick={handleSoreAreaClick} // this will have to pass a reference to the soreness or injury array
            // onNoteChange={handleNoteChange}
            isMobile={isMobile}
          />
          <MyBodymap                // only title, areas inputName and content required for display of Injury Bodymap
            key={refreshHurtAreas}
            title="Injury Body Map" 
            areas={hurtBodyAreas}
            content={injuryExplanation}
            inputName="EXPLAIN INJURIES"
            placeholder="Explain injuries here..."
            readOnly={false} // this can be true here in coach-facing Readiness, since it is for display only
            onMapAreaClick={handleHurtAreaClick}// this will have to pass a reference to the soreness or injury array
            onNoteChange={handleNoteChange}
            isMobile={isMobile}
            style={{ marginTop: isMobile ? "-100px" : "-20px" }}
          />
          <div className="ButtonDiv" style={{ width: "100%", paddingBottom: "10vh", paddingTop: "0px", marginTop: "-100px", display: "flex", justifyContent: "center", zIndex: "10000" }}>
            <button 
              onClick={submitUserReadiness} 
              style={{
                cursor: "pointer",
                marginTop: "1vh",
                width: "40vw",
                height: "10vw",
                borderRadius: "2vw",
                fontSize: "4vw",
                color: "#fff",
                backgroundColor: "#516ffe"
              }}
            >
              Save Readiness
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyReadiness;