// Shell.js 

import React, { useEffect, useRef, useState } from 'react';
// import ReactPlayer from 'react-player'; // might not need this...
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  SplitTabContentWrapper2,
  TabSectionLeft2,
  TabSectionRight2,
  TabSectionHeader,
  TabSectionHeaderTitle,
  TabSectionBody 
} from './styled/title';
import { 
  iconSrc,
  logoSrc,
  northsideLogoSrc,
  massageAddictLogoSrc
 } from '../constants';
import { 
  checkUserAuth,
  getUser, 
  getMemberAssessments, 
  getGroup, 
  getGroups, 
  createGroup, 
  updateGroup, 
  explore, 
  addAssociatedItem, 
  removeAssociatedItem, 
  deleteGroup,
  getExercises,
  getMovements,
  getWorkout,
  deleteWorkout
} from '../services'; // getExploreRows or getExploreData
import ShellSidebar from './styled/ShellSidebar';
import Athlete from './styled/Athlete';
// import ShellSidebarButton from './styled/ShellSidebarButton';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import Explore from './styled/Explore';
import UserSection from './styled/UserSection';
import WorkoutSection from './styled/WorkoutSection';
import UserInvite from './styled/UserInvite';
import UserPending from './styled/UserPending';
import GroupEdit from './styled/GroupEdit'; // GroupCreate
import ComplianceDetail from './styled/ComplianceDetail';
import ExerciseEdit from './styled/ExerciseEdit'; // ExerciseCreate
import { differenceInDays } from 'date-fns';


// let fakeArray = [ // used as default array for const groupTreesArray = useRef(fakeArray);
//   { id: "36dc9c28-d150-416b-a5be-0eddd65feeca", name: "District 1", closed: true, kids: ["c3d14a0b-fc06-41db-9e1b-5cef696e355a", "480881dd-b2cc-4d0f-a4d1-97747879b30a", "cec05f42-d4b4-42b0-b358-fb526c91c390"] },
//   { id: "c3d14a0b-fc06-41db-9e1b-5cef696e355a", name: "School A", closed: true, kids: ["13f58845-cb2b-4ca2-a510-99b8d19dbac8", "5d44d052-ced8-405f-a095-58fd54ca0623"] },
//   { id: "13f58845-cb2b-4ca2-a510-99b8d19dbac8", name: "Football", closed: true, kids: ["2dcb3af0-fb9e-428a-aab4-cc1f7820b5c2", "ca35af0f-13ae-4b54-8f90-2d315fb0ce23"] },
//   { id: "2dcb3af0-fb9e-428a-aab4-cc1f7820b5c2", name: "JV", closed: true, kids: [] },
//   { id: "ca35af0f-13ae-4b54-8f90-2d315fb0ce23", name: "Varsity", closed: true, kids: ["5b0370f4-9a04-4935-bafd-55aae139695e"] },
//   { id: "5b0370f4-9a04-4935-bafd-55aae139695e", name: "Linemen", closed: true, kids: [] },
//   { id: "5d44d052-ced8-405f-a095-58fd54ca0623", name: "Baseball", closed: true, kids: ["0f7dc64e-ce00-49b4-aab0-c9d422092df6", "9d6d0057-4dd2-4985-8431-d9766b39df03"] },
//   { id: "0f7dc64e-ce00-49b4-aab0-c9d422092df6", name: "JV", closed: true, kids: [] },
//   { id: "9d6d0057-4dd2-4985-8431-d9766b39df03", name: "Freshmen", closed: true, kids: [] },
//   { id: "480881dd-b2cc-4d0f-a4d1-97747879b30a", name: "School B", closed: true, kids: ["8149d7a6-b183-4b43-b404-5628308b7305"] },
//   { id: "8149d7a6-b183-4b43-b404-5628308b7305", name: "Basketball", closed: true, kids: ["f1c1cd1e-11bd-4d97-85a4-59c72056a159", "6109953e-a454-4320-9d57-9ae03118adeb"] },
//   { id: "f1c1cd1e-11bd-4d97-85a4-59c72056a159", name: "JV", closed: true, kids: [] },
//   { id: "6109953e-a454-4320-9d57-9ae03118adeb", name: "Varsity", closed: true, kids: [] },
//   { id: "cec05f42-d4b4-42b0-b358-fb526c91c390", name: "School C", closed: true, kids: [] }
// ];

// let fakeParentsArray = [
//   { id: "36dc9c28-d150-416b-a5be-0eddd65feeca", name: "District 1", closed: true, kids: [
//     { 
//       id_guid: "c3d14a0b-fc06-41db-9e1b-5cef696e355a",
//       name_string: "School A",
//       has_children_boolean: true,
//       closed: false
//     },
//     {
//       id_guid: "480881dd-b2cc-4d0f-a4d1-97747879b30a", 
//       name_string: "School B",
//       has_children_boolean: true,
//       closed: false
//     },
//     {
//       id_guid: "cec05f42-d4b4-42b0-b358-fb526c91c390",
//       name_string: "School C",
//       has_children_boolean: false,
//       closed: false
//     }] 
//   },
//   { id: "c3d14a0b-fc06-41db-9e1b-5cef696e355a", name: "School A", closed: true, kids: [
//     { 
//       id_guid: "13f58845-cb2b-4ca2-a510-99b8d19dbac8",
//       name_string: "Football",
//       has_children_boolean: true,
//       closed: true
//     },
//     {
//       id_guid: "5d44d052-ced8-405f-a095-58fd54ca0623",
//       name_string: "Baseball",
//       has_children_boolean: true,
//       closed: true
//     }]
//   },
//   { id: "13f58845-cb2b-4ca2-a510-99b8d19dbac8", name: "Football", closed: true, kids: [
//     { 
//       id_guid: "2dcb3af0-fb9e-428a-aab4-cc1f7820b5c2", 
//       name_string: "JV",
//       has_children_boolean: false,
//       closed: true
//     },
//     {
//       id_guid: "ca35af0f-13ae-4b54-8f90-2d315fb0ce23",
//       name_string: "Varsity",
//       has_children_boolean: true,
//       closed: true
//     }]
//   },
//   { id: "2dcb3af0-fb9e-428a-aab4-cc1f7820b5c2", name: "JV", closed: true, kids: [] },
//   { id: "ca35af0f-13ae-4b54-8f90-2d315fb0ce23", name: "Varsity", closed: false, kids: [
//     { 
//       id_guid: "5b0370f4-9a04-4935-bafd-55aae139695e",
//       name_string: "Linemen",
//       has_children_boolean: false,
//       closed: true
//     }]
//   },
//   { id: "5b0370f4-9a04-4935-bafd-55aae139695e", name: "Linemen", closed: true, kids: [] },
//   { id: "5d44d052-ced8-405f-a095-58fd54ca0623", name: "Baseball", closed: true, kids: [
//     {
//       id_guid: "0f7dc64e-ce00-49b4-aab0-c9d422092df6", 
//       name_string: "JV",
//       has_children_boolean: false,
//       closed: true
//     },
//     {
//       id_guid: "9d6d0057-4dd2-4985-8431-d9766b39df03",
//       name_string: "Freshmen",
//       has_children_boolean: false,
//       closed: true
//     }]
//   },
//   { id: "0f7dc64e-ce00-49b4-aab0-c9d422092df6", name: "JV", closed: true, kids: [] },
//   { id: "9d6d0057-4dd2-4985-8431-d9766b39df03", name: "Freshmen", closed: true, kids: [] },
//   { id: "480881dd-b2cc-4d0f-a4d1-97747879b30a", name: "School B", closed: true, kids: [
//     {
//       id_guid: "8149d7a6-b183-4b43-b404-5628308b7305",
//       name_string: "Basketball",
//       has_children_boolean: false,
//       closed: true
//     }]
//   },
//   { id: "8149d7a6-b183-4b43-b404-5628308b7305", name: "Basketball", closed: true, kids: [] },
//   // { id: "8149d7a6-b183-4b43-b404-5628308b7305", name: "Basketball", closed: true, kids: [
//   //   {
//   //     id_guid: "f1c1cd1e-11bd-4d97-85a4-59c72056a159", 
//   //     name_string: "JV",
//   //     has_children_boolean: false,
//   //     closed: true
//   //   },
//   //   {
//   //     id_guid: "6109953e-a454-4320-9d57-9ae03118adeb",
//   //     name_string: "Varsity",
//   //     has_children_boolean: false,
//   //     closed: true
//   //   }] 
//   // },
//   // { id: "f1c1cd1e-11bd-4d97-85a4-59c72056a159", name: "JV", closed: true, kids: [] },
//   // { id: "6109953e-a454-4320-9d57-9ae03118adeb", name: "Varsity", closed: true, kids: [] },
//   { id: "cec05f42-d4b4-42b0-b358-fb526c91c390", name: "School C", closed: true, kids: [] }
// ];

const emptyWorkout = {
  name_string: "",
  note_string: "",
  interval_integer: 1,
  interval_string: "",
  exercise_array: []
};

const fake_results = { // used in 8 different useState() Explore resuslts declarations (allResults, groupResultsL, ... etc. ln 295)
	group_array: [
		{
			id_guid: "c3d14a0b-fc06-41db-9e1b-5cef696e355a",
			group_id_guid: "c3d14a0b-fc06-41db-9e1b-5cef696e355a",
			name_string: "School A",
			has_children_boolean: true
		},
		{
			id_guid: "480881dd-b2cc-4d0f-a4d1-97747879b30a",
			group_id_guid: "480881dd-b2cc-4d0f-a4d1-97747879b30a",
			name_string: "School B",
			has_children_boolean: true
		}
	],
	user_array: [
		{
			id_guid: "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
			user_id_guid: "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
			user_group_id_guid: "f31480b8-64d6-43f2-b82d-71335c3a78d8",
			group_user_id_guid: null,
			email_string: "coach@physmodo.com",
			first_name_string: "John",
			last_name_string: "Physmodo"
		}
	],
	workout_array: [
    {
      id_guid: "fake_workout_id_guid",
      name_string: "Fake Shoulder Workout",
      assigned_by: "Coach Menter"
    },
    {
      id_guid: "fake_workout_id_guid",
      name_string: "Fake Shoulder Workout",
      assigned_by: "Physmodo Assessment Results"
    },
  ]
}


const defaultShow = ['sidebar','icon_l_assessment','title_l_Welcome_To...','icon_r_assessment','title_r_The_Dashboard']; // this works, with WIP <Explore> 
const MtGroup = { id_guid: "", name_string: "", note_string: "", parent_group_id_guid: "", user_id_guid: localStorage.getItem('loggedInUser')};


const Shell = ({
  logout, deviceInfo, isMobile, deepLink
}) => {
  const topLogoSrcIndex = useRef(0);
  const isAthlete = localStorage.getItem('athlete') === 'true' && localStorage.getItem('coach') !== 'true' && localStorage.getItem('enterprise') !== 'true' && localStorage.getItem('administrator') !== 'true';
  const mounted = useRef(true);
  const parentsArray = useRef([]);
  // const groupTreesArray = useRef(fakeArray);
  const groupTreesArray = useRef([]);
  // const [groupLevel, setGroupLevel] = useState(0);
  const [groupObjArray, setGroupObjArray] = useState([]);
  const [exercises, setExercises] = useState([]); // make a useEffect() to fetch these once here in Shell (as opposed to in WorkoutEdit)
  const [alreadyFetchedGroups, setAlreadyFetchedGroups] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // const [config, setConfig] = useState('home'); // how should this be used?
  const [sidebarBtn, setSidebarBtn] = useState('');
  // show is an array of strings of currently active component keys ['update_group_l','explore_group_l','new_group_r']
  const [show, setShow] = useState(defaultShow);
  const [group, setGroup] = useState(MtGroup); // this will be set to a groupObj by the handleGroupChange() method
  const [groupR, setGroupR] = useState(MtGroup); // this will be set to a groupObj by the handleGroupChange() method
  let mtGroup = { id_guid: "", name_string: "", note_string: "", parent_group_id_guid: "", user_id_guid: localStorage.getItem('loggedInUser') };
  // let currentParentId ="not yet set!!";
  const [parentId, setParentId] = useState('');

  const [refresh, setRefresh] = useState('');
  const [refreshSB, setRefreshSB] = useState('');
  const [refreshWorkouts, setRefreshWorkouts] = useState(''); 
  const [refreshExercises, setRefreshExercises] = useState('');
  const [refreshMovements, setRefreshMovements] = useState('');
  const [refreshUser, setRefreshUser] = useState('');

  const [userId, setUserId] = useState("6b6886f3-9460-4d41-9820-c335c4be72e6"); // this will be set when there is a user to look at!
  const [workout, setWorkout] = useState(emptyWorkout);
  const [exercise, setExercise] = useState(null);
  const [movement, setMovement] = useState(null);

  const [idGroupL, setIdGroupL] = useState(null);
  // const [idGroupAddL, setIdGroupAddL] = useState(null);

  const [idGroupR, setIdGroupR] = useState(null);
  // const [idGroupAddR, setIdGroupAddR] = useState(null);

  const [idUser, setIdUserR] = useState(null);
  // const [idUserAddR, setIdUserAddR] = useState(null);

  //idWorkout
  const [idWorkout, setIdWorkout] = useState(null);

  const [activeUserTabIndex, setActiveUserTabIndex] = useState(0);
  const [activeWorkoutTabIndex, setActiveWorkoutTabIndex] = useState(0);
  
  let leftTitleString = "Welcome To";
  let rightTitleString = "The Dashboard";
  let leftTitleIconSrc = iconSrc.assessment;
  let rightTitleIconSrc = iconSrc.assessment;

  setTitles(show);
// nathan@physmodo? aba773bb-2b97-4dab-b7f2-77a45966a810
// spamdies-aa? f44074d9-8c9f-4960-a063-bfc6a415524a
// spamdies-cf? 40f8b3f5-8128-4b7e-acbd-934d1a44e0e7
// spamdies-test? 738135e9-b171-4105-9bcb-591145345fad
// gsmith@websmith.us 6b6886f3-9460-4d41-9820-c335c4be72e6 
// coach@physmodo ce8228fd-a3ab-4ef5-8f11-8ee469095b1b

  const explore_ = ['icon_l_search','title_l_Explore','explore_l','icon_r_assessment','title_r_The_Dashboard'];
  const explore_group = ['icon_l_search','title_l_Explore','explore_l','icon_r_group','title_r_GroupName','group_edit_r','explore_group_r'];
  const group_ = ['icon_l_group','title_l_GroupName','group_edit_l','explore_group_l','icon_r_assessment','title_r__'];
  
  const group_user = ['icon_l_group','title_l_GroupName','group_edit_l','explore_group_l','icon_r_user','title_r_UserName','user_r'];
  const group_group = ['icon_l_group','title_l_GroupName','group_edit_l','explore_group_l','icon_r_group','title_r_GroupName','explore_group_r'];
  // const group_group = ['icon_l_group','title_l_GroupName', 'explore_l','icon_r_group','title_r_GroupName', 'group_edit_r', 'explore_group_r'];

  const group_add = ['icon_l_group','title_l_GroupName','group_edit_l','explore_group_l','icon_r_group','title_r_Add_to_GroupName','explore_add_to_group_r'];
  const group_add_new_group = ['icon_l_group','title_l_GroupName','group_edit_l','explore_group_l','icon_r_group','title_r_Create_Group_in_GroupName','group_create_r'];
  const group_add_new_users = ['icon_l_group','title_l_GroupName','group_edit_l','explore_group_l','icon_r_user','title_r_Invite_Users_to_GroupName','user_invite_r'];
  
  const user_ = ['icon_l_search','title_l_Explore','explore_l','icon_r_user','title_r_UserName','user_r'];
  const user_pending_explore = ['icon_l_search','title_l_Explore','explore_l','icon_r_user','title_r_UserName_is_Pending','user_pending_r'];
  const user_pending_explore_group = ['icon_l_group','title_l_GroupName','group_edit_l','explore_group_l','icon_r_user','title_r_UserName_is_Pending','user_pending_r'];
  const user_group = ['icon_l_group','title_l_GroupName','explore_group_l','icon_r_user','title_r_UserName','user_r'];
  const user_pending_staff_explore = ['icon_l_search','title_l_Explore','explore_l','icon_r_staff','title_r_UserName_is_Pending','user_pending_r'];
  const user_pending_staff_explore_group = ['icon_l_group','title_l_GroupName','explore_group_l','icon_r_staff','title_r_UserName_is_Pending','user_pending_r'];

  const user_add = ['icon_l_user','title_l_UserName','user_l','icon_r_user','title_r_Adding_to_User','explore_add_to_user_r'];
  //const user_add = ['icon_l_user','title_l_UserName','user_l','icon_r_user','title_r_Adding_to_User','explore_add_to_user_r'];
  
  const user_asessments = ['icon_l_search','title_l_GroupName','explore_l','icon_r_user','title_r_UserName','user_r'];
  const user_readiness = ['icon_l_search','title_l_GroupName','explore_l','icon_r_user','title_r_UserName','user_r'];
  // const user_compliance = ['icon_l_search','title_l_GroupName','explore_l','icon_r_user','title_r_UserName','user_r'];
  const user_compliance = ['icon_l_user','title_l_UserName','user_l','icon_r_user','title_r_Compliance_for_WorkoutName','compliance_r'];
  // const user_user = ['icon_l_search','title_l_GroupName','explore_l','icon_r_user','title_r_User','user_r'];

  const exercise_ = ['icon_l_exercise','title_l_Exercises_','explore_exercise_l','icon_r_exercise','title_r__'];
  const exercise_new = ['icon_l_exercise','title_l_Exercises_','explore_exercise_l','icon_r_exercise','title_r_Create_Exercise','exercise_create_r'];
  const exercise_edit = ['icon_l_exercise','title_l_Exercises_','explore_exercise_l','icon_r_exercise','title_r_ExerciseName','exercise_edit_r'];
  
  const workout_ = ['icon_l_workout','title_l_Workouts_','explore_workout_l','icon_r_workout','title_r__'];
  const workout_add = ['icon_l_workout','title_l_WorkoutName','workout_section_l','icon_r_workout','title_r_Add_to_WorkoutName','explore_add_to_workout_r'];
  // need to have the state of the tabIndex in workout_section saved somewhere, so we can show the proper tab as open...
  const workout_new = ['icon_l_workout','title_l_Workouts_','explore_workout_l','icon_r_workout','title_r_Create_Workout','workout_create_r']; // TODO: clean this up to reflect WorkoutSection use
  const workout_edit = ['icon_l_workout','title_l_Workouts_','explore_workout_l','icon_r_workout','title_r_WorkoutName','workout_edit_r']; // TODO: clean this up to reflect WorkoutSection use
  const workout_new_adding_exercise = ['icon_l_workout','title_l_Create_Workout','workout_create_l','icon_r_workout','title_r_Add_Exercise_to_WorkoutName','explore_movement_r']; // TODO: clean this up to reflect WorkoutSection use
  const workout_edit_adding_exercise = ['icon_l_workout','title_l_WorkoutName','workout_edit_l','icon_r_workout','title_r_Add_Exercise_to_WorkoutName','explore_movement_r']; // TODO: clean this up to reflect WorkoutSection use
  
  // const workout_ = ['icon_l_workout','title_l_Workouts_','workout_l','icon_r_workout','title_r__'];
  // const workout_new = ['icon_l_workout','title_l_Workouts_','workout_l','icon_r_workout','title_r_Create_Workout','workout_create_r'];
  // const workout_edit = ['icon_l_workout','title_l_Workouts_','workout_l','icon_r_workout','title_r_WorkoutName','workout_edit_r'];

  
  // more configs for workouts?

  // <Explore> section START =========================================================================================
  //  State: 
  // do we need to persist multiple versions of exploreState? ie, an array of exploreStates? 
  // so when we assign it to the state prop of an instance: <Explore state={exploreStates["group"]}, or "group_add"? or ...?
  const [allResults, setAllResults] = useState({});
  const [groupResultsL, setGroupResultsL] = useState({});
  const [groupResultsR, setGroupResultsR] = useState({});
  const [groupAddResults, setGroupAddResults] = useState({});
  const [userResults, setUserResults] = useState({});
  const [userAddResults, setUserAddResults] = useState({});
  const [workoutResults, setWorkoutResults] = useState({});
  const [workoutAddResults, setWorkoutAddResults] = useState({});
  const [workoutTabResults, setWorkoutTabResults] = useState({});
  const [workoutTabAddResults, setWorkoutTabAddResults] = useState({fake_results});
  const [exerciseResults, setExerciseResults] = useState({fake_results});
  const [movementResults, setMovementResults] = useState({fake_results});
  const [group286, setGroup286] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoTitle, setVideoTitle] = useState('Placeholder Video Title');
  const logosArray = [logoSrc, northsideLogoSrc, massageAddictLogoSrc]; // so far...
  const [topLogoSrc, setTopLogoSrc] = useState(logoSrc);
  const [complianceUserId, setComplianceUserId] = useState('no-compliance-user-id');
  const [complianceWorkoutId, setComplianceWorkoutId] = useState('no-compliance-workout-id');
  const [complianceWorkoutAssignedBy, setComplianceWorkoutAssignedBy] = useState('no-compliance-workout-assigned-by');
  const [complianceId, setComplianceId] = useState('no-compliance-id');

  function handleLogoClick() {
    const arrayLength = logosArray.length;
    console.log('~~~~~ Shell(), handleLogoClick() - topLogoSrcIndex is ' + topLogoSrcIndex.current + ', and logosArray.length is ' + arrayLength);
    
    topLogoSrcIndex.current += 1;
    console.log('~~~~~ Shell(), handleLogoClick() - after +1 topLogoSrcIndex is ' + topLogoSrcIndex.current);

    if (topLogoSrcIndex.current === arrayLength) { 
      console.log('~~~~~ Shell(), handleLogoClick() - topLogoSrcIndex (' + topLogoSrcIndex.current + ') is === arrayLength (' + arrayLength + ') - setting it to 0');
      topLogoSrcIndex.current = 0; 
    }
    // topLogoSrcIndex = (topLogoSrcIndex < arrayLength - 1) ? topLogoSrcIndex + 1 : 0;

    console.log('~~~~~ Shell(), handleLogoClick() - NEW topLogoSrcIndex is ' + topLogoSrcIndex.current);
    
    setTopLogoSrc(logosArray[topLogoSrcIndex.current]);
    // setTopLogoSrc(logosArray[0]);

    // if (localStorageExists) {
    //   if (localStorage.getItem('theme') !== "alternate") {
    //     localStorage.setItem('theme', '');
    //     window.location.reload();
    //   } else {
    //     localStorage.setItem('theme', 'alternate');
    //     window.location.reload();
    //   }
    // }
    // else
    // {
    //   console.log("~~~~~ handkeLogoClick() - localStorage does not Exist :-("); // TODO: possibly annunciate this? "get a modern browser"?
    // }
  }

  console.log('~~~~~ Shell.js, isAthlete is ' + isAthlete);

  function handleUserTabChange(newIndex){
    console.log('~~~~~ Shell(), handleUserTabChange() - newIndex is *' + newIndex + '*');
    setActiveUserTabIndex(newIndex);
  }

  function setPane(save_side, new_show){ // replace only one pane of current config, leaving other alone
    console.log('~~~~~ Shell(), setPane() - save_side is ' + save_side + ', and new_show is ' + new_show);
    // save_side would be _l or _r, and show would be the definition of the show to transition to
    // the idea would be to replace the elements in the show we're transitioning to with the show elements
    // of the CURRENT show for the save_side
    // Example if the current show is:
    // explore (which is ['icon_l_search','title_l_Explore','explore_l','icon_r_assessment','title_r_The_Dashboard']),
    // and the new show is:
    // workout_edit (which is ['icon_l_workout','title_l_Workouts_','explore_workout_l','icon_r_workout','title_r_WorkoutName','workout_edit_r'])
    // then setPane('_l', workout_edit) should perform:
    // setShow(['icon_l_search','title_l_Explore','explore_l','icon_r_workout','title_r_WorkoutName','workout_edit_r'])
    
    const replaceSide = save_side === '_l' ? '_r' : '_l';
    console.log('~~~~~ Shell(), setPane() - replaceSide is ' + replaceSide);
    const newElements = new_show.filter((element) => element.includes(replaceSide));
    console.log('~~~~~ Shell(), setPane() - newElements is ' + newElements);
    const newShowArray = show.filter((element) => element.includes(save_side)).concat(newElements);
    console.log('~~~~~ Shell(), setPane() - newShowArray is ' + newShowArray);
    setShow(newShowArray);
  }

  function handleSearch(refr, like_string, group_id, user_id, workout_id, group_boolean, user_boolean, workout_boolean, inverse_boolean){ // should we operate only with data we get from the onSearch fn?
    console.log('~~~~~~~~~~ Shell.js, handleSearch(' + refr + ', ' + like_string + ', ' + group_id + ', ' + user_id + ', ' + workout_id + ', ' + group_boolean + ', ' + user_boolean + ', ' + workout_boolean + ', ' + inverse_boolean + ')');
    // do we need the key or id for the explore instance we want to present results to, so we can update the correct resultsState?
    // how do we distinguidh here between a search of everything and a search inside a group, or inside a user, or inside a workout?
    const groupOk = group_id && group_id.length > 20;
    const userOk = user_id && user_id.length > 20;
    const workoutOk = workout_id && workout_id.length > 20;
    // const allSearch = !groupOk && !userOk;
    const workoutTab = refr.includes('workout_tab'); // what about workout_add?
    const workouts = refr.includes('workout') && !refr.includes('tab') && !refr.includes('_add_'); // what about workout_add?
    // const workouts = refr.includes('workout') && !refr.includes('tab') && !refr.includes('_add'); // attempt with workout_add
    const addToWorkout = refr.includes('workout_add'); // maybe change to some piece of actual refr, which is workout_add_r?
    const exercises = refr.includes('exercise');
    const movements = refr.includes('movement');

    const isLeft = refr.includes('_l');
    const isRight = refr.includes('_r');
    const isInverted = refr.includes('add');

    // we need to know what kind of search this was, in order to know where to store the results that were returned
    // if it was a plain explore search, with no valid group_id or user_id, then we would use allResults, setAllResults
    
    // if it was a group search with a valid group_id, we would use groupResults, setGroupResults
    // if adding is asserted, or inverse_boolean is asserted, and there is a valid group_id, we use groupAddResults, setGroupAddResults
    
    // if it was a user search with a valid user_id, we would use userResults, setUserResults
    // if adding is asserted, or inverse_boolean is asserted, and there is a valid user_id, we use userAddResults, setUserAddResults

    // if it was a workout search with a valid workout_id, we would use workoutResults, setWorkoutResults
    // if adding is asserted, or inverse_boolean is asserted, and there is a valid workout_id, we use workoutAddResults, setWorkoutAddResults

    // if it was a workoutTab search with a valid workout_id, we would use workoutTabResults, setWorkoutTabResults
    // if adding is asserted, or inverse_boolean is asserted, and there is a valid workout_id, we use workoutTabAddResults, setWorkoutTabAddResults


    // query parts:
    // like_string      // passed back from the explore instance with the call that brought us here
    // user_id_guid     // if provided, does this imply the user is our context? for group context, we should use exploreGroup()
    // group_boolean    // from activeFilters
    // user_boolean     // from activeFilters
    // workout_boolean  // from activeFilters
    // inverse_boolean  // << intended to be used when exploring to add to a group or a user

    // const user_boolean = activeFilters.includes("user");
    // const group_boolean = activeFilters.includes("group");
    // const workout_boolean = activeFilters.includes("workout");

    // const contextType = exploreState.exploreWhat;
    // const contextId = group.id_guid; // TODO: this will only for for contextType "group"
    // console.log('~~~~~ Shell(), handleSearch(' + like_string + ', ' + group_boolean + ', ' + user_boolean + ', ' + workout_boolean + ')');
    //exploreGroup(like_string, group_id_guid, group_boolean, user_boolean, workout_boolean)

    // explore(like_string, group_id, user_id, group_boolean, user_boolean, workout_boolean)  // if no group_id or user_id, this is exploreAll
                                                                                              // with group_id it is exploreGroup
                                                                                              // with user_id it is exploreUser

    //exploreAll(group_boolean, user_boolean, workout_boolean)
    // exploreAll(like_string, group_id, user_id, group_boolean, user_boolean, workout_boolean) // TODO: this is not saved in state anywhere...
    console.log('~~~~~~~~~~ Shell(), handleSearch() - like_string = *' + like_string + '*, group_id = *' + group_id + '*, and addToWorkout is *' + addToWorkout + '*');

    if (exercises){
      getExercises(like_string)
      .then(itemsResp => {
        // fetchedOptionItems = true; 
        if(mounted.current) {
          let items = Array.isArray(itemsResp) ? itemsResp : itemsResp.data; // accept response as [exercise], or as { data: [exercise] }
          // sort Exercises alphabetically
          if (items) {
            items.sort((a, b) => {
              const nameA = a.name_string.toUpperCase(); // ignore upper and lowercase
              const nameB = b.name_string.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            });
        
            console.log('~~~~~ Shell.js, handleSearch() - getExercises() - items[0].name_string is *' + items[0].name_string + '*');

            setExerciseResults({ exercise_array: items });
          }

          if (!items) {
            console.log('~~~~~ Shell.js, handleSearch() - getExercises() - items is currently undefined');
          } else {
            console.log('~~~~~ Shell.js, handleSearch() - getExercises() - ' + items.length + ' Exercises were returned');
          
            return items[0]
          }
        }
      })

    } else if (movements){
      getMovements(like_string)
      .then(itemsResp => {
        // fetchedOptionItems = true; 
        if(mounted.current) {
          let items = Array.isArray(itemsResp) ? itemsResp : itemsResp.data; // accept response as [exercise], or as { data: [exercise] }
          // sort Exercises alphabetically
          if (items) {
            items.map(item => item.name_string = item.title_string);
            items.sort((a, b) => {
              const nameA = a.name_string.toUpperCase(); // ignore upper and lowercase
              const nameB = b.name_string.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            });
        
            console.log('~~~~~ Shell.js, handleSearch() - getMovements() - items[0].name_string is *' + items[0].name_string + '*');

            setMovementResults({ movement_array: items, like_string: like_string });
          }

          if (!items) {
            console.log('~~~~~ Shell.js, handleSearch() - getMovements() - items is currently undefined');
          } else {
            console.log('~~~~~ Shell.js, handleSearch() - getMovements() - ' + items.length + ' Movements were returned');
          
            return items[0]
          }
        }
      })

    } else {
      console.log('~~~~~~~~~~ Shell(), handleSearch() - like_string = *' + like_string + '*, group_id = *' + group_id + '*, workouts is *' + workouts + '* and addToWorkout is *' + addToWorkout + '*');
      explore(
        like_string,                                                // provided search term
        group_id,                                                   // group_id of group context for search
        user_id,                                                    // user_id of user context for search
        workout_id,                                                 // workout_id of workout context for search
        workouts ? false : group_boolean,                           // if workouts is asserted, do not return groups - this implies workouts is asserted to get a list of workouts
        workouts ? false : user_boolean,                            // if workouts is asserted, do not return users - this implies workouts is asserted to get a list of workouts
        workouts ? true : addToWorkout || workoutTab ? false : workout_boolean,   // if workouts is asserted, return workouts unless addToWorkout or workout_boolean is asserted 
        isInverted
      )
      .then(item => {
        const results = item;
        
        if (groupOk && results.inverse_boolean){ // this might be a group_add search
          if (results.group_array) {
            console.log('~~~~~~~~~~ Shell(), handleSearch() - userOk && results.inverse_boolean, results.group_array.length is ' + results.group_array.length);
          }
          if (results.user_array) {
            console.log('~~~~~~~~~~ Shell(), handleSearch() - userOk && results.inverse_boolean, results.user_array.length is ' + results.user_array.length);
          }          
          if (results.workout_array) {
            console.log('~~~~~~~~~~ Shell(), handleSearch() - userOk && results.inverse_boolean, results.workout_array.length is ' + results.workout_array.length);
          }
          setGroupAddResults(results);
        } else if (userOk && results.inverse_boolean){ // user_add search
          if (results.group_array) {
            console.log('~~~~~~~~~~ Shell(), handleSearch() - userOk && results.inverse_boolean, results.group_array.length is ' + results.group_array.length);
          }
          if (results.workout_array) {
            console.log('~~~~~~~~~~ Shell(), handleSearch() - userOk && results.inverse_boolean, results.workout_array.length is ' + results.workout_array.length);
          }
          setUserAddResults(results);
        } else if (groupOk && isLeft){
          console.log('~~~~~~~~~~ Shell(), handleSearch() - groupOk && isLeft');
          setGroupResultsL(results);
        } else if (groupOk && isRight){
          console.log('~~~~~~~~~~ Shell(), handleSearch() - groupOk && isRight');
          setGroupResultsR(results);
        } else if (userOk){
          console.log('~~~~~~~~~~ Shell(), handleSearch() - userOk');
          setUserResults(results);
        } else if (workouts){
          console.log('~~~~~~~~~~ Shell(), handleSearch() - workouts');
          setWorkoutResults(results);
        } else if (workoutTab){
          console.log('~~~~~~~~~~ Shell(), handleSearch() - workoutTab');
          setWorkoutTabResults(results);
        } else if (addToWorkout){
          console.log('~~~~~~~~~~ Shell(), handleSearch() - addToWorkout');
          // setWorkoutTabResults(results); // TODO: remove
          setWorkoutTabAddResults(results); // should be setWorkoutAddResults, since this is not showing in the WorkoutsSection ExploreTab...?
        } else {
          console.log('~~~~~~~~~~ Shell(), handleSearch() - setAllResults');
          setAllResults(results);
        }  
      })
    }
  }

  function handleAddNewClick(refr){
    console.log('~~~~~ Shell(), handleAddNewClick((' + refr + ')');
    if (refr.includes('group')){
      if (refr.includes('_r')){
        // move the explore_group_r instance to the left side
        setGroup(groupR); // copies the groupObj for explore_group_r over to the one used for group_explore_l
        setIdGroupL(groupR.id_guid); // changes the id for the explore_group_r search context
        setParentId(groupR.id_guid); // sets parentId so that if a new group is added, it will be added where it should be
        // check to see if group highlighted in sidebar needs to change, it should be the parent of the new groupObj in group
        // so the selected btnId in the sidebar should be 'group_[group.parent_group_id_guid] '
      /*
              {(show.includes("explore_add_to_group_r") && true) &&
                <Explore 
                  className="explore_add_to_group_r"
                  refr="group_add_r" 
                  scopeId={idGroupAddR}
    */
        setShow(group_add);
      } else {
        // alert('Did not expect to get here - handleAddNewClick() - refr is ' + refr); WHY NOT? this just means the explore_group is on the left...
        setShow(group_add);
      }
    } else if (refr.includes('user')){
      console.log('~~~~~ Shell(), handleAddNewClick() - this is an explore user addNewClick');
      // TODO: code to deselect whatever group or explore (or ??) button was selected in the Sidebar

      // code to update the scopeId used over there, or can user_user explore have it's own private scopeId & results?

      // code to set the appropriate scopeId for the right column explore 

      // code to move UserSection to the left column, and open explore_user_add in the right column (explore_user_add_r)
      setShow(user_add);
    } else if (refr.includes('workout')){
      console.log('~~~~~ Shell(), handleAddNewClick() - this is an explore workout addNewClick');
      // code to move WorkoutSection to the left pane, with Explore tab open, and open explore_workout_add in the right pane (explore_add_to_workout_r)
      setShow(workout_add); // correctly moves WorkoutSection to left pane with Explore tab still open
                            // correctly adds proper header to right pane 'Adding to [WorkoutName]'
                            // correctly shows an Explore instance, with refr='workout_add_r'
                            // TODO: stop Search (performed by handleSearchClick from adding workout_boolean=true
      setRefreshWorkouts(uuidv4());

    } else if (refr.includes('exercise')){
      // alert('Did not expect to get here yet - handleAddNewClick() - refr is ' + refr);
      setShow(exercise_new);
    } else {
      alert('Did not expect to get here - handleAddNewClick() - refr is ' + refr);
    }
  }

  function handleRowClick(refr, type, id, name, isStaff = false, groupId = null, readiness = {}){
    // alert('handleRowClick in Shell! - type is ' + type + ', and id is ' + id + ', and name is ' + name);
    console.log('~~~~~ Shell(), handleRowClick(refr: ' + refr + ', type: ' + type + ', id: ' + id + ', name: ' + name + ', isStaff: ' + isStaff + ', groupId: ' + groupId + ')');
    if (type.includes('group') && !type.includes('_group')){
      console.log('~~~~~ Shell(), handleRowClick() - type was group');
      // right here we may have a requirement for 2 simultaneous groupObjects :-( , unless we can use the explore_l results to save the required state of
      // the group we were looking inside of when we clicked the subgroup that is about to open in explore_group_r (group_explore_r?)
      // should we have a piece of internal state in explore that contains the passed-in group_id, or can we just update/refresh that from results?
      // get the groupObj for the subgroup we are about to explore in the right tab
      getGroup(id)
      .then(groupObject => {
        let thisGroupObj = groupObject;
        console.log('~~~~~ Shell(), handleRowClick() - child Group Name is ' + thisGroupObj.name_string);
        setGroupR(thisGroupObj);
      });
      
      // let tempGroupSearch = Object.assign({}, groupSearch); // this clones the current groupSearch as tempGroupSearch
      // tempGroupSearch['group_id'] = id;
      // setGroupSearch(tempGroupSearch); // autoSearch={groupSearch} for explore_group_l instance down in Shell render code
      // let tempGroupAddSearch = Object.assign({}, groupAddSearch); // this clones the current groupAddSearch as tempGroupAddSearch
      // tempGroupAddSearch['group_id'] = id;
      // setGroupAddSearch(tempGroupAddSearch); // autoSearch={groupAddSearch} for explore_add_to_group_r instance down in Shell render code
      setIdGroupR(id);

      if (refr.includes('user_add_r')){
        setPane('_l', explore_group); // leave left hand pane alone, set right pane the same as for explore_group
      } else if (refr.includes('group_l')){
          setPane('_l', explore_group); // leave left hand pane alone, set right pane the same as for explore_group
      } else {
        setShow(explore_group); // this was what we had...
        // setShow(group_group); // from group_explore we go to group_group...? // type === group here
      }
    }
    
    // types matching 'user_': // "user_pending_user", "user_pending_group", "user_approved", "user_not"
    if (type.includes('user_')){
      if (type.includes('_not')) { 
        // alert('Somehow a user with no approvals at all made it into the list');
        return ;
      }
      // set user id that UserSection will use as scopeId for explore_user_r (is it really explore_user_r, or user_r_explore_user ?)
      setIdUserR(id); //

      let fakeUser = { // TODO: actually fetch the user, and bring in their data?
        id_guid: id,
        email_string: name,
        staff_boolean: isStaff,
        group_approval_boolean: type.includes('pending_group') ? false : true,
        user_approval_boolean: type.includes('pending_user') ? false : true,
        readiness: readiness
      }
      setUser(fakeUser);
      
      if (type.includes('_pending_')){ // pending user case
        console.log('~~~~~ Shell(), handleRowClick() - clicked row was a pending User ');
        if (show.includes('explore_group_l')){
          setShow(user_pending_explore_group); // the Explore instance we came from was a Group Explore, with GroupEdit above it
        } else {
          setShow(user_pending_explore); // we came from a regular Explore Group instance, with no GroupEdit
        }
      } else { // not pending user case
        console.log('~~~~~ Shell(), handleRowClick() - clicked row was NOT a pending User ');
        if (show.includes('explore_group_l')){ 
          setShow(group_user); // the Explore instance we came from was a Group Explore, with GroupEdit above it
        } else {
          setShow(user_); // we came from a regular Explore Group instance, with no GroupEdit
        }
      }
      
    }

    if (type.includes('workout')){
      console.log('~~~~~ Shell(), handleRowClick() - clicked row was a Workout ');
      // we want to handle the case where workout explore is going to open a workout viewedit instance as workout_edit_r              OK
      // we will want a Shell state variable for the workout in viewEdit const [workout, setWorkout] = useEffect(null);               DONE
      // we will pull the workout object from workoutResultsArray.workout_array where arrayObj.id_guid === incoming id above ^^^
      // and then we will save that workoutObj in the workout state const using setWorkout(workoutObj)
      // the workout_edit instance can be populated from workout in Shell state by passing workout down to WorkoutEdit as a prop
      let workouts;
      let workoutObj;
      setIdWorkout(id);
      setActiveWorkoutTabIndex(0);

      getWorkout(id)
      .then(workoutResp => {
        if(mounted.current) {
          setWorkout(workoutResp);
          // loadingMyWorkout = false; // TODO: might not be required, since getWorkoutInfo() is not fired automatically like useEffect() 
          // showWorkout(workout_id)
          setPane('_l', workout_edit);
          setRefreshWorkouts(uuidv4());
        }
      })
      
      // if (workoutResults && workoutResults.workout_array && workoutResults.workout_array.length > 0){
      //   console.log('~~~~~ Shell(), handleRowClick(), type includes workout - workoutResults.workout_array exists, and workout_array[0].id_guid is ' + workoutResults.workout_array[0].id_guid);
      //   workouts = workoutResults.workout_array;
      // } else if (groupResultsL && groupResultsL.workout_array && groupResultsL.workout_array.length > 0){
      //   console.log('~~~~~ Shell(), handleRowClick(), type includes workout - groupResultsL.workout_array exists');
      //   workouts = groupResultsL.workout_array;
      // } else if (groupResultsR && groupResultsR.workout_array && groupResultsR.workout_array.length > 0){ 
      //   console.log('~~~~~ Shell(), handleRowClick(), type includes workout - groupResultsR.workout_array exists');
      //   workouts = groupResultsR.workout_array;
      // } else if (userResults && userResults.workout_array && userResults.workout_array.length > 0){
      //   console.log('~~~~~ Shell(), handleRowClick(), type includes workout - userResults.workout_array exists');
      //   workouts = userResults.workout_array;
      // } else if (allResults && allResults.workout_array && allResults.workout_array.length > 0){
      //   console.log('~~~~~ Shell(), handleRowClick(), type includes workout - allResults.workout_array exists');
      //   workouts = allResults.workout_array;    
      // } else {
      //   console.log('~~~~~ Shell(), handleRowClick(), type includes workout, but no results.workout_array exists!');
      // }

      // if (workouts && workouts.length > 0){
      //   workoutObj = workouts.find(workout => workout.id_guid === id);
      //   if (workoutObj){
      //     setWorkout(workoutObj);
      //     // how to make sure WorkoutEdit will refresh here?
      //     // give it a prop that gets updated every time we get here
      //     // setShow(workout_edit);
      //     setPane('_l', workout_edit);
      //     setRefreshWorkouts(uuidv4());
      //   } else {
      //     alert('The chosen workout cannot be found');
      //   }
      // } else {
      //   alert('There are no available workouts');
      // }
      // const workouts = workoutResults.workout_array;
      // const workoutObj = workouts.find(workout => workout.id_guid === id);
      // setWorkout(workoutObj);
      // // how to make sure WorkoutEdit will refresh here?
      // // give it a prop that gets updated every time we get here
      // setShow(workout_edit);
      // setRefreshWorkouts(uuidv4());

    }

    if (type.includes('exercise')){
      console.log('~~~~~ Shell(), handleRowClick() - clicked row was an Exercise ');
      // we want to handle the case where workout explore is going to open a workout viewedit instance as workout_edit_r              OK
      // we will want a Shell state variable for the workout in viewEdit const [workout, setWorkout] = useEffect(null);               DONE
      // we will pull the workout object from workoutResultsArray.workout_array where arrayObj.id_guid === incoming id above ^^^
      // and then we will save that workoutObj in the workout state const using setWorkout(workoutObj)
      // the workout_edit instance can be populated from workout in Shell state by passing workout down to WorkoutEdit as a prop
      const exercises = exerciseResults.exercise_array;
      const exerciseObj = exercises.find(exercise => exercise.id_guid === id);
      setExercise(exerciseObj);
      // how to make sure WorkoutEdit will refresh here?
      // give it a prop that gets updated every time we get here
      setShow(exercise_edit);
      setRefreshExercises(uuidv4());
    }

    if (type.includes('movement')){
      console.log('~~~~~ Shell(), handleRowClick() - clicked row was a Movement, so we add this to the Workout');

      const movements = movementResults.movement_array;
      const movementObj = movements.find(movement => movement.id_guid === id);
      setMovement(movementObj);

      // code here to create new workout exercise from movement? // all we should need is to setMovement,
      // because that should re-render WorkoutEdit, which should add an exercise to exercise_array of formItem - BUT...
      // re-rendering would zap every other unsaved change to formItem if there have been any since the last render, right?


      
      // code here to add movement into Workout ExerciseList as a default (unfinished? invalid?) exercise

      // how to make sure WorkoutEdit will refresh here?
      // give it a prop that gets updated every time we get here
      // setShow(exercise_edit);


      setRefreshMovements(uuidv4());
    }

  }

  function handleRowRemoveClick(type, id, name, addingToType, addingToId){
    console.log('~~~~~ Shell(), handleRowRemoveClick(' + type + ', ' + id + ', ' + name + ', ' + addingToType + ', ' + addingToId + ')');

    switch(type){
      case "group": // the itemType (or rowType) being removed
        if (addingToType === 'user') { // the itemType it is being removed from - here we are removing a group from a user
          removeAssociatedItem(addingToType, addingToId, type, id)
          .then(respStatus => {
            let alertMsg = '';
            if (respStatus === 201 || respStatus === 204) {
              // alertMsg = 'The ' + type + ' ' + name + ' has been removed from your list';
              alertMsg = console.log('~~~~~ Shell(), handleRowRemoveClick() - The ' + type + ' ' + name + ' has been removed from your list');
            } else {
              alertMsg = 'There was a problem removing the ' + type + ' ' + ' - Response Status Code was ' + respStatus;
            }
            console.log('~~~~~ Shell(), handleRowRemoveClick(), switch case user or workout - response status code was ' + respStatus);
            // alert(alertMsg);
            setRefresh(uuidv4());
          })
        } else if (addingToType === 'workout') { // the itemType it is being removed from - here we are removing a group from a workout
          // removing a group from a workout ??
          console.log('~~~~~ Shell(), handleRowRemoveClick() - case group, removing a group from a ' + addingToType);
          removeAssociatedItem(addingToType, addingToId, type, id) // group|user, group_id|user_id, workout, workout_id, 
          .then(respStatus => {
            let alertMsg = '';
            if (respStatus === 201 || respStatus === 204) {
              // alertMsg = 'The ' + type + ' ' + name + ' has been removed from your list';
              console.log('~~~~~ Shell(), handleRowRemoveClick() - The ' + type + ' ' + name + ' has been removed from your list');
            } else {
              alertMsg = 'There was a problem removing the ' + type + ' ' + ' - Response Status Code was ' + respStatus;
            }
            console.log('~~~~~ Shell(), handleRowRemoveClick(), remove group from workout - response status code was ' + respStatus);
            // alert(alertMsg);
            setRefresh(uuidv4());
            setRefreshWorkouts(uuidv4());
          })

        } else { // this deleteGroup is appropriate for removing a group from a group
          console.log('~~~~~ Shell(), handleRowRemoveClick() - deleting a Group  ');
          // remove association between group and user
          // remove association between group and workout
          deleteGroup(id)
          .then(respStatus => {
            let alertMsg = '';
      
            if (respStatus === 204) {
              // alertMsg = itemType + ' ' + itemName + ', with id_guid ' + itemId + ' has been deleted and removed from your list';
              alertMsg = type + ' ' + id + ' has been deleted and removed from your list';
              console.log('~~~~~ Shell(), handleRowRemoveClick() - ' + type + ' ' + id + ' has been deleted and removed from your list');
              // code to refresh the GroupList in ShellSidebar
              // removeThisGroup(id);
              removeGroupFromParentsArray(id);
              setRefresh(uuidv4());
              setRefreshSB(uuidv4());
            } else {
              alert('There was a problem deleting the group ' + name + ' - this group may have Groups, Users or Workouts associated with it, that would need to be removed first.');
            }
          })
        }
        break;
      case "user":  // this would work for removing users from somewhere (groups|workouts)
        console.log('~~~~~ Shell(), handleRowRemoveClick() - deleting a ' + type);
        removeAssociatedItem(addingToType, addingToId, type, id)
        .then(respStatus => {
          let alertMsg = '';
          if (respStatus === 201 || respStatus === 204) {
            alertMsg = 'The ' + type + ' ' + name + ' has been removed from your list';
          } else {
            alertMsg = 'There was a problem removing the ' + type + ' ' + ' - Response Status Code was ' + respStatus;
          }
          console.log('~~~~~ Shell(), handleRowRemoveClick(), workout switch case user - response status code was ' + respStatus);
          // alert(alertMsg);
          setRefresh(uuidv4());
          setRefreshWorkouts(uuidv4());
        })
        break;
      case "workout": // this is not the same above, with the "user" case, because this might perform a delete in some cases
        if (addingToType !== 'user' && addingToType !== 'group'){ // in this case we will perform a delete
          // alert('~~~~~ Shell(), HandleRemoveClick() - deleting a Workout');
          console.log('~~~~~ Shell(), HandleRemoveClick() - deleting a Workout, refreshWorkouts is ' + refreshWorkouts);
          deleteWorkout(id)
          .then(respStatus => {
            let alertMsg = '';
      
            if (respStatus === 204) {
              // alert('Workout ' + id + ' has been deleted and removed from your list');
              console.log('~~~~~ Shell(), handleRowRemoveClick() - ' + type + ' ' + id + ' has been deleted and removed from your list');

              // code to refresh the WorkoutList in Explore Workout
              // 
              // setRefreshWorkouts(uuidv4()); // refresh Workout Explore AND WorkoutEdit
            } else {
              alert('There was a problem deleting the workout ' + name + ' - this workout may have Groups or Users associated with it, that would need to be removed first.');
            }
            setRefreshWorkouts(uuidv4()); // refresh Workout Explore AND WorkoutEdit
            console.log('~~~~~ Shell(), HandleRemoveClick() - after deleting or attempting to delete a Workout, refreshWorkouts is ' + refreshWorkouts);
            setTimeout(() => {
              console.log('~~~~~ Shell(), HandleRemoveClick() - 1 second after after deleting or attempting to delete a Workout, refreshWorkouts is ' + refreshWorkouts);
            }, 1000);
          })
        }
        else
        {
          console.log('~~~~~ Shell(), handleRowRemoveClick() - case workout, removing a workout from a ' + addingToType);
          removeAssociatedItem(addingToType, addingToId, type, id) // group|user, group_id|user_id, workout, workout_id, 
          .then(respStatus => {
            let alertMsg = '';
            if (respStatus === 201 || respStatus === 202 || respStatus === 204) {
              alertMsg = 'The ' + type + ' ' + name + ' has been removed from your list';
            } else {
              alertMsg = 'There was a problem removing the ' + type + ' ' + ' - Response Status Code was ' + respStatus;
            }
            console.log('~~~~~ Shell(), handleRowRemoveClick(), remove workout from user or group - response status code was ' + respStatus);
            // alert(alertMsg);
            setRefresh(uuidv4());
            setRefreshUser(uuidv4());
          })
        }
        break;
      default:
        alert('~~~~~ Shell(), handleRowRemoveClick() - deleting a default');
    }

  }

  function handleRowAddClick(type, id, name, addingToType, addingToId){
    // alert('handleRowAddClick in Shell! #1 - type is ' + type + ', and id is ' + id + ', and new association would be with ' + addingToType + ' with ID ' + addingToId);
    console.log('~~~~~ Shell(), handleRowAddClick(' + type + ', ' + id + ', ' + name + ', ' + addingToType + ', ' + addingToId + ')');

    let addMethodType = type;
    let addMethodId = id;
    let addMethodAddingToType = addingToType;
    let addMethodAddingToId = addingToId;
    // accommodate user/group association table deprecation
    if ((type === 'group' && addingToType === 'user') || (type === 'workout' && addingToType === 'user')) {
      addMethodType = addingToType;
      addMethodId = addingToId;
      addMethodAddingToType = type;
      addMethodAddingToId = id;
    }
    
    addAssociatedItem(addMethodAddingToType, addMethodAddingToId, addMethodType, addMethodId)
    .then(respStatus => {
      let alertMsg = '';
      if (respStatus === 201) {
        // alertMsg = 'The ' + type + ' ' + name + ' has been added and will appear in your list';
      } else {
        alertMsg = 'There was a problem adding the ' + type + ' ' + ' - Response Status Code was ' + respStatus;
      }
      console.log('~~~~~ Shell(), handleRowAddClick(), addAssociatedItem() - response status code was ' + respStatus);
      // alert(alertMsg);
      setRefresh(uuidv4());
      setRefreshWorkouts(uuidv4());
    })
    
    // the switch statement will hit the appropriate method for creating the particular association:
    switch(type) {
      case "group":
        // code to hit api and add a new association using type, id and addingToType, addingToId

        break;
      case "user":
        // code to hit api and add a new association using type, id and addingToType, addingToId

        break;
      case "workout":
        // code to hit api and add a new association
        break;
      default:
        // code to hit api and add a new association
    }
  }

  function handleAddUserClick(){
    // alert('handleAddUserClick in Shell!');
    console.log('~~~~~ Shell(), handleAddUserClick()');
    setShow(group_add_new_users); // TODO: make sure explore_[...]_add_r instance has the proper search results preloaded

  }

  function handleAddGroupClick(){
    // alert('handleAddGroupClick in Shell!');
    console.log('~~~~~ Shell(), handleAddGroupClick()');
    setShow(group_add_new_group);

  }
  
  // State:
  const [user, setUser] = useState({ id_guid: "aba773bb-2b97-4dab-b7f2-77a45966a810" }); //TODO: should be useState({})
  const [assessments, setAssessments] = useState([]);
  const [assessment, setAssessment] = useState({}); // note this is an object...
  // const [activeTab, setActiveTab] = useState(0);  // integer index of active tab // TODO: does this need to be here, or in UserSection?
  const userState = {
    user: user,
    assessments,
    assessment
    // activeTab // TODO: does this need to be here, or can it reside in UserSection?
  }

  // Event handlers:
  // function handleUserTabChange(index){ // TODO: does this need to be here, or in UserSection?
  //   console.log('~~~~~ Shell(), handleUserTabChange() (same as userHandlers .tabChange()) - new tab index is ' + index.toString());
  //   // check to make sure Sidebar has finished rendering???
  //   // - maybe have an onGroupListUpdatCompleted event with the handler up here in Shell,
  //   // -- and set a state variable like 'sidebarLoaded' that could be used here to enable/disable the automatic setActiveTab?
  //   // OR ---> just don't have that setAciveTab happen until later, by not having the user_r component in the default set :-)
  //   setActiveTab(index);
  // }

  function handleUserAssessmentChange(assessmentObj){
    console.log('~~~~~ Shell(), handleUserAssessmentChange() (same as userHandlers .assessmentChange()) - new Assessment id_guid is ' + assessmentObj.id_guid);
    setAssessment(assessmentObj);
  }

  function handleUserAccept(id_guid){
    console.log('~~~~~ Shell(), handleUserAccept() (same as userHandlers .accept()) - User id_guid is ' + id_guid);
    
  }

  const userHandlers = { // these work, and are successfully accessed from UserSection
    // tabChange: handleUserTabChange,
    assessmentChange: handleUserAssessmentChange,
    accept: handleUserAccept
  }

  // useEffect functions for UserSection (and it's children?)
  // useEffect(() => {
  //   if (!isAthlete){ // if user is NOT an Athlete, then this useEffect is run 
  //     mounted.current = true; // TODO: how is this useful?
  //     if(userId.length === 0) { // don't try it if there is no userId, because we have not picked a user
  //       console.log('~~~~~ Shell(), useEffect() for User - userId.length == 0');
  //       return;
  //     }
  //     console.log('~~~~~ Shell(), useEffect() for User - user.Id is ' + userId);
      
  //     getUser(userId)
  //       .then(item => {
  //         if(mounted.current) { // TODO: how is this useful?
  //           setUser(item)
  //         }
  //       })
  //   }
  // }, [userId])

  // useEffect(() => {
  //   if (!isAthlete){
  //     if(!user || !user.id_guid || user.id_guid.length === 0) { // TODO: do we need to check both?
  //       console.log('~~~~~ Shell(), useEffect() for MemberAssessments - user.id_guid.length == 0');
  //       return;
  //     }
  //     console.log('~~~~~ Shell(), useEffect() for MemberAssessments - user.id_guid is ' + user.id_guid);
  //     getMemberAssessments(user.id_guid)
  //       .then(items => {
  //         if(mounted.current) { // TODO: how is this useful?
  //           setAssessments(items)
  //           setLoaded(true); // arbitrary choice of this api call as longest
  //         }
  //       })

  //     // return () => mounted.current = false; // TODO: how is this useful?
  //   }
  // }, [user, userId])

  // <User> section END ============================================================================================


  function handleSidebarBtnClick(btn_id){
    console.log('~~~~~ Shell(), handleSidebarBtnClick(), btn_id is ' + btn_id);
    if (!btn_id.includes("group_")) { // we should disable highlights for Groups in GroupList
      setGroup(mtGroup);
      setRefreshSB(uuidv4());
    }
    // decide if this click represents a change, if not - do nothing
    // if (btn_id === sidebarBtn){
    //   return;
    // } else {
      // setSidebarBtn(btn_id);
      console.log('~~~~~ Shell(), handleSidebarBtnClick() - proceeding to pick a Shell config for ' + btn_id);
      // decide if we need special processing because this is a group button console.log(str.includes(substr));
      if(btn_id.includes("group_")){
        let group_id = btn_id.substring(6);
        console.log('~~~~~ Shell(), handleSidebarBtnClick() - before setShow, show is ' + show);
        handleGroupChange(group_id);
        // need to set the group_id in groupSearch
        // let tempGroupSearch = Object.assign({}, groupSearch); // this clones the current groupSearch as tempGroupSearch
        // tempGroupSearch['group_id'] = group_id;
        // setGroupSearch(tempGroupSearch); // autoSearch={groupSearch} for explore_group_l instance down in Shell render code
        setIdGroupL(group_id);
        setShow(group_);
        console.log('~~~~~ Shell(), handleSidebarBtnClick() - after setShow, show is ' + show);
      } else {
        if(sidebarBtn.includes("group_")){ // special case where we are switching away from a highlighted Group
          // code to remove the formerly selected group from the group State variable
          setGroup(mtGroup); // TODO: is this appropriate? we had {} before
        }
        // switch statement here for btn_id?
        switch(btn_id) {
          case "ExploreBtn":
            setShow(explore_);
            break;
          case "WorkoutsBtn":
            setShow(workout_);
            break;
          case "ExercisesBtn":
            setShow(exercise_);
            break;
          default:
            setShow(explore_);
        }
      }
      setSidebarBtn(btn_id);
      // more code, to change Shell config, etc.
    // }
  }

  function addGroupToParentsArray(groupObject){
    const parentGroupId = groupObject.parent_group_id_guid;
    console.log('~~~~~ Shell(), addGroupToParentsArray() - new group_id_guid is ' + groupObject.id_guid + ', parentGroupId is ' + parentGroupId);
    // let tempGroupObjArray =  parentsArray.current;
    let tempParentsArray =  parentsArray.current;

    /*  // Structure of the elements in the parentsArray[x].kids array:
      group_id_guid: 'fb1c3d49-c59b-4bf8-989c-7404f49b3195' 
      group_path_string: ' < School B < District 1'
      has_children_boolean: true
      id_guid: 'fb1c3d49-c59b-4bf8-989c-7404f49b3195'
      level_integer: 2 
      name_string: 'Baseball'
      note_string: 'School B - Baseball'
      parent_group_id_guid: '480881dd-b2cc-4d0f-a4d1-97747879b30a'
      user_id_guid: 'aba773bb-2b97-4dab-b7f2-77a45966a810'
    */

    const objIndex = tempParentsArray.findIndex((obj => obj.id === parentGroupId));
    // objIndex is -1 for a group that has no kids...
    // TODO: should we change parentsArray creation to include EVERY group, and filter it in GroupList to only act on groups with kids?
    if (objIndex === -1){ // code to add 'soon-to-be' group to parentsArray
        //// 

          // in this case, we should add the expecting parent to tempGroupObjArray
          // first we construct a groupObj for the expecting parent
          // where do we get the attributes for the expecting parent?
          // let newParentObj = {
          //   id: parentGroupId, 
          //   level: level,                 // this is used for something later?
          //   name: grpName, 
          //   closed: true,                 // all groups will default to closed
          //   kids: hasKids ? [] : null     // this null will be used later to mean noKids, instead of having a boolean
          // };
      
      // if (hasKids){ 
      //   // check to see if this group needs to be added to parentsArray.current, and add it if required
      //   if (!parentsArray.current.find(parent => parent.id === groupId)){ // const objAfter = parentsArray.current.find(parent => parent.id === id);
      //     console.log('~~~~~ Shell(), useEffect() for groups - about to add ' + grpName + ' to parentsArray.current');

      //     //  make an array of the direct children of the parent we are working with, and add it to the parentObj
      //     parentObj.kids = tempGroupArray.filter(group => group.parent_group_id_guid === groupId);
      //   }  
      //   parentsArray.current.push(parentObj) 
      // }

      // console.log("~~~~~ Shell(), useEffect() for groups, tempGroupArray.forEach() - grpName: " + grpName + ', hasKids: ' + hasKids);

      // let thisGroupObject = {...groupObj, is_collapsed: true}; // show_children sets caret, is_visible sets height
      // tempGroupObjArray.push(thisGroupObject);

  ////
    }
    
    console.log('~~~~~ Shell(), addGroupToParentsArray() - before add, objIndex is ' + objIndex); // index in parentsArray of group we are adding to
    // note that if the group we are adding to currently has no kids, it will not be in parentsArray at all...

    let tempKidsArray = tempParentsArray[objIndex].kids;
    // tempParentsArray[objIndex] is undefined for a group that has no kids, 
    // - this makes sense, since tempKidsArray is parentsArray, and it only contains groups with kids ;-)
    // - so, we check for undefined tempParentsArray[objIndex], and add a new element to tempGroupObjArray ??

    console.log('~~~~~ Shell(), addGroupToParentsArray() - before add, tempKidsArray.length is ' + tempKidsArray.length);

    groupObject.level_integer = tempParentsArray[objIndex].level + 1; // TODO: why do we need level_integer?
    tempKidsArray.push(groupObject);
    console.log('~~~~~ Shell(), addGroupToParentsArray() - after add, tempKidsArray.length is ' + tempKidsArray.length);

    tempKidsArray.sort((a, b) => {
      const nameA = a.name_string.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name_string.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    tempParentsArray[objIndex].kids = tempKidsArray;
    parentsArray.current = tempParentsArray;

    setRefresh(uuidv4()); // TODO: do we need this?
    setRefreshSB(uuidv4()); // TODO: do we need this??
  }

  function removeGroupFromParentsArray(groupId){
    // we want to delete a group from the parentsArray so it will disappear from the GroupList in the sidebar
    // the group id will only appear as an id of an object in the parentsArray if it is a PARENT
    // it is very likely to be a CHILD, in which case it would appear only in the .kids attribute of it's parent group
    // so we need to search the .kids arrays of every (parent) object in parentsArray

    let groupRemovedFromGroupList = false; // we will assert this when we find and remove the group
    console.log('~~~~~ Shell(), removeGroupFromParentsArray() - id of group to be removed is *' + groupId + '*');
    let tempParentsArray =  parentsArray.current;
    console.log(tempParentsArray);

    // check to see if the groupId being removed is a parent, which would have it's id at the top level (as .id attribute of object in parentsArray)
    let groupIsParentIndex = tempParentsArray.findIndex((obj => obj.id === groupId));

    if (groupIsParentIndex !== -1){ // this is the case if the group we are removing is a parent, and has an object in parentsArray
      // code to remove element from tempParentsArray at groupIsParentIndex
      tempParentsArray.splice(groupIsParentIndex, 1);
      groupRemovedFromGroupList = true;
    } else { // this is the case if the group to be removed is NOT a parent, but just a child of one of the parent groups in parentsArray
      // code to loop through all the objects in parentsArray and search their .kids array to remove the group
      for (const parentObj of tempParentsArray) {
        console.log('~~~~~ parentId is ' + parentObj.id);
        let removeGroupIndex = parentObj.kids.findIndex((obj => obj.id_guid === groupId));
        if (removeGroupIndex !== -1){ // this is the case if the group we are removing is a child of the current parentObj
          parentObj.kids.splice(removeGroupIndex, 1);
          groupRemovedFromGroupList = true;
          break;
        }
      }
    }

    if (groupRemovedFromGroupList === false){
      alert('the group with id ' + groupId + ' was not found in the GroupList, and not removed');
    }

    parentsArray.current = tempParentsArray;
  }

  function updateNameInParentsArray(updateObj){ // { id: [string], name: [string] } can we update anything but the group name?
    console.log('~~~~~ Shell(), updateNameInParentsArray() - id of group to be updated is *' + updateObj.id + '*');
    // this code can be developed from the code for removeGroupFromParentsArray
    // the same code can be used to find the group record by id
    // but instead of removing the group from an array, we will update it's name attribute

    let groupUpdatedInGroupList = false; // we will assert this when we find the Group and update the name
    console.log('~~~~~ Shell(), updateNameInParentsArray() - current Name of group to be updated is *' + updateObj.name + '*');
    let tempParentsArray =  parentsArray.current; 
    console.log(tempParentsArray); // this array has the updated name already...

    // check to see if the groupId being updated is a parent, which would have it's id at the top level (as .id attribute of object in parentsArray)
    let groupIsParentIndex = tempParentsArray.findIndex((obj => obj.id === updateObj.id));

    if (groupIsParentIndex !== -1){ // this is the case if the group we are updating is a parent, and has an object in parentsArray
      // code to update element name from tempParentsArray at groupIsParentIndex
      tempParentsArray[groupIsParentIndex].name = updateObj.name;
      console.log('~~~~~ Shell(), updateNameInParentsArray() - tempGroupObjArray[groupIsParentIndex].name is *' + tempParentsArray[groupIsParentIndex].name + '*');
      groupUpdatedInGroupList = true;
    }
      
    // this is for all Groups except the top (level 0) Group, since they all appear in the "kids" array attribute of some parent group in parentsArray
    if (updateObj.level_integer !== 0) {
      // code to loop through all the objects in parentsArray and search their .kids array to update the group
      for (const parentObj of tempParentsArray) {
        console.log('~~~~~ parentId is ' + parentObj.id);
        let updateGroupIndex = parentObj.kids.findIndex((obj => obj.id_guid === updateObj.id));
        if (updateGroupIndex !== -1){ // this is the case if the group we are updating is a child of the current parentObj
          parentObj.kids[updateGroupIndex].name_string = updateObj.name;
          groupUpdatedInGroupList = true;
          break;
        }
      }
    }

    if (groupUpdatedInGroupList === false){
      alert('the group with id ' + updateObj.id + ' was not found in the GroupList, and not updated');
    }

    parentsArray.current = tempParentsArray;
    
    setRefresh(uuidv4()); // TODO: do we need this?
    setRefreshSB(uuidv4()); // TODO: do we need this??
  }

  function handleGroupChange(id_guid){
    console.log('~~~~~ Shell(), handleGroupChange() - the chosen group has id ' + id_guid);
    // update the parent_group_id_guid in the default empty group object that is passed to GroupEdit for New Group creation
    mtGroup.parent_group_id_guid = id_guid; // this 'blank' group will always be ready with the right parent ID ;-)
    console.log('~~~~~ Shell(), handleGroupChange() - mtGroup.parent_group_id_guid is now ' + mtGroup.parent_group_id_guid);
    // currentParentId = id_guid;
    // console.log('~~~~~ Shell(), handleGroupChange() - currentParentId is now ' + currentParentId);
    setParentId(id_guid);
    getGroup(id_guid, true, false, false)
    .then(groupObject => {
      let thisGroupObj = groupObject;
      console.log('~~~~~ Shell(), handleGroupChange() - new Group Name is ' + thisGroupObj.name_string);
      setGroup(thisGroupObj);
    });
  }

  function updateGroupInGroupObjArray(groupObj){
    console.log('~~~~~ Shell(), updateGroupInGroupObjArray() - Group Name is ' + groupObj.name_string);
    console.log(groupObj);
    // create a copy of the groupObjArray
    let tempGroupObjArray = groupObjArray;
    // find index in groupObjArray for this object
    const index = tempGroupObjArray.findIndex((group) => group.id_guid === groupObj.id_guid);
    // update the temporary array with the new Group
    tempGroupObjArray[index] = groupObj;
    console.log(tempGroupObjArray);
    setGroupObjArray(tempGroupObjArray);
    console.log(groupObjArray);
  }

  function handleSaveClick(type, item){
    console.log('~~~~~ Shell(), handleSaveClick() - the type to save is ' + type + ', and the id of the item is ' + item.id_guid);

    if (type === "exercise") {
      console.log('~~~~~ Shell(), handleSaveClick() - saving an exercise');
      // code to refresh exercise explore component
      setRefreshExercises(uuidv4());
    } else if (type === "workout") {
      console.log('~~~~~ Shell(), handleSaveClick() - saving a workout');
      // code to refresh workout explore component
      setRefreshWorkouts(uuidv4());
      // what about updating the workout being presented to WorkoutSection? workout, setWorkout?
      setWorkout(item);
      // what about setting the WorkoutEdit/Create form to Edit
    } else if (type === "group") {
      console.log('~~~~~ Shell(), handleSaveClick() - saving a group');
      // code to refresh group explore component is in createGroup and updateGroup code below...

      if (item.id_guid === undefined || item.id_guid.length < 20){ // creating a new group
        console.log('~~~~~ Shell(), handleSaveClick() - the type to save is ' + type + ', and this will be a NEW item');
        // call service method to post a new Item (switch statement on itemType??)
        createGroup(item) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
        .then(thisItem => { // item is going to be what is returned from the service method... 
  
          let alertMsg = 'New ' + type + ' with Name ' + thisItem.name_string + ' has been created and added to your list';
          // update the Shell state variable for the related itemType
          // setGroup(item);
          // setParentId(item.id_guid);
          addGroupToParentsArray(thisItem);
          setRefresh(uuidv4());   // TODO: figure out why this does not update GroupList to show new groups
          setRefreshSB(uuidv4()); // TODO: figure out why this does not update GroupList to show new groups
                                  // TODO: make a function to call here to update the useRef parentsArray to add entry for new group!!
          // setShow(group_add);
          setShow(group_);
          // clickHeader();
          // we would like to force the GroupList in sidebar to re-render, without destroying anything else,
          // and we would like the current group to be highlighted...
          // setTimeout(function() {
          //   // window.location.reload(); // this is pretty ugly, we do not control the config we go to next :-(
          //   alert(alertMsg);
          // }, 500);
        })
      } else {
        // call service method to put a modified item (switch statement on itemType??)
        updateGroup(item) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
        .then(thisItem => { // item is going to be what is returned from the service method... 
          
          let alertMsg = 'Updated ' + type + ' with id_guid ' + thisItem.id_guid + ' has been updated in your list';
          // update the Shell state variable for the related itemType
          setGroup(item);
          setParentId(item.id_guid); // this not useful here - but what can we do to invoke GroupList re-render?
          updateNameInParentsArray({ id: item.id_guid, name: item.name_string });
          // need to update the Shell state variable for the Groups Array: groupObjArray
          updateGroupInGroupObjArray(item);
          setRefresh(uuidv4());
          // setRefreshSB(uuidv4());
          setTimeout(function() {
            // window.location.reload();
            console.log(groupObjArray);
            setRefreshSB(uuidv4());
            // alert(alertMsg);
            // alert('Updated Group with id_guid ' + thisItem.id_guid + ' has been updated in the GroupList in the Sidebar');
          }, 500);
        })
      }
    } 
  }

  function handleInviteSubmit(athleteEmailList, groupId, isStaff, isResend = false){
    console.log('~~~~~ Shell(), handleInviteSubmit()');
    setShow(isResend ? group_ : group_add);
    // code here to refresh group explore in left pane...
    setTimeout(function() {
      setRefresh(uuidv4())
    }, 1000);
  }

  function handleAcceptUser(){
    console.log('~~~~~ Shell(), handleAcceptUser()');
    setShow(group_);
    setRefresh(uuidv4());
  }

  function handleWorkoutDuplicate(){
    console.log('~~~~~ Shell(), handleWorkoutDuplicate()');
    // mostly just changing the Header to "Create Workout", but how can we get it to update without losing state?
    // rightTitleString = "Create Workout";
    // setRefresh(uuidv4());
    setShow(workout_new);
  }
/*
  function handleUserTabChange(newIndex){
    console.log('~~~~~ Shell(), handleUserTabChange() - newIndex is *' + newIndex + '*');
    setActiveUserTabIndex(newIndex);
  }
  */
  function handleWorkoutTabChange(newIndex){
    console.log('~~~~~ Shell(), handleWorkoutTabChange() - newIndex is *' + newIndex + '*');
    setActiveWorkoutTabIndex(newIndex);
  }

  function handleClearMovement(){
    console.log('~~~~~ Shell(), handleClearMovement()');
    setMovement(null);
  }

  function setTitles(showArray){
    // find showArray element that includes "title_l_"
    leftTitleString = showArray.filter((item) => { return item.includes("title_l_"); })[0].substring(8).replaceAll('_', ' ');
    if (leftTitleString === "GroupName"){
      // code to find the name for the current Group and use it as the title
      leftTitleString = group.name_string;
    }
    console.log('~~~~~ Shell(), setTitles() - leftTitleString is *' + leftTitleString + '*');

    // find showArray element that includes "icon_l_" and parse the icon src from it
    let leftTitleIcon = showArray.filter((item) => { return item.includes("icon_l_"); })[0].substring(7).replace('_', ' ');
    leftTitleIconSrc = iconSrc[leftTitleIcon];
    console.log('~~~~~ Shell(), setTitles() - leftTitleIconSrc is *' + leftTitleIconSrc + '*');


    // find showArray element that includes "title_r_" and parse the Title from it
    rightTitleString = showArray.filter((item) => { return item.includes("title_r_"); })[0].substring(8).replaceAll('_', ' ');
    console.log('~~~~~ Shell(), setTitles() - rightTitleString is ' + rightTitleString);
    
    if (rightTitleString.includes("GroupName")){
      let groupNameString = rightTitleString.includes("Invite") ? group.name_string : groupR.name_string;
      console.log('~~~~~ Shell(), setTitles() - we found right GroupName, and groupNameString is ' + groupNameString);
      // code to find the name for the current Group and use it in the title
      let GroupName = groupNameString;
      // if the explore instance on the right is an 'add' instance, the groupname we should use is from the left explore instance
      if (rightTitleString.includes('Add')){
        GroupName = group.name_string;
      }
      rightTitleString = rightTitleString.replace("GroupName", GroupName);
      console.log('~~~~~ Shell(), setTitles() - rightTitleString after replace() is ' + rightTitleString);
    }

    if (rightTitleString.includes("UserName")){
      // code to find the name for the current User and use it in the title
      let UserName = "User";

      // TODO: why does code below fail with 'cannot access user before initialiation'?
      // let UserName = user && user.email_string ? user.email_string : "*UserName*";
      // if (rightTitleString.includes('Add')){
      //   UserName = user.email_string;
      // }
      rightTitleString = rightTitleString.replace("UserName", UserName); // TODO: make a userName that incorporates, [last, first] or email
      // rightTitleString = rightTitleString.replace("_", " ");
    }

    if (rightTitleString.includes("WorkoutName")){
      // code to find the name for the current Workout and use it in the title
      let WorkoutName = workout.name_string;

      rightTitleString = rightTitleString.replace("WorkoutName", WorkoutName);
      rightTitleString = rightTitleString.replace("_", " ");
    }

    if (leftTitleString.includes("WorkoutName")){
      // code to find the name for the current Workout and use it in the title
      let WorkoutName = workout.name_string;

      leftTitleString = leftTitleString.replace("WorkoutName", WorkoutName);
      leftTitleString = leftTitleString.replace("_", " ");
    }

    if (rightTitleString.includes("ExerciseName")){
      // code to find the name for the current User and use it in the title
      let ExerciseName = exercise.name_string;

      rightTitleString = rightTitleString.replace("ExerciseName", ExerciseName);
      rightTitleString = rightTitleString.replace("_", " ");
    }

    console.log('~~~~~ Shell(), setTitles() - rightTitleString is *' + rightTitleString + '*');

    // find showArray element that includes "icon_r_" and parse the icon src from it
    let rightTitleIcon = showArray.filter((item) => { return item.includes("icon_r_"); })[0].substring(7).replace('_', ' ');
    rightTitleIconSrc = iconSrc[rightTitleIcon];
    console.log('~~~~~ Shell(), setTitles() - rightTitleIconSrc is *' + rightTitleIconSrc + '*');
  }

  console.log('~~~~~ show: ' + show);

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  function clickHeader(){
    // const newInteger = Math.floor(Math.random() * 100);
    // const newString = newInteger.toString();
    const newString = uuidv4();
    alert('clickHeader! - newString is ' + newString);
    // setGroup286(newString);
    setRefresh(newString); // setRefresh(uuidv4());
    // setGroup286(Math.floor(Math.random() * 100));
  }

  function handleCaretChange(id, showKids){
    console.log('~~~~~ Shell(), handleCaretChange() - group_id_guid is ' + id + ', and showKids is now ' + showKids);
    // update parentsArray.current

    console.log('~~~~~ Shell(), handleCaretChange() - parentsArray.current.length is ' + parentsArray.current.length);

    // let tempGroupObjArray =  groupObjArray;
    let tempGroupObjArray =  parentsArray.current;
    
    const obj = tempGroupObjArray.find(parent => parent.id === id);
    if (obj) {
      console.log('~~~~~ Shell(), handleCaretChange() - before update obj.name_string is ' + obj.name + ' and obj.closed is ' + obj.closed);
      obj.closed = !showKids;
    }

    parentsArray.current = tempGroupObjArray;

    const objAfter = parentsArray.current.find(parent => parent.id === id);

    console.log('~~~~~ Shell(), handleCaretChange() - after update obj.name_string is ' + obj.name + ' and obj.closed is ' + obj.closed + ', now that showKids has changed to ' + showKids);

    // console.log('~~~~~ Shell() - handleCaretChange() groupLevel is ' + groupLevel);

  }

  function handleWorkoutAddExercise(creating){
    // alert('Shell(), handleWorkoutAddExercise(), creating is *' + creating + '*');
    console.log('~~~~~ Shell(), handleWorkoutAddExercise(), creating is *' + creating + '*');

    setShow(creating ? workout_new_adding_exercise : workout_edit_adding_exercise);
  }

  function handleVideoClick(url, title){
    console.log('~~~~~ Shell(), handleVideoClick(), url is *' + url + '*, and the title is *' + title + '*');
    console.log('~~~~~ should be using this local State value for videUrl: ' + videoUrl);
    setVideoUrl(url);
    setVideoTitle(title)
    setShowVideo(true); // shows the modal, which has default visibility "hidden"
    setTimeout(() => {
      document.getElementById("video_modal").style.visibility = "visible";
    }, 1000)
  }

  function handleCloseVideo(){
    console.log('~~~~~ Shell(), handleCloseVideo()');
    setShowVideo(false); // hides the modal
  }

  function handleComplianceClick(userId, workoutId, workoutName, assignedBy){ // userId, workoutId, workoutName, assignedBy
    console.log('~~~~~ Shell(), handleComplianceClick() - userId is *' + userId + '*, workoutId is *' + workoutId + '*, and workoutName is *' + workoutName + '*, and assignedBy is +' + assignedBy + '*');
    // alert('The Compliance Detail View is temporarily offline');
    // need to move the UserSection/ComplianceTab view to the left pane
    // need to open the ComplianceDetail view in the right pane, which requires userId, workoutId and assignedBy
    // need to set right pane title to include WorkoutName
    setComplianceUserId(userId); // used to fetch complianceDetail
    setComplianceWorkoutId(workoutId); // used to fetch complianceDetail
    // setComplianceWorkoutAssignedBy(assignedBy);
    setComplianceWorkoutAssignedBy(assignedBy);
    setWorkout({name_string: workoutName, note_string: "", interval_integer: 1, interval_string: "", exercise_array: []});
    setShow(user_compliance);
  }

  const exploreHandlers = {
    onSearch: (...props) => { handleSearch(...props); },
    onAddNewClick: (...props) => { handleAddNewClick(...props); },
    onRowClick: (...props) => { handleRowClick(...props); },
    onRowRemoveClick: (...props) => { handleRowRemoveClick(...props); },
    onRowAddClick: (...props) => { handleRowAddClick(...props); },
    onAddUserClick: (...props) => { handleAddUserClick(...props); },
    onAddGroupClick: (...props) => { handleAddGroupClick(...props); },
    onVideoClick: (...props) => { handleVideoClick(...props); }
  }

  function parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  /**
 * Take the difference between the dates and divide by milliseconds per day.
 * Round to nearest whole number to deal with DST.
 */
  function datediff(first, second) {        
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

      /**
     * Take the difference between the dates and divide by milliseconds per day.
     * Round to nearest whole number to deal with DST.
     */
      // function datediff(first, second) {        
      //   return Math.round((second - first) / (1000 * 60 * 60 * 24));
      // }

  /*

*Mon Sep 16 2024 13:44:34 GMT-0500 (Central Daylight Time)*

  */



  useEffect(() => { // this is the useEffect that fetches groups and fills the groupObjArray
    if(alreadyFetchedGroups || isAthlete) { // how to NOT return early if this was a refreshSB-induced useEfect()?
      return;
    }

    getGroups(true)
      .then(item => {
        // let parentsArray.current = [];  // using useRef version now, with parentsArray.current
        parentsArray.current = [];  // using useRef version now, with parentsArray.current
        let returnedGroupArray = item;
        let tempGroupObjArray = [] // was []
        // let orderedGroupObjArray = item; // was []
        setAlreadyFetchedGroups(true);
        if(mounted.current) { // mounted.current is the current value of the mounted reference,
                              //  which persists across re-renders of Shell
          returnedGroupArray.forEach(groupObj => { // we are stepping through the array returned by the API
            // the array has all the groups in the order they would be listed in the sidebar if all children were displayed
            // - this means that every descendant of a group will be below it in the list somewhere...
            // - if we find a has_children_boolean asserted for a group, we can create a children_array for it, and save the parentId in parentsArray
            // -- like this: if (groupObj.has_children_boolean && !parentsArray.indexOf(groupObj.id_guid) === -1){ parentsArray.push(groupObj.id_guid) }
            // -- as we continue to move thorough the array we should find any children immediately afterwards,
            // -- we would be scanning for them with tempParentsArray.find(groupObj.parent_group_id_guid)
            let groupId = groupObj.id_guid;
            let grpName = groupObj.name_string;
            let hasKids = groupObj.has_children_boolean;
            let level   = groupObj.level_integer;
            // every group will need a CGR-friendly object to represent it
            let parentObj = {
              id: groupId, 
              level: level,                 // this is used for something later?
              name: grpName, 
              closed: true,                 // all groups will default to closed
              kids: hasKids ? [] : null     // this null will be used later to mean noKids, instead of having a boolean
            }
            
            if (hasKids){ 
              // check to see if this group needs to be added to parentsArray.current, and add it if required
              if (!parentsArray.current.find(parent => parent.id === groupId)){ // const objAfter = parentsArray.current.find(parent => parent.id === id);
                console.log('~~~~~ Shell(), useEffect() for groups - about to add ' + grpName + ' to parentsArray.current');
 
                //  make an array of the direct children of the parent we are working with, and add it to the parentObj
                parentObj.kids = returnedGroupArray.filter(group => group.parent_group_id_guid === groupId);
              }  
              parentsArray.current.push(parentObj); 
              console.log('~~~~~ Shell(), useEffect() for groups - after updating, parentsArray.current.length is ' + parentsArray.current.length);
            }

            console.log("~~~~~ Shell(), useEffect() for groups, tempGroupArray.forEach() - grpName: " + grpName + ', hasKids: ' + hasKids);

            let thisGroupObject = {...groupObj, is_collapsed: true}; // show_children sets caret, is_visible sets height
            tempGroupObjArray.push(thisGroupObject);
            console.log("~~~~~ Shell(), useEffect() for groups, tempGroupArray.forEach() - fetched groupObject for group: " + thisGroupObject.name_string + ", has_children_boolean is " + thisGroupObject.has_children_boolean);

          }) // end of the tempGroupArray.forEach
          // setGroupArray(tempGroupArray); // TODO - is this useful?
          setGroupObjArray(tempGroupObjArray);
          setLoaded(true);

        } // end of if nounted.current conditional
      }) // end of getGroups.then(item => { })
  }, [groupObjArray, group286, alreadyFetchedGroups, isAthlete, parentsArray])

  
  // localStorage.setItem('signInDateTime', '2024-08-16');

  useEffect(() => { // this is the useEfect that checks the age of the authentication token, and redirects to sign-in if older than 28 days

    // const limitDays = 28;
    // const signInDate = new Date(convert(localStorage.getItem('signInDateTime')));
    // const today = new Date(convert(Date()));

    // console.log('~~~~~ Shell(), useEffect() - auth - signInDate is *' + signInDate + '*');
    // console.log('~~~~~ Shell(), useEffect() - auth - today is *' + today + '*');

    // const diffDays = datediff(signInDate, today);
    // console.log('~~~~~ Shell(), useEffect() - auth - token diffDays is *' + diffDays + '*');

    // if (diffDays > limitDays) {
    //   logout();
    // }

    checkUserAuth()
      .then(authHeader => {
        console.log("~~~~~ Shell(), useEffect() for auth, checkUserAuth.then() - authHeader:" + authHeader + '*');
        if (authHeader === null){
          logout();
        }

      }) // end of checkUserAuth.then(authHeader => { })

  }, [])

  /*
  useEffect(() => { // this is the useEffect that fetches groups and fills the groupObjArray
    if(isAthlete) { // how to NOT return early if this was a refreshSB-induced useEfect()?
      return;
    }

    getGroups(true)
      .then(item => {
        // let parentsArray = [];  // using useRef version now, with parentsArray.current
        let tempGroupArray = item;
        let tempGroupObjArray = [] // was []
        // let orderedGroupObjArray = item; // was []
        if(mounted.current) { // mounted.current is the current value of the mounted reference,
                              //  which persists across re-renders of Shell
          tempGroupArray.forEach(groupObj => { // we are stepping through the array returned by the API
            // the array has all the groups in the order they would be listed in the sidebar if all children were displayed
            // - this means that every descendant of a group will be below it in the list somewhere...
            // - if we find a has_children_boolean asserted for a group, we can create a children_array for it, and save the parentId in parentsArray
            // -- like this: if (groupObj.has_children_boolean && !parentsArray.indexOf(groupObj.id_guid) === -1){ parentsArray.push(groupObj.id_guid) }
            // -- as we continue to move thorough the array we should find any children immediately afterwards,
            // -- we would be scanning for them with tempParentsArray.find(groupObj.parent_group_id_guid)
            let groupId = groupObj.id_guid;
            let grpName = groupObj.name_string;
            let hasKids = groupObj.has_children_boolean;
            let level   = groupObj.level_integer;
            // every group will need a CGR-friendly object to represent it
            let parentObj = {
              id: groupId, 
              level: level,                 // this is used for something later?
              name: grpName, 
              closed: true,                 // all groups will default to closed
              kids: hasKids ? [] : null     // this null will be used later to mean noKids, instead of having a boolean
            }
            
            if (hasKids){ 
              // check to see if this group needs to be added to parentsArray.current, and add it if required
              if (!parentsArray.current.find(parent => parent.id === groupId)){ // const objAfter = parentsArray.current.find(parent => parent.id === id);
                console.log('~~~~~ Shell(), useEffect() for groups - about to add ' + grpName + ' to parentsArray.current');
 
                //  make an array of the direct children of the parent we are working with, and add it to the parentObj
                parentObj.kids = tempGroupArray.filter(group => group.parent_group_id_guid === groupId);
              }  
              parentsArray.current.push(parentObj)
              console.log('~~~~~ Shell(), useEffect() for groups - after updating, parentsArray.current.length is ' + parentsArray.current.length); 
            }

            console.log("~~~~~ Shell(), useEffect() #2 for groups, tempGroupArray.forEach() - grpName: " + grpName + ', hasKids: ' + hasKids);

            let thisGroupObject = {...groupObj, is_collapsed: true}; // show_children sets caret, is_visible sets height
            tempGroupObjArray.push(thisGroupObject);
            console.log("~~~~~ Shell(), useEffect() #2 for groups, tempGroupArray.forEach() - fetched groupObject for group: " + thisGroupObject.name_string + ", has_children_boolean is " + thisGroupObject.has_children_boolean);

          }) // end of the tempGroupArray.forEach
          // setGroupArray(tempGroupArray); // TODO - is this useful?
          setGroupObjArray(tempGroupObjArray);

        } // end of if nounted.current conditional
      }) // end of getGroups.then(item => { })
  }, [isAthlete, refreshSB])
*/

  return (
    <div>
      {!isAthlete &&
        <div className="ShellWrapperDiv" style={{ display: "flex", flexDirection: "row" }}>
          {!show.includes("no_sidebar") &&
            <ShellSidebar 
              className="ShellSidebar"
              style={{ display: "flex", opacity: "1.00" }}
              logout={logout} 
              deviceInfo={deviceInfo} 
              key={refreshSB}
              activeGroupId={group.id_guid}
              group286={group286}
              selectedBtn={sidebarBtn}
              groupTreesArray={groupTreesArray.current}
              onSidebarBtnClick={handleSidebarBtnClick} 
              onCaretChange={handleCaretChange}
              onLogoClick={handleLogoClick} 
              topLogoSrc={logosArray[topLogoSrcIndex.current]}
              groupObjArray={groupObjArray} 
              parentsArray={parentsArray.current}
            />
          } 
          <div className="ShellContentDiv" style={{ display: "flex", width: "100%" }}>
            {loaded ? '' : <LoadingDivLayer />} 
            <SplitTabContentWrapper2 className="SplitTabContentWrapper2" loaded={loaded} style={{ opacity: "0.99", width: "100%" }}>
              <TabSectionLeft2 className="TabSectionLeft2" style={{ display: "flex", opacity: "0.98" }}>
                {(!show.includes("user_l") && !show.includes("workout_l")) &&
                  <TabSectionHeader className="TabSectionHeader_l" style={{ opacity: "0.97" }} active={true} onClick={() => clickHeader()}>
                    <TabSectionHeaderTitle className="TabSectionHeaderTitle_l" text={leftTitleString} iconSrc={leftTitleIconSrc} />
                  </TabSectionHeader>
                }
                <TabSectionBody className="TabSectionBody_l" style={{ height: "calc(100vh - 30px)", overflowY: "scroll" }}>
                  {show.includes("group_edit_l") &&
                    <GroupEdit
                      create={false}
                      onSaveClick={handleSaveClick}  
                      item={group}
                      propKey="group_edit_l"
                      key="group_edit_l"
                    />
                  }
                  {show.includes("explore_l") &&
                    <Explore 
                      className="explore"
                      refr="_l" // can be parsed to get scope (all|group|user|workout|exercise), adding, side
                      results={allResults}
                      handlers={exploreHandlers}
                    />
                  }
                  {show.includes("explore_group_l") &&
                    <Explore 
                      className="explore_group_l" 
                      refr="group_l" 
                      key={"group_l_" + refresh}
                      scopeId={idGroupL}
                      results={groupResultsL}
                      handlers={exploreHandlers}
                    />
                  }
                  {show.includes("explore_exercise_l") &&
                    <Explore 
                      key={"explore_exercise_l" + refreshExercises} // refreshExercises, exerciseResults
                      className="explore_exercise_l"
                      refr="exercise_l"  
                      //scopeId={id_group_l}
                      results={exerciseResults}
                      handlers={exploreHandlers}
                    />
                  }
                  {show.includes("explore_workout_l") &&
                    <Explore 
                      key={"explore_workout_l" + refreshWorkouts}
                      className="explore_workout_l"
                      refr="workout_l" 
                      scope="workout"
                      // scopeId={id_group_l}
                      results={workoutResults}
                      handlers={exploreHandlers}
                    />
                  }
                  {show.includes("user_l") && 
                    <UserSection
                      isMobile={isMobile} 
                      key={"user_l" + refresh}
                      refr="user_l"
                      state={userState} 
                      handlers={userHandlers} 
                      exploreHandlers={exploreHandlers} 
                      scopeId={idUser} // should this be something like idGroupUserSection? or idGroupUser?
                      results={userResults}
                      onTabChange={handleUserTabChange}
                      onComplianceClick={handleComplianceClick}
                      activeTabIndex={activeUserTabIndex}
                      refreshUser={refreshUser}
                    />
                  }
                  {show.includes("workout_section_l") && 
                    <WorkoutSection
                      // className="workout_section_l" // TODO: add 'edit' in these various reference strings? 
                      key={"workout_section_l" + refreshWorkouts}
                      refr="workout_section_l"
                      isMobile={isMobile}
                      refresh={refreshWorkouts}
                      results={workoutTabResults}
                      item={workout}
                      movement={movement}
                      activeTabIndex={activeWorkoutTabIndex}

                      handlers={exploreHandlers}
                      onSaveClick={handleSaveClick}
                      onDuplicate={handleWorkoutDuplicate}
                      onAddExercise={handleWorkoutAddExercise}
                      onVideoClick={handleVideoClick}
                      onTabChange={handleWorkoutTabChange}
                      onClearMovement={handleClearMovement}
                    />
                  }
                  {show.includes("workout_edit_l") && 
                    <WorkoutSection
                      // className="workout_section_l" // TODO: add 'edit' in these various reference strings?
                      key={"workout_section_l_" + refreshWorkouts}
                      refr="workout_section_l" 
                      isMobile={isMobile}
                      item={workout}
                      movement={movement}
                      exercises={exercises} // TODO: do we need this? or is the list of exercises fetched by PickList inside of WorkoutEdit?
                      create={false}
                      refresh={refreshWorkouts}
                      results={workoutTabResults}
                      activeTabIndex={activeWorkoutTabIndex}

                      handlers={exploreHandlers}
                      onSaveClick={handleSaveClick}
                      onDuplicate={handleWorkoutDuplicate}
                      onAddExercise={handleWorkoutAddExercise}
                      onVideoClick={handleVideoClick}
                      onTabChange={handleWorkoutTabChange}
                      onClearMovement={handleClearMovement}
                  />
                  } 

                  {show.includes("workout_create_l") && 
                    <WorkoutSection
                      // className="workout_section_l" // TODO: add 'create' in these various reference strings?
                      key={"workout_section_l_" + refreshWorkouts}
                      refr="workout_section_l" 
                      isMobile={isMobile}
                      item={workout}
                      movement={movement}
                      exercises={exercises} // TODO: do we need this? or is the list of exercises fetched by PickList inside of WorkoutEdit?
                      create={true}
                      refresh={refreshWorkouts}
                      results={workoutResults}
                      activeTabIndex={activeWorkoutTabIndex}

                      handlers={exploreHandlers}
                      onSaveClick={handleSaveClick}
                      onDuplicate={handleWorkoutDuplicate}
                      onAddExercise={handleWorkoutAddExercise}
                      onVideoClick={handleVideoClick}
                      onTabChange={handleWorkoutTabChange}
                      onClearMovement={handleClearMovement}
                  />
                  } 

                </TabSectionBody>
              </TabSectionLeft2>
              
              <TabSectionRight2 className="TabSectionRight2">
                {!show.includes("user_r") &&
                  <TabSectionHeader className="TabSectionHeader_r">
                    <TabSectionHeaderTitle className="TabSectionHeaderTitle_r" text={rightTitleString} iconSrc={rightTitleIconSrc} />
                  </TabSectionHeader>
                }
                <TabSectionBody className="TabSectionBody_r" style={{ height: "calc(100vh - 30px)", overflowY: "scroll" }}> 
                {show.includes("group_edit_r") &&
                    <GroupEdit
                      create={false}
                      onSaveClick={handleSaveClick}  
                      item={groupR}
                      propKey="group_edit_r"
                      key="group_edit_r"
                    />
                  }
                  {show.includes("user_r") && 
                    <UserSection className="UserSection" 
                      isMobile={isMobile}
                      key={"user_r" + refresh}
                      refr="user_r"
                      state={userState} 
                      handlers={userHandlers} 
                      exploreHandlers={exploreHandlers}
                      scopeId={idUser} // should this be something like idGroupUserSection? or idGroupUser?
                      results={userResults}
                      onTabChange={handleUserTabChange}
                      onComplianceClick={handleComplianceClick}
                      activeTabIndex={activeUserTabIndex}
                      refreshUser={refreshUser}
                    />
                  }

                  {show.includes("user_invite_r")  && <UserInvite className="UserInvite" key="user_invite_r" groupId={group.id_guid} onInviteSubmit={handleInviteSubmit} />}

                  {show.includes("user_pending_r") && <UserPending className="UserPending" key="user_pending_r" state={userState} groupId={group.id_guid} onInviteSubmit={handleInviteSubmit} onAcceptUser={handleAcceptUser} />}

                  {show.includes("group_create_r") && <GroupEdit className="GroupEdit" key="group_create_r" create="true" item={mtGroup} parent={parentId} propKey="group_create_r" onSaveClick={handleSaveClick} />}

                  {show.includes("explore_group_r") &&
                    <Explore 
                      className="explore_group_r"
                      key={"explore_group_r_" + refresh}
                      refr="group_r" 
                      scopeId={idGroupR}
                      results={groupResultsR}
                      handlers={exploreHandlers}
                    />
                  }
                  
                  {show.includes("explore_add_to_group_r") &&
                    <Explore 
                      className="explore_add_to_group_r"
                      refr="group_add_r" 
                      key={"group_add_r_" + refresh}
                      scopeId={idGroupL}
                      results={groupAddResults}
                      handlers={exploreHandlers}
                    />
                  }

                  {show.includes("explore_user_r") &&
                    <Explore 
                      className="explore_user_r"
                      refr="user_r" 
                      key={"user_r_" + refresh}
                      scopeId={idUser}
                      results={allResults}
                      handlers={exploreHandlers}
                    />
                  }
                  
                  {show.includes("explore_add_to_user_r") &&
                    <Explore 
                      className="explore_add_to_user_r"
                      refr="user_add_r" 
                      key={"user_add_r_" + refresh}
                      scopeId={idUser}
                      results={userAddResults}
                      handlers={exploreHandlers}
                    />
                  }

                  {show.includes("workout_edit_r") && 
                    <WorkoutSection 
                      // className="workout_section_r" // TODO: add 'edit' in these various reference strings?
                      key={"workout_section_r_" + refreshWorkouts}
                      refr="workout_section_r" 
                      isMobile={isMobile}
                      item={workout}
                      exercises={exercises} // TODO: do we need this? or is the list of exercises fetched by PickList inside of WorkoutEdit?
                      create={false}
                      refresh={refreshWorkouts}
                      results={workoutTabResults}
                      activeTabIndex={activeWorkoutTabIndex}

                      handlers={exploreHandlers}
                      onSaveClick={handleSaveClick}
                      onDuplicate={handleWorkoutDuplicate}
                      onAddExercise={handleWorkoutAddExercise}
                      onVideoClick={handleVideoClick}
                      onTabChange={handleWorkoutTabChange}
                      onClearMovement={handleClearMovement}
                  />
                  } 

                  {show.includes("workout_create_r") && 
                    <WorkoutSection
                      // className="workout_section_r" // TODO: add 'create' in these various reference strings?
                      key={"workout_section_r_" + refreshWorkouts}
                      refr="workout_section_r" 
                      isMobile={isMobile}
                      item={workout}
                      exercises={exercises} // TODO: do we need this? or is the list of exercises fetched by PickList inside of WorkoutEdit?
                      create={true}
                      refresh={refreshWorkouts}
                      results={workoutResults}
                      activeTabIndex={activeWorkoutTabIndex}

                      handlers={exploreHandlers}
                      onSaveClick={handleSaveClick}
                      onDuplicate={handleWorkoutDuplicate}
                      onAddExercise={handleWorkoutAddExercise}
                      onVideoClick={handleVideoClick}
                      onTabChange={handleWorkoutTabChange}
                      onClearMovement={handleClearMovement}
                  />
                  } 

                  {/* explore_add_to_workout_r */}
                  {show.includes("explore_add_to_workout_r") &&
                    <Explore 
                      className="explore_add_to_workout_r"
                      refr="workout_add_r" 
                      key={"workout_add_r_" + refresh}
                      scopeId={idWorkout}
                      // results={workoutAddResults} //workoutTabAddResults?
                      results={workoutTabAddResults}
                      handlers={exploreHandlers}
                    />
                  }

                  {show.includes("explore_movement_r") &&
                    <Explore 
                      className="explore_movement_r"
                      key={"explore_movement_r_" + refreshMovements}
                      refr="movement_r" 
                      scope="movement"
                      scopeId={null} // this needs to be the id for the currently editing workout, or null
                      results={movementResults}
                      handlers={exploreHandlers}
                    />
                  }
                  
                  {/* 'exercise_edit_r' - eliminate one of these create=true|create=false instances, replace with state var create={creatingExercise} */}

                  {show.includes("exercise_edit_r") &&       
                    <ExerciseEdit 
                      className="exercise_edit_r"
                      refr="exercise_edit_r" 
                      key="exercise_edit_r_"
                      item={exercise}
                      create={false}
                      onSaveClick={handleSaveClick} 
                    /> 
                  } 

                  {show.includes("exercise_create_r") &&                     
                    <ExerciseEdit 
                      className="exercise_edit_r"
                      refr="exercise_edit_r" 
                      key="exercise_edit_r_"
                      // item={exercise}
                      create={true}
                      onSaveClick={handleSaveClick} 
                    /> 
                  } 

                  {show.includes("compliance_r") &&                     
                    <ComplianceDetail 
                      className="compliance_r"
                      refr="compliance_r" 
                      key="compliance_r"
                      userId={complianceUserId}
                      workoutId={complianceWorkoutId}
                      assignedBy={complianceWorkoutAssignedBy}
                    /> 
                  } 

                </TabSectionBody>
              </TabSectionRight2>
              <Modal
                show={showVideo}
                onHide={handleCloseVideo}
                backdrop="static"
                keyboard={true}
                centered={true}
                size='lg'
                id="video_modal" 
                style={{ visibility: "hidden" }}
              >
                <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "100px" }}>
                  <Modal.Title style={{ opacity: "0.8", fontSize: "18px", width: "50%", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>{videoTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ opacity: "0.9" }}>
                  <video 
                    src={videoUrl}
                    // src="https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm"
                    // src="https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBYlU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0c769cedd6dd6ee0f5dbb4075052b22ef2e4e88b/87.mp4"
                    controls
                    autoPlay={true}
                    preload="none"
                    style={{ 
                      cursor: "pointer",
                      width: "90%", // use maxWidth?
                      // height: "60%", // use maxHeight?
                      margin: "auto",
                      display: "flex", 
                      // flexDirection: "column", 
                      alignItems: "center",
                      // border: "1px solid #eee", 
                      borderRadius: "3vw", 
                      borderTop:    "1px solid  #fff",
                      borderRight:  "1px solid #eee",
                      borderBottom: "1px solid #eee",
                      borderLeft:   "1px solid  #eee",
                      marginLeft: "auto",
                      marginRight: "auto",
                      // backgroundImage: thumbnail_url,
                      // backgroundRepeat: "no-repeat",
                      // backgroundSize: "contain",
                      // backgroundPosition: "center",
                      paddingTop: "12vw"
                    }}
                  >
                  </video>
                </Modal.Body>
                {/* <Modal.Footer style={{ borderTop: "none" }}>
                  <Button variant="primary" style={{ width: "20vw", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleCloseVideo}>Close</Button>
                </Modal.Footer> */}
              </Modal>
            </SplitTabContentWrapper2>
          </div>
        </div>
      }
      {isAthlete &&
        <div className="ShellWrapperDiv" style={{ display: "flex", flexDirection: "row" }}>
          <Athlete logout={logout} isMobile={isMobile} deepLink={deepLink} />
        </div>
      }
    </div>
  );
}

export default Shell;

/*

  <ReactPlayer                                                    
    className="videoFrame"
    url={video_url}
    light={thumbnail_url}
    playing
    controls
    style={{ width: "100px", height: "100px" }}
  />

  <video controls
    style={{ width: "100px", height: "100px" }}
    // src='/Slam Dunk Contest 2008.mp4'
    // poster='https://user-images.githubusercontent.com/28612032/172026551-e5a96748-d724-4a08-b6b3-f44655d4ef39.png'
    src={video_url}
    poster={thumbnail_url}
    width="100px"
  >

    Sorry, your browser doesn't support embedded videos,
    but don't worry, you can <a href={video_url}>download it</a>
    and watch it with your favorite video player!

  </video>
                  
  <video 
    src={video_url}
    controls
    autoPlay={false}
    preload="none"
    // height="480vw" 
    // width="480vw"
    poster={thumbnail_url}
    style={{ 
      cursor: "pointer",
      // width: "80vw", 
      // height: "80vw", 
      width: "40px", 
      height: "100px", 
      display: "flex", 
      // flexDirection: "column", 
      alignItems: "center",
      // border: "1px solid #eee", 
      borderRadius: "3vw", 
      borderTop:    "1px solid  #fff",
      borderRight:  "1px solid #eee",
      borderBottom: "1px solid #eee",
      borderLeft:   "1px solid  #eee",
      marginLeft: marginLeft,
      // backgroundImage: thumbnail_url,
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "contain",
      // backgroundPosition: "center",
      paddingTop: "12vw"
    }}
  >
  </video> 

*/


