// exploreStuff.js

import styled from 'styled-components';
import { colors } from '../../constants';

const tabSectionItemsWidth = "100%";
const tabSectionItemsVerticalSpacing = "20px";

const ExploreBarCircleButton = styled.div`
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: inline-block;
  border: 1px solid black;
  padding-left: 2px;
  border-radius: 14px;
  overflow: clip;
  margin-left: 10px;
  margin-right: 0px;
  ${ props => props.selected === true
    ? '{ background-color: #5674fe }'
    : '{ background-color: #dddddd }' // use colors.sidebarBg ?
  }
`;

const ExploreBarOvalButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 28px;
  display: inline-block;
  border: 1px solid black;
  padding-top: 3px;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
  overflow: clip;
  margin-left: 8px;
  margin-right: 0px;
  background: #dddddd;
`;

const ExploreBarSearchInput = styled.input`
  width: 290px;
  height: 28px;
  background: #dddddd;
  border: 1px solid black;
  border-radius: 14px;
  padding-left: 15px;
  margin-left: 10px;
`;

const ExploreBarWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  white-space: nowrap;
  padding: 0px;
  padding-top: 10px;
  padding-left: 34px;
  margin: auto;
`;

const ExploreHeaderCell = styled.td`
  height: 30px;
  text-align: center;
  background-color: #eeeeee; 
`;

const ExploreIcon = styled.img`
  display: inline-block;
  width: 28px;
  height: 28px;
  margin: 0 0 0 0 !important;
  /* margin-top: 0px !important; */
  padding: 0 !important;
  opacity: 1.0;
`;

const ExploreTableBodyWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 320px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  text-align: center;
`;

const ExploreTableCell = styled.td`
  height: 35px;
  text-align: center;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  border-bottom: 1px solid #eeeeee;
  /* border: 0.5px solid red; */
`;

const ExploreTableMovementCell = styled.td`
  /* width: 100%; */
  height: 120px;
  text-align: left;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  /* border: 2px solid #bbbbbb;
  border-radius: 10px; */
  border-bottom: 1px solid #ccc;
  padding: 5px;
`;

const ExploreTableHeader = styled.thead`
  width: 100%;
  height: 30px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  margin-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  border-radius: 12px;
`;

const ExploreTableRow = styled.tr`
  width: 100%;
  border-top: 2px solid white;
  ${ props => props.selected === true
    ? '{ background-color: #eeeeee }'
    : '{ background-color: #ffffff }' // use colors.sidebarBg ?
  }
  ${ props => props.dimmed === true
    ? '{ opacity: 0.5 }'
    : '{ opacity: 1.0 }'
  }
`;

const ExploreTableMovementRow = styled.tr`
  width: 100%;
  /* border-collapse: separate; */
  border-top: 2px solid white;
  /* border-bottom: 2px solid black; */
  ${ props => props.selected === true
    ? '{ background-color: #eeeeee }'
    : '{ background-color: #ffffff }' // use colors.sidebarBg ?
  }
  ${ props => props.dimmed === true
    ? '{ opacity: 0.5 }'
    : '{ opacity: 1.0 }'
  }
`;

const ExploreTableRowMessage = styled.div`
  cursor: pointer;
  color: ${colors.exploreBlueBtn};
  ${ props => props.staffAccept === true
    ? '{ color: #ffcccc }'
    : ''
  }
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
`;

const ExploreTableWrapper = styled.div`
  width: ${tabSectionItemsWidth};
  max-width: 700px;
  min-height: 360px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  margin: auto;
  margin-top: ${tabSectionItemsVerticalSpacing};
  margin-right: -15px;
  text-align: left;
`;

const ExploreWrapper = styled.div`
  width: 100%;
  min-height: 400px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  text-align: left;
  margin-top: ${tabSectionItemsVerticalSpacing};
`;


export { 
  ExploreBarCircleButton,
  ExploreBarOvalButton,
  ExploreBarSearchInput,
  ExploreBarWrapper,
  ExploreHeaderCell,
  ExploreIcon,
  ExploreTableBodyWrapper,
  ExploreTableCell,
  ExploreTableMovementCell,
  ExploreTableHeader,
  ExploreTableRow,
  ExploreTableMovementRow,
  ExploreTableRowMessage,
  ExploreTableWrapper,
  ExploreWrapper
};