// /components/styled/ExerciseCard.js

import React, { useEffect, useRef, useState } from 'react';

import { Icon, RedSpan, Thumbnail, AreaTitle, ScrollingListContainer, ListContentRow, ListContentBox } from './title';
import { iconSrc } from '../../constants';


const ExerciseCard = ({
  setLoadUsed, exercise
}) => {
  const inputWidth = "60vw";
  const inputHeight = "6vw";
  const inputFontSize = "3vw";
  const inputBorderRadius = "2.5vw";
  const inputPaddingLeft = "2vw";
  const marginLeft = "0vw";
  // const thumbnail_url = "url(" + exercise.thumbnail_url_string + ")";
  const thumbnail_url = exercise.thumbnail_url_string;
  const video_url = exercise.video_url_string;
  // const play_arrow_url  = "url(" + exercise.thumbnail_url_string + ")";

  const name_display_string = exercise.name_string;
  const notes_display_string = "Notes: " + exercise.note_string;
  const hasNote = exercise.note_string && exercise.note_string.length > 0;
  
  const distance_integer = exercise.distance_integer;
  const distance_string = exercise.distance_string;
  const hasDistance = distance_integer && parseInt(distance_integer) > 0 && distance_string && distance_string.length > 0;
  const distance_display_string = hasDistance ? distance_integer + " " + distance_string : "";

  const duration_integer = exercise.duration_integer;
  const duration_string = exercise.duration_string;
  const hasDuration = duration_integer && parseInt(duration_integer) > 0 && duration_string && duration_string.length > 0;
  const duration_display_string = hasDuration ? duration_integer + " " + duration_string + ".": "";

  const load_string = exercise.load_string;
  const hasLoad = load_string && load_string.length > 0;
  const load_display_string = hasLoad ? load_string + ", ": "";

  const repetition_integer = exercise.repetition_integer;
  const hasReps = repetition_integer && parseInt(repetition_integer) > 0;
  const repetition_display_string = hasReps ? " of " + repetition_integer + " Reps ": "";

  const set_integer = exercise.set_integer;
  const hasSets = set_integer && parseInt(set_integer) > 0;
  const set_display_string = hasSets ? set_integer + " Sets ": "";

  const instruction = set_display_string + 
    repetition_display_string + 
    " at " + load_display_string + 
    " for " + distance_display_string + 
    " or " + duration_display_string;

  console.log('~~~~~ ExerciseCard(), thumbnail_url for background is ' +  thumbnail_url);
  console.log('~~~~~ ExerciseCard(), exercise.id_guid is ' +  exercise.exercise_id_guid);

  return (
    <div className="ExerciseCard" 
      // key={keyString}
      style={{
      //   background: "url('Shell_assets/fakes/DW-22_Workout_exercise_and_load_input.png')",
      //   backgroundSize: "cover"
      paddingBottom: "3vh"
      }}
    >
    <div className="ExerciseMetadataAndVideo">
      <div style={{ width: "80vw", marginLeft: marginLeft, fontSize: "3.5vw", fontWeight: "bold" }}>{name_display_string}</div>
      <div style={{ width: "80vw", marginLeft: marginLeft, fontSize: "3vw", fontWeight: "bold" }}>{instruction}</div>
      {hasNote &&
        <div style={{ width: "80vw", marginLeft: marginLeft, fontSize: "2.5vw", fontWeight: "bold", color: "#888", paddingBottom: "1vw" }}>{notes_display_string}</div>
      }
      {/* <div 
        className="VideoThumbnail" 
        style={{ 
          cursor: "pointer",
          width: "80vw", 
          height: "40vw", 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center",
          border: "1px solid #eee", 
          borderRadius: "3vw", 
          marginLeft: marginLeft,
          backgroundImage: thumbnail_url,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          paddingTop: "12vw"
        }}
      >
        <Icon style={{ width: "12vw", height: "12vw", opacity: "0.5" }} src={iconSrc.play} />
      </div> */}
      <video 
        src={video_url}
        controls
        autoPlay={false}
        preload="none"
        // height="480vw" 
        // width="480vw"
        poster={thumbnail_url}
        style={{ 
          cursor: "pointer",
          width: "80vw", 
          height: "80vw", 
          display: "flex", 
          // flexDirection: "column", 
          alignItems: "center",
          // border: "1px solid #eee", 
          borderRadius: "3vw", 
          borderTop:    "1px solid  #fff",
          borderRight:  "1px solid #eee",
          borderBottom: "1px solid #eee",
          borderLeft:   "1px solid  #eee",
          marginLeft: marginLeft,
          // backgroundImage: thumbnail_url,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "contain",
          // backgroundPosition: "center",
          paddingTop: "12vw"
        }}
      >
      </video>
    </div>
    {hasLoad &&
      <label className="LoadLabel" style={{ width: "80vw", marginLeft: marginLeft, marginTop: "2vw", fontSize: "3.5vw", fontWeight: "bold" }}>
        Load
        <input 
          className="LoadInput" 
          name="load_used" 
          type="text"
          placeholder="What load did you actually use?"
          title="Enter the load you used for this exercise" 
          onChange={e => setLoadUsed(e, exercise.exercise_id_guid, e.target.value)} 
          style={{ 
            width: inputWidth, 
            height: inputHeight, 
            fontSize: inputFontSize,
            marginLeft: "2vw",
            border: "2px solid #333333",
            borderRadius: inputBorderRadius,
            paddingLeft: inputPaddingLeft
          }} 
        />
      </label>
    }
    

  </div>
  );
}

export default ExerciseCard;