// ExerciseEdit.js

import React, { useState, useCallback, useRef, useEffect } from 'react';

import { createExercise, updateExercise } from '../../services';
import { MouseOverVideoDiv } from './title';
import {
  ShellOvalBtn,
  ShellInputLabel,
  ShellFieldDisplayName,
  ShellTextInput,
  ShellTextarea
} from './title';
import PickList from './PickList';
import exercise from './../../models/exercise';


const ExerciseEdit = ({
  create, item, onSaveClick
}) => {
  const modelInfo = exercise;
  // const fields = modelInfo.displayFields;

  const fields = [ // TODO: figure out why the line above did not work
    { // name
      type: "text",                           // could be "text", "textarea", "association", "image"?, "video"?
      displayName: "Name",                   // any string up to a maxLength of 20 characters
      placeholder: "Enter the name or title of the Exercise",                        // a string that will show in the text input until something is entered there
      accessor: "name_string",                // attribute name when item delivered from API
      default: "",                            // value of this attribute for a brand new 'blank' item
      maxChars: "20",                         // an integer as a string, if we are going to try to limit this...
      minChars: "2",                          // an integer as a string, if we are going to try to limit this, or use as empty check
      pickListInfo: "",                       // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
      required: true                          // should this field be submitted to the api for a create or update access?
    },
    // { // subtitle
    //   type: "text",
    //   displayName: "Subtitle",
    //   placeholder: "Enter a subtitle, if desired", 
    //   accessor: "subtitle_string",
    //   default: "",
    //   maxChars: "20",
    //   minChars: "2",
    //   pickListInfo: "",
    //   required: false
    // },
    // { // video
    //   type: "video",
    //   displayName: "Video (URL) [mouseover video to play, once URL entered]",
    //   placeholder: "Enter something like https://ourdomain.com/videos/move_1.mp4",
    //   accessor: "video_url_string",
    //   default: iconSrc.exercises,             // TODO: put an asset in src/public to show when no url provided
    //   maxChars: "2000",
    //   minChars: "",
    //   pickListInfo: "",
    //   required: true 
    // },
    // { // thumbnail
    //   type: "image",
    //   displayName: "Thumbnail (URL)",
    //   placeholder: "Enter something like https://ordomain.com/thumbnails/move_1.png",
    //   accessor: "thumbnail_url_string",
    //   default: iconSrc.exercises_active,      // TODO: put an asset in src/public to show when no url provided
    //   maxChars: "2000",
    //   minChars: "",
    //   pickListInfo: "",
    //   required: true 
    // },
    // { // tags
    //   type: "text",
    //   displayName: "Tags",
    //   placeholder: "Enter tags, separated by commas",
    //   accessor: "tag_list_string",
    //   default: "",
    //   maxChars: "64",
    //   minChars: "2",
    //   pickListInfo: "", 
    //   required: true
    // },
    { // movement_id_guid
      type: "movement",
      displayName: "Video",
      placeholder: "Choose the Exercise Movement",  // words that might explain or prompt for picklist?
      accessor: "movement_id_guid",
      default: "",
      maxChars: "64",
      minChars: "2",
      pickListInfo: "movement", 
      required: true
    },
    { // duration_seconds_integer
      type: "text", // how about "text_integer", so we can provide a picker or validate the string provided?
      displayName: "Duration, in seconds",
      placeholder: "Enter required duration, if applicable",
      accessor: "duration_seconds_integer",
      default: "",
      maxChars: "64",
      minChars: "2",
      pickListInfo: "", // maybe csv list of integers? "5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,105,110,115,120"
      required: true
    },
    { // repetition_integer
      type: "text", // how about "text_integer", so we can provide a picker or validate the string provided?
      displayName: "Repetitions",
      placeholder: "Enter required repetitions, if applicable",
      accessor: "repetition_integer",
      default: "",
      maxChars: "64",
      minChars: "2",
      pickListInfo: "",  // maybe csv list of integers? "1,2,3,4,..."
      required: true
    },
    { // note
      type: "textarea",
      displayName: "Notes",
      placeholder: "Visible to Athletes, and can explain specifics regarding this Exercise",
      accessor: "note_string",
      default: "",
      maxChars: "2000",
      minChars: "",
      pickListInfo: "",
      required: true 
    },
    { // id
      type: "hidden",
      displayName: "ID",
      accessor: "id_guid",
      default: "",                            // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-id_guid")
      maxChars: "64",
      minChars: "",
      pickListInfo: "",
      required: false
    },
    { // user_id
      type: "hidden",
      displayName: "User ID",
      accessor: "user_id_guid",               // TODO: create a non-valid guid as a default vale (e.g. "not-a-valid-user_id_guid")
      default: "",                        
      maxChars: "64",
      minChars: "",
      pickListInfo: "",
      required: false
    }
  ];
  if (!item || !item.id_guid || !item.name_string) { item = defaultItem(fields); }
  const [formItem, setFormItem] = useState(item);  
  const myId = localStorage.getItem('loggedInUser');
  const notEditable = item && item.user_id_guid && item.user_id_guid.length > 20 && item.user_id_guid !== myId;
  const publicRecordMsg = "This record is Public, and cannot be edited here";
  
  function noEditAlert(){
    alert(publicRecordMsg);
  }

  const videoRef = useRef(null);

  const handleMouseEnter = useCallback(() => { // bug where play/pause called too close together causes runtime error
    // The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22
    setTimeout(function () {      
      videoRef.current.play();
   }, 350);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTimeout(function () {      
      videoRef.current.pause();
   }, 350);
  }, []);
 
  useEffect(() => {
    console.log('~~~~~ ExerciseEdit.js, useEffect()');
    
    if (item === undefined || !item || !item.id_guid) {
      console.log('~~~~~ ExerciseEdit.js, useEffect() - early return, no item or no item.id_guid');
      return;
    }

    console.log('~~~~~ ExerciseEdit.js, useEffect() - going to set formItem');

    setFormItem(item);
  }, [item])

  if (!item) { // should never happen, because ternary sets default as item  (line 36)
    console.log('~~~~~ ExerciseEdit.js - there is no valid item, so we will set formItem to a default empty item');

    const mtItem = defaultItem(fields);

    setFormItem(mtItem);
  }

  // function uuidv4() {
  //   return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
  //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  //   );
  // }

  function defaultItem(fields) {
    // console.log('~~~~~ ExerciseEdit.js, defaultItem()');

    let emptyItem = {};
 
    fields.map((field) => {
      // console.log('~~~~~ ExerciseEdit.js, defaultItem() item[' + field.accessor + '] will be *' + field.default + '*');
      emptyItem[field.accessor] = field.default;
      // console.log('~~~~~ ExerciseEdit.js, defaultItem() emptyItem[' + field.accessor + '] is now *' + emptyItem[field.accessor] + '*');
  });

    return emptyItem;
  }

  // function checkItemChanged() { // compare current values for all item attributes in State, to values in item found in items (in State)
  //   // for Groups we would check itemName and itemNotes vsv
  //   // we would also want to check the associated_id_guids for as many associations as we allow editing of in this ViewEdit
  //   // we should probably save those guids in one or more collections in State somewhere...
  //   // then we could check the collections versus the collections on the appropriate item in the items array 
  //   // and if there are differences in the item attributes or associated items collection -> setEditingItem(true), 
    
  //   // in the functions that handle Submit, we would parse the missing or added guids and hit an api to remove or add, as required
  //   // IN ADDITION to submitting a new item or updating the existing item in the database...
  //   console.log('~~~~~ ExerciseEdit.js, checkItemChanged() 1');
    
  //   if (create) {
  //     console.log('~~~~~ ExerciseEdit.js, checkItemChanged() 2');
  //     return;
  //   }
  //   console.log('~~~~~ ExerciseEdit.js, checkItemChanged() 3');
    
  //   let changedFields = [];

  //   {fields.map((field) => {
  //       console.log('~~~~~ ExerciseEdit.js, checkItemChanged() 4, field.accessor');
  //       if (field.required) {
  //         console.log('~~~~~ ExerciseEdit.js, checkItemChanged() item[' + field.accessor + '] was *' + item[field.accessor] + '*');
  //         console.log('~~~~~ ExerciseEdit.js, checkItemChanged() formItem[' + field.accessor + '] is  now *' + formItem[field.accessor] + '*');
  //         if (item[field.accessor] !== formItem[field.accessor]) {
  //           console.log('~~~~~ ExerciseEdit.js, checkItemChanged() item has been changed!');
  //           changedFields.push(field.accessor);
  //         }
  //       }
  //     });

  //     if (changedFields.length > 0) {
  //       console.log('~~~~~ ExerciseEdit.js, checkItemChanged() item has been changed, ' + changedFields.length + ' fields were modified');
  //       // code here to make button show
  //       setEdit(true);
  //     } else {
  //       console.log('~~~~~ ExerciseEdit.js, checkItemChanged() item has not been changed, no field values were modified');
  //       // code here to make button hide
  //       setEdit(false);
  //     }
  //   }
  // }
  
  function handleValueChange(event) {

    if (notEditable){
      alert('not Editable here');
      noEditAlert();
    } else {
      const accessor = event.target.id;
      const tvalue = event.target.value;
      let newItem = Object.assign({}, formItem); // this clones the current formItem as newItem
  
      console.log('~~~~~ ExerciseEdit.js, ExerciseEdit() - handleValueChange() attribute changing is *' + accessor + '* and value is *' + tvalue + '*');
      console.log('~~~~~ ExerciseEdit.js, ExerciseEdit() - handleValueChange() - newItem[' + accessor + '] is *' + newItem[accessor] + '*');
  
      newItem[accessor] = tvalue;
  
      console.log('~~~~~ ExerciseEdit.js, ExerciseEdit() - handleValueChange() - after edit, newItem[' + accessor + '] is *' + newItem[accessor] + '*');
      
      setFormItem(newItem);
    
      // checkItemChanged();
  
      // console.log('~~~~~ ExerciseEdit.js, handleValueChange() - after checkItemChanged()');
    }
  }
  
  // function getFieldNames(fieldsArray) {

  //   console.log('~~~~~ Groups.js - ExerciseEdit - fieldsArray.Length for Exercise is ' + fieldsArray.length);
  //   let outputString = '';

  //   fieldsArray.forEach(field => {
  //     if (outputString.length > 0) {
  //       outputString = outputString + ", " + field.displayName;
  //     } else {
  //       outputString = field.displayName;
  //     }

  //   });

  //   return outputString;
  // }

  function handleCreateItemConfirmClick() {
    console.log('~~~~~ ExerciseEdit.js, handleCreateItemConfirmClick() - formItem.name_string is *' + formItem.name_string + '*');

    createExercise(formItem) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
    .then(thisItem => { // item is going to be what is returned from the service method... 

      onSaveClick("exercise", formItem); // this will refresh the Explore Exercises list

      let alertMsg = 'New Exercise with Name ' + thisItem.name_string + ' has been created and added to your list';

      setTimeout(function() {
        alert(alertMsg);
      }, 500);
    })
  }

  function handleUpdateItemConfirmClick() { // TODO: put this in Shell(), and add onWorkoutUpdate prop here
    console.log('~~~~~ ExerciseEdit.js - handleUpdateItemConfirmClick()');

    updateExercise(formItem) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
    .then(thisItem => { // item is going to be what is returned from the service method... 

      onSaveClick("exercise", formItem); // this will refresh the Explore Exercises list

      let alertMsg = 'Updated Workout with Name ' + thisItem.name_string + ', and id ' + thisItem.id_guid + ' has been updated in your list';

      setTimeout(function() {
        alert(alertMsg);
      }, 500);
    })
  }

  // function createViewEditInputs(itemType, fields, formItem) {
  //   console.log(`~~~~~ ExerciseEdit.js, createViewEditInputs() - fields are: ` + getFieldNames(fields));
  //   // note, if this is a workout-only edit form, it will have:
  //   // - 2 text inputs, 1 for Name and 1 for Interval
  //   // - 1 textarea input, for Notes
  //   // - 1 OrderedItemsList & Picker, for Exercises
  //   // - 1 submit button, labeled 'Create This Workout' or 'Update this Workout' (or simply 'Save', like GroupEdit?)
  //   return (
  //     <div>
  //       {fields.map((field) => {
  //         let value = (formItem && formItem[field.accessor]) ? formItem[field.accessor] : "";
          
  //         console.log('~~~~~ ExerciseEdit.js, createViewEditInputs() - for accessor ' + field.accessor + ', the value is ' + value);
  //         let inputCode = 
  //           <PrettyInputLabel style={{ width: "100%", marginBottom: "10px" }} key={field.accessor + '_label'}>
  //             <p>{field.displayName}</p>
  //             <PrettyInput
  //               key={field.accessor} 
  //               id={field.accessor}
  //               name={field.displayName}
  //               placeholder={field.placeholder}
  //               type={field.type} 
  //               autoComplete="name" 
  //               value={value} 
  //               onChange={e => handleValueChange(e)} // pass in this function?
  //               style={{width: "100%"}} 
  //             />
  //           </PrettyInputLabel>;

  //         if (field.type === 'hidden') { // do not display anything
  //           inputCode = 
  //           <>

  //           </>;
  //         }

  //         if (field.type === 'textarea') {
  //           inputCode = 
  //           <PrettyInputLabel style={{ width: "100%", marginBottom: "10px", overflow: "auto" }} key={field.accessor + '_label'}>
  //             <p>{field.displayName}</p>
  //             <PrettyTextArea
  //               key={field.accessor}
  //               id={field.accessor} 
  //               name={field.displayName}
  //               placeholder={field.placeholder}
  //               type={field.type} 
  //               autoComplete="name" 
  //               value={value} 
  //               onChange={e => handleValueChange(e)} // pass in this function?
  //               style={{width: "100%", height: "80px", fontSize: "smaller", verticalAlign: "top"}} 
  //             />
  //           </PrettyInputLabel>;      
  //         }

  //         if (field.type === 'image') { // will take a url for thumbnail if itemType is movement, exercise or program
  //           inputCode = 
  //           <>
  //             <PrettyInputLabel style={{ width: "100%", marginBottom: "20px" }} key={field.accessor + '_label'}>
  //             <p>{field.displayName}</p>
  //             <PrettyInput
  //               key={field.accessor}
  //               id={field.accessor} 
  //               name={field.displayName}
  //               placeholder={field.placeholder}
  //               type="text" 
  //               autoComplete="name" 
  //               value={value} 
  //               onChange={e => handleValueChange(e)} // pass in this function?
  //               style={{width: "100%", height: "45px", fontSize: "smaller", verticalAlign: "top"}} 
  //             /> 
  //             <Thumbnail src={value} style={{ height: "150px", marginTop: "-15px" }}/> {/* src="bars.svg" */}
  //             </PrettyInputLabel>
  //           </>;
  //         }

  //         if (field.type === 'video') { // will take a url for video if itemType is movement or exercise
  //           inputCode = 
  //           <>
  //             <PrettyInputLabel style={{ width: "100%", marginBottom: "20px" }} key={field.accessor + '_label'}>
  //               <p>{field.displayName}</p>
  //               <MouseOverVideoDiv style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
  //                 <video
  //                   onMouseEnter={handleMouseEnter}
  //                   onMouseLeave={handleMouseLeave}
  //                   ref={videoRef}
  //                   src={value}
  //                   type="video/mp4"
  //                   loop
  //                   className="w-100"
  //                   style={{ display: (value && value.length > 5) ? "flex" : "none" }}
  //                 ></video>
  //               </MouseOverVideoDiv>
  //             </PrettyInputLabel>
  //           </>;
  //         }

  //         // if (field.type === 'exercise_array') { // show exercises dropdown, and ordered list of selected exercises
  //         //   inputCode = 
  //         //   <>
  //         //     <PrettyInputLabel style={{ width: "100%", marginBottom: "20px" }} key={field.accessor + '_label'}>
  //         //       <p>{field.displayName} </p>
  //         //       <p>
  //         //         <PickList 
  //         //           optionItemType="exercise"
  //         //           onSelectChange={handleAddExercise}
  //         //           key={field.accessor}
  //         //           id={field.accessor} 
  //         //           value={value} 
  //         //           onChange={e => handleValueChange(e)} // pass in this function?
  //         //         />
  //         //         <InlineLoader active={fetchingData} />
  //         //       </p>

  //         //       <OrderedItemList
  //         //         key={refreshExercisesList} 
  //         //         active={true} 
  //         //         mobile={false} 
  //         //         array={(formItem && formItem.exercise_array) ? formItem.exercise_array : fakeItemsArray} 
  //         //         updateArray={handleUpdateArray}
  //         //         onRemoveClick={handleRemoveExercise}
  //         //       />  
  //         //     </PrettyInputLabel>
  //         //   </>;
  //         // }

  //         return inputCode;
  //       })}
  //     </div>
  //   );
  // }

  function onFieldChange(fieldName, newValue){
    console.log('~~~~~ ExerciseEdit(), onFieldChange() - new contents of fieldName input: ' + newValue);
    let newItem = Object.assign({}, formItem); // this clones the current formItem as newItem
    newItem[fieldName] = newValue;

    console.log('~~~~~ ExerciseEdit() - onFieldChange() - after edit, newItem[' + fieldName + '] is *' + newItem[fieldName] + '*');
    
    if (notEditable){
      noEditAlert();
    } else {
      setFormItem(newItem);
    }   
  }

  function handleSaveClick(){
    console.log('~~~~~ ExerciseEdit(), handleSaveClick() - id_guid of item to save is ' + formItem.id_guid);

    if (create){
      handleCreateItemConfirmClick();
    } else {
      handleUpdateItemConfirmClick();
    }
  }

  function updateMovement(movementObj){

    if (notEditable){
      noEditAlert();
    } else {
      let newItem = Object.assign({}, formItem); // this clones the current formItem as newItem
      newItem["movement_id_guid"] = movementObj.id_guid;
      newItem["thumbnail_url_string"] = movementObj.thumbnail_url_string;
      newItem["video_url_string"] = movementObj.video_url_string;

      setFormItem(newItem); // code to update thumbnail and video is not needed, because they use formItem
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "left", width: "100%", paddingLeft: "40px", paddingTop: "30px" }}>
      <ShellInputLabel>
        <ShellFieldDisplayName style={{ width: "80px" }}>Name</ShellFieldDisplayName>
        <ShellTextInput 
          type="text" 
          value={(formItem && formItem.name_string) ? formItem.name_string : ''} 
          id="exercise_name_string" 
          placeholder = "Enter a Name for this Exercise"  
          name="name_string" 
          onChange={(e) => onFieldChange("name_string", e.target.value)}
          style={{ width: "420px" }}  
          disabled={notEditable}
          title={notEditable ? publicRecordMsg : "Enter a Name for this Exercise"}
        />
        <ShellOvalBtn type="button" onClick={handleSaveClick} disabled={notEditable}>Save</ShellOvalBtn>
      </ShellInputLabel>

      <ShellInputLabel>
        <ShellFieldDisplayName style={{ width: "80px" }}>Duration</ShellFieldDisplayName>
        <ShellTextInput 
          type="text" 
          value={(formItem && formItem.duration_seconds_integer) ? formItem.duration_seconds_integer : ''} 
          id="duration_seconds_integer" 
          placeholder = "Enter a Duration for this Exercise, in seconds"  
          name="duration_seconds_integer" 
          onChange={(e) => onFieldChange("duration_seconds_integer", e.target.value)}
          style={{ width: "420px" }}  
          disabled={notEditable}
          title={notEditable ? publicRecordMsg : "Enter a Duration for this Exercise, in seconds"}
        />
      </ShellInputLabel>

      <ShellInputLabel>
        <ShellFieldDisplayName style={{ width: "80px" }}>Repetitions</ShellFieldDisplayName>
        <ShellTextInput 
          type="text" 
          value={(formItem && formItem.repetition_integer) ? formItem.repetition_integer : ''} 
          id="repetition_integer" 
          placeholder = "Enter a Repetition count for this Exercise"  
          name="repetition_integer" 
          onChange={(e) => onFieldChange("repetition_integer", e.target.value)}
          style={{ width: "420px" }}  
          disabled={notEditable}
          title={notEditable ? publicRecordMsg : "Enter a Repetition count for this Exercise"}
        />
      </ShellInputLabel>

      <ShellInputLabel>
        <ShellFieldDisplayName style={{ width: "80px" }}>Notes</ShellFieldDisplayName>
        <ShellTextarea 
          value={(formItem && formItem.note_string) ? formItem.note_string : ''} 
          id="exercise_note_string" 
          name="note_string" 
          placeholder = "Enter a note" 
          onChange={(e) => onFieldChange("note_string", e.target.value)} 
          style={{ width: "500px" }}
          disabled={notEditable}
          title={notEditable ? publicRecordMsg : "Enter a Note for this Exercise"}
        />
      </ShellInputLabel>

      <ShellInputLabel style={{ display: "block", width: "100%" }}>
        <div style={{ display: "block", width: "100%" }}>
          <ShellFieldDisplayName style={{ width: "80px", marginRight: "15px" }}>Video</ShellFieldDisplayName>
          <PickList 
            optionItemType="movement"
            onSelectChange={updateMovement}
            key="Movement_list"
            id="movement_list" 
            value={(formItem && formItem.movement_id_guid) ? formItem.movement_id_guid : ''} 
            defaultValue={(formItem && formItem.movement_id_guid) ? formItem.movement_id_guid : ''} 
            onChange={e => handleValueChange(e)}
            disabled={notEditable}
            title={notEditable ? publicRecordMsg : "Choose a Video for this Exercise"}
          />
        </div>
          
        <MouseOverVideoDiv style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
          <video
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={videoRef}
            src={(formItem && formItem.video_url_string) ? formItem.video_url_string : ''}
            type="video/mp4"
            loop
            className="w-100"
            style={{ display: (formItem && formItem.video_url_string && formItem.video_url_string.length > 5) ? "flex" : "none" }}
          ></video>
        </MouseOverVideoDiv>
      </ShellInputLabel>
    </div>
  );
}

export default ExerciseEdit;
