// Athlete.js

import React, { useState } from "react";
import styled, { createGlobalStyle } from 'styled-components';
import { iconSrc } from '../../constants';
import { Icon } from './title';
import AthleteWorkoutsMobile from './AthleteWorkoutsMobile';
import MyReadiness from './MyReadiness';
// import History from '../History';
import MyAssessmentHistory from './MyAssessmentHistory';


const Styles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 100vh;
  `,
};

const CSSReset = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 100%; /* 62.5% for 1rem = 10px */
    box-sizing: border-box;
  }

  body {
    font-size: 1.4rem;
    font-family: sans-serif;
  }
`;

const Navbar = {
  Wrapper: styled.nav`
    flex: 1;

    align-self: flex-start;

    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
  `,
  Logo: styled.h1`
    border: 1px solid gray;
    padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
  `,
};

const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    z-index: 10000;
    width: 100%;
    bottom: 0px;
    /* left: 0; */
    background-color: black;
    align-items: center;
    justify-content: center;
    padding-bottom: 2vw; /* was 3vw */
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    padding: 0;
    /* margin-bottom: -0.5rem; */
    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    color: white;
  `,
  Icon: styled.img`
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  `,
};


  const Athlete = ({
    logout, isMobile, deepLink
  }) => {
  const isChrome = navigator.userAgent.match('CriOS');
  const athleteId = localStorage.getItem('loggedInUser');
  const initialTab = (deepLink && deepLink.type === 'athlete' && deepLink.page === 'readiness') ? "1" : (deepLink && deepLink.type === 'athlete' && deepLink.page === 'assessments') ? "2" : "0";
  console.log('~~~~~ Athlete(), deepLink.page is ' + deepLink.page + ', and initialTab is ' + initialTab);
  const [currentTab, setCurrentTab] = useState(initialTab);

  function onTabSelect(e) {
    console.log('~~~~~ Athlete.js onTabSelect - e.currentTarget.id is *' + e.currentTarget.id + '*');

    if (e.currentTarget.id.split('_')[1] === currentTab) {
      return;
    }

    setCurrentTab(e.currentTarget.id.split('_')[1]);
  }

  return (
    <Styles.Wrapper>
      <CSSReset />
      {isMobile ? (
        <MobileNavbar.Wrapper>
          <MobileNavbar.Items>
            <MobileNavbar.Item id="tab_0" onClick={onTabSelect}>
              <MobileNavbar.Icon className="icon" src={currentTab === "0" ? iconSrc.schedule_weight_white_filled : iconSrc.schedule_weight_white} />
              Workouts
            </MobileNavbar.Item>
            <MobileNavbar.Item id="tab_1" onClick={onTabSelect}>
              <MobileNavbar.Icon className="icon" src={currentTab === "1" ? iconSrc.rocket_white_filled : iconSrc.rocket_white} />
              Readiness
            </MobileNavbar.Item>
            <MobileNavbar.Item id="tab_2" onClick={onTabSelect}>
              <MobileNavbar.Icon className="icon" src={currentTab === "2" ? iconSrc.overview_white_filled : iconSrc.overview_white} />
              Assessments
            </MobileNavbar.Item>
            <MobileNavbar.Item id="logout" onClick={logout} style={{ color: isChrome ? "red" : "white" }}>
              <MobileNavbar.Icon className="icon" src={iconSrc.logout_white} />
              Logout
            </MobileNavbar.Item>
          </MobileNavbar.Items>
        </MobileNavbar.Wrapper>
      ) : (
        <Navbar.Wrapper>
          <Navbar.Logo>Logo</Navbar.Logo>
          <Navbar.Items>
            <Navbar.Item id="tab_0" onClick={onTabSelect}>
              <Icon className="icon" src={iconSrc.programs} />
             &nbsp; Workouts
            </Navbar.Item>
            <Navbar.Item id="tab_1" onClick={onTabSelect}>
              <Icon className="icon" src={iconSrc.rocket_white} />
              &nbsp; Readiness
            </Navbar.Item>
            <Navbar.Item id="tab_2" onClick={onTabSelect}>
              <Icon className="icon" src={iconSrc.assessment_white} />
              &nbsp; Assessments
            </Navbar.Item>
            <MobileNavbar.Item id="logout" onClick={logout}>
              <Icon className="icon" src={iconSrc.logout} />
              Logout
            </MobileNavbar.Item>
          </Navbar.Items>
        </Navbar.Wrapper>
      )}
      {currentTab === "0" &&
        <div style={{ width: "100vw" }}>
          <AthleteWorkoutsMobile athleteId={athleteId} />
        </div>
      }
      {currentTab === "1" &&
        <div style={{ width: "100vw" }}>
          <MyReadiness athleteId={athleteId} isMobile={isMobile} />
        </div>
      }
      {currentTab === "2" &&
        <div style={{ width: "100vw" }}>
          <MyAssessmentHistory athleteId={athleteId} isMobile={isMobile} />
        </div>
      }
    </Styles.Wrapper>
  );
}

export default Athlete;
