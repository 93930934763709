// PickList.js

import React, { useState, useRef, useEffect } from 'react';
import { getMovements, getExercises } from '../../services';

const PickList = ({
  optionItemType, onSelectChange, defaultValue, disabled
}) => {
  const [optionItems, setOptionItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(); // optionItems[0].id_guid ?
  const defaultOptionLabel = optionItemType === "movement" ?
    "Choose a Video to Use" : "Choose an Exercise to Add";
  // const optionItemType = props.optionItemType;
  const nameAcessor = optionItemType === "movement" ? "title_string" : "name_string";
  const mounted = useRef(true);
  let fetchedOptionItems = false;

  useEffect(() => {
    console.log('~~~~~ PickList, useEffect()');
    // setLoaded(false);
    mounted.current = true; // TODO: how is this useful?
    if(fetchedOptionItems || optionItems.length > 0) { 
      console.log('~~~~~ PickList, returning from fetch! - optionItems.length is ' + optionItems.length);
      return;
    }
    // an array of optionItemType objects will be returned

    console.log('~~~~~ PickList.js, PickList() - in useEffect() optionItemType is *' + optionItemType + '*');

    if (optionItemType === "movement") {
      getMovements()
      .then(itemsResp => {
        fetchedOptionItems = true; 
        if(mounted.current) {
          let items = Array.isArray(itemsResp) ? itemsResp : itemsResp.data; // works for Athletes when finding associated Groups
          // let items = itemsResp; // works for Groups when finding associated Athletes

          if (items) {
            items.sort((a, b) => {
              const nameA = a.title_string.toUpperCase(); // ignore upper and lowercase
              const nameB = b.title_string.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            });

            console.log('~~~~~ PickList, Movements, items[0].title_string is *' + items[0].title_string + '*');

            setOptionItems(items);
            setSelectedOption(defaultValue);
          }

          if (!items) {
            console.log('~~~~~ PickList.js, PickList() - in useEffect() - items is currently undefined');
          } else {
            console.log('~~~~~ PickList.js, PickList() - in useEffect() - ' + items.length + ' Movements were returned');
          
            return items[0]
          }
        }
      })
    } else { // assuming here we only use this component for movements and exercises
      getExercises()
      .then(itemsResp => {
        fetchedOptionItems = true; 
        if(mounted.current) {
          let items = Array.isArray(itemsResp) ? itemsResp : itemsResp.data;

          if (items) {
            items.sort((a, b) => {
              const nameA = a.name_string.toUpperCase(); // ignore upper and lowercase
              const nameB = b.name_string.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            });
        
            console.log('~~~~~ PickList, Exercises, items[0].name_string is *' + items[0].name_string + '*');

            setOptionItems(items);
            setSelectedOption(defaultValue);
          }

          if (!items) {
            console.log('~~~~~ PickList.js, PickList() - in useEffect() - items is currently undefined');
          } else {
            console.log('~~~~~ PickList.js, PickList() - in useEffect() - ' + items.length + ' Exercises were returned');
          
            return items[0]
          }
        }
      })
    }
  }, [optionItems])

// note that if this select renders before the fetch is done, the dropdown will be empty of options...
// this would be mitigated if the useEffect were outside the dropdown component, and optionItems were passed in as a prop...

if (optionItems[0]) {
  console.log('~~~~~ PickList, optionItems[0].title_string is *' + optionItems[0].title_string + '*');
}
  return (
      <select
        disabled={disabled}
        title={disabled ? "This record is Public, and cannot be edited here" : null} 
        defaultValue={defaultValue}
        value={selectedOption}
        onChange={(e) => {
          let newValue = e.target.value;
          const selectedVideoObj = optionItems.find(obj => obj.id_guid === newValue)
          onSelectChange(selectedVideoObj);
          setSelectedOption(e.target.value);
        }} 
      >
        <option key={"default_option"} value={null}>{defaultOptionLabel}</option>
        {optionItems.map(o => (
          <option key={o.id_guid} value={o.id_guid}>{o[nameAcessor]}</option>
        ))}
      </select>
  );
};

export default PickList;