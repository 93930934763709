// components/styled/AthleteWorkoutsMobile.js

import React, { useEffect, useRef, useState } from 'react';
import packageJson from '../../../package.json';

import { iconSrc, colors } from '../../constants';
import { getAthleteWorkouts, getWorkout } from '../../services'; // sort/filter Workouts by day/date on client side

import MobileLoadingDivLayer  from './MobileLoadingDivLayer';
import MyWorkouts  from './MyWorkouts';
// import WorkoutList from '/WorkoutsList';
import MyWorkout  from './MyWorkout';
import { MobileVersionDiv } from './title';


const mockWorkoutArray = [
  {
    "id_guid": "2b724043-f239-4f52-8d20-50e235b02e14",
    "workout_id_guid": "2b724043-f239-4f52-8d20-50e235b02e14",
    "workout_name_string": "Public Workout 1",
    "user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
    "public_boolean": true,
    "created_datetime": "2023-11-23T05:31:08.567Z",
    "updated_datetime": "2023-11-23T05:31:08.567Z",
    "name_string": "Public Workout 1",
    "interval_day_decimal": "1.00",
    "note_string": "This is a public workout",
    "movement_thumbnail_url_list_string": null,
    "group_id_guid": "36dc9c28-d150-416b-a5be-0eddd65feeca",
    "group_name_string": "District 1",
    "group_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
    "group_user_email_string": "nathan@physmodo.com",
    "group_user_first_name_string": "Nathan",
    "group_user_last_name_string": "Physmodo",
    "group_user_name_string": null,
    "workout_user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
    "workout_user_email_string": "nathan@physmodo.com",
    "workout_user_first_name_string": "Nathan",
    "workout_user_last_name_string": "Physmodo",
    "workout_user_name_string": null,
    "compliance_integer": 4,
    "compliance_color_string": "red",
    "user_workout_compliance_boolean": "false", // new
    "movement_thumbnail_url_string_array": []
  },
  {
    "id_guid": "23dfdec8-2d78-489c-b357-cd6cebc1fe42",
    "workout_id_guid": "23dfdec8-2d78-489c-b357-cd6cebc1fe42",
    "workout_name_string": "Workout A",
    "user_id_guid": "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
    "public_boolean": null,
    "created_datetime": "2023-11-14T01:29:07.268Z",
    "updated_datetime": "2024-02-13T05:36:15.175Z",
    "name_string": "Workout A",
    "interval_day_decimal": "3.00",
    "note_string": "Just an awesome workout for athletes!",
    "movement_thumbnail_url_list_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4dde28c69d3e19f88c83d12820529eb25c63eea2/Pike%20Arch.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--53e48831ae954b821a34ff97fcb7239dab93ff6a/Foam%20Roll%20Glutes.png",
    "group_id_guid": "0ae6fa20-37df-418e-85ec-3d031ceb39b7",
    "group_name_string": "Volleyball",
    "group_user_id_guid": "abec62f6-a0fb-49be-b2d7-71695390b204",
    "group_user_email_string": "school_director0@physmodo.com",
    "group_user_first_name_string": null,
    "group_user_last_name_string": null,
    "group_user_name_string": null,
    "workout_user_id_guid": "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
    "workout_user_email_string": "coach@physmodo.com",
    "workout_user_first_name_string": "John",
    "workout_user_last_name_string": "Physmodo",
    "workout_user_name_string": null,
    "compliance_integer": 4,
    "compliance_color_string": "red",
    "user_workout_compliance_boolean": "true", // new
    "movement_thumbnail_url_string_array": [
        "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png",
        "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4dde28c69d3e19f88c83d12820529eb25c63eea2/Pike%20Arch.png",
        "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--53e48831ae954b821a34ff97fcb7239dab93ff6a/Foam%20Roll%20Glutes.png"
    ]
  }
];

const mockWorkoutDetail = {
  "id_guid": "23dfdec8-2d78-489c-b357-cd6cebc1fe42",
  "created_datetime": "2023-11-14T01:29:07.268Z",
  "updated_datetime": "2024-02-13T05:36:15.175Z",
  "user_id_guid": "a9a7c1a1-9f39-48b4-aedc-dde17b7ed999",
  "name_string": "Workout A",
  "interval_day_decimal": "3.00",
  "note_string": "Just an awesome workout for athletes!",
  "last_performed_datetime": "2024-02-15T05:36:15.175Z", // new
  "public_boolean": null,
  "movement_thumbnail_url_list_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--53e48831ae954b821a34ff97fcb7239dab93ff6a/Foam%20Roll%20Glutes.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4dde28c69d3e19f88c83d12820529eb25c63eea2/Pike%20Arch.png, https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png",
  "movement_thumbnail_url_string_array": [
      "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--53e48831ae954b821a34ff97fcb7239dab93ff6a/Foam%20Roll%20Glutes.png",
      "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4dde28c69d3e19f88c83d12820529eb25c63eea2/Pike%20Arch.png",
      "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbzBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--33f635c013d6a8ae7e6634252f244ae114d74655/TRX%20Lateral%20Lunge.png"
  ],
  "exercise_array": [
		{
			"exercise_id_guid": "57096423-d477-48ef-ba5d-85a9adcf2f8b",
			"rank_integer": 0,
			"note_string": "note_string1",
			"set_integer": 3, // new
			"repetition_integer": 10,
			"load_string": "70% of 1RM", // new
			"duration_integer": 30,
			"duration_string": "seconds", // new
			"distance_integer": 5, // new
			"distance_string": "miles", // new
			"movement_id_guid": "d7234863-1504-40c6-bb67-183ce10ba15c",
			"video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBKZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b0397d2f15dead7395a54b95f0416ab2c3442f58/88.mp4",
			"thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbkVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9a2a157e9adcfa422d23f4beae9ecb806a8da685/Butterfly%20Stretch.png",
			"title_string": "Butterfly Stretch",
			"subtitle_string": null
		},
		{
			"exercise_id_guid": "e404a4f2-af52-4f5f-8fc5-fcbcdb98eced",
			"rank_integer": 1,
			"note_string": "note_string2",
			"set_integer": null,
			"repetition_integer": null,
			"load_string": null,
			"duration_integer": null,
			"duration_string": null,
			"distance_integer": null,
			"distance_string": null,
			"movement_id_guid": "a560ee8d-e338-4802-bb64-c568dbf43b7a",
			"video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBYlU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0c769cedd6dd6ee0f5dbb4075052b22ef2e4e88b/87.mp4",
			"thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--59ff586e99477aa50d8e4987c4735bf6bd69463a/90-90%20Stretch.png",
			"title_string": "90/90 Stretch",
			"subtitle_string": null
		},
		{
			"exercise_id_guid": "e05dc2e9-cc12-4a51-be59-d3269fdf7ee5",
			"rank_integer": 2,
			"note_string": "note_string3",
			"set_integer": null,
			"repetition_integer": null,
			"load_string": null,
			"duration_integer": null,
			"duration_string": null,
			"distance_integer": null,
			"distance_string": null,
			"movement_id_guid": "5249b3a5-d413-4b1e-8b8f-bbe83e34154c",
			"video_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBGZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b5750b9bf3abda031e67e0098c98208c944d1e37/285.mp4",
			"thumbnail_url_string": "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbUlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e9de189b003caaf37be49262091e01ea8169f1cb/Floor%20to%20Sky%20Reach.png",
			"title_string": "Floor to Sky Reach",
			"subtitle_string": null
		}
  ]
};




const AthleteWorkoutsMobile = ({
  athleteId
}) => {
  const mounted = useRef(true);
  const [showList, setShowList] = useState(true); // just use !showList instead of having showDetail
  
  let loadingWorkouts = false; // only will become true during fetch
  const [workouts, setWorkouts] = useState(mockWorkoutArray); // TODO: should be []
  // const [workouts, setWorkouts] = useState([]);
  
  const [loadedWorkoutsArray, setLoadedWorkoutsArray] = useState(false); // TODO: should be false w/o mockData

  const [selectedWorkoutName, setSelectedWorkoutName] = useState('No Name Yet');
  
  const [loadedWorkoutDetail, setLoadedWorkoutDetail] = useState(true); // TODO: should be false w/o mockData
  
  let loadingMyWorkout = false; // only will become true during fetch
  const [myWorkout, setMyWorkout] = useState(mockWorkoutDetail); // TODO: should be {}
  

  
  const [currentWorkout, setCurrentWorkout] = useState(null); // will be an id_guid string (myWorkout.id_guid)

  function workoutClickHandler(event, id) {
    // alert('~~~~~ AthleteWorkoutsMobile(), - workoutClickHandler() event.type is *' + event.type + '*, and event.currentTarget.id is *' + event.currentTarget.id + '*');
    const key = event.currentTarget.id;
    const workoutId = key.split('_')[0];
    
    setSelectedWorkoutName(event.currentTarget.dataset["name"]); // <<

    getWorkoutInfo(workoutId);
  }

  function goToList(){
    setShowList(true);
  }

  function showWorkout(workout_id){
    console.log('~~~~~ AthleteWorkoutsMobile(), showWorkout(' + workout_id + ')');
    setCurrentWorkout(workout_id);
    setShowList(false);
  }

  function getWorkoutInfo(workout_id){
    console.log('~~~~~ AthleteWorkoutsMobile(), getWorkoutInfo(' + workout_id + ' fetching Workout Info)');
    if (loadingMyWorkout){ // TODO: might not be required, since getWorkoutInfo() is not fired automatically like useEffect()
      return;
    }
    
    loadingMyWorkout = true; // TODO: might not be required, since getWorkoutInfo() is not fired automatically like useEffect()
    getWorkout(workout_id)
    .then(workoutResp => {
      if(mounted.current) {
        setMyWorkout(workoutResp);
        loadingMyWorkout = false; // TODO: might not be required, since getWorkoutInfo() is not fired automatically like useEffect() 
        showWorkout(workout_id)
      }
    })
  }

  // fetch workouts for this Athlete
  useEffect(() => {
    if (loadingWorkouts) { return; } //

    getAthleteWorkouts(athleteId)
    .then(itemsResp => {
      if(mounted.current) {
        let items = itemsResp;
        loadingWorkouts = false;
        setWorkouts(items);
        setLoadedWorkoutsArray(true);
        console.log('~~~~~ AthleteWorkoutsMobile() - useEffect() #1 fetching Workouts - ' + items.length + ' Workouts were returned');
      }
    })
  }, [athleteId, loadedWorkoutsArray])

  return ( 
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* <MobileVersionDiv>v{packageJson.version}</MobileVersionDiv> */}
      {!loadedWorkoutsArray && <MobileLoadingDivLayer />} 
      {loadedWorkoutsArray &&
        <div className="SomeWrapper">
          {showList && <MyWorkouts workouts={workouts} onWorkoutClick={workoutClickHandler} loaded={loadedWorkoutsArray} />}
          {!showList &&
            <MyWorkout loaded={loadedWorkoutDetail} workout={myWorkout} goToList={goToList} />}
        </div>
      }
    </div>
  );

}

export default AthleteWorkoutsMobile;
