// /components/styled/PickOneOfFive.js

import React, { useState } from 'react';
import styled from "styled-components";

const FakePickOneOfFiveWrapper = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 3.3vw;                        
  letter-spacing: 0;
  white-space: nowrap;
  /* font-size: 14px;                        // might change when size of entire element is increased */
  /* width: 100%;                            // might change when size of entire element is increased */
  /* height: 150px;                          // might change when size of entire element is increased */
vw;                          
  padding: 0px;
  padding-top: 2.5vw;                      
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  vertical-align: middle;
  /* padding-top: 10px;                      // might change when size of entire element is increased */
`;

const PickOneOfFiveTitle = styled.h5`
  cursor: auto;
  color: #777777;
  font-weight: 400;
  font-size: 4vw;    
`;

const PickOneOfFiveSubtitle = styled.div`
  cursor: auto;
  font-size: 2.59vw;
  /* font-size: 11px;                        // might change when size of entire element is increased */
`;

const ReadinessBarDiv = styled.div`
  cursor: auto;
  margin: auto;
  /* background: #808080; */ /* moved to inline style to change with title */
  width: 80vw;
  height: 22vw;
  border: 0.35vw solid;                    
  border-radius: 11vw; // note that for reference 1vw was 4.25px
  padding-top: 4.44vw;
  padding-right: 2.22vw;
  padding-left: 2.22vw;
  /* width: 340px;                           // might change when size of entire element is increased */
  /* height: 94px;                           // might change when size of entire element is increased */
  /* border: 1.5px solid;                    // might change when size of entire element is increased */
  /* border-radius: 47px;                    // might change when size of entire element is increased */
  /* padding-top: 20px;                      // might change when size of entire element is increased
  padding-right: 10px;                    // might change when size of entire element is increased
  padding-left: 10px;                     // might change when size of entire element is increased */
`;

const PickOneOfFiveRow = styled.div`
  cursor: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const PickOneOfFiveCircle = styled.div`
  cursor: pointer;
  height: 11.8vw;
  width: 11.8vw;
  border-radius: 5.9vw;
  border: 0.59vw solid red;
  text-align: center;
  vertical-align: middle;
  padding-top: 1.4vw;
  font-size: 5.65vw;
  color: #FFFFFF;
  /* height: 50px;                        // might change when size of entire element is increased
  width: 50px;                            // might change when size of entire element is increased
  border-radius: 25px;                    // might change when size of entire element is increased
  border: 2.5px solid red;                // might change when size of entire element is increased */
`;

const ReadinessSubtitleRow = styled.div`
  cursor: auto;
  height: 3.53vw;
  padding-top: 0.8vw;
  padding-left: 5.41vw;
  padding-right: 5.41vw;
  color: #FFFFFF;
  font-weight: 300;
  /* height: 15px;                        // might change when size of entire element is increased
  padding-top: 0px;
  padding-left: 23px;                     // might change when size of entire element is increased
  padding-right: 23px;                    // might change when size of entire element is increased */
`;


const PickOneOfFive = ({
  title, subtitle_left, subtitle_right, onChange
}) => {

  const isLOE = title.includes('EFFORT');

  const display_title = title.toUpperCase();                // TODO: just use title as provided in prop? More flexibility, less control?
  const display_subtitle_l = subtitle_left.toUpperCase();   // TODO: just use subtitle as provided in prop? More flexibility, less control?
  const display_subtitle_r = subtitle_right.toUpperCase();  // TODO: just use subtitle as provided in prop? More flexibility, less control?
  const [rValue, setRValue] = useState(0);

  const redFirst = title.toLowerCase().includes('stress') || title.toLowerCase().includes('sore') || title.toLowerCase().includes('effort') ? false : true;
  const oneColor = redFirst ? "#ff0000" : "#03ff00";
  const twoColor = redFirst ? "#ff0000" : "#03ff00";
  const fourColor = redFirst ? "#03ff00" : "#ff0000";
  const fiveColor = redFirst ? "#03ff00" : "#ff0000";

  // const sizeFactor = window.innerWidth/360;
  // console.log('~~~~~ PickOneOfFive() - sizeFactor is ' + sizeFactor);

  // const [width, setWidth] = useState(window.innerWidth); // useState<number>(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight); // useState<number>(window.innerHeight;

  // function handleWindowSizeChange() {
  //     console.log('~~~~~ App.js, handleWindowSizeChange - window.innerWidth is ' + window.innerWidth);
  //     setWidth(window.innerWidth);
  //     setHeight(window.innerHeight);
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //       window.removeEventListener('resize', handleWindowSizeChange);
  //   }
  // }, []);

  // handleWindowSizeChange();
  
  // const ResponsiveSize = (size) => {
  //   if(height===568) { return size }
  //   else if(height===667) size*1.17 
  //   else if(height===736) size*1.29 
  //   else if(height===1024) size*1.8 
  // }

  function onValueClick(e, ref){
    console.log('~~~~~ PickOneOfFive(), onValueClick() - clicked ' + ref);
    const value = ref.split('_')[1];
    console.log('~~~~~ PickOneOfFive(), onValueClick() - value is ' + value);
    setRValue(value);
    // code to update database via API
    let rObj_property = "readiness_overall_integer";
    const intValue = parseInt(value);

    // the switch statement will set the appropriate property of the readiness object:
    switch(true) {
      case ref.includes('overall'):
        rObj_property = "readiness_overall_integer";
        break;
      case ref.includes('sleep'):
        rObj_property = "readiness_sleep_integer";
        break;
      case ref.includes('hydration'):
        rObj_property = "readiness_hydration_integer";
        break;
      case ref.includes('stress'):
        rObj_property = "readiness_stress_integer";
        break;
      case ref.includes('soreness'):
        rObj_property = "readiness_soreness_integer";
        break;
      default:
    }

    onChange(rObj_property, intValue);
  }

  return (
    <FakePickOneOfFiveWrapper>
      <PickOneOfFiveTitle style={{ fontWeight: isLOE ? "bold" : "400" }}>{display_title}</PickOneOfFiveTitle>
      <ReadinessBarDiv style={{ background: isLOE ? "#ddd" : "#808080", borderColor: isLOE ? "#000" : "#808080" }}>
        <PickOneOfFiveRow>
          <PickOneOfFiveCircle 
            onClick={(e) => { onValueClick(e, title + "_1") }} 
            style={{
              borderColor: rValue === '1' ? isLOE ? "#000" : "#dddddd" : oneColor, 
              background: rValue === '1' ? oneColor : "none", 
              color: isLOE ? "#000" : rValue === '1' ? "#000000" : "#ffffff"}}>1</PickOneOfFiveCircle>
          <PickOneOfFiveCircle 
            onClick={(e) => { onValueClick(e, title + "_2") }} 
            style={{borderColor: rValue === '2' ? isLOE ? "#000" : "#dddddd" : twoColor, 
            background: rValue === '2' ? twoColor : "none", 
            color: isLOE ? "#000" : rValue === '2' ? "#000000" : "#ffffff"}}>2</PickOneOfFiveCircle>
          <PickOneOfFiveCircle 
            onClick={(e) => { onValueClick(e, title + "_3") }} 
            style={{borderColor: rValue === '3' ? isLOE ? "#000" : "#dddddd" : "#ffff00", 
            background: rValue === '3' ? "#ffff00" : "none", 
            color: isLOE ? "#000" : rValue === '3' ? "#000000" : "#ffffff"}}>3</PickOneOfFiveCircle>
          <PickOneOfFiveCircle 
            onClick={(e) => { onValueClick(e, title + "_4") }} 
            style={{borderColor: rValue === '4' ? isLOE ? "#000" : "#dddddd" : fourColor, 
            background: rValue === '4' ? fourColor : "none", 
            color: isLOE ? "#000" : rValue === '4' ? "#000000" : "#ffffff"}}>4</PickOneOfFiveCircle>
          <PickOneOfFiveCircle 
            onClick={(e) => { onValueClick(e, title + "_5") }} 
            style={{borderColor: rValue === '5' ? isLOE ? "#000" : "#dddddd" : fiveColor, 
            background: rValue === '5' ? fiveColor : "none", 
            color: isLOE ? "#000" : rValue === '5' ? "#000000" : "#ffffff"}}>5</PickOneOfFiveCircle>
        </PickOneOfFiveRow>
        <ReadinessSubtitleRow>
          <PickOneOfFiveSubtitle style={{ float: "left" }}>{display_subtitle_l}</PickOneOfFiveSubtitle>
          <PickOneOfFiveSubtitle style={{ float: "right" }}>{display_subtitle_r}</PickOneOfFiveSubtitle>
        </ReadinessSubtitleRow>
      </ReadinessBarDiv>
    </FakePickOneOfFiveWrapper>
  );

}

export default PickOneOfFive;