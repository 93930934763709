import React from 'react';
import { useState, useEffect } from "react";

import styled from 'styled-components';
import { RedSpan } from './title';
import { accentColor, trashcanSrc_active, groupsSrc, programsSrc } from '../../constants';

const lightGreyText = "#b4b4b4";
const darkGreyText = "#555555";
const greyText = "#888888";
const tealText = accentColor;
const isCoach = localStorage.getItem('coach') === 'true' ? true : false;

const SortableTableRow = styled.tr`
  cursor: pointer;
  padding-top: 2vw;
  padding-bottom: 2vw;
  border-bottom: 1px solid #f0f0f0;
  &:hover { background: ghostwhite };
`

const TableHead = ({ columns, handleSorting }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    // console.log('~~~~~ MembersTable - handleSortingChange(), accessor = ' + accessor);
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    // console.log('~~~~~ MembersTable - handleSortingChange(), sortOrder = ' + sortOrder);
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
   <thead>
    <tr style={{ borderBottom: "1px solid #e9e9e9", whiteSpace: "nowrap" }}>
     {columns.map(({ label, accessor, sortable, minWidth }) => {
      return (
        <th 
          key={accessor}
          title={sortable ? "Click to sort by this column" : ""}
          onClick={sortable ? () => handleSortingChange(accessor) : null}
          style={{
            fontSize: "1vw", 
            color: "#b4b4b4", 
            fontWeight: "400", 
            paddingRight: "0.5vw", 
            paddingLeft: "1.7vw", 
            minWidth: minWidth ? minWidth : "60px", 
            cursor: sortable ? "pointer" : "default"
          }}
        >
          {label}
        </th>
      );
     })}
    </tr>
   </thead>
  );
 };


 const TableBody = ({ tableData, columns, onRowClick, editClick, groupsClick, programsClick }) => {

  const EditIcon = styled.img`
    display: inline-block;
    width: 1.6vw;
    height: 1.6vw;
    margin: 0 0 0 0 !important; // updated for 1.6vw svg images
    padding: 0 !important;
    opacity: 0.5;
    &:hover {
      opacity: 1.0;
    }
  `;

// function athleteObjFromId(id_guid) {

//   console.log("~~~~~ Members.js, athleteObjFromId() - id_guid = *" + id_guid + "*");
  
//   const matchingObjsArray = tableData.filter((athlete) => {
//     return athlete.id_guid === id_guid;
//   });

//   return matchingObjsArray[0];
// }

  const pending = 'Pending Acceptance...';
  // TODO: make this a link that will open the Invite Athlete form for 're-Invites'...
  // handlePendingLinkClick() -> pass in athlete name and email to form, and change wording to reflect re-Invite...

  return (
   <tbody>
    {tableData.map((data) => {
      const accepted = data.user_association_athlete_user_approval_boolean;
      const athleteName = data.full_name_string;
     return (
      <SortableTableRow key={data.id_guid} id={data.id_guid} onClick={function(event){
        accepted ? 
        onRowClick(event, data.id_guid) : 
        alert('Assessment History will be available once the Athlete has accepted your Invite!'); // or noop ?
      }}>
       {columns.map(({ accessor, align, color }) => {
        let tData = data[accessor] ? data[accessor] : "——";
        // const accepted = data.user_association_athlete_user_approval_boolean;
        let cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}>{tData}</td>;
        if (accessor === 'lastAssessmentDate' && tData === "pending") {
          cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}><RedSpan>{pending}</RedSpan></td>;
        }
        if (accessor === 'AssessmentsCountThisMonth' || accessor === 'AssessmentsCountAllTime') {
          cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}>{tData}</td>;
        }        
        if (accessor === 'groupsLink') {
          if (accepted) {
            cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}} onClick={function(event){groupsClick(event, data.id_guid)}}>
              <EditIcon src={groupsSrc} title="Show this Athlete's Groups, or click to edit them" />
            </td>;
          } else {
            cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}}></td>;
          }
        }
        if (accessor === 'programsLink') {
          if (accepted) {
            cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}} onClick={function(event){programsClick(event, data.id_guid)}}>
              <EditIcon src={programsSrc} title="Show this Athlete's Programs, or click to edit them" />
            </td>;
          } else {
            cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}}></td>;
          }
        }
        if (accessor === 'editLink') {
          cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}} onClick={function(event){editClick(event, data.id_guid)}}>
            <EditIcon src={trashcanSrc_active} title="Stop monitoring this Athlete, or revoke their invitation" />
          </td>;
        }

        return cellCode;
       })}
      </SortableTableRow>
     );
    })}
   </tbody>
  );

 };

function MembersTable(props) {

  const [tableData, setTableData] = useState(props.members);
  const [firstSortDone, setFirstSortDone] = useState(false);
  let sorted = [];

  const coachColumns = [
    { label: "Member Name", accessor: "full_name_string", align: "left", color: darkGreyText, sortable: true, minWidth: "150px" },
    { label: "Member Email", accessor: "email_string", align: "left", color: darkGreyText, sortable: true, minWidth: "200px" },
    { label: "Last Assessment", accessor: "lastAssessmentDate", align: "left", color: lightGreyText, sortable: true, minWidth: "150px" },
    { label: "This Month", accessor: "AssessmentsCountThisMonth", align: "center", color: lightGreyText, sortable: true },
    { label: "All Time", accessor: "AssessmentsCountAllTime", align: "center", color: lightGreyText, sortable: true },
    // { label: "Score Change", accessor: "ScoreChange", align: "center", color: lightGreyText, sortable: true },
    { label: "Groups", accessor: "groupsLink", align: "center", color: lightGreyText, sortable: false },
    { label: "Programs", accessor: "programsLink", align: "center", color: lightGreyText, sortable: false },
    { label: "Remove", accessor: "editLink", align: "center", color: lightGreyText, sortable: false }
  ];

  const notCoachColumns = [
    { label: "Member", accessor: "email", align: "left", color: tealText, sortable: true },
    { label: "Last Assessment", accessor: "lastAssessmentDate", align: "center", color: lightGreyText, sortable: true },
    { label: "This Month", accessor: "AssessmentsCountThisMonth", align: "center", color: lightGreyText, sortable: true },
    { label: "All Time", accessor: "AssessmentsCountAllTime", align: "center", color: lightGreyText, sortable: true }
  ];

  let columns = isCoach ? coachColumns : notCoachColumns;

  const handleSorting = (sortField, sortOrder) => {

    // console.log('~~~~~ MembersTable.js, MembersTable() - handleSorting() [...props.members].length: ' + [...props.members].length);
    // console.log('~~~~~ MembersTable.js, MembersTable() - handleSorting() sortField: ' + sortField + ', sortOrder: ' + sortOrder);

    if (sortField) {
      sorted = [...props.members].sort((a, b) => {
      // sorted = [tableData].sort((a, b) => {
        let aToString = "";
        let bToString = "";
        if (a[sortField]) {
          aToString = a[sortField].toString().length > 0 ? a[sortField].toString().replace('.','') : "";
        }
        if (b[sortField]) {
          bToString = b[sortField].toString().length > 0 ? b[sortField].toString().replace('.','') : "";
        }

        return (
          aToString.localeCompare(bToString) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  useEffect(() => {
    // console.log('~~~~~ MembersTable.js, MembersTable() - useEffect()) [...props.members].length: ' + [...props.members].length);
      
    if ([...props.members].length > 0 && !firstSortDone) {
      handleSorting('lastAssessmentDate', 'desc');
      setFirstSortDone(true);
    }
  }, [[...props.members]])

  return (
    <>
      <table cellPadding={10} width={"96%"}>
        <TableHead {...{ columns, handleSorting }} />
        <TableBody 
          columns={columns}  
          tableData={tableData}
          onRowClick={props.handleClick} 
          editClick={props.editClick} 
          groupsClick={props.groupsClick}
          programsClick={props.programsClick}
        />
      </table>
    </>
  );

}

export default MembersTable;
