import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import { propTypes } from 'react-bootstrap/esm/Image';

import Shell from '../Shell';
import Overview from '../Overview';
import Members from '../Members';

import Groups from '../Groups';
import Programs from '../Programs';
import Exercises from '../Exercises';
import Movements from '../Movements';
import Workouts from '../Workouts';
import Readiness from '../Readiness';
import TestList from '../TestList';

import History from '../History';
import Assessment from '../Assessment';
import Accept from '../Accept';
import Signup from '../Signup';


import styled from 'styled-components'

/*
import { useLocation } from 'react-router-dom';

function App() {
  let location = useLocation();
  console.log(location);
  return <div>Current URL is {location.pathname}</div>;
}

*/

const ContentDiv = styled.div`
  flex: 1;
  /* padding: 10px; */
  padding: 0px;
  padding-left: 0px; // for mobile only?
  padding-right: 0px; // for mobile only?
  background: #ffffff;
  height: 100vh;
  overflow-y: scroll;
`;

const Content = ({ // props: deviceInfo Object { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina }
  deviceInfo, isMobile, logout, deepLink
}) => {  
  console.log('~~~~~ Content(), deepLink.type is ' + deepLink.type);
  const isCoach = localStorage.getItem('coach') === 'true';
  const isAthlete = localStorage.getItem('athlete') === 'true' && localStorage.getItem('coach') !== 'true' && localStorage.getItem('enterprise') !== 'true' && localStorage.getItem('administrator') !== 'true';
  const isEnterprise = localStorage.getItem('enterprise') === 'true';
  
  // const deviceInfo = props.deviceInfo; // deviceInfo.isMobileOrTablet
  // const isMobile = props.isMobile;
  // const logout = props.logout;

  console.log('~~~~~ Content.js, Content() - isMobile is *' + isMobile + '*, and isAthlete is *' + isAthlete + '*, and isCoach is *' + isCoach + '*');
  return (
    <ContentDiv>
        <Routes>
        <Route
          exact
          path="/"
          // element={isCoach ? <Members deviceInfo={deviceInfo} isMobile={isMobile} /> : <Overview deviceInfo={deviceInfo} isMobile={isMobile} />}
          element={
            isCoach ? <Navigate replace to={"/shell"} /> : // TODO: ? expand conditional, or add others for other shell-centric types
            isAthlete ? <Navigate replace to={"/shell"} /> : // TODO: ? expand conditional, or add others for other shell-centric types
            isEnterprise ? <Navigate replace to={"/shell"} /> : // TODO: ? expand conditional, or add others for other shell-centric types
            // isAthlete ? <Navigate replace to={"/workouts"} /> : // TODO: ? add mobile/... routing for mobile devices?  simplify layouts?
            <Overview deviceInfo={deviceInfo} isMobile={isMobile} />
          }
          // element={
          //   isCoach ? <Members deviceInfo={deviceInfo} isMobile={isMobile} /> :
          //   isAthlete ? <Workouts deviceInfo={deviceInfo} isMobile={isMobile} /> :
          //   <Overview deviceInfo={deviceInfo} isMobile={isMobile} />
          // }
        />
          {/* <Route path='/' element={<Overview deviceInfo={deviceInfo} isMobile={isMobile} />} /> */}

        <Route
          exact
          path="/athlete/readiness"
          element={
            <Navigate replace to={"/shell"} /> // TODO: ? expand conditional, or add others for other shell-centric types
          }
        />

        <Route
          exact
          path="/athlete/workouts"
          element={
            <Navigate replace to={"/shell"} /> // TODO: ? expand conditional, or add others for other shell-centric types
          }
        />

        <Route
          exact
          path="/athlete/assessments"
          element={
            <Navigate replace to={"/shell"} /> // TODO: ? expand conditional, or add others for other shell-centric types
          }
        />

          {/* <Route path='/athlete/readiness' element={<Members deviceInfo={deviceInfo} isMobile={isMobile} />} />
          <Route path='/athlete/workouts' element={<Members deviceInfo={deviceInfo} isMobile={isMobile} />} /> */}
          
          <Route path='/members' element={<Members deviceInfo={deviceInfo} isMobile={isMobile} />} />


          { isCoach && 
          <>
            <Route path='/groups' element={<Groups deviceInfo={deviceInfo} isMobile={isMobile} />} />
            <Route path='/programs' element={<Programs deviceInfo={deviceInfo} isMobile={isMobile} />} />
            <Route path='/exercises' element={<Exercises deviceInfo={deviceInfo} isMobile={isMobile} />} />
            <Route path='/movements' element={<Movements deviceInfo={deviceInfo} isMobile={isMobile} />} />
            <Route path='/workouts' element={<Workouts deviceInfo={deviceInfo} isMobile={isMobile} />} />
          </>
          }
          {/* {
            (isAthlete && deepLink.type === 'athlete' && deepLink.page === 'readiness') &&
            <>
              <Route path='/myreadiness' element={<Readiness deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />} />
            </>
          }
          {
            (isAthlete && deepLink.type === 'athlete' && deepLink.page === 'assessments') &&
            <>
              <Route path='/myreadiness' element={<Readiness deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />} />
            </>
          }
          {
            (isAthlete && deepLink.type === 'athlete' && deepLink.page !== 'readiness' && deepLink.page !== 'workouts' ) &&
            <>
              <Route path='/myworkouts' element={<Readiness deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />} />
            </>
          }
          {
            isAthlete &&
            <>
              <Route path='/workouts' element={<Workouts deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />} />
              <Route path='/readiness' element={<Readiness deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />} />
              <Route path='/myworkouts' element={<Workouts deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />} />
              <Route path='/myreadiness' element={<Readiness deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} />} />
              <Route path='/testlist' element={<TestList deviceInfo={deviceInfo} isMobile={isMobile} />} />
            </>
          } */}

          <Route path='/shell' element={<Shell deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} deepLink={deepLink} />} />
          <Route path='/history' element={<History deviceInfo={deviceInfo} isMobile={isMobile} />} />
          <Route path='/assessment' element={<Assessment deviceInfo={deviceInfo} isMobile={isMobile} />} />
          <Route path='/accept' element={<Accept deviceInfo={deviceInfo} isMobile={isMobile} />} />
          <Route path='/signup' element={<Signup deviceInfo={deviceInfo} isMobile={isMobile} />} />
        </Routes>
    </ContentDiv>
  );

}

export default Content;