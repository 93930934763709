import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styled from 'styled-components';
import { accentColor } from '../../constants';

export const SiteDropdownButton = styled(DropdownButton)`
  display: inline-block;
  cursor: pointer;
  height: 4.5vw;
  color: #ffffff;   
  margin: 1.0vw 0 0.2vw 0 !important; /* top right bottom left */
  padding: 1.1vw; 
  border: none;
  border-radius: 0.75vw; /* was 10px */
`;

const SiteButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  background: ${accentColor};
  height: 4.5vw;
  color: #ffffff;   
  margin: 1.0vw 0 0.2vw 0 !important; /* top right bottom left */
  padding: 1.1vw; 
  border: none;
  border-radius: 0.75vw; /* was 10px */
`;

const SiteButtonIcon = styled.img`
  display: inline-block;
  width: 2.0vw;
  height: 2.0vw;
  margin: 0 1vw 0 0 !important;
  padding: 0 !important;
  /* padding-left: 0.25vw !important; */
`;

const SiteButtonTitleDiv = styled.div`
  display: inline-block;
  height: 1.8vw;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 0!important;
  font-size: 1.3vw;
  /* white-space: nowrap; */
  vertical-align: middle;
`;

function SiteButton(props) {
  const bgColor = accentColor;
  const titleColor = "#ffffff";
  const icon = props.icon;
  const iconOpacity = 1.0; // or 0.4; ?
  const hidden = props.hidden;

  return (
    <SiteButtonDiv style={{ background: bgColor, display: hidden ? "none" : "block" }} onClick={props.onClick}>
      <SiteButtonIcon src={icon} style={{ opacity: iconOpacity }}/>
      <SiteButtonTitleDiv style={{ color: titleColor }}>
        {props.title}
      </SiteButtonTitleDiv>
    </SiteButtonDiv>
  );
}

export default SiteButton;