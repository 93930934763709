// GroupEdit.js

import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';

import { createGroup, updateGroup, createMovement, updateMovement, createExercise, updateExercise, createProgram, updateProgram } from '../../services';
import { colors, iconSrc } from '../../constants';
import { capitalize } from '../../helpers';

import {
  ShellOvalBtn,
  ShellInputLabel,
  ShellFieldDisplayName,
  ShellTextInput,
  ShellTextarea
} from './title';

const FakeGroupEditWrapper = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  white-space: nowrap;
  width: 100%;
  height: 140px;
  padding: 0px;
  padding-top: 10px;
  padding-left: 40px;
  margin: auto;
  margin-top: 10px;
  text-align: center;
  vertical-align: middle;
`;

const GroupEdit = ({
  // visible, create, edit, itemId, modelInfo, item
  onSaveClick, item, parent, create, propKey // item is incoming groupObj from Shell state, create is CreateNew (not edit) flag
}) => {

  const MtGroup = { id_guid: "", name_string: "", note_string: "", parent_group_id_guid: "", user_id_guid: localStorage.getItem('loggedInUser')};
  const [formItem, setFormItem] = useState(MtGroup); // does this need to reside in Shell State? we have [group, setGroup] there...
  
  useEffect(() => {
  // mounted.current = true; // TODO: how is this useful?
  console.log('~~~~~ GroupEdit.js, useEffect()');
  
  if (!item) {
    return;
  }

  let newItem = Object.assign({}, item); // this clones the current item prop as newItem
  newItem.parent_group_id_guid = parent;

  console.log('~~~~~ GroupEdit() - useEffect() - after edit, newItem.parent_group_id_guid] is *' + newItem.parent_group_id_guid + '*');
  
  setFormItem(newItem);

  let side = "";

  console.log('~~~~~ GroupEdit(), propKey is ' + propKey);
  if (propKey !== undefined && propKey.length > 0){
    side = propKey.substring(propKey.length - 2);
  }
  side = propKey.substring(propKey.length - 2);
  console.log('~~~~~ GroupEdit(), side is ' + side);
}, [item, parent, propKey]) // just do this when the item or parent props change


  function onFieldChange(fieldName, newValue){
    console.log('~~~~~ GroupEdit(), onFieldChange() - new contents of fieldName input: ' + newValue);
    let newItem = Object.assign({}, formItem); // this clones the current formItem as newItem
    newItem[fieldName] = newValue;

    console.log('~~~~~ GroupEdit() - onFieldChange() - after edit, newItem[' + fieldName + '] is *' + newItem[fieldName] + '*');
    
    setFormItem(newItem);
  }

  function handleSaveClick(){
    console.log('~~~~~ GroupEdit(), handleSaveClick() - id_guid of item to save is ' + formItem.id_guid);

    onSaveClick("group", formItem);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <FakeGroupEditWrapper style={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
        <ShellInputLabel>
          <ShellFieldDisplayName>Name</ShellFieldDisplayName>
          <ShellTextInput type="text" value={formItem.name_string} id="group_name_string" placeholder = "Enter a Name for this Group" name="name_string" onChange={(e) => onFieldChange("name_string", e.target.value)} />
          <ShellOvalBtn type="button" onClick={handleSaveClick}>Save</ShellOvalBtn>
        </ShellInputLabel>
        <ShellInputLabel>
          <ShellFieldDisplayName>Notes</ShellFieldDisplayName>
          <ShellTextarea value={formItem.note_string} id="group_note_string" name="note_string" onChange={(e) => onFieldChange("note_string", e.target.value)} />
        </ShellInputLabel>
      </FakeGroupEditWrapper>
    </div>
  );
}

export default GroupEdit;
