// /components/styled/MyAssessmentHistory.js

import React, { useEffect, useRef, useState } from 'react';
import packageJson from '../../../package.json';

import MemberRecentScansChart from './MemberRecentScansChart';
import AssessmentsTable from './AssessmentsTable';
import MyAssessment from './MyAssessment';
import MobileLoadingDivLayer  from './MobileLoadingDivLayer';

import { Title, EZSpacer, Icon, MobileVersionDiv } from './title';
import { iconSrc } from '../../constants';
import { getMember, getMemberAssessments, getAssessmentDetail } from '../../services';


const MyAssessmentHistory = ({
  athleteId, isMobile
}) => {
  console.log('~~~~~ MyAssessmentHistory() isMobile is ' + isMobile);
  const [member, setMember] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [detail, setDetail] = useState(false);
  const [issues, setIssues] = useState([]);
  const [scores, setScores] = useState([]);
  const [metadata, setMetadata] = useState({});
  const mounted = useRef(true);

  function goToHistory() {
    setDetail(false);
  }

  useEffect(() => {
    mounted.current = true; // TODO: how is this useful?
    if(member.email || member.id) {
      return;
    }
    getMember(athleteId)
      .then(item => {
        if(mounted.current) { // TODO: how is this useful?
          setMember(item)
        }
      })
  }, [athleteId, member.email, member.id])

  useEffect(() => {
    if(assessments.length || loaded || athleteId === null) {
      return;
    }
    getMemberAssessments(athleteId)
      .then(items => {
        if(mounted.current) { // TODO: how is this useful?
          setAssessments(items)
          setLoaded(true); // arbitrary choice of this api call as longest
        }
      })

    // return () => mounted.current = false; // TODO: how is this useful?
  }, [assessments, loaded, athleteId])

  function handleAssessmentClick(assessmentMetaData) {
    //navigate('/assessment?from=History&assessmentid=' + assessmentMetaData.id_guid + "&prior=" + assessmentMetaData.prior_id_guid);
    // alert('MyAssessmentHistory(), handleAssessmentClick() - Assessmeent DateTime  is ' + assessmentMetaData.date + ' at ' + assessmentMetaData.time);
    console.log('~~~~~ MyAssessmentHistory(), handleAssessmentClick() - AssessmentId  is ' + assessmentMetaData.id_guid);
    // setAssessmentId(assessmentMetaData.id_guid);
    fetchAssessmentDetail(assessmentMetaData.id_guid)
    setDetail(true);
  }

  function fetchAssessmentDetail(assessmentId) {
    console.log('~~~~~ MyAssessmentHistory(), handleAssessmentClick() - AssessmentId  is ' + assessmentId);
    // const priorId = thePriorAssessmentId(assessmentId);
    // console.log('~~~~~ MyAssessmentHistory(), handleAssessmentClick() - priorAssessmeentId  is ' + priorId);
    
    setLoaded(false);
    // code to fetch assessment data, which will setLoaded(true) in the .then of service call....
    // if(alreadyFetched) { // was (scores && scores[0])
    //   return;
    // }
    getAssessmentDetail(assessmentId)
      .then(item => {
        setAssessmentData(item);
        setIssues(item.issuesData);
        setScores(item.scoresData);
        setMetadata(item.metaData);
        // setalreadyFetched(true); 
        setLoaded(true);
        // setTimeout(() => { // TODO: is delay necessary?
        //   // console.log("Delayed for 2 seconds.");
        //   setLoaded(true);
        // }, 1000)
      })
  }

  // function thePriorAssessmentId(assessmentId){
  //   console.log('~~~~~ MyAssessmentHistory(), thePriorAssessmentId() - assessmeentId  is ' + assessmentId);

  //   const isCurrentAssessment = (element) => element.id_guid === assessmentId;
  //   const currentIndex = assessments.findIndex(isCurrentAssessment);

  //   console.log('~~~~~ MyAssessmentHistory(), thePriorAssessmentId() - currentIndex  is ' + currentIndex);
  //   if (currentIndex === 0){
  //     return null;
  //   }
  //   else
  //   {
  //     const priorId = assessments[currentIndex - 1].id_guid;
  //     console.log('~~~~~ MyAssessmentHistory(), thePriorAssessmentId() - priorId  is ' + priorId);
  //     return priorId;
  //   }
  // }

  return (
    <div>
      <MobileVersionDiv>v{packageJson.version}</MobileVersionDiv>
      {!loaded && <MobileLoadingDivLayer />} 
      {(loaded && !detail) &&
        <div className="SomeWrapper" style={{ padding: "0px" }}>
          <div className="IconAndTitle" style={{ width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center", paddingBottom: "3vw", paddingTop: "0vw" }}>
            <div className="HeaderIconDiv" style={{ width: "100vw", display: "flex", justifyContent: "center", paddingTop: "3vw", paddingBottom: "3vw" }}>
              <Icon style={{ width: "6vw", height: "6vw", margin: "auto" }} src={iconSrc.fizzy_squat_black} />
            </div>
            <h4 style={{ width: "100vw", fontSize: "5vw", fontWeight: "bold", textAlign: "center" }}>ASSESSMENTS</h4>
            {/* <div className="Subtitle" style={{ width: "100vw", fontSize: "2.5vw", textAlign: "center", color: "#777" }}>
              Trends Over Time
            </div> */}
          </div>
          <MemberRecentScansChart data={assessments} isMobile={isMobile} />
          <EZSpacer height={"6vh"} />
          <Title style={{ display: "flex", justifyContent: "center", fontSize: "2.5vh" }}>Assessment History</Title>
          <EZSpacer height={"2vh"} />
          <AssessmentsTable assessments={assessments} isMobile={isMobile} handleClick={handleAssessmentClick} />
        </div>
      }
      {(loaded && detail) &&
        <div className="SomeWrapper">
          <div className="IconAndTitle" style={{ width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center", paddingBottom: "5vw", paddingTop: "0vw" }}>
            <div className="HeaderIconDiv" style={{ width: "100vw", display: "flex", justifyContent: "center", paddingTop: "3vw", paddingBottom: "3vw" }}>
              <Icon style={{ width: "6vw", height: "6vw", margin: "auto" }} src={iconSrc.fizzy_squat_black} />
            </div>
            <h4 style={{ width: "100vw", fontSize: "5vw", fontWeight: "bold", textAlign: "center" }}>ASSESSMENT DETAIL</h4>
            {/* <div className="Subtitle" style={{ width: "100vw", fontSize: "2.5vw", textAlign: "center", color: "#777" }}>
              Trends Over Time
            </div> */}
          </div>
          <MyAssessment data={assessmentData} isMobile={isMobile} goToHistory={goToHistory} />
        </div>
      }
    </div>
  );
}

export default MyAssessmentHistory;