// ComplianceDetail.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { 
  colors,
  logoSrc,
  iconSrc
 } from '../../constants';
 import { 
  ExploreBarWrapper,
  ExploreHeaderCell,
  // ExploreIcon,
  ExploreTableBodyWrapper,
  ExploreTableCell,
  ExploreTableMovementCell,
  ExploreTableHeader,
  ExploreTableRow,
  ExploreTableMovementRow,
  ExploreTableRowMessage,
  ExploreTableWrapper,
  ExploreWrapper
} from './exploreStuff';
 import { ExploreIcon, ShellOvalBtn, AreaTitle } from './title';
 import { getUserCompliance, getWorkoutCompliance, getWorkout } from '../../services';

 function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


const tabSectionItemsWidth = "90%";
const tabSectionItemsVerticalSpacing = "10px";

const TopTitle = styled.div`
  width: 100%; 
  text-align: center; 
  margin: auto;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 0.8vw;
  font-weight: bold;
`;

const BottomTitle = styled.div`
  width: 100%; 
  text-align: left; 
  margin: 0;
  margin-top: 15px;
  font-size: 0.8vw;
  font-weight: bold;
`;

const AthleteNotes = styled.div`
  width: 100%; 
  text-align: left; 
  margin: 0;
  margin-top: 10px;
  padding: 0px;
  font-size: 0.8vw;
  font-weight: normal;
`;

// same as ExploreTableWrapper?
const ComplianceDetailWrapper = styled.div`
  width: ${tabSectionItemsWidth};
  max-width: 700px;
  min-height: 360px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  margin: auto;
  margin-top: ${tabSectionItemsVerticalSpacing};
  margin-right: -15px;
  text-align: left;
`;

// same as ExploreHeaderCell?
const ComplianceDetailHeaderCell = styled.td`
  height: 30px;
  text-align: center;
  background-color: #eeeeee; 
`;

// same as ExploreTableBodyWrapper?
const ComplianceDetailBodyWrapper = styled.div`
  width: 100%;
  height: 320px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  text-align: center;
`;

// same as ExploreTableCell?
const ComplianceDetailCell = styled.td`
  cursor: pointer;
  height: 40px;
  text-align: center;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  border-bottom: 1px solid #eeeeee;
  /* border: 0.5px solid red; */
`;

// same as ExploreTableHeader?
const ComplianceDetailHeader = styled.thead`
  width: 100%;
  height: 30px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  margin-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  border-radius: 12px;
`;

// same as ExploreTableRow?
const ComplianceDetailRow = styled.tr`
  width: 100%;
  height: 35px;
  border-top: 2px solid white;
  ${ props => props.selected === true
    ? '{ background-color: #eeeeee }'
    : '{ background-color: #ffffff }' // use colors.sidebarBg ?
  }
  ${ props => props.dimmed === true
    ? '{ opacity: 0.5 }'
    : '{ opacity: 1.0 }'
  }
`;


const ComplianceDetail = ({
  userId, workoutId, assignedBy // with these, compliance can be fetched
}) => {
  console.log('~~~~~ ComplianceDetail() - userId is: ' + userId + ', and workoutId is ' + workoutId + ', and assignedBy is ' + assignedBy);
  const mounted = useRef(true);
  const fetched = useRef(false);
  const [exercises, setExercises] = useState([]); // set by useEffect call to getWorkout method
  const [compliances, setCompliances] = useState([]);
  const [complianceData, setComplianceData] = useState();  // set by useEffect call to getWorkoutCompliance method
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  
  const event = complianceData !== undefined && complianceData.performed_datetime !== undefined ? new Date(complianceData.performed_datetime) : "";
  const displayCompletedDatetime = fetched.current === true && event > 0 ? event.toLocaleTimeString(undefined, dateOptions) : "";
  console.log('~~~~~ ComplianceDetail() - event is: ' + event + ', and fetched.current is ' + fetched.current);
  console.log('~~~~~ ComplianceDetail() - displayCompletedDatetime from event is: ' + displayCompletedDatetime);
  const completedMsg = complianceData !== undefined && complianceData.performed_datetime !== undefined ? "Completed " + displayCompletedDatetime : "Not Completed Recently";

  function exerciseDisplayData(exerciseId){ // get this from workout
    console.log('~~~~~ ComplianceDetail(), exerciseDisplayData() - exerciseId is: ' + exerciseId);
    if (exercises && Array.isArray(exercises) && exercises.length >= 1) {
      console.log('~~~~~ ComplianceDetail(), exerciseDisplayData() - exercises.length is ' + exercises.length);
      const exerciseObj = exercises.find(exercise => exercise.exercise_id_guid === exerciseId.toLowerCase());
      
      if (!exerciseObj) {
        console.log('~~~~~ ComplianceDetail(), exerciseDisplayData() - no matching exercise found');
        return 'no matching exercise found';
      }
      console.log('~~~~~ ComplianceDetail(), exerciseDisplayData() - exerciseObj.name_string is ' + exerciseObj.name_string);
      console.log('~~~~~ ComplianceDetail(), exerciseDisplayData() - exerciseObj.load_string is ' + exerciseObj.load_string);
      let display_name = exerciseObj && exerciseObj.name_string && exerciseObj.name_string.length > 0 ? exerciseObj.name_string : exerciseId + ' no name';
      let specified_load = exerciseObj && exerciseObj.load_string && exerciseObj.load_string.length > 0 ? exerciseObj.load_string : exerciseId + ' no load';
      return { display_name: display_name, specified_load: specified_load };   
    } else {
      console.log('~~~~~ ComplianceDetail(), exerciseDisplayData() - exercises array did not exist, or had no elements');
      return false;
    }
  }

  useEffect(() => {
    mounted.current = true; // TODO: how is this useful?
    fetched.current = false;

    getWorkoutCompliance(userId, workoutId) // get compliance for this user for this workout
    .then(items => { // item is going to be what is returned from the service method...
      const complianceArray = items;
      setCompliances(complianceArray); // in case we need the whole (historical) list of compliances for this workout for something, later...
      const complianceObj = complianceArray[0];
      const complianceId = complianceObj && complianceObj.id_guid && complianceObj.id_guid.length > 0 ? complianceObj.id_guid : 'no-compliance-id';
      
      console.log('~~~~~ ComplianceDetail(), useEffect() - complianceId is : ' + complianceId);

      setComplianceData(complianceObj);
      fetched.current = true;
    })
  }, [userId, workoutId])

  useEffect(() => {
    mounted.current = true; // TODO: how is this useful?
    fetched.current = false;

    getWorkout(workoutId) // get data for this workout
    .then(item => { // item is going to be the array of exercises that is returned from the service method...
      const exercisesArray = item.exercise_array;
      
      console.log('~~~~~ ComplianceDetail(), useEffect(), getWorkoutExercises() - exercisesArray is : ' + exercisesArray);

      setExercises(exercisesArray);
      fetched.current = true;
    })
  }, [workoutId])

  const TableBody = ({ // we will iterate through the elements in the array complianceData.load_array
    exerciseArray
  }) => {
    const workoutExerciseCt = exercises.length;
    const exerciseWithLoadCt = complianceData !== undefined && complianceData.load_array !== undefined ? complianceData.load_array.length : 0;
    const zeroStateMsg = workoutExerciseCt === 0 ? "This Workout has no Exercises - fix it!" : exerciseWithLoadCt === 0 ? "None of the Exercises specified a loading" : "Unspecified Error";
    if (complianceData !== undefined && complianceData.load_array !== undefined && complianceData.load_array.length > 0) {
      return (
        <tbody style={{ width: "100%" }}> 
          {complianceData.load_array.map(rowData => {
              console.log('~~~~~ ComplianceDetail(), TableBody(), tableData.map() - rowData.exercise_id_guid is : ' + rowData.exercise_id_guid);
              let exerciseName = exerciseDisplayData(rowData.exercise_id_guid).display_name;
              let suggestedLoading = exerciseDisplayData(rowData.exercise_id_guid).specified_load;
              let loadingUsed = rowData.load_string;
              console.log('~~~~~ ComplianceDetail(), TableBody(), tableData.map() - exerciseName is : ' + exerciseName);
              console.log('~~~~~ ComplianceDetail(), TableBody(), tableData.map() - suggestedLoading is : ' + suggestedLoading);
              console.log('~~~~~ ComplianceDetail(), TableBody(), tableData.map() - loadingUsed is : ' + loadingUsed);
              return (
                <ComplianceDetailRow key={workoutId + "_" + uuidv4()}>
                  <ComplianceDetailCell style={{ textAlign: "left", paddingLeft: "20px" }}>{exerciseName}</ComplianceDetailCell>
                  <ComplianceDetailCell style={{ textAlign: "center", paddingLeft: "5px" }}>{suggestedLoading}</ComplianceDetailCell>
                  <ComplianceDetailCell style={{ textAlign: "center", paddingLeft: "5px" }}>{loadingUsed}</ComplianceDetailCell>
                </ComplianceDetailRow>
              ) 
            })
          }
        </tbody>
      )
    } else {
      return (
        <tbody style={{ width: "100%" }}>
          <ComplianceDetailRow key={workoutId + "_" + uuidv4()}>
          <ComplianceDetailCell align="left" style={{ textAlign: "left", paddingLeft: "20px" }}>
           {zeroStateMsg}
          </ComplianceDetailCell>
        </ComplianceDetailRow>
      </tbody>
      )
    }
  };

  return (
    <>
      <TopTitle>{completedMsg}</TopTitle>
      <TopTitle>Assigned by {assignedBy}</TopTitle>
      <ComplianceDetailWrapper>
        <table style={{ display: "table", borderCollapse: "separate", borderSpacing: "0 2px", marginTop: "15px", marginBottom: "25px", width: "100%" }}>
          <ComplianceDetailHeader>
            <tr>
              <ComplianceDetailHeaderCell align="left" style={{ borderRadius: "15px 0 0 15px", textAlign: "left", paddingLeft: "20px" }}>Exercise</ComplianceDetailHeaderCell>
              <ComplianceDetailHeaderCell width="25%" align="center">Specified Loading</ComplianceDetailHeaderCell>
              <ComplianceDetailHeaderCell width="25%" align="center" style={{ borderRadius: "0 15px 15px 0" }}>Loading Used</ComplianceDetailHeaderCell>
            </tr>
          </ComplianceDetailHeader>
          <TableBody exerciseArray={exercises} />
        </table>
        {!completedMsg.includes("Not") &&
          <>
            <BottomTitle>Perceived Level of Effort for this Workout: {complianceData.level_of_effort_integer} / 5</BottomTitle>
            <BottomTitle>Workout Comments:</BottomTitle>
            <AthleteNotes>{complianceData.note_string}</AthleteNotes>
          </>
        }       


      </ComplianceDetailWrapper>
    </>
  );
}

export default ComplianceDetail;