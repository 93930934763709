// models/athlete.js

import { iconSrc, colors } from '../constants';

const displayFields = [
  { // name
    type: "text",                        // could be "text", "textarea", "association", "image"?, "video"?
    displayName: "Name",                 // any string up to a maxLength of 20 characters
    accessor: "name_string",             // attribute name when item delivered from API
    default: "",                         // value of this attribute for a brand new 'blank' item
    maxChars: "20",                      // an integer as a string, if we are going to try to limit this...
    minChars: "2",                       // an integer as a string, if we are going to try to limit this, or use as empty check
    pickListInfo: "",                    // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
    required: true                       // should this field be submitted to the api for a create or update access?
  },
  { // athletes
    type: "association", // this type of field requires lists and add/remove actions that are forthcoming...
    displayName: "Athletes in Group",
    accessor: "group_users", // we'll parse the association table name from this to perform getAllAssoc and getNotAssoc
    default: "",
    maxChars: "",
    minChars: "",
    pickListInfo: "{ 'relatedModel': 'user', 'relationTable': 'user_group', 'visibleRows': '20', 'colsInfo': '[{ 'title': 'Name', 'minWidth': '200px', 'colAssessor': 'name_string' }, { 'title': 'Email', 'minWidth': '200px', 'colAssessor': 'email_string' }] }",                    // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
    required: false
  },
  { // programs
    type: "hidden", // TODO: change back to 'association' this type of field requires lists and add/remove actions that are forthcoming...
    displayName: "Programs for Group",
    accessor: "group_programs", // we'll parse the association table name from this to perform getAllAssoc and getNotAssoc
    default: "",
    maxChars: "",
    minChars: "",
    pickListInfo: "{ 'relatedModel': 'program', 'relationTable': 'group_program', 'visibleRows':, 'colsInfo': '[{ 'title': 'Name', 'minWidth': '200px', 'colAssessor': 'name_string' }, { 'title': 'Days', 'minWidth': '50px', 'colAssessor': 'days_string' }] }",
    required: false
  },
  { // note
    type: "textarea",
    displayName: "Notes",
    accessor: "note_string",
    default: "",
    maxChars: "2000",
    minChars: "",
    pickListInfo: "",
    required: true 
  },
  { // id
    type: "hidden",
    displayName: "ID",
    accessor: "id_guid",
    default: "",
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  }
];

const listColumns = [ // the columns list for the main table for this itemType
  { label: "Athlete Name or Email", accessor: "full_name_string", align: "left", color: colors.darkGreyText, sortable: true, minWidth: "150px" },
  // { label: "Athlete Email", accessor: "email_string", align: "left", color: colors.darkGreyText, sortable: true, minWidth: "200px" },
  { label: "Last Assessment", accessor: "lastAssessmentDate", align: "left", color: colors.lightGreyText, sortable: true, minWidth: "150px", replaceDataIf: "pending", replaceDataWith: "Pending Acceptance..." },
  // { label: "This Month", accessor: "AssessmentsCountThisMonth", align: "center", color: colors.lightGreyText, sortable: true },
  // { label: "All Time", accessor: "AssessmentsCountAllTime", align: "center", color: colors.lightGreyText, sortable: true },
  // { label: "Score Change", accessor: "ScoreChange", align: "center", color: colors.lightGreyText, sortable: true },
  { label: "Groups", accessor: "groupsLink", align: "center", color: colors.lightGreyText, sortable: false, title: "Show this Athlete's Groups, or click to edit them", iconSrc: iconSrc.groups },
  { label: "Workouts", accessor: "programsLink", align: "center", color: colors.lightGreyText, sortable: false, title: "Show this Athlete's Workouts, or click to edit them", iconSrc: iconSrc.programs },
  { label: "Edit", accessor: "editLink", align: "center", color: colors.lightGreyText, sortable: false, title: "Stop monitoring this Athlete, or revoke their invitation", iconSrc: iconSrc.remove_active }
];

const attributes = [ // these are attributes for an Athlete (more generally, a User)
  "id_guid",                                          // the athlete, member or user id_guid
  "email_string",                                     // the email address for the user
  "user_association_user_approval_boolean",           // the coach has approved the linkage
  "user_association_athlete_user_approval_boolean",   // the athlete has approved the linkage
  "user_association_athlete_user_name_string",        // the name the coach assigned to the athlete
  "first_name_string",                                // the first name provided by the athlete when signing up
  "last_name_string",                                 // the last name provided by the athlete when signing up
  "enterprise_assessment_total_count_integer",        // count of all assessments performed by athlete on enterprise apps
  "enterprise_assessment_last_month_count_integer",   // count of assessments performed last month by athlete on enterprise apps
  "enterprise_assessment_last_datetime",              // date of last assessment performed by athlete on enterprise apps
  "athlete_assessment_total_count_integer",           // count of all assessments performed by athlete on athlete app
  "athlete_assessment_last_month_count_integer",      // count of assessments performed last month by athlete on athlete app
  "athlete_assessment_last_datetime",                 // date of last assessment performed by athlete on athlete app
  "assessment_total_count_integer",                   // count of all assessments performed by athlete on both apps
  "assessment_last_month_count_integer",              // count of assessments performed last month by athlete on both apps
  "assessment_last_datetime",                         // date of last assessment performed by athlete on either app
  "group_list_string",                                // csv string of names of groups the athlete is a member of
  "program_list_string",                              // csv string of names of programs the athlete is a member of
  "program_count_integer",                            // count of programs assigned directly to athlete, and athlete's groups
];

const defaultSort = {
  field: "lastAssessmentDate",
  order: "desc"
}

const strings = {
  noItemsMsg: "You have no associated Athletes yet, use Invite Athlete button to invite some!",
  noFilteredItemsMsg: "You have no Athletes in this Group, use the Groups tab to add some!",
  itemClickEnabledBooleanAccessor: "user_association_athlete_user_approval_boolean",
  itemClickDisabledMessage: "Assessment History will be available once the Athlete has accepted your Invite!"
}

const athleteModel = {
  type: "athlete",
  attributes,             // attributes: attributes,          would also have worked...
  listColumns,            // attributes: listColumns,         would also have worked...
  displayFields,          // displayFields: displayFields,    would also have worked...
  defaultSort,            // defaultSort: defaultSort,        would also have worked...
  strings                 // strings: strings                 would also have worked...
}

export default athleteModel;