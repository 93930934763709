// ShellSubdomainScoresTable.js

import React from 'react';
import styled from 'styled-components';

import { Title } from './title';
import ShellScoreDisplay from './ShellScoreDisplay';

const TableTitle = styled(Title)`
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  ${ props => props.isMobile === true
    ? '{ margin-top: 7vw }'
    : '{ margin-top: 30px }'
  }
  /* margin-top: 30px; */
  margin-bottom: 20px;
  ${ props => props.isMobile === true
    ? '{ font-size: 3vw }'
    : '{ font-size: 14px }'
  }
  /* font-size: 14px; */
`;

const ScoresContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ShellSubDomainScoresTable = ({
  data, domain, isMobile    /* data: a scores object,  domain: "mobility" | "stability" | "posture" | "symmetry" */
}) => {
  var domainData = data.filter((score) => {
    return score.domain === domain;
  });

  return (
    <>
      <TableTitle className="tabletitle" isMobile={isMobile}>SUBDOMAINS</TableTitle>
      <ScoresContainerDiv>
        {domainData.map(data => {
          return (<ShellScoreDisplay data={data} key={"score-display-" + data.id} twoColumns isMobile={isMobile} />)
        })}
      </ScoresContainerDiv>
    </>
  );
}

export default ShellSubDomainScoresTable;