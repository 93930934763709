// LoadingDivLayer.js

import React from 'react';
import { loadingSrc, accentColor } from '../../constants';

import styled from 'styled-components';

const LoadingDiv = styled.div`
  display: block;
  min-width: 100%;
  min-height: 100%;
  text-align: center;
  ${ props => props.sidebar !== false
    // ? '{ background-color: #eeeeee }'
    // : '{ background-color: #ffffff }' // use colors.sidebarBg ?
  }
  ${ props => props.sidebar !== false
    // ? '{ padding-top: 2vh }'
    // : '{ padding-top: 40vh }' // use colors.sidebarBg ?
  }
`;
// backgroundColor: props.sidebar ? "#eeeeee" : "#ffffff"
// paddingTop: props.sidebar || props.explore ? "2vh" : "40vh"

const LoadingImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  ${ props => props.sidebar === true
    // ? '{ width: 40px }'
    // : '{ width: auto }' // use colors.sidebarBg ?
  }
  ${ props => props.sidebar !== false
    // ? '{ margin-bottom: 0px }'
    // : '{ margin-bottom: 2vw }' // use colors.sidebarBg ?
  }
`;
// width: props.sidebar || props.explore ? "40px" : "auto"
// marginBottom: props.sidebar || props.explore ? "0px" : "2vw"

const LoadingText = styled.div`
  width: 10vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  ${ props => props.sidebar !== false
    // ? '{ font-size: smaller }'
    // : '{ font-size: auto }' // use colors.sidebarBg ?
  }
  color: ${accentColor};
`;
// fontSize: props.sidebar || props.explore ? "smaller" : "auto"

function LoadingDivLayer(props) {
  return (
    <LoadingDiv style={{ backgroundColor: props.sidebar ? "#eeeeee" : "#ffffff", paddingTop: props.sidebar || props.explore ? "2vh" : "40vh" }} >
      <LoadingImage src={loadingSrc} style={{ width: props.sidebar || props.explore ? "40px" : "auto", marginBottom: props.sidebar || props.explore ? "0px" : "2vw" }} />
      <LoadingText style={{ fontSize: props.sidebar || props.explore ? "smaller" : "auto" }} >Loading...</LoadingText>
    </LoadingDiv>
  );
}

export default LoadingDivLayer;