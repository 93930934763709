// components/styled/ReadinessHeader.js

import React from 'react';

import styled from 'styled-components';
import { AreaTitle, BackLink } from './title';


const ReadinessHeaderDiv = styled.div`
  text-align: center;
`;

function ReadinessHeader(props) {
  const isMobile = props.isMobile;

  console.log('~~~~~ ReadinessHeader.js, ReadinessHeader() - isMobile is *' + isMobile + '*');
  return (
    <ReadinessHeaderDiv>
      <AreaTitle style={{ display: "inline-block", width: "30%", textAlign: "left", fontSize: "smaller" }}>
        <BackLink onClick={(e) => {window.location = './workouts'}}>{ "View Workouts" }</BackLink>
      </AreaTitle>
      <AreaTitle style={{ display: "inline-block", width: "36%", color: isMobile ? "red" : "black" }}>Please Report Your Readiness</AreaTitle>
      <AreaTitle style={{ display: "inline-block", width: "30%", textAlign: "right", fontSize: "smaller" }}>
        {/* <a onClick={(e) => {window.location.reload()}}  style={{ cursor: "pointer", color: colors.accent, textDecoration: "underline" }}>Recommendations</a> */}
        {/* <BackLink onClick={(e) => {window.location.reload()}}>{ showMovementsLink ? "Recommendations" : "" }</BackLink> */}
      </AreaTitle>
    </ReadinessHeaderDiv>
  );
}

export default ReadinessHeader;


