import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import styled from 'styled-components';

import { accentColor, colors } from '../../constants';


const Title = styled.h2`
  margin: 0;
  padding: 0;
  padding-left: 1vw;
  font-size: 1.3vw;
  color: #000000;
`;

const AreaTitle = styled.h2`
  margin: 0;
  padding: 0;
  padding: 20px;
  font-size: 1.3vw;
  color: ${colors.greyTitleText};
`;

const AreaTitle2 = styled.h2`
  margin: 0;
  padding: 0;
  padding: 5px;
  font-size: 1.3vw;
  color: ${colors.greyTitleText};
`;

const BackLink = styled.h4`
  color: ${accentColor};
  font-size: 1.2vw;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const RedSpan = styled.span`
  color: ${colors.scoreGreen};
`;

const Thumbnail = styled.img`
  /* width: 200px; */
  height: 100px;
  background: ghostwhite;
`;

const MouseOverVideoDiv = styled.div`
  cursor: pointer;
  background: ghostwhite;
`;

/* height: ${(props) => ((props.src && props.src.length > 5) ? "#f00250px" : "20px")}; */

const ContentWrapper = styled.div`
  background: white;
  /* padding-left: 3vw; */
  padding-top: 3vw;
  display: none;
  ${ props => props.loaded 
    ? '{ display: block }'
    : '{ display: none }'
  } 
`;

const SplitTabContentWrapper = styled.div`
  display: flex; 
  /* flex-direction: row;  */
  ${ props => props.isMobile 
    ? '{ flex-direction: column }'
    : '{ flex-direction: row }'
  }
  margin-left: -20px; 
  margin-top: -20px;
  background: white;
  padding-left: 3vw;
  padding-top: 3vw;
  display: none;
  ${ props => props.loaded 
    ? '{ display: flex }'
    : '{ display: none }'
  } 
`;

const SplitTabContentWrapper2 = styled.div`
  display: flex; 
  /* flex-direction: row;  */
  ${ props => props.isMobile 
    ? '{ flex-direction: column }'
    : '{ flex-direction: row }'
  }
  margin: 0px;
  background: white;
  padding: 0px;
  display: none;
  ${ props => props.loaded 
    ? '{ display: flex }'
    : '{ display: none }'
  } 
`;

const TabSectionLeft = styled.div`
  ${ props => props.isMobile 
    ? '{ display: block }'
    : '{ display: flex }'
  } 
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  ${ props => props.isMobile 
    ? '{ width: 100% }'
    : '{ width: 50% }'
  }
  /* width: 50%;  */
  color: #333333;
  ${ props => props.isMobile 
    ? '{ margin-left: 10px }'
    : '{ margin-left: 0px }'
  }
  padding: 10px;
  margin-right: 10px;
  margin-top: -30px;
  margin-bottom: -30px;
  /* min-height: 100vh; */
  ${ props => props.isMobile 
    ? '{ min-height: 90vh }'
    : '{ min-height: 100vh }'
  }
  /* border-right: 1px solid ${colors.lightGreyDividers} */
  ${ props => props.isMobile 
    ? '{ border-right: none }'
    : '{ border-right: 1px solid lightgrey}'
  }
`;

const TabSectionLeft2 = styled.div`
  max-width: 720px;
  ${ props => props.isMobile 
    ? '{ display: block }'
    : '{ display: flex }'
  } 
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  ${ props => props.isMobile 
    ? '{ width: 100% }'
    : '{ width: 50% }'
  }
  /* width: 50%;  */
  color: black;
  ${ props => props.isMobile 
    ? '{ margin-left: 10px }'
    : '{ margin-left: 0px }'
  }
  padding: 0px;
  margin: 0px;

  /* min-height: 100vh; */
  ${ props => props.isMobile 
    ? '{ min-height: 90vh }'
    : '{ min-height: 100vh }'
  }
  border-right: 2px solid ${colors.sidebarBg}
  ${ props => props.isMobile 
    ? '{ border-right: none }'
    : ''
  }
`;

  /* Adapt the colours based on primary prop */
  // background: ${props => props.primary ? "palevioletred" : "white"};
  // color: ${props => props.primary ? "white" : "palevioletred"};

const TabSectionRight = styled.div`
${ props => props.isMobile 
    ? '{ display: block }'
    : '{ display: flex }'
  } 
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  ${ props => props.isMobile 
    ? '{ width: 100% }'
    : '{ width: 50% }'
  }
  /* width: 50%;  */ 
  ${ props => props.isMobile 
    ? '{ margin-top: 30px }'
    : '{ margin-top: -30px }'
  }
  /* margin-top: -30px; */
  margin-bottom: -30px;
  min-height: 100vh;
  color: #333333;
  padding: 10px;
`;

const TabSectionRight2 = styled.div`
${ props => props.isMobile 
    ? '{ display: block }'
    : '{ display: flex }'
  } 
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  ${ props => props.isMobile 
    ? '{ width: 100% }'
    : '{ width: 50% }'
  }
  /* width: 50%;  */ 
  margin: 0px;    
  /* margin-top: -30px; */
  /* margin-bottom: -30px; */
  min-height: 100vh;
  max-width: 720px;
  color: black;
  padding: 0px;
`;

const ItemFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 90%;
  color: #333333;
  padding: 0;
`;

function EZSpacer(props) {
  let spacerHeight = props.height ? props.height : "10px";
  let spacerWidth = props.width ? props.width : "100%";

  return (
    <div style={{
      margin: 0,
      padding: 0,
      width: spacerWidth,
      height: spacerHeight
    }} />
  );
}

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`;

const TabContentWithHeaderWrapper = styled.div`
  ${ props => props.isMobile 
    ? '{ max-height: 950vh }'
    : '{ max-height: 95vh }'
  } 
  /* max-height: 95vh; */
  overflow: hidden;
`;

const TabContentHeader = styled.div`

`;

const TabSectionHeader = styled.div`
  width: 100%;
  height: 30px;
  color: black;
  text-align: center;
  font-size: 14px;
  background-color: ${colors.sidebarBg};
  display: block;
`; 

const TabSectionHeaderText = styled.span`
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
`;

const TabSectionHeaderTitle = ({
  iconSrc, text
}) => {
  return (
    <div>
      <Icon className="icon" src={iconSrc} />
      <TabSectionHeaderText>{text}</TabSectionHeaderText>
    </div>
  );
};

const TabSectionBody = styled.div`
  width: 100%;
  height-min: 100%;
  color: black;
  font-size: 12px;
  background-color: white;
`;

const ScrollingListContainer = styled.div`
  ${ props => props.isMobile 
    ? '{ height: auto }'
    : '{ height: 75vh }'
  }
  /* height: 75vh; */
  overflow-y: scroll;
  margin-bottom: 40px;
`;

const ListContentRow = styled.div`
  flex-direction: row;
  padding: 5px;
  cursor: ponter;
`;

const ListContentBox = styled.div`
  cursor: pointer;
  border: 1px solid #eeeeee;
  display: flex; 
  flex-direction: row; 
  width: 100%; 
  margin: 0px; 
  padding: 10px;
  font-size: smaller;
  :hover {
    background: ghostwhite;
  }
`;

const ContentBox = styled.div`
  cursor: pointer;
  /* border: 1px solid #eeeeee; */
  ${ props => props.isMobile 
    ? '{ border: none }'
    : '{ border: 1px solid #eeeeee }'
  }
  display: flex; 
  flex-direction: row; 
  width: 100%; 
  margin: 0px;
  margin-left: 5px; 
  padding: 10px;
  font-size: smaller;
`;

const PrettyInput = styled.input`
  background: #f7f7f7;
  width: 25vw;
  min-width: 100px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  border:solid 1px #ccc;
  border-radius: 1.0vw;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 1.5vw;
`;

const PrettyTextArea = styled.textarea`
  background: #f7f7f7;
  width: 25vw;
  min-width: 100px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  border:solid 1px #ccc;
  border-radius: 1.0vw;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 1.5vw;
`;

const PrettyInputLabel = styled.label`
  text-align: left;
  font-size: 1.0em;
  padding: 0;
  margin: 0;
  color: #7a7c8d;
`;

const LoginButton = styled.button`
  background: ${accentColor};
  width: 25vw;
  min-width: 100px;
  color: #ffffff;
  padding: 12px;
  border: none;
  border:solid 1px #ccc;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 1.0em;
`;

const ForgotPasswordLink = styled.a`
  font-size: 1.1vw;
  text-align: center;
  color: ${accentColor};
  display: block;
  margin-top: 15px;
`;

const LoginForm = styled.form`
  text-align: center;
`;

const EditIcon = styled.img`
  display: inline-block;
  width: 1.6vw;
  height: 1.6vw;
  margin: 0 0 0 0 !important; // updated for 1.6vw svg images
  padding: 0 !important;
  opacity: 0.5;
  &:hover {
    opacity: 1.0;
    cursor: pointer;
  }
`;

const Icon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 0 0 0 !important;
  margin-top: -3px !important;
  padding: 0 !important;
  opacity: 1.0;
`;

const IconTwo = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  opacity: 1.0;
`;

const ShellOvalBtn = styled.div`
  cursor: pointer;  
  min-width: 50px;
  height: 30px;
  display: inline-block;
  border: 2px solid black;
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 14px;
  text-align: center;
  vertical-align: middle;
  overflow: clip;
  margin-left: 8px;
  margin-right: 0px;
  background: #dddddd;
  font-weight: 700;
  font-size: 14px;
`; 

const ShellInputLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: top;
  align-items: left;
  padding-bottom: 5px;
`;

const ShellFieldDisplayName = styled.span`
  font-size: 16px;
  padding-top: 3px;
  padding-right: 5px;
`;

const ShellTextInput = styled.input`
  width: 470px;
  height: 28px;
  background: #dddddd;
  border: 1px solid black;
  border-radius: 14px;
  padding-left: 15px;
  margin-left: 10px;
  margin-right: 5px;
`;

const ShellTextarea = styled.textarea`
  width: 535px;
  height: 100px;
  background: #dddddd;
  border: 1px solid black;
  border-radius: 14px;
  padding-left: 15px;
  margin-left: 10px;
  resize: none;
`;
const SidebarExerciseBtnIcon = styled.img`
  width: 18px;
  height: 18px;
  top: 2px;
  left: 3px;
  position: absolute;
  object-fit: cover;
`;

const SidebarWorkoutBtnIcon = styled.img`
  width: 24px;
  height: 24px;
  top: -1px;
  left: 0;
  position: absolute;
  object-fit: cover;
`;

const SidebarSearchBtnIcon = styled.img`
  width: 20px;
  height: 21px;
  top: 3px;
  left: 4px;
  position: absolute;
`;

/* const SidebarBtnIcon = styled.img`
  width: 20px;
  height: 21px;
  top: 2px;
  left: 4px;
  position: absolute;
  object-fit: cover;
`; */

const SidebarBtnIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0 !important;
  margin-top: -3px !important;
  padding: 0 !important;
  opacity: 1.0;
`;

const UserNameDiv = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  white-space: nowrap;
  width: 100%;
  height: 30px;
  padding: 0px;
  padding-top: 20px;
  margin: auto;
  margin-top: 0px;
  text-align: center;
  vertical-align: middle;
`;

const TabButton = styled.button`
  display: inline-block;
  min-width: 7.6vw;
  min-height: 2.0vw;
  color: ${accentColor}; 
  background: ${accentColor};  
  margin: 0.76vw 1.9vw 0.15vw 0vw !important; /* top right bottom left */
  padding: 0.4vw 0.75vw 0.4vw 0.75vw !important; /* top right bottom left */  
  border: none;
  border-radius: 0.45vw; /* was 10px */
  ${ props => props.tabTitle === props.currentTab 
    ? '{ color: #ffffff; background: ' + accentColor + '; }'
    : '{ color: ' + accentColor + '; background: #ffffff; }'
  } 
  font-size: 1.0vw;
  font-weight: 500;
  letter-spacing: 0.15vw;
`;

const ShellTabButton = styled.button`
  display: inline-block;
  min-width: 80px;
  min-height: 25px;
  color: ${accentColor}; 
  background: ${accentColor};  
  margin: 10px 0px 3px 0px !important; /* top right bottom left */
  /* padding: 3px !important;   */
  border: none;
  border-radius: 10px; /* was 10px */
  ${ props => props.tabTitle === props.currentTab 
    ? '{ color: #ffffff; background: ' + accentColor + '; }'
    : '{ color: ' + accentColor + '; background: #ffffff; }'
  } 
  ${ props => props.isMobile 
    ? '{ font-size: 2.8vw }'
    : '{ font-size: 15px }'
  }
  ${ props => props.isMobile 
    ? '{ padding: 0.8vw }'
    : '{ padding: 4px 12px 4px 12px }' /* top right bottom left */
  }
  /* font-size: 13px; */
  font-weight: 500;
  /* letter-spacing: 1.5px; */
`;

const StaffCheckboxDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: start;
  padding-left: 55px;
`;

const MobileVersionDiv = styled.div`
  height: 2vh;
  width: 100vw;
  padding: 0;
  padding-right: 1vw;
  text-align: right;
  font-size: 2vw;
  color: ${colors.lightGreyText};
`;

export { 
  Title, 
  AreaTitle,
  AreaTitle2,
  Wrapper, 
  PrettyInput, 
  PrettyTextArea,
  PrettyInputLabel, 
  ForgotPasswordLink,
  LoginForm,
  LoginButton,
  EZSpacer,
  EditIcon,
  Thumbnail,
  ScrollingListContainer,
  ListContentRow,
  ListContentBox,
  ContentBox,
  MouseOverVideoDiv,
  BackLink,
  ContentWrapper,
  Icon,
  IconTwo,
  ShellOvalBtn,
  ShellInputLabel,
  ShellFieldDisplayName,
  ShellTabButton,
  ShellTextInput,
  ShellTextarea,
  SidebarExerciseBtnIcon,
  SidebarBtnIcon,
  SidebarSearchBtnIcon,
  SidebarWorkoutBtnIcon,
  SplitTabContentWrapper,
  SplitTabContentWrapper2,
  TabButton,
  TabSectionLeft,
  TabSectionRight,
  TabSectionLeft2,
  TabSectionRight2,
  TabSectionHeader,
  TabSectionHeaderTitle,
  TabSectionBody,
  TabContentWithHeaderWrapper,
  TabContentHeader,
  ItemFields,
  RedSpan,
  UserNameDiv,
  StaffCheckboxDiv,
  MobileVersionDiv
};
