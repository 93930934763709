// /components/styled/MyWorkout.js

import React, { useRef, useState } from 'react';

import MobileLoadingDivLayer  from './MobileLoadingDivLayer';
import ExerciseCard from './ExerciseCard';
import PickOneOfFive from './PickOneOfFive';

import { iconSrc } from '../../constants';

import { submitWorkoutCompliance } from '../../services';






const MyWorkout = ({
  loaded, workout, goToList
}) => {
  const mounted = useRef(true)
  const tableData = workout.exercise_array;
  // const workoutIntervalString = "Complete twice per day";
  
  const inputWidth = "80vw";
  const inputHeight = "20vw";
  const inputFontSize = "3vw";
  const inputBorderRadius = "2.5vw";

  const fakePayload = {
    // "external_id_guid" : workout.id_guid,
    // "performed_datetime" : new Date(),
    "load_array" :  [
      { exercise_id_guid: "77c297db-0de4-4754-afd2-b50b8b5574a7", load_string: "100% of 1RM" },
      { exercise_id_guid: "fe11255a-90e6-41cc-80f5-7660023cd650", load_string: "110% of 1RM" },
      { exercise_id_guid: "4943aea1-03f2-4bca-a5ea-61e78565fc6c", load_string: "200% of 1RM" }
    ],
    "note_string" : "Notes",
    "level_of_effort_integer" : 4
  };

  const [loadArray, setLoadArray] = useState([]); // empty array means no exercises had loads input
  const [workoutCompliance, setWorkoutCompliance] = useState(fakePayload); // this is an object that will become the POST payload
  // it will have this form:
  /*{
    "workout_id_guid" : workout.id_guid,
    "load_array" :  [{ exercise_id_guid: "0d44f246-6bba-4565-b786-a2baa30ffba5", load_string: "load_string" }]
    "note_string" : "Notes",
    "level_of_effort_integer" : 4
  }*/

  const left_arrow_url = "url(" + iconSrc.chevron_left_black_filled + ")";

  const name_display_string = workout.name_string;
  const notes_display_string = "Notes: " + workout.note_string;
  const hasNote = workout.note_string && workout.note_string.length > 0;

  const interval_day_decimal = workout.interval_day_decimal;
  const times_per_day_bool = interval_day_decimal > 0 && interval_day_decimal < 0.6;
  const times_per_day = Math.floor(parseInt(1 / interval_day_decimal));
  const times_per_day_string = "Complete " + times_per_day + " per day.";

  const days_string = interval_day_decimal >= 0.99 && interval_day_decimal < 1.5 ? " Complete every day" : 
    interval_day_decimal >= 1.5 && interval_day_decimal < 2.5 ? " Complete every other day" :
    " Complete every " + parseInt(interval_day_decimal) + " days.";
  const complete_display_string = times_per_day_bool ? times_per_day_string : days_string;

  const last_performed_datetime = workout.last_performed_datetime;
  const now = new Date();
  const millisecondsSince = Math.abs(now-last_performed_datetime); // TODO: if longer ago than the interval, should we emphasize that?
  const timeSinceCompleted = last_performed_datetime === undefined ? " too long ago" : millisecondsSince < (36 * 60 * 60 * 1000) ? Math.floor(millisecondsSince/(60 * 60 * 1000)) + " hours ago" : Math.floor(millisecondsSince/(24 * 60 * 60 * 1000)) + " days ago";

  // const distance_integer = exercise.distance_integer;
  // const distance_string = exercise.distance_string;
  // const hasDistance = distance_integer && parseInt(distance_integer) > 0 && distance_string && distance_string.length > 0;
  // const distance_display_string = hasDistance ? distance_integer + " " + distance_string : "";

  // const duration_integer = exercise.duration_integer;
  // const duration_string = exercise.duration_string;
  // const hasDuration = duration_integer && parseInt(duration_integer) > 0 && duration_string && duration_string.length > 0;
  // const duration_display_string = hasDuration ? duration_integer + " " + duration_string + ".": "";

  let rowIndex = 0;

  function handleNoteInput(note_string){
    console.log('~~~~~ MyWorkout(), handleNoteInput() - note_string is ' + note_string);
    let tempObj = workoutCompliance;
    tempObj.note_string = note_string;
    setWorkoutCompliance(tempObj); // is this guaranteed to be updated before a submit?
  }

  function handleLoeInput(r_object_property, loe_integer){
    const LOE_int = parseInt(loe_integer);
    console.log('~~~~~ MyWorkout(), handleLoeInput() - loe_integer  is ' + loe_integer);
    let tempObj = workoutCompliance;
    tempObj.level_of_effort_integer = loe_integer;
    setWorkoutCompliance(tempObj); // is this guaranteed to be updated before a submit?
    setTimeout(() => { // TODO: is delay necessary?
      submitCompliance();
    }, 1000)
  }

  function setLoadUsed(e, exercise_id_guid, exercise_load_used){
    console.log('~~~~~ MyWorkout(), setLoadUsed() - exercise_id_guid is ' + exercise_id_guid + ', and load_used is ' + exercise_load_used);
    //exerciseLoadUsedArray for this exercise, and add or update the load_used_string value
    const tempCompliancePayload = workoutCompliance;
    const newArrayElement = {
      exercise_id_guid: exercise_id_guid, 
      load_string: exercise_load_used
    }
    let tempLoadArray = tempCompliancePayload.load_array;
    let objIndex = tempLoadArray.findIndex((obj => obj.exercise_id_guid === exercise_id_guid)); 
    if (objIndex === -1) {
      tempLoadArray.push(newArrayElement);
    } else {
      tempLoadArray[objIndex] = newArrayElement;
    }
    tempCompliancePayload.load_array = tempLoadArray;
    setWorkoutCompliance(tempCompliancePayload);   
  }

  function submitCompliance(){ // invoke this function either when a Submit button is tapped, or when the Level of Effort is input...
    const workout_id_guid = workout.id_guid;
    console.log('~~~~~ MyWorkout(), submitCompliance() - workout_id_guid is ' + workout_id_guid + ', and load_aray is ' + loadArray);

    // let payload = workoutCompliance;
    // payload.load_array = loadArray;

    submitWorkoutCompliance(workout_id_guid, workoutCompliance).then(resp => {
      console.log('~~~~~ MyWorkout(), submitCompliance(), submitWorkoutCompliance() - resp is ' + resp);
      const complianceResp = resp;
      console.log('~~~~~ MyWorkout(), submitWorkoutCompliance(), submitCompliance - complianceResp is ' + complianceResp);
      if(mounted.current) {
        // if(complianceResp.status === 200 || complianceResp.status === 201){
        //   alert("Your Workout compliance has been noted!");
        // } else {
        //   alert("For some reason your Workout compliance could not be recorded at this time");
        // }
        if(complianceResp.id_guid){
          alert("Your Workout compliance has been noted!");
        } else {
          alert("For some reason your Workout compliance could not be recorded at this time");
        }
        // if(!complianceResp.id_guid){
        //   alert("For some reason your Workout compliance could not be recorded at this time. Response Code: " + complianceResp);
        // } else {
        //   alert("Your Workout compliance has been noted!");
        // }
      }
    })
  }


  return (
    <div 
      className="MyWorkoutWrapper" 
      style={{ 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        padding: "0", 
        paddingBottom: "15vh" /* was 15vh */
      }}
    >
      {loaded ? '' : <MobileLoadingDivLayer />}
      <div 
        className="MyWorkoutDetail" 
        style={{ 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center", 
          padding: "0" 
        }} 
      >
        <div className="MyWorkoutHeader" 
          style={{ 
            // position: "sticky",
            // position: "-webkit-sticky",
            display: "flex", 
            flexDirection: "column", 
            height: "25vh",
            alignItems: "center",
            padding: "0", 
            paddingTop: "1.3vh",
            paddingBottom: "2vh",
            // background: "url('Shell_assets/fakes/DW-22_Workout_name_and_notes_display.png')",
            // backgroundSize: "cover"
          }}
        >
          <img src={iconSrc.workout} style={{ width: "6vw", height: "6vw" }} alt="Workout icon" />
          <div style={{ fontSize: "5vw", fontWeight: "bold", paddingTop: "1vh", }} >{workout.name_string}</div>
          {/* <div>Subtitle</div> */}
          <div style={{ fontSize: "3vw", fontWeight: "bold" }}>{complete_display_string}</div>
          <div 
            key="interval"
            style={{ 
              color: "#aaa", 
              fontSize: "2.5vw",
              fontStyle: "italic", 
              // fontWeight: "bold", 
              paddingBottom: "1vh", 
              marginTop: "-0.5vh" 
            }}
          >
            (Last completed {timeSinceCompleted})
          </div>
          <div 
            key="workout_notes"
            style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "left", 
              padding: "0", 
              paddingBottom: "1vh", 
              fontSize: "3vw", 
              fontWeight: "bold" 
            }}
          >
            Notes: {workout.note_string}
          </div>
        </div>
        <div className="ExerciseList" style={{ width: "80vw" }}>
          {tableData.length === 0 &&
            <div>
              <br />
              Unfortunately this Workout has no Exercises :-0
              <br />
              <br />
            </div>
          }
          {
            tableData.map((exercise) => {
              rowIndex++;
              console.log('~~~~~ MyWorkout(), return(), tableData.map -  exercise.id_guid is ' + exercise.id_guid);
              return (
                <ExerciseCard key={exercise.id_guid + "_" + rowIndex} setLoadUsed={setLoadUsed} exercise={exercise} />
              )
            })
          }
        </div>
      </div>
      <div className="NotesAndLOE">
        <label className="LoadLabel" style={{ width: "80vw", marginLeft: "0vw", marginTop: "2vw", marginBottom: "2vw", fontSize: "3vw", fontWeight: "bold" }}>
          Your Notes
          <textarea 
            className="NoteInput" 
            name="my_notes" 
            type="textarea"
            placeholder="What would you like us to hear about this workout?"
            title="What would you like us to hear about this workout?" 
            onBlur={e => handleNoteInput(e.target.value)} 
            style={{ 
              width: inputWidth, 
              height: inputHeight, 
              fontSize: inputFontSize,
              marginTop: "1vw",
              border: "2px solid #333333",
              borderRadius: inputBorderRadius,
              padding: "2vw"
            }} 
          />
        </label>
      </div>

      {/*
            user, title, subtitle_left, subtitle_right, onChange
          }) => {
            const email = user && user.email_string && user.email_string.length > 0 ? user.email_string : "no user email :-(";

            console.log('~~~~~ PickOneOfFive() - for user *' + email + '*');
      */}

      <PickOneOfFive user={null} title="LEVEL OF EFFORT" subtitle_left="EASY" subtitle_right="HARD" onChange={handleLoeInput} />
      
      <div style={{ fontSize: "3vw", color: "#aaa", fontStyle: "italic", marginTop: "1vw" }}>(Enter to mark Workout Completed and submit)</div>

      <button 
        onClick={goToList} 
        style={{
          cursor: "pointer",
          marginTop: "5vh",
          width: "80vw",
          height: "7vw",
          border: "0.5vw solid",
          borderRadius: "3.5vw",
          backgroundImage: left_arrow_url,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "20px center",
          backgroundSize: "4vw 4vw",
          fontSize: "4vw",
          fontWeight: "bold",
          color: "#000",
          opacity: "0.5"
        }}
      >
        {/* &#60; &nbsp; Back to Assigned Workouts */}
        WORKOUTS
      </button>
    </div>
  );

};

export default MyWorkout;