import React from 'react';
import styled from 'styled-components';

const minYellowScore = 40; // eventually import something like { minYellowScore } from './config'
const maxYellowScore = 59; // eventually import something like { maxYellowScore } from './config'

/*  // score color logic from ScoreCircle component
  let minYellowScore = 40; // eventually inport something like { minYellowScore } from './config'
  let maxYellowScore = 59; // eventually inport something like { maxYellowScore } from './config'
  
  let circleTwoStroke = redStroke; // default stroke color is red
  if (score >= minYellowScore) { circleTwoStroke = yellowStroke; }
  if (score > maxYellowScore) { circleTwoStroke = greenStroke; }
*/

const BodyMapDiv = styled.div`
position: relative;
background-image: url("bodymap.svg");
background-repeat: no-repeat;
background-position: center right;
background-size: cover;
margin-left: 4.5vw;
`;

const MarkerDiv = styled.div`
  position: absolute; 
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
  /* border: 1px solid #888888; */
`;

const StyledSVG = styled.svg`
  position: absolute; 
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
`;


function markerData(issue) {
  // console.log("~~~~~ issueOffsets() - issue.title: " + issue.title);
  
  const issueArea = issue.title.split(' ')[0];
  // console.log("~~~~~ issueOffsets() - issueArea: " + issueArea);
  
  let offset;

  switch(issueArea) {
    case "Elbow":
      offset = { x: issue.side === "right" ? 0.27 : -0.27, y: -0.13 };
      break;
    case "Shoulder":
    case "Scap":
      offset = { x: issue.side === "right" ? 0.20 : -0.20, y: -0.28 };
      break;
    case "Spine":
    case "Center":
      offset = { x: 0, y: -0.14 };
      break;
    case "Hip":
      offset = { x: issue.side === "right" ? 0.12 : -0.12, y: -0.01 };
      break;
    case "Femur":
      offset = { x: issue.side === "right" ? 0.12 : -0.12, y: 0.12 };
      break;
    case "Knee":
    case "Valgus/Varus":
      offset = { x: issue.side === "right" ? 0.135 : -0.135, y: 0.22 };
      break;
    case "Ankle":
    case "Tibia":
      offset = { x: issue.side === "right" ? 0.11 : -0.12, y: 0.43 };
      break;
    case "Squat":
        offset = { x: issue.side === "right" ? 0.12 : -0.13, y: 0.13 };
        break;
    default:
      offset = { x: 0.0, y: 0.0 };
  }

  return {
    x_offset: offset.x,
    y_offset: offset.y,
    color: issue.score > maxYellowScore ? "green" : issue.score < minYellowScore ? "red" : "yellow",
    type: "circle" // when we have more, we'll set them in the switch(issueArea) statement
  }
}

function IssueMarker(props) {
  const {
    markerData,
    size
  } = props;
  const strokeWidth = size / 10;
  // const center = size / 2;
  const radius = size / 2.5 - strokeWidth / 2.5;
  const backgroundStroke = "#ffffff";

  const redStroke = "#ff2957";
  const yellowStroke = "#f4f53d";
  const greenStroke = "#7dea62";
  
  let circleStroke = redStroke; // default stroke color is red

  if (markerData.color && markerData.color === "yellow") {
    circleStroke = yellowStroke;
  } else if (markerData.color && markerData.color === "green") {
    circleStroke = greenStroke;
  }

  if (markerData.type === "circle") { // returns a circle centered on the provided offsets from center
    const cx = (0.5 + markerData.x_offset) * 100 + "%";
    const cy = (0.5 + markerData.y_offset) * 100 + "%";
    
    return (
      <MarkerDiv>
        <StyledSVG className="svg">
          <circle
            className="svg-circle"
            stroke={backgroundStroke}
            cx={cx}
            cy={cy}
            r={radius / 2}
            strokeWidth={radius}
            opacity={0.4}
          />
          <circle
            className="svg-circle"
            stroke={circleStroke}
            cx={cx}
            cy={cy}
            r={radius}
            strokeWidth={strokeWidth}
          />
        </StyledSVG> 
      </MarkerDiv>
    )  
  }
}


const BodyMap = ({                    
    data, domain, maxIssues, isMobile
}) => {

  const bodyMapWidthInVw = isMobile ? 33 : 11;
  const bodyMapHeightInVw = bodyMapWidthInVw * 1506 / 722;
  const issueMarkerSize = isMobile ? bodyMapWidthInVw * 1 : "33";
  // const issueMarkerSize = isMobile ? "66" : "33";

  // if (data && data[0] && data[1] && data[2] && data[3] && data[4] && data[5] && data[6] && data[7] && data[8] && data[9]){
  //   // console.log('~~~~~ BodyMap(), data[0].toString() is *' + data[0].toString() + '*');
  //   console.log('~~~~~ BodyMap(), data[0].domain is *' + data[0].domain + '*, and data[0].title is *' + data[0].title + '*, and data[0].score is *' + data[0].score + '*');
  //   console.log('~~~~~ BodyMap(), data[1].domain is *' + data[1].domain + '*, and data[1].title is *' + data[1].title + '*, and data[1].score is *' + data[1].score + '*');
  //   console.log('~~~~~ BodyMap(), data[2].domain is *' + data[2].domain + '*, and data[2].title is *' + data[2].title + '*, and data[2].score is *' + data[2].score + '*');
  //   console.log('~~~~~ BodyMap(), data[3].domain is *' + data[3].domain + '*, and data[3].title is *' + data[3].title + '*, and data[3].score is *' + data[3].score + '*');
  //   console.log('~~~~~ BodyMap(), data[4].domain is *' + data[4].domain + '*, and data[4].title is *' + data[4].title + '*, and data[4].score is *' + data[4].score + '*');
  //   console.log('~~~~~ BodyMap(), data[5].domain is *' + data[5].domain + '*, and data[5].title is *' + data[5].title + '*, and data[5].score is *' + data[5].score + '*');
  //   console.log('~~~~~ BodyMap(), data[6].domain is *' + data[6].domain + '*, and data[6].title is *' + data[6].title + '*, and data[6].score is *' + data[6].score + '*');
  //   console.log('~~~~~ BodyMap(), data[7].domain is *' + data[7].domain + '*, and data[7].title is *' + data[7].title + '*, and data[7].score is *' + data[7].score + '*');
  //   console.log('~~~~~ BodyMap(), data[8].domain is *' + data[8].domain + '*, and data[8].title is *' + data[8].title + '*, and data[8].score is *' + data[8].score + '*');
  //   console.log('~~~~~ BodyMap(), data[9].domain is *' + data[9].domain + '*, and data[9].title is *' + data[9].title + '*, and data[9].score is *' + data[9].score + '*');
  // }
  
  const allIssues = data.filter((issue) => { // same filtering as PrimaryIssuesTable
    return issue.domain === domain;
  });

  const displayIssues = allIssues.slice(0, maxIssues); // just issues we show (the worst)
  
  return (
    <BodyMapDiv style={{ width: bodyMapWidthInVw + "vw", height: bodyMapHeightInVw + "vw" }}>
        {displayIssues.map((issue) => {
          return (
            <IssueMarker key={issue.id}
              markerData={markerData(issue)}
              size={issueMarkerSize}
            />
          )
        })}
      {/* <IssueMarker 
        markerData={testMarkerData}
        size={"33"}
      /> */}
    </BodyMapDiv>
  );
}

export default BodyMap;