// exercises.js

const token = localStorage.getItem('token');
// const coachId = localStorage.getItem('loggedInUser');
const fizzy_data_url = "https://data.physmodo.net";

export function getExercises(like_string = null) {
  const likeQueryString = like_string ? '?like_string=' + like_string : '';
  return fetch(fizzy_data_url + '/api/v202203/app/exercise/' + likeQueryString, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function getExercise(id_guid) {
  return fetch(fizzy_data_url + '/api/v202203/app/exercise/' + id_guid, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function createExercise(exerciseObj) {
  console.log('~~~~~ services/exercises.js, createExercise() - name_string is *' + exerciseObj.name_string + '*');
  console.log('~~~~~ services/exercises.js, createExercise() - note_string is *' + exerciseObj.note_string + '*');
  console.log('~~~~~ services/exercises.js, createExercise() - id_guid is *' + exerciseObj.id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/exercise/', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: exerciseObj.name_string, 
        note_string: exerciseObj.note_string ? exerciseObj.note_string : "",
        movement_id_guid : exerciseObj.movement_id_guid,
        duration_seconds_integer : exerciseObj.duration_seconds_integer,
        repetition_integer : exerciseObj.repetition_integer
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/exercises, createExercise() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/exercises.js, createExercise() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function updateExercise(exerciseObj) {
  console.log('~~~~~ services/exercises.js, updateExercise() - name_string is *' + exerciseObj.name_string + '*');
  console.log('~~~~~ services/exercises.js, updateExercise() - note_string is *' + exerciseObj.note_string + '*');
  console.log('~~~~~ services/exercises.js, updateExercise() - id_guid is *' + exerciseObj.id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/exercise/' + exerciseObj.id_guid, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: exerciseObj.name_string, 
        note_string: exerciseObj.note_string ? exerciseObj.note_string : "",
        movement_id_guid : exerciseObj.movement_id_guid,
        duration_seconds_integer : exerciseObj.duration_seconds_integer,
        repetition_integer : exerciseObj.repetition_integer
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/members.js, updateExercise() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/members.js, updateExercise() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function deleteExercise(id_guid) {
  console.log('~~~~~ services/exercises.js, deleteExercise() - id_guid is *' + id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/exercise/' + id_guid, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    // .then(data => data.json())
    .then(response => { if (response.status === 204) {
      console.log('~~~~~ services/exercises.js, deleteExercise() - response status code was 200 or 201 or 204!');
      // return response.json(); 
      return response.status; 
    } else  if (response.status === 422) {
      alert('~~~~~ services/exercises.js, deleteExercise() - OOPS! response message was ' + 422222 + ' :-(');
      return response.status;
    } else {
      alert('~~~~~ services/exercises.js, deleteExercise() - OOPS! some problem other than code 422...? :-(');
      return response.status;
    }})
  }
  catch (e) {
    console.log(e)
  }
}
