// models/group.js

import { iconSrc, colors } from '../constants';

const displayFields = [
  { // name
    type: "text",                        // could be "text", "textarea", "association", "image"?, "video"?
    displayName: "Name",                 // any string up to a maxLength of 20 characters
    accessor: "name_string",             // attribute name when item delivered from API
    default: "",                         // value of this attribute for a brand new 'blank' item
    maxChars: "20",                      // an integer as a string, if we are going to try to limit this...
    minChars: "2",                       // an integer as a string, if we are going to try to limit this, or use as empty check
    pickListInfo: "",                    // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
    required: true                       // should this field be submitted to the api for a create or update access?
  },
  { // athletes
    type: "association", // this type of field requires lists and add/remove actions that are forthcoming...
    displayName: "Athletes in Group",
    accessor: "group_users", // we'll parse the association table name from this to perform getAllAssoc and getNotAssoc
    default: "",
    maxChars: "",
    minChars: "",
    pickListInfo: "{ 'relatedModel': 'user', 'relationTable': 'user_group', 'visibleRows': '20', 'colsInfo': '[{ 'title': 'Name', 'minWidth': '200px', 'colAssessor': 'name_string' }, { 'title': 'Email', 'minWidth': '200px', 'colAssessor': 'email_string' }] }",                    // JSON object with metadata and colsInfo collection or empty string if not a type "picklist"  
    required: false
  },
  { // programs
    type: "association", // TODO: change back to 'association' this type of field requires lists and add/remove actions that are forthcoming...
    displayName: "Workouts for Group",
    accessor: "group_programs", // we'll parse the association table name from this to perform getAllAssoc and getNotAssoc
    default: "",
    maxChars: "",
    minChars: "",
    pickListInfo: "{ 'relatedModel': 'program', 'relationTable': 'group_program', 'visibleRows': '20', 'colsInfo': '[{ 'title': 'Name', 'minWidth': '200px', 'colAssessor': 'name_string' }, { 'title': 'Days', 'minWidth': '50px', 'colAssessor': 'days_string' }] }",
    required: false
  },
  { // note
    type: "textarea",
    displayName: "Notes",
    placeholder: "These notes will only be visible to you...",
    accessor: "note_string",
    default: "",
    maxChars: "2000",
    minChars: "",
    pickListInfo: "",
    required: true 
  },
  { // id
    type: "hidden",
    displayName: "ID",
    accessor: "id_guid",
    default: "",
    maxChars: "64",
    minChars: "",
    pickListInfo: "",
    required: false
  },
];

const listColumns = [
  { label: "Name", accessor: "name_string", align: "left", color: colors.accent, sortable: true, minWidth: "200px" },
  { label: "Athletes", accessor: "user_count_integer", align: "center", color: colors.lightGreyText, sortable: true },
  { label: "Workouts", accessor: "program_count_integer", align: "center", color: colors.lightGreyText, sortable: true },
  { label: "Remove", accessor: "removeLink", align: "center", color: colors.lightGreyText, sortable: false, title: "Delete this Group, remove it from your lists", iconSrc: iconSrc.remove_active }
];

const attributes = [
  "id_guid",
  "name_string",
  "note_string",
  "user_count_integer",
  "program_count_integer"
];

const defaultSort = {
  field: "name_string",
  order: "asc"
}

const strings = {
  noItemsMsg: "You have no Groups yet - create some!",
  noFilteredItemsMsg: "",
  itemClickEnabledBooleanAccessor: "",
  itemClickDisabledMessage: ""
}

const groupModel = {
  type: "group",
  attributes,
  listColumns,
  displayFields,
  defaultSort,
  strings
};

export default groupModel;