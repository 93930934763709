// UserSection.js

import React, { useState, useRef, useEffect } from 'react';

import styled from "styled-components";
import { iconSrc } from '../../constants';
import { UserNameDiv, StaffCheckboxDiv } from './title';
import { ExploreIcon } from './exploreStuff';
import { getUserReadiness, getMemberAssessments } from '../../services';

import SectionHeaderTabs from './SectionHeaderTabs';
import UserReadinessSummary from './UserReadinessSummary';
import UserBodymap from './UserBodymap';
import UserAssessmentHistory from './UserAssessmentHistory';
import UserAssessment from './UserAssessment';
import Explore from './Explore';
import ComplianceList from './ComplianceList';

const userTabs = [
  {title: "Readiness", icon: iconSrc.readiness, component: "UserReadinessTab" }, 
  {title: "Assessments", icon: iconSrc.assessment, component: "UserAssessmentsTab" },
  {title: "Compliance", icon: iconSrc.assessment, component: "UserComplianceTab" },
  {title: "User", icon: iconSrc.user, component: "UserUserTab" }
];

const UserSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UserTabSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0;
  padding-top: 40px;
  font-size: larger;
  /* background: green; */
`;

const UserSection = ({
  // do we need any input props here, for UserSection context? a user_id? anything?
  // we might need everything, so that user_l can use the same state as user_r
  // so in Shell(): const[userState, setUserState] = useState({});
  // where userState = { user, assessments, assessment, activeTab, handlers, ...? }
  // and userHandlers = { tabChange, assessmentClick }
  state, handlers, exploreHandlers, scopeId, results, isMobile, activeTabIndex, onTabChange, onComplianceClick, refreshUser
}) => {
  console.log('~~~~~ UserSection(), state.user.id_guid: ' + state.user.id_guid);
  const mounted = useRef(true);
  const [assessmentsFetched, setAssessmentsFetched] = useState(state && state.assessments && state.assessments.length > 0 );
  const [assessments, setAssessments] = useState(state && state.assessments && state.assessments.length > 0 ? state.assessments : []);
  // const [currentAssessmentId, setCurrentAssessmentId] = useState('');
  const [isStaff, setIsStaff] = useState(state.user.staff_boolean); // setIsStaff(state.user.staff_boolean);
  const [currentAssessmentId, setCurrentAssessmentId] = useState('');
  const [priorAssessmentId, setPriorAssessmentId] = useState('');
  const [readinessResponse, setReadinessResponse] = useState([]);
  const [recentReadiness, setRecentReadiness] = useState({});
  
  // const fakeSoreAreas = [
  //   { id: 16, color: "#FFFF0D" },
  //   { id: 21, color: "#FFFF0D" },
  //   { id: 9, color: "#FF0808" },
  //   { id: 14, color: "#FFFF0D" },
  //   { id: 18, color: "#FF0808" },
  //   { id: 22, color: "#FFFF0D" },
  //   { id: 25, color: "#FF0808" }
  // ]
  // const fakeHurtAreas = [
  //   { id: 18, color: "#FF0808" },
  //   { id: 22, color: "#FFFF0D" },
  //   { id: 25, color: "#FF0808" }
  // ]
  const fakeInjuryExplanation = "Crashed my motorcycle jumping the creek...";
  // const [soreBodyAreas, setSoreBodyAreas] = useState(fakeSoreAreas); // TODO: have this pulled from API
  // const [hurtBodyAreas, setHurtBodyAreas] = useState(fakeHurtAreas); // TODO: have this pulled from API
  const [soreBodyAreas, setSoreBodyAreas] = useState([]); // TODO: have this pulled from API
  const [hurtBodyAreas, setHurtBodyAreas] = useState([]); // TODO: have this pulled from API
  const [injuryExplanation, setInjuryExplanation] = useState(fakeInjuryExplanation); // TODO: have this pulled from API
  const [refreshSoreAreas, setRefreshSoreAreas] = useState(uuidv4());
  const [refreshHurtAreas, setRefreshHurtAreas] = useState(uuidv4());
  // const soreAreas = []; // find these in user?
  // const hurtAreas = []; // find these in user?
  
  // const [userId, setUserId] = useState[state.user.id_guid]
  // need to move to User tab (to show <Accept>) if user is in pending state
  // const pendingUserAccept = !state.user.user_association_athlete_user_approval_boolean;
  // const pendingStaffAccept = !state.user.user_association_user_approval_boolean;
  // let showAccept = false;

  const userDisplayName = state.user &&
    state.user.last_name_string && state.user.last_name_string.length > 0 && 
    state.user.first_name_string && state.user.first_name_string.length > 0 ?
    state.user.last_name_string + ', ' + state.user.first_name_string : 
    state.user && state.user.email_string && state.user.email_string.length > 0 ?
    state.user.email_string : "SomeLastName, SomeFirstName";

  const userDisplayEmail = state.user.email_string;
  console.log('~~~~~ UserSection(), state.user.email_string: ' + state.user.email_string);

  const [activeTab, setActiveTab] = useState(activeTabIndex);  // integer index of active tab // TODO: does this need to be here, or in UserSection?

  function handleUserTabChange(index){
    console.log('~~~~~ WorkoutSection(), handleUserTabChange() (same as userHandlers .tabChange()) - new tab index is ' + index.toString());
    // check to make sure Sidebar has finished rendering???
    // - maybe have an onGroupListUpdatCompleted event with the handler up here in Shell,
    // -- and set a state variable like 'sidebarLoaded' that could be used here to enable/disable the automatic setActiveTab?
    // OR ---> just don't have that setAciveTab happen until later, by not having the user_r component in the default set :-)
    setActiveTab(index);
    onTabChange(index);
  }

  function handleAssessmentClick(assessmentObj){ // decide if it represents a change, if so - call handlers.assessmentChange
    console.log('~~~~~ UserSection(), handleAssessmentClick() - assessment_id is ' + assessmentObj.id_guid + ', and priorAssessmentId is ' + priorAssessmentId);
    
    if (assessmentObj.id_guid !== currentAssessmentId) {
      console.log('~~~~~ UserSection(), handleAssessmentClick() - old assessment_id was ' + currentAssessmentId + ' we will update it!');
      setCurrentAssessmentId(assessmentObj.id_guid);
      setPriorAssessmentId(assessmentObj.prior_id_guid);
    }
    
    // code to handle updating the assessment object in Shell State // TODO: why? is this required?
    // if (assessmentObj.id_guid !== state.assessment.id_guid) {
    //   console.log('~~~~~ UserSection(), handleAssessmentClick() - old assessment_id was ' + state.assessment.id_guid + ' we will update it!');
    //   handlers.assessmentChange(assessmentObj);
    // }
  }

  function handleBackToAssessmentList(){
    console.log('~~~~~ UserSection(), handleBackToAssessmentList()');
    setCurrentAssessmentId('');
    setPriorAssessmentId('');
  }

  function handleStaffChkbxClick(e){
    // const cbx_value = e.target.value;

    alert('handleStaffChkbxClick() - set Staff Privileges to ' + !isStaff + ' for ' + userDisplayName);

    if (isStaff === true) {
      setIsStaff(false);
    } else {
      setIsStaff(true);
    }
  }

  function handleNoteChange(text){
    console.log('~~~~~ UserSection(), handleNoteChange() - Explanation text is ' + text);
    // code here to submit the change via the API - *IF* we were allowing changes here...
  }

  function handleHurtAreaClick(areaRef){
    handleMapAreaClick('hurt', areaRef);
  }

  function handleSoreAreaClick(areaRef){
    handleMapAreaClick('sore', areaRef);
  }

  function handleMapAreaClick(mapRef, areaRef){
    console.log('~~~~~ UserSection(), handleMapAreaClick() - mapRef is ' + mapRef + ', and areaRef is ' + areaRef);
    const isInjuryMap = mapRef.includes('sore') ? false : true;
    let updatedAreasArray = isInjuryMap ? hurtBodyAreas : soreBodyAreas;
    let objIndex = 0;
    // parse the areaRef to find the integer id of the area
    const area_id = parseInt(areaRef.split('_')[1]);
    // const area.body_map_id_integer = areaRef.split('_')[1];
    console.log('~~~~~ UserSection(), handleMapAreaClick() - area_id is ' + area_id);
    // find out if this area is already in the areas array
    if (updatedAreasArray.some(area => area.body_map_id_integer === area_id)){
      console.log('~~~~~ UserSection(), handleMapAreaClick() - area_id ' + area_id + ' was found in bodyAreas');
      // if so, find current color of this area in the areas array
      const currentLevel = updatedAreasArray.find(area => area.body_map_id_integer === area_id).level_integer;
      if (currentLevel === 2){
        console.log('~~~~~ UserSection(), handleMapAreaClick() - area_id ' + area_id + ' was level 2');
        // if the color was red (or integer 2), delete the element from the array
        updatedAreasArray = updatedAreasArray.filter(e => e.body_map_id_integer !== area_id);
      } else { // the only other choice is the yellow color, since grey would not appear in the array at all
        console.log('~~~~~ UserSection(), handleMapAreaClick() - area_id ' + area_id + ' was yellow');
        // modify the color to be red, and save the element in the areas array, locally and via the API
        objIndex = updatedAreasArray.findIndex((obj => obj.body_map_id_integer === area_id));
        updatedAreasArray[objIndex].level_integer = 2;
        console.log('~~~~~ UserSection(), handleMapAreaClick() - updatedAreasArray[objIndex].level_integer is now ' + updatedAreasArray[objIndex].level_integer);
      }
    } else {
      console.log('~~~~~ UserSection(), handleMapAreaClick() - area_id ' + area_id + ' was NOT found in bodyAreas');
      // if the area is not already in the array, then add it to the array with the yellow color, then save
      updatedAreasArray.push({ body_map_id_integer: area_id, level_integer: 1 });
      objIndex = updatedAreasArray.findIndex((obj => obj.body_map_id_integer === area_id));
      console.log('~~~~~ UserSection(), handleMapAreaClick() - updatedAreasArray[objIndex].level_integer is now ' + updatedAreasArray[objIndex].level_integer);
    }
    if (isInjuryMap) {
      setHurtBodyAreas(updatedAreasArray);
      setRefreshHurtAreas(uuidv4());
    } else {
      setSoreBodyAreas(updatedAreasArray);
      setRefreshSoreAreas(uuidv4());
    }
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  // useEffect(() => {
  //   if (pendingUserAccept || pendingStaffAccept){
  //     console.log('~~~~~ UserSection(), user is pending, setting tabIndex = 2');
  //     handlers.tabChange(2);
  //     showAccept = true;
  //   }
  // }, [])

  useEffect(() => {
    const stateStaff = state.user.staff_boolean;
    setIsStaff(!!stateStaff); // sometimes it's null, so here we force it to false
  }, [scopeId, state.user.staff_boolean])

  useEffect(() => {
    getUserReadiness(state.user.user_id_guid ? state.user.user_id_guid : state.user.id_guid)
    .then(response => {
      if (response && Array.isArray(response)){
        setReadinessResponse(response);
        setRecentReadiness(response[0]);
        console.log('~~~~~ UserSection(), useEffect() for readiness - response.length is ' + response.length);
  
        const sorenessArray = response[0].readiness_soreness_body_map_array === null ? [] : response[0].readiness_soreness_body_map_array;
        const injuryArray = response[0].readiness_injury_body_map_array === null ? [] : response[0].readiness_injury_body_map_array;
        const injuryWords = response[0].readiness_injury_explanation_string === null ? '' : response[0].readiness_injury_explanation_string;
        setSoreBodyAreas(sorenessArray);
        setHurtBodyAreas(injuryArray);
        setInjuryExplanation(injuryWords);
        setRefreshSoreAreas(uuidv4());
        setRefreshHurtAreas(uuidv4());
      }
      else
      {
        console.log('~~~~~ UserSection(), useEffect() for readiness - ReadinessResponse was not an array :-(');
        // TODO: should we set a zeroState for the case where readiness is not returned, or set the values to defaults?
      }
    })
    
  }, [scopeId, state.user.id_guid, state.user.user_id_guid])

  useEffect(() => {
    if(!state.user || !state.user.id_guid || state.user.id_guid.length === 0) { // TODO: do we need to check both?
      console.log('~~~~~ UserSection(), useEffect() for MemberAssessments - user.id_guid.length == 0');
      return;
    }
    console.log('~~~~~ UserSection(), useEffect() for MemberAssessments - user.id_guid is ' + state.user.id_guid);
    getMemberAssessments(state.user.id_guid)
      .then(items => {
        if(mounted.current) { // TODO: how is this useful?
          setAssessments(items)
          setAssessmentsFetched(true); // arbitrary choice of this api call as longest
        }
      })

    // return () => mounted.current = false; // TODO: how is this useful?

  }, [state])

  /*
created_datetime: '2023-11-22T08:10:09.793Z'
id_guid: 'd3985820-fe3b-4255-9ef2-a5db559d2784'
readiness_hydration_color_string: 'yellow'
readiness_hydration_integer: 3
readiness_injury_body_map_array: (3) [{…}, {…}, {…}]
readiness_injury_color_string: 'yellow'
readiness_injury_explanation_string: 'A banana peel transformed a casual stroll into slapstick gold, leaving me contemplating life's absurdity amid a cartoonish tumble.'
readiness_injury_integer: 1
readiness_overall_color_string: 'red'
readiness_overall_integer: 2
readiness_sleep_color_string: 'red'
readiness_sleep_integer: 1
readiness_soreness_body_map_array: (1) [{…}]
readiness_soreness_color_string: 'red'
readiness_soreness_integer: 5
readiness_stress_color_string: 'yellow'
readiness_stress_integer: 2
updated_datetime: '2023-11-22T08:10:09.793Z'
user_id_guid: '738135e9-b171-4105-9bcb-591145345fad'

  */



  return (
    <UserSectionWrapper >
      <SectionHeaderTabs tabObjects={userTabs} onTabChange={handleUserTabChange} initialTab={activeTab} />
      <UserNameDiv>
        {/* {userDisplayName} */}
        <p>{userDisplayName}</p>
        <p style={{ fontSize: "smaller", color: "#DDDDDD", marginTop: "-15px" }}>{userDisplayName !== userDisplayEmail ? userDisplayEmail : ''}</p>
      </UserNameDiv>
      {activeTab === 0 && // Readiness tab
        <UserTabSectionWrapper>
          <UserReadinessSummary user={state.user} isMobile={isMobile} /> 
          <UserBodymap                // only title and areas required for display of Soreness Bodymap
            key={refreshSoreAreas}
            title="Soreness Body Map" 
            areas={soreBodyAreas}
            readOnly={true}
            isMobile={isMobile}
            // content=""
            // inputName=""
            onMapAreaClick={() => { console.log("onMapAreaClick for Soreness") }} // this will have to pass a reference to the soreness or injury array
            // onNoteChange={handleNoteChange}
          />
          <UserBodymap                // only title, areas inputName and content required for display of Injury Bodymap
            key={refreshHurtAreas}
            title="Injury Body Map" 
            areas={hurtBodyAreas}
            isMobile={isMobile}
            content={injuryExplanation}
            inputName="Injury Explanation"
            readOnly={true} // this can be true here in coach-facing Readiness, since it is for display only
            onMapAreaClick={() => { console.log("onMapAreaClick for Injury") }}// this will have to pass a reference to the soreness or injury array
            // onNoteChange={handleNoteChange}
          />
        </UserTabSectionWrapper>
      }
      {activeTab === 1 && // Assessments tab
        <UserTabSectionWrapper style={{ padding: "0px" }}>
          {(!currentAssessmentId || !currentAssessmentId.length > 20) && 
            <UserAssessmentHistory isMobile={isMobile} user={state.user} historyLoaded={assessmentsFetched} assessments={assessments} onAssessmentClick={handleAssessmentClick} />
          }
          {(currentAssessmentId && currentAssessmentId.length > 20) && 
            <UserAssessment isMobile={isMobile} user={state.user} assessmentId={currentAssessmentId} priorAssessmentId={priorAssessmentId} onBackLinkClick={handleBackToAssessmentList} />
          }
        </UserTabSectionWrapper>
      }
      {activeTab === 2 && // Compliance tab
        <UserTabSectionWrapper>
          <ComplianceList userId={state.user.id_guid} state={state} onComplianceClick={onComplianceClick} />
        </UserTabSectionWrapper>
      }
      {activeTab === 3 && // User tab
        <UserTabSectionWrapper>
            <StaffCheckboxDiv style={{ display: "none" }}>
              <label style={{ display: "flex", flexDirection: "row", verticalAlign: "middle" }}>
                <input type="checkbox" name="staff_chkbx" value="staff_privileges" checked={isStaff} style={{ marginRight: "10px", paddingTop: "2px" }} onChange={handleStaffChkbxClick} />
                <ExploreIcon src={iconSrc.staff} style={{ marginLeft: "10px", height: "24px", width: "24px" }} />
                <div style={{ display: "inline-block", marginLeft: "10px", paddingTop: "0px" }}>
                  Staff Privileges
                </div>
              </label>
            </StaffCheckboxDiv>
            <Explore style={{ marginTop: "-60px" }}  
              key={"user_user_" + refreshUser}
              refr="user_user" // the location is user, because it is in UserSection
              scope="user"
              scopeId={scopeId} // should this be something like idGroupUserSection? or idGroupUser?
              results={results}
              handlers={exploreHandlers}
              // onSearch={onSearch} // -> needs to hit api
              // onAddNewClick={onAddNewClick} // -> needs to change shell config
              // onRowClick={onRowClick} // store row selection internally? -> needs to change shell config
              // onRowRemoveClick={onRowRemoveClick} // -> needs to change shell config, hit api
              // onRowAddClick={onRowAddClick} // -> needs to change shell config, hit api
              // onAddUserClick={onAddUserClick} // -> needs to change shell config
              // onAddGroupClick={onAddGroupClick} // -> needs to change shell config
            />
        </UserTabSectionWrapper>
      }

    </UserSectionWrapper >

  );
}

export default UserSection;
