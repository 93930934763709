// ReadinessInput.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
// import { iconSrc } from '../../constants';
// import { 
  // colors,
  // logoSrc,
  // iconSrc,
  // northsideLogoSrc,
  // overviewSrc,
  // athletesSrc,
  // groupsSrc,
  // programsSrc,
  // exercisesSrc,
  // movementsSrc
//  } from '../../constants';
 import { 
  ExplorerHeaderCell,
  ExploreIcon,
  ExplorerTableCell,
  ExploreTableHeader
} from './exploreStuff';
 import ExploreScoreCircle from './ExploreScoreCircle';


const FakeReadinessInputWrapper = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  white-space: nowrap;
  /* width: 100%; */
  height: 150px;
  padding: 0px;
  padding-top: 10px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  vertical-align: middle;
  /* background: url("Shell_assets/fakes/readiness_input.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
  /* background: red; */
`;

const ReadinessInputTitle = styled.h5`
  cursor: auto;
  color: #777777;
  font-weight: 400;
`;

const ReadinessInputSubtitle = styled.div`
cursor: auto;
font-size: 11px;
`;

const ReadinessBarDiv = styled.div`
  cursor: auto;
  background: #808080;
  width: 340px;
  height: 94px;
  border-radius: 47px;
  margin: auto;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
`;

const ReadinessInputRow = styled.div`
  cursor: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const ReadinessInputCircle = styled.div`
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 2.5px solid red;
  text-align: center;
  vertical-align: middle;
  padding-top: 8px;
  font-size: 20px;
  color: #FFFFFF;
`;

const ReadinessSubtitleRow = styled.div`
  cursor: auto;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 7px;
  color: #FFFFFF;
  font-weight: 300;
`;

/*
background: url("Shell_assets/fakes/explore_searchbar.png");// fake <ExploreBar> implemented in <Explore> & looks good
background: url("Shell_assets/fakes/group_create.png");     // fake <GroupCreate> implemented in <Shell> & looks good
background: url("Shell_assets/fakes/group_edit.png");       // fake <GroupEdit> implemented in <Shell> & looks good
background: url("Shell_assets/fakes/injury_bodymap.png");   //
background: url("Shell_assets/fakes/readiness.png");        // fake <ReadinessInput> implemented in <UserSection> & looks good
background: url("Shell_assets/fakes/soreness_bodymap.png"); //
background: url("Shell_assets/fakes/user.png");             // not really needed...
background: url("Shell_assets/fakes/user_accept.png");      // fake <UserAccept> implemented in <UserSection> & looks good
background: url("Shell_assets/fakes/user_invite.png");      // fake <UserInvite> implemented in <Shell> & looks good
*/


const ReadinessInput = ({
  // user is userObj for current user
  user, title, subtitle_left, subtitle_right, onChange
}) => {
  const email = user && user.email_string && user.email_string.length > 0 ? user.email_string : "no user email :-(";

  console.log('~~~~~ ReadinessInput() - for user *' + email + '*');

  const display_title = title.toUpperCase();
  const display_subtitle_l = subtitle_left.toUpperCase();
  const display_subtitle_r = subtitle_right.toUpperCase();
  const [rValue, setRValue] = useState(0);

  const redFirst = title === 'stress' || title === "soreness" ? false : true;
  const oneColor = redFirst ? "#ff0000" : "#03ff00";
  const twoColor = redFirst ? "#ff0000" : "#03ff00";
  const fourColor = redFirst ? "#03ff00" : "#ff0000";
  const fiveColor = redFirst ? "#03ff00" : "#ff0000";

  // const lastScore = user.readiness.lastScore;
  // const lastScoreDelta = user.readiness.lastScoreDelta;
  // const lastScoreColor = user.readiness.lastScoreColor;

  // const readinessScore = user.readiness.readinessScore;
  // const readinessColor = user.readiness.readinessColor;

  // const sleepScore = user.readiness.sleepScore;
  // const sleepColor = user.readiness.sleepColor;

  // const hydrationScore = user.readiness.hydrationScore;
  // const hydrationColor = user.readiness.hydrationColor;

  // const stressScore = user.readiness.stressScore;
  // const stressColor = user.readiness.stressColor;

  // const sorenessScore = user.readiness.sorenessScore;
  // const sorenessColor = user.readiness.sorenessColor;

  // const injuryScore = user.readiness.injuryScore;
  // const injuryColor = user.readiness.injuryColor;

  // const complianceScore = user.readiness.complianceScore;
  // const complianceColor = user.readiness.complianceColor;

  // const diameter = "30px";
  // const userIdTemp = user.id_guid ? user.id_guid : user.user_id_guid; // until Nathan puts id_guid back as an attribute of user in inverse_boolean results

  function onValueClick(e, ref){
    console.log('~~~~~ ReadinessInput(), onValueClick() - clicked ' + ref);
    const value = ref.split('_')[1];
    console.log('~~~~~ ReadinessInput(), onValueClick() - value is ' + value);
    setRValue(value);
    // code to update database via API
    let rObj_property = "readiness_overall_integer";
    const intValue = parseInt(value);

    // "readiness_overall_integer": 5
    // , "readiness_sleep_integer": 4
    // , "readiness_hydration_integer": 3
    // , "readiness_stress_integer": 2
    // , "readiness_soreness_integer": 1
    
    // , "readiness_injury_integer": 1

    // the switch statement will set the appropriate property of the readiness object:
    switch(true) {
      case ref.includes('overall'):
        rObj_property = "readiness_overall_integer";
        break;
      case ref.includes('sleep'):
        rObj_property = "readiness_sleep_integer";
        break;
      case ref.includes('hydration'):
        rObj_property = "readiness_hydration_integer";
        break;
      case ref.includes('stress'):
        rObj_property = "readiness_stress_integer";
        break;
      case ref.includes('soreness'):
        rObj_property = "readiness_soreness_integer";
        break;
      default:
    }

    onChange(rObj_property, intValue);
  }

  return (

    <FakeReadinessInputWrapper>
      <ReadinessInputTitle>{display_title}</ReadinessInputTitle>
      <ReadinessBarDiv>
        <ReadinessInputRow>
          <ReadinessInputCircle 
            onClick={(e) => { onValueClick(e, title + "_1") }} 
            style={{
              borderColor: rValue === '1' ? "#dddddd" : oneColor, 
              background: rValue === '1' ? oneColor : "none", 
              color: rValue === '1' ? "#000000" : "#ffffff"}}>1</ReadinessInputCircle>
          <ReadinessInputCircle 
            onClick={(e) => { onValueClick(e, title + "_2") }} 
            style={{borderColor: rValue === '2' ? "#dddddd" : twoColor, 
            background: rValue === '2' ? twoColor : "none", 
            color: rValue === '2' ? "#000000" : "#ffffff"}}>2</ReadinessInputCircle>
          <ReadinessInputCircle 
            onClick={(e) => { onValueClick(e, title + "_3") }} 
            style={{borderColor: rValue === '3' ? "#dddddd" : "#ffff00", 
            background: rValue === '3' ? "#ffff00" : "none", 
            color: rValue === '3' ? "#000000" : "#ffffff"}}>3</ReadinessInputCircle>
          <ReadinessInputCircle 
            onClick={(e) => { onValueClick(e, title + "_4") }} 
            style={{borderColor: rValue === '4' ? "#dddddd" : fourColor, 
            background: rValue === '4' ? fourColor : "none", 
            color: rValue === '4' ? "#000000" : "#ffffff"}}>4</ReadinessInputCircle>
          <ReadinessInputCircle 
            onClick={(e) => { onValueClick(e, title + "_5") }} 
            style={{borderColor: rValue === '5' ? "#dddddd" : fiveColor, 
            background: rValue === '5' ? fiveColor : "none", 
            color: rValue === '5' ? "#000000" : "#ffffff"}}>5</ReadinessInputCircle>
        </ReadinessInputRow>
        <ReadinessSubtitleRow>
          <ReadinessInputSubtitle>{display_subtitle_l}</ReadinessInputSubtitle>
          <ReadinessInputSubtitle></ReadinessInputSubtitle>
          <ReadinessInputSubtitle></ReadinessInputSubtitle>
          <ReadinessInputSubtitle>{display_subtitle_r}</ReadinessInputSubtitle>
        </ReadinessSubtitleRow>
      </ReadinessBarDiv>

      {/* <div style={{ textAlign: "center", paddingTop: "15px", width: "65%", margin: "auto" }}>



      </div> */}
    </FakeReadinessInputWrapper>

  );

}

export default ReadinessInput;