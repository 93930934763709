// ExploreScoreCircle.js

import styled from 'styled-components'
import React from 'react';
import { colors } from '../../constants';

const ScoreText = styled.text`
  text-anchor: middle;
  color: #ffffff;
  font-weight: 400;
`

function ExploreScoreCircle(props) {
  const {
    diameter, // should be in px
    score,    // (integer between 1 and 99 inclusive)
    delta,    // ('up' or 'dn')
    color
  } = props;
  let simple = true;

  const diameterNoPx = diameter ? parseInt(diameter) : 20;
  const sizeNoPx = diameterNoPx;
  
  console.log('~~~~~ ExploreScoreCircle(), diameterNoPx: ' + diameterNoPx + ', sizeNoPx: ' + sizeNoPx);
  // let size = sizeCalc.toString() + 'px';
  let bigNum = delta === undefined;
  const strokeWidthNoPx = sizeNoPx / 2; // was size/11
  const centerNoPx = sizeNoPx / 2;
  // const radius = size / 2 - strokeWidth / 2;
  const radiusNoPx = sizeNoPx / 2 - strokeWidthNoPx / 1.7;
  const circumferenceNoPx = 2 * Math.PI * radiusNoPx;   // do we need this?

  const trend = delta === undefined ? null : delta > 0 ? 'up' : 'dn';
  
  // TODO: figure out best sizes & css for subdomain scores with trend arrows
  // const fontSize =  simple ? radius * 0.9 : bigNum ? radius * 0.85 : radius * 0.85;
  // const textY = simple ? center * 1.25 : bigNum ? center * 1.19 : center * 1.19;
  const fontSizeNoPx = bigNum ? radiusNoPx * 2.5 : radiusNoPx * 2.5; // was bigNum ? radius * 0.85 : radius * 2;  2.3 might work  for case where delta triangle is displayed (after : )
  const textYNoPx = bigNum ? centerNoPx * 1.35 : centerNoPx * 1.19; // was textY = center * 1.35; 1.19 might work for case where delta triangle is displayed (after : )
  
  const svgStyle = simple ? { margin: 0 } : { margin: "20px auto" };
  // const svgStyle = { margin: 0 };

  // calculate triangle dimensions based on props.size (circle outer diameter)
  // the fill color and the orientation will be swapped by the trend direction
  let triangleBaseNoPx = sizeNoPx / 4; /* triangle side is 1/3 circle radius */
  let triangleYNoPx = 2 * sizeNoPx / 3; /* triangle located 67% down from top */
  let triangleXNoPx = 4.5 * sizeNoPx / 12;
  let triangleHeightNoPx = triangleBaseNoPx / 1.414;
  // these next numbers are for the triangle pointing UP
  let UpTrianglePoint1xNoPx = triangleXNoPx;
  let UpTrianglePoint1yNoPx = triangleYNoPx + triangleHeightNoPx;
  let UpTrianglePoint2xNoPx = triangleXNoPx + triangleBaseNoPx/2;
  let UpTrianglePoint2yNoPx = triangleYNoPx;
  let UpTrianglePoint3xNoPx = triangleXNoPx + triangleBaseNoPx;
  let UpTrianglePoint3yNoPx = triangleYNoPx + triangleHeightNoPx;
  let triangleUpPoint1 = UpTrianglePoint1xNoPx + "," + UpTrianglePoint1yNoPx;
  let triangleUpPoint2 = UpTrianglePoint2xNoPx + "," + UpTrianglePoint2yNoPx;
  let triangleUpPoint3 = UpTrianglePoint3xNoPx + "," + UpTrianglePoint3yNoPx;
  let triangleUpPoints = triangleUpPoint1 + " " + triangleUpPoint2 + " " + triangleUpPoint3;

  let trianglePoints = triangleUpPoints; // default orientation is UP
  let triangleColor = "black"; // default color is green
  // these next numbers are for the triangle pointing DOWN
  let DnTrianglePoint1xNoPx = triangleXNoPx;
  let DnTrianglePoint1yNoPx = triangleYNoPx;
  let DnTrianglePoint2xNoPx = triangleXNoPx + triangleBaseNoPx/2;
  let DnTrianglePoint2yNoPx = triangleYNoPx + triangleHeightNoPx;
  let DnTrianglePoint3xNoPx = triangleXNoPx + triangleBaseNoPx;
  let DnTrianglePoint3yNoPx = triangleYNoPx;
  let triangleDnPoint1 = DnTrianglePoint1xNoPx + "," + DnTrianglePoint1yNoPx;
  let triangleDnPoint2 = DnTrianglePoint2xNoPx + "," + DnTrianglePoint2yNoPx;
  let triangleDnPoint3 = DnTrianglePoint3xNoPx + "," + DnTrianglePoint3yNoPx;
  let triangleDnPoints = triangleDnPoint1 + " " + triangleDnPoint2 + " " + triangleDnPoint3;

  //set the triangle to be pointed down if the trend is down,
  //and set the color to be red, too
  if (trend === "dn" || trend === "down") {
    trianglePoints = triangleDnPoints;
    // triangleColor = "red";
  }

  // let offsetNoPx = ((100 - score) / 100) * circumferenceNoPx; // was ((100 - score) / 100) * circumferenceNoPx
  let offsetNoPx = 0;

  function scoreCircleBgColor(input_color_string){
    let outputColor = colors.exploreCircleGray;
    switch(color) {
      case "green":
        outputColor = colors.exploreCircleGreen;
        break;
      case "yellow":
        outputColor = colors.exploreCircleYellow;
        break;
      case "red":
        outputColor = colors.exploreCircleRed;
        break;
      case "grey":
        outputColor = colors.exploreCircleGray;
        break;
      default:
        outputColor = colors.exploreCircleGray;
    }
    return outputColor;
  }

  // let redStroke = "#ff2957";
  // let yellowStroke = "#f4f53d";
  // let greenStroke = "#7dea62";
  let circleTwoStroke = scoreCircleBgColor(color);
  let circleBgStroke = "#000000"; // was #eeeeee for assessment scoreCircle

  // let minYellowScore = 40; // eventually inport something like { minYellowScore } from './config'
  // let maxYellowScore = 59; // eventually inport something like { maxYellowScore } from './config'
  
  // let circleTwoStroke = redStroke; // default stroke color is red
  // if (score >= minYellowScore) { circleTwoStroke = yellowStroke; }
  // if (score > maxYellowScore) { circleTwoStroke = greenStroke; }

  // let circleTransform = "rotate(-89.9, " + center + ", " + center + ")";

  return (
    <>
      <svg className="svg" width={sizeNoPx + "px"} height={sizeNoPx + "px"} style={svgStyle}>
        <circle
          className="svg-circle-bg"
          stroke={circleBgStroke}
          cx={centerNoPx + "px"}
          cy={centerNoPx + "px"}
          r={(radiusNoPx * 1.1) + "px"}
          strokeWidth={(strokeWidthNoPx * 1.1) + "px"}
        />
        <circle
          className="svg-circle"
          stroke={circleTwoStroke}
          cx={centerNoPx + "px"}
          cy={centerNoPx + "px"}
          r={radiusNoPx + "px"}
          strokeLinecap="round"
          strokeWidth={strokeWidthNoPx + "px"}
          strokeDasharray={circumferenceNoPx + "px"}
          strokeDashoffset={offsetNoPx + "px"}
          // transform={circleTransform}
        />
        {/* {delta !== undefined && {/* <polygon /* points are x,y with origin upper left */}
        {delta !== undefined && <polygon
            points={trianglePoints} 
            className="svg-triangle"
            strokeWidth={0}
            fill={triangleColor}
          />
        }
        <ScoreText x={centerNoPx + "px"} y={textYNoPx + "px"} style={{ fontSize: fontSizeNoPx + "px", color: "#222222" }}>
          {score}
        </ScoreText>
        Sorry, your browser does not support inline SVG.
      </svg>
    </>
  );
}

export default ExploreScoreCircle;
