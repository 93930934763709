// History.js

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css'
import { getMember } from '../services/members';
import { getMemberAssessments } from '../services/assessments';
import MemberRecentScansChart from './styled/MemberRecentScansChart';
import AssessmentsTable from './styled/AssessmentsTable';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import { Title, EZSpacer, BackLink, ContentWrapper } from './styled/title';

function History(props) {
  // const token = props.token;
  const [member, setMember] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const mounted = useRef(true);
  const navigate = useNavigate();
  
  const queryString = document.location.search;
  const params = new URLSearchParams(queryString);
  const memberid = props.isAthlete ? localStorage.getItem('loggedInUser') : params.get("memberid");
  const backPage = params.get("from") ? params.get("from") : "Back";
  const backText = "< " + backPage; 

  useEffect(() => {
    mounted.current = true; // TODO: how is this useful?
    if(member.email || member.id) {
      return;
    }
    getMember(memberid)
      .then(item => {
        if(mounted.current) { // TODO: how is this useful?
          setMember(item)
        }
      })
  }, [memberid, member.email, member.id])

  useEffect(() => {
    if(assessments.length || loaded || memberid === null) {
      return;
    }
    getMemberAssessments(memberid)
      .then(items => {
        if(mounted.current) { // TODO: how is this useful?
          setAssessments(items)
          setLoaded(true); // arbitrary choice of this api call as longest
        }
      })

    // return () => mounted.current = false; // TODO: how is this useful?
  }, [assessments, loaded, memberid])

  function handleAssessmentClick(assessmentMetaData) {
    navigate('/assessment?from=History&assessmentid=' + assessmentMetaData.id_guid + "&prior=" + assessmentMetaData.prior_id_guid);
  }

  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper className="wrapper" loaded={loaded}>
        <BackLink onClick={() => navigate(-1)}>{backText}</BackLink>
        <h1>{member.email}</h1>
        <EZSpacer height={"5vh"} />
        <Title>Recent Assessment Scores</Title>
        <MemberRecentScansChart data={assessments} />
        <EZSpacer height={"6vh"} />
        <Title>Assessment History</Title>
        <EZSpacer height={"2vh"} />
        <AssessmentsTable assessments={assessments} handleClick={handleAssessmentClick}/>
      </ContentWrapper>
    </div>
  );
}

export default History;