import React, { useState } from 'react';

import styled from 'styled-components';
import ScoreCircle from './ScoreCircle';

const isStretchLab = localStorage.getItem('stretchlab') === 'true' ? true : false;

const lightGreyText = "#b4b4b4";

const SortableTableRow = styled.tr`
  cursor: pointer;
  padding-top: 2vw;
  padding-bottom: 2vw;
  border-bottom: 1px solid #f0f0f0;
  &:hover { background: ghostwhite };
`;

const TableHead = ({ columns, handleSorting, isMobile }) => {

  console.log('~~~~~ AssessmentsTable.js, TableHead() - isMobile is ' + isMobile);
  
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const thFontSize = isMobile ? "2.5vw" : "0.85vw";
  const thPadRight = isMobile ? "0vw" : "5px";
  const thPadLeft = isMobile ? "2vw" : "20px";
  // const firstScoreTitle = isMobile ? "Composite" : "Overall Score";

  const handleSortingChange = (accessor) => {
    const sortOrder =
    accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
   <thead style={{ width: "100%", overflow: "clip" }}>
    <tr style={{ borderBottom: "1px solid #e9e9e9" }}>
     {columns.map(({ label, accessor, sortable }) => {
      console.log('~~~~~ AssessmentsTable(), TableHead() - accessor is ' + accessor + ', and label is ' + label);
      if (isMobile && accessor === "scoreComposite") {label = "Composite"}
      
      return (
        <th 
          key={accessor}
          onClick={sortable ? () => handleSortingChange(accessor) : null}
          style={{ fontSize: thFontSize, color: "#b4b4b4", fontWeight: "400", paddingRight: thPadRight, paddingLeft: thPadLeft }}
        >
          {label}
        </th>
      );
     })}
    </tr>
   </thead>
  );
 };

 const TableBody = ({ tableData, columns, onRowClick, isMobile }) => {

  console.log('~~~~~ AssessmentsTable.js, TableBody() - isMobile is ' + isMobile);

  const tdFontSize = isMobile ? "2.5vw" : "0.85vw";
  const scoreCircleSize = isMobile ? "50" : "80";

  function shortDate(date){ // original date = "12/24/2024"

    const trimDate = date.trim();
    const dateParts = trimDate.split("/");
    const newDate = dateParts[0] + "/" + dateParts[1] + "/" + dateParts[2].substring(2);
        
    // console.log('~~~~~ AssessmentsTable(), shortDate(' + date + ') - trimDate is ' + trimDate + ', and newDate is ' + newDate);

    return newDate;
  }

  function shortTime(time){ // original time = "12:23:33 PM"

    const trimTime = time.trim();
    const timeParts = trimTime.split(":");
    const amPm = timeParts[2].split(" ")[1];
    const newTime = timeParts[0] + ":" + timeParts[1] + " " + amPm;
        
    // console.log('~~~~~ AssessmentsTable(), shortTime(' + time + ') - trimTime is ' + trimTime + ', and newTime is ' + newTime);
    
    return newTime;
  }

  return (
   <tbody>
    {tableData.map((data) => {

     return (
      <SortableTableRow key={data.id_guid}  onClick={function(){onRowClick(data)}} style={{ width: "100%", overflow: "clip" }}>
       {columns.map(({ accessor, align, color }) => {
         if (accessor === "date" || accessor === "time") {
          let tData = data[accessor] ? data[accessor] : "——";
          if (isMobile && accessor === "date"){
            // tData = "12/12/24"; // TODO: create string manipulation to change to 2-digit year
            tData = shortDate(tData);
          }
          if (isMobile && accessor === "time"){
            // tData = "12:33 PM"; // TODO: create string manipulation to change to drop seconds
            tData = shortTime(tData);
          }
          return <td key={accessor} align={align} style={{ color: color, fontSize: tdFontSize, padding: 0 }}>
            {tData}
          </td>;
         } else {
           const trendAccessor = accessor + "_trend";
          return <td key={accessor} align={align} style={{ color: color, fontSize: tdFontSize, padding: 0 }}>
            <ScoreCircle
              // size={window.innerWidth*0.04}
              size={scoreCircleSize}
              score={data[accessor]}
              trend={data[trendAccessor]}
            />
          </td>;
         }

       })}
      </SortableTableRow>
     );
    })}
   </tbody>
  );
 };


const AssessmentsTable = ({
  assessments, isMobile, handleClick
}) => {

  console.log('~~~~~ AssessmentsTable.js, AssessmentsTable() - isMobile is ' + isMobile);

  const [tableData, setTableData] = useState(assessments); // TODO

  // console.log('~~~~~ tableData.length: ' + tableData.length);
  // console.log('~~~~~ props.assessments.length: ' + props.assessments.length);

  const columns = [
    { label: "Date", accessor: "date", align: "center", color: lightGreyText, sortable: true },
    { label: "Time", accessor: "time", align: "center", color: lightGreyText, sortable: false },
    { label: "Overall Score", accessor: "scoreComposite", align: "center", color: lightGreyText, sortable: true },
    { label: "Mobility", accessor: "scoreMobility", align: "center", color: lightGreyText, sortable: true },
    { label: isStretchLab ? "Activation" : "Stability", accessor: "scoreStability", align: "center", color: lightGreyText, sortable: true },
    { label: "Posture", accessor: "scorePosture", align: "center", color: lightGreyText, sortable: true },
    { label: "Symmetry", accessor: "scoreSymmetry", align: "center", color: lightGreyText, sortable: true }
  ];

   const handleSorting = (sortField, sortOrder) => {
    setTableData(assessments);
    console.log('~~~~~ handleSorting() - tableData.length: ' + tableData.length);
    
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
       return (
        a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
         numeric: true,
        }) * (sortOrder === "asc" ? 1 : -1)
       );
      });
      setTableData(sorted);
     }
   };

  return (
    <div style={{ padding: "0px", paddingLeft: isMobile ? "5vw" : "0px" }}>
      <table cellPadding={0} style={{ width: "95%", overflow: "clip", paddingRight: "30px" }}>
        {/* <TableHead columns={columns} handleSorting={handleSorting} /> */}
        {/* <TableHead {...{ columns, handleSorting, isMobile }} /> */}
        <TableHead columns={columns} handleSorting={handleSorting} isMobile={isMobile} />
        <TableBody columns={columns} tableData={assessments} onRowClick={handleClick} isMobile={isMobile} />
      </table>
    </div>
  );
}

export default AssessmentsTable;
