// ComplianceList.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { 
  colors,
  logoSrc,
  iconSrc
 } from '../../constants';
 import { 
  ExploreBarWrapper,
  ExploreHeaderCell,
  // ExploreIcon,
  ExploreTableBodyWrapper,
  ExploreTableCell,
  ExploreTableMovementCell,
  ExploreTableHeader,
  ExploreTableRow,
  ExploreTableMovementRow,
  ExploreTableRowMessage,
  ExploreTableWrapper,
  ExploreWrapper
} from './exploreStuff';
 import { ExploreIcon, ShellOvalBtn } from './title';
 import ExploreScoreCircle from './ExploreScoreCircle';
 import { getUserCompliance,  } from '../../services';

 function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


const tabSectionItemsWidth = "90%";
const tabSectionItemsVerticalSpacing = "10px";

// same as ExploreTableWrapper?
const ComplianceListWrapper = styled.div`
  width: ${tabSectionItemsWidth};
  max-width: 700px;
  min-height: 360px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  margin: auto;
  margin-top: ${tabSectionItemsVerticalSpacing};
  margin-right: -15px;
  text-align: left;
`;

// same as ExploreHeaderCell?
const ComplianceListHeaderCell = styled.td`
  height: 30px;
  text-align: center;
  background-color: #eeeeee; 
`;

// same as ExploreTableBodyWrapper?
const ComplianceListBodyWrapper = styled.div`
  width: 100%;
  height: 320px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  text-align: center;
`;

// same as ExploreTableCell?
const ComplianceListCell = styled.td`
  /* cursor: pointer; */
  height: 35px;
  text-align: center;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  border-bottom: 1px solid #eeeeee;
  /* border: 0.5px solid red; */
`;

// same as ExploreTableHeader?
const ComplianceListHeader = styled.thead`
  width: 100%;
  height: 30px;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0px;
  white-space: nowrap;
  margin-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  border-radius: 12px;
`;

// same as ExploreTableRow?
const ComplianceListRow = styled.tr`
  cursor: pointer;
  width: 100%;
  height: 35px;
  border-top: 2px solid white;
  ${ props => props.selected === true
    ? '{ background-color: #eeeeee }'
    : '{ background-color: #ffffff }' // use colors.sidebarBg ?
  }
  ${ props => props.dimmed === true
    ? '{ opacity: 0.5 }'
    : '{ opacity: 1.0 }'
  }
`;


const fakeCompliances = [ // workout_id_guid, workout_name_string, workout_user_first_name_string workout_user_last_name_string || group_name_string, user_workout_compliance_boolean
  {
    workout_id_guid: 'some_workout_id_guid',
    workout_name_string: 'some_workout_name_string',
    workout_user_first_name_string: 'some_workout_user_first_name_string',
    workout_user_last_name_string: 'some_workout_user_last_name_string',
    group_name_string: null,
    user_workout_compliance_boolean: true
  },
  {
    workout_id_guid: 'another_workout_id_guid',
    workout_name_string: 'workout_from_group_name_string',
    workout_user_first_name_string: null,
    workout_user_last_name_string: null,
    group_name_string: 'group_that_assigned_workout',
    user_workout_compliance_boolean: false
  },
  {
    workout_id_guid: 'third_workout_id_guid',
    workout_name_string: 'third_workout_name_string',
    workout_user_first_name_string: 'third_workout_user_first_name_string',
    workout_user_last_name_string: 'third_workout_user_last_name_string',
    group_name_string: null,
    user_workout_compliance_boolean: false
  }
];

const fakeExercises = [

]





const ComplianceList = ({
  // do we need any input props here, for Compliance context? a group_id? a user_id? anything?
  userId, state, onComplianceClick
}) => {
  console.log('~~~~~ Compliance() - userId is: ' + userId);
  const mounted = useRef(true);
  const [compliances, setCompliances] = useState([]);
  // const [compliances, setCompliances] = useState(fakeCompliances);
  // const [exercises, setExercises] = useState([]);
  const [exercises, setExercises] = useState(fakeExercises);
  const [complianceDetail, setComplianceDetail] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState('no-row-selected');
  const diameter = "30px";

  const userDisplayName = state.user &&
  state.user.last_name_string && state.user.last_name_string.length > 0 && 
  state.user.first_name_string && state.user.first_name_string.length > 0 ?
  state.user.last_name_string + ', ' + state.user.first_name_string : 
  state.user && state.user.email_string && state.user.email_string.length > 0 ?
  state.user.email_string : "SomeLastName, SomeFirstName";

  const ComplianceListBody = ({
    userId, onComplianceClick
  }) => {
    const [selectedRowId, setSelectedRowId] = useState(''); 
    const [dimmedRowId, setDimmedRowId] = useState('');
  
    function handleRowClick(userId, workoutId, workoutName, assignedBy){
      console.log('~~~~~ Compliance(), ComplianceListBody() - handleRowClick(' + userId + ', ' + workoutId + ')');
      setSelectedRowId(workoutId);
      onComplianceClick(userId, workoutId, workoutName, assignedBy); // or find the assigned by with data available to ComplioanceDetail?
    }

    function exerciseDisplayName(exerciseId){
      console.log('~~~~~ Compliance(), exerciseDisplayName() - exerciseId is: ' + exerciseId);
      // exercises array like: [ { id_guid: string, name_string: string }, { }, { } ... { } ]
      const exerciseObj = exercises.find(exercise => exercise.id_guid === exerciseId);
      return exerciseObj && exerciseObj.name_string ? exerciseObj.name_string : exerciseId + ' nope';
      
    }

    return (
      <tbody style={{ width: "100%" }}> 
        
        {compliances?.map(rowData => {
          let rowSelected = selectedRowId === rowData.workout_id_guid;
          let rowDimmed = dimmedRowId === rowData.workout_id_guid;
          let workoutName = rowData.workout_name_string;
          let workoutId = rowData.workout_id_guid;
          let assignedBy = rowData.assessment_workout_boolean === true ? "Physmodo Assessment" : rowData.group_name_string && rowData.group_name_string.length !== 0 ? rowData.group_name_string : 
           rowData.workout_user_first_name_string + ' ' + rowData.workout_user_last_name_string;
          let complianceColor = rowData.user_workout_compliance_boolean === true ? "green" : "red";

          return (
            <ComplianceListRow selected={rowSelected} dimmed={rowDimmed} key={workoutId + "_" + uuidv4()} onClick={() => handleRowClick(userId, workoutId, workoutName, assignedBy)} title={"workout_id_guid: " + rowData.workout_id_guid + ", " + "group_name_string: " + rowData.group_name_string + ", workout_user_first_name_string: " + rowData.workout_user_first_name_string + ", workout_user_last_name_string: " + rowData.workout_user_last_name_string + ", rowDimmed: " + rowDimmed}>
              <ComplianceListCell style={{ textAlign: "left", paddingLeft: "20px" }} title="Click to view Compliance Details for this Workout">{workoutName}</ComplianceListCell>
              <ComplianceListCell title="Click to view Compliance Details for this Workout">{assignedBy}</ComplianceListCell>
              <ComplianceListCell style={{ textAlign: "center", paddingLeft: "65px" }} title="Click to view Compliance Details for this Workout">
                <ExploreScoreCircle diameter={diameter} score="" color={complianceColor} />
              </ComplianceListCell>
            </ComplianceListRow>
          )

        })
        }
      </tbody>
    )
  }

  useEffect(() => {
    mounted.current = true; // TODO: how is this useful?
    // if(compliances.length !== 0) { 
    //   console.log('~~~~~ Compliance(), useEffect() - compliances.length !== 0');
    //   return;
    // }

    getUserCompliance(userId) // get list of workouts with compliance boolean
    .then(item => { // item is going to be what is returned from the service method...
      const complianceArray = item;
      
      console.log('~~~~~ Compliance(), complianceArray.length is : ' + complianceArray.length);

      setCompliances(complianceArray);
    })
  }, [userId, compliances.length])

  return (
    <ComplianceListWrapper>
      <table style={{ display: "table", borderCollapse: "separate", borderSpacing: "0 2px", marginTop: "-5px", width: "100%" }}>
        <ComplianceListHeader>
          <tr>
            <ComplianceListHeaderCell align="left" style={{ borderRadius: "15px 0 0 15px", textAlign: "left", paddingLeft: "20px" }}>Workout</ComplianceListHeaderCell>
            <ComplianceListHeaderCell width="25%" align="center">Assigned By</ComplianceListHeaderCell>
            <ComplianceListHeaderCell width="25%" align="center" style={{ borderRadius: "0 15px 15px 0" }}>Compliance</ComplianceListHeaderCell>
          </tr>
        </ComplianceListHeader>
        <ComplianceListBody userId={userId} onComplianceClick={onComplianceClick} />
      </table>
    </ComplianceListWrapper>
  );

}

export default ComplianceList;