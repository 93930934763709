// SectionHeaderTabs.js

import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { TabSectionHeaderTitle } from './title';
import { colors, iconSrc } from '../../constants';

const TabSectionHeader = styled.div`
  /* position: fixed; */
  width: 100%;
  height: 30px;
  color: black;
  text-align: left;
`; 

const HeaderTabGroupWrapper = styled.div` // this wraps the entire set of tabs, and fills the width of the ContentSection
  height: 30px;
  width: 100%;
  text-align: left;
  display: block;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
`;

const HeaderTabWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
  padding-left: 15px;
  white-space: nowrap;
  min-width: 128px;
  height: 30px;
  text-align: left;
  border-right: 1px solid #ffffff;
  ${ props => props.selected === true
    ? '{ background-color: #FFFFFF }'
    : '{ background-color: #eeeeee }' // use colors.sidebarBg ?
  }
`;


// const SectionHeaderTabs = ({
//   onTabChange
// }) => {


const SectionHeaderTabs = ({
  onTabChange, tabObjects, initialTab
}) => {
  const tabCount = tabObjects && tabObjects.length ? tabObjects.length : 0;
  const tabWidthPct = tabCount > 0 ? 100/tabCount : 100;
  const tabWidthPctString = Math.floor(100 * tabWidthPct)/100 + "%";
  const [activeTab, setActiveTab] = useState(initialTab); // this is the index in the tabObjects array
  console.log('~~~~~ SectionHeaderTabs() - initialTab was ' + initialTab + ', and activeTab is ' + activeTab);

  function handleTabClick(e){
    const tabId = e.currentTarget.id;
    let oldSelection = activeTab;
    let currentSelection = tabId.split('_')[1]; // assumes the only underscore in the tabId is just before the tab int index
    console.log('~~~~~ SectionHeaderTabs() - oldSelection was ' + oldSelection + ', and currentSelection is ' + currentSelection);

    if (currentSelection !== oldSelection){
      let index = parseInt(currentSelection, 10);
      setActiveTab(index);
      console.log('~~~~~ SectionHeaderTabs() - new selection should be the ' + tabObjects[index].title + ' tab!');
      onTabChange(index);
    }
  }

  return (
    <HeaderTabGroupWrapper>
      {  
        tabObjects.map((tabObj, index) => {
          // console.log('~~~~~ SectionHeaderTabs() - tabObj.title = ' + tabObj.title + ', and iconSrc is *' + tabObj.icon + '*, and tabIndex is *' + index + '*');
          const index_string = index.toString();
          const uniqueKey = "sectionTab_" + index_string;
          const isActiveTab = activeTab.toString() === index.toString();
          console.log('~~~~~ SectionHeaderTabs(), render section - activeTab is *' + activeTab + '*, and tabIndex is *' + index + '*, and isActiveTab is *' + isActiveTab + '*');

          return (
            <HeaderTabWrapper style={{ width: tabWidthPctString }} selected={isActiveTab} onClick={handleTabClick} id={uniqueKey} key={uniqueKey} >
              <TabSectionHeader>
                <TabSectionHeaderTitle text={tabObj.title} iconSrc={tabObj.icon} />
              </TabSectionHeader> 
            </HeaderTabWrapper>
          )
        })
      }
    </HeaderTabGroupWrapper>
  ); 
}

export default SectionHeaderTabs;