// services/workouts.js

const token = localStorage.getItem('token');
const fizzy_data_url = "https://data.physmodo.net";

export function getWorkouts() {
  return fetch(fizzy_data_url + '/api/v202203/app/workout/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function getAthleteWorkouts(athleteId) {
  return fetch(fizzy_data_url + '/api/v202203/app/user/' + athleteId + '/workout/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function createWorkout(workoutObj) {
  console.log('~~~~~ services/workouts.js, createWorkout() - name_string is *' + workoutObj.name_string + '*');
  console.log('~~~~~ services/workouts.js, createWorkout() - note_string is *' + workoutObj.note_string + '*');
  console.log('~~~~~ services/workouts.js, createWorkout() - exercise_array is *' + workoutObj.exercise_array + '*');
  console.log(workoutObj.exercise_array);
  try {
    return fetch(fizzy_data_url + '/api/v202203/app/workout/', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: workoutObj.name_string, 
        // workout_name_string: workoutObj.name_string,
        interval_integer: workoutObj.interval_integer,
        interval_string: workoutObj.interval_string.toLowerCase().includes("day") ? "day" : "week",
        note_string: workoutObj.note_string ? workoutObj.note_string : "",
        exercise_array: workoutObj.exercise_array

          // name_string: "Workout AAA Duplicate",
          // note_string: "The AAA workout, duplicated & modified! Foam Roll only, 2 a Day",
          // exercise_array: [
          //   {
          //     exercise_id_guid: "2cbb79d4-7aad-4b58-9380-91a528b8833b", 
          //     rank_integer: 1, 
          //     note_string: "notes",
          //     distance_integer: 173,
          //     distance_string: "meters",
          //     duration_integer: 189,
          //     duration_string: "seconds",
          //     load_string: "49% of 1RM",
          //     movement_id_guid: "cfcf677f-336b-4a65-afce-f281ffa20bc3",
          //     name_string: "Foam Roll Glutes",
          //     repetition_integer: 17,
          //     set_integer: 3,
          //     subtitle_string: null,
          //     thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--53e48831ae954b821a34ff97fcb7239dab93ff6a/Foam%20Roll%20Glutes.png",
          //     title_string: "Foam Roll Glutes",
          //     video_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZlU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f77c961df558ffa7de0272d428257bae9370dd69/262.mp4"
          //   }
          // ]

      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/workouts, createWorkout() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/workouts.js, createWorkout() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function updateWorkout(workoutObj) {
  console.log('~~~~~ services/workouts.js, updateWorkout() - name_string is *' + workoutObj.name_string + '*');
  console.log('~~~~~ services/workouts.js, updateWorkout() - note_string is *' + workoutObj.note_string + '*');
  console.log('~~~~~ services/workouts.js, updateWorkout() - interval_integer is *' + workoutObj.interval_integer + '*');
  console.log('~~~~~ services/workouts.js, updateWorkout() - interval_string is *' + workoutObj.interval_string + '*');
  console.log('~~~~~ services/workouts.js, updateWorkout() - exercise_array is *' + workoutObj.exercise_array + '*');
  console.log('~~~~~ services/workouts.js, updateWorkout() - id_guid is *' + workoutObj.id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/workout/' + workoutObj.id_guid, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: workoutObj.name_string,
        // workout_name_string: workoutObj.name_string,
        interval_integer: workoutObj.interval_integer, 
        interval_string: workoutObj.interval_string.toLowerCase().includes("day") ? "day" : "week",
        note_string: workoutObj.note_string ? workoutObj.note_string : "",
        exercise_array: workoutObj.exercise_array
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/members.js, updateWorkout() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/members.js, updateWorkout() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function deleteWorkout(id_guid) {
  console.log('~~~~~ services/workouts.js, deleteWorkout() - id_guid is *' + id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/workout/' + id_guid, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    // .then(data => data.json())
    .then(response => { if (response.status === 204) {
      console.log('~~~~~ services/workouts.js, deleteWorkout() - response status code was 200 or 201 or 204!');
      // return response.json(); 
      return response.status; 
    } else {
      console.log('~~~~~ services/workouts.js, deleteWorkout() - OOPS! response status code was ' + response.status + ' :-(');

    }
    return response.status;
  })
  }
  catch (e) {
    console.log(e)
  }
}

export function getWorkoutExercises(workoutId) {
  return fetch(fizzy_data_url + '/api/v202203/app/workout/' + workoutId + '/workout_exercise/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function getWorkout(workoutId) {
  return fetch(fizzy_data_url + '/api/v202203/app/workout/' + workoutId, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}