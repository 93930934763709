// /services/compliance.js

const token = localStorage.getItem('token');
const fizzy_data_url = "https://data.physmodo.net";
const athlete_id_guid = localStorage.getItem('loggedInUser');

export async function getUserCompliance(id_guid){ // fetches list of current workouts with compliance
  console.log('~~~~~ compliance service - getUserCompliance(), user_id_guid: ' + id_guid);

  try {
    return fetch('https://data.physmodo.net/api/v202203/app/user/' + id_guid + '/workout', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    .then(response => { if (response.status === 200 || response.status === 201) {
      console.log('~~~~~ services/compliance.js, getUserCompliance() - response status code was 200 or 201!');
      return response.json(); 
    } else {
      console.log('~~~~~ services/compliance.js, getUserCompliance() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}

export async function getWorkoutCompliance(userId, workoutId){ // fetches complianceDetail for user&&workout pairing
  console.log('~~~~~ compliance service - getWorkoutCompliance(), userId: ' + userId + ', workoutId: ' + workoutId);

  try {
    return fetch('https://data.physmodo.net/api/v202203/app/user/' + userId + '/workout/' + workoutId + '/compliance', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    .then(response => { if (response.status === 200 || response.status === 201) {
      console.log('~~~~~ services/compliance.js, getWorkoutCompliance() - response status code was 200 or 201!');
      return response.json(); 
    } else {
      console.log('~~~~~ services/compliance.js, getWorkoutCompliance() - OOPS! response status code was ' + response.status + ' :-(');
    }})
  }
  catch (e) {
    console.log(e)
  }
}

export async function submitWorkoutCompliance(workout_id_guid, payload) {
  // POST /api/v202203/app/user/{user_id_guid}/workout/{workout_id_guid}/compliance

  try {
    fetch(fizzy_data_url + '/api/v202203/app/user/' + athlete_id_guid + '/workout/' + workout_id_guid + '/compliance', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify(payload)
    })
    // .then(data => data.json()) // all we care about is the status? we don't use the json for anything?
    .then(response => {
      const complianceResp = response;
      console.log('~~~~~ /services/compliance, submitWorkoutCompliance() - complianceResp is ' + complianceResp);
      const complianceRespJson = complianceResp.json();
      console.log('~~~~~ /services/compliance, submitWorkoutCompliance() - complianceRespJson is ' + complianceRespJson);
      
      // return complianceResp.json();
      return complianceRespJson;
    })
    // .then(response => response.json())
      // // console.log('~~~~~ /services/compliance, submitWorkoutCompliance() - response.json() is ' + response.json());
      // const complianceResp = response;
      // // console.log('~~~~~ /services/compliance, submitWorkoutCompliance() - complianceResp.json() is ' + complianceResp.json());
      // if (complianceResp.status === 200 || complianceResp.status === 201) {
      //   console.log('~~~~~ services/compliance, submitWorkoutCompliance() - response status code was 200 or 201!');
      //   const respJsonPromise = complianceResp.json();
      //   // console.log('~~~~~ /services/compliance, submitWorkoutCompliance() - complianceResp.json() is ' + complianceResp.json());
      //   // console.log('~~~~~ /services/compliance, submitWorkoutCompliance() - respJsonPromise is ' + respJsonPromise);
      //   // return complianceResp.json(); 
      //   // return complianceResp;
      //   // return respJsonPromise;
      // } else {
      //   console.log('~~~~~ services/compliance.js, submitWorkoutCompliance() - OOPS! response status code was ' + response.status + ' :-(');
      //   // return complianceResp;
      // }
      // return complianceResp;
    // )
  }
  catch (e) {
    console.log(e)
  }
}
