// GroupList.js

import React, { useRef, useState } from 'react';
import styled from "styled-components";

// import {
//   AreaTitle2,
//   SplitTabContentWrapper2,
//   TabSectionLeft2,
//   TabSectionRight2,
//   TabSectionHeader,
//   TabSectionHeaderTitle,
//   TabSectionBody,
//   EZSpacer 
// } from './title';
// import { iconSrc, colors } from '../../constants';
import LoadingDivLayer  from './LoadingDivLayer';
// import ShellSidebarButton from './ShellSidebarButton';
import CollapsibleGroupRow from './CollapsibleGroupRow';
// import { getGroups, getGroup } from '../../services';


const GroupListWrapperDiv = styled.div`
  min-height: 30px;
  padding-left: 0px;
  height: calc(100vh - 335px);
  overflow-y: scroll;
`;

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const GroupList = ({
  groupsActive, activeGroupId, group286, groupObjArray, groupTreesArray, parentsArray, onGroupChange, onCaretChange // if I get it to re-render by changing key 'outside', how to get it to select the groupId from out there?
}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [loaded, setLoaded ] = useState(true); // TODO: REMOVE? - was false, when there was a useEffect here in this component 
  const mounted = useRef(true);

  const topLevelGroups = groupObjArray.filter(obj => {
    return obj.level_integer === 0;
  })

  groupObjArray = topLevelGroups; // strip out all the group objects except top level groups

  function selectGroup(id){
    // alert('Selected group_id_guid is ' + id);
    console.log('~~~~~ GroupList() - selectGroup() group_id_guid is ' + id);
    setSelectedGroup(id); // set id in local State variable selectedGroup - why? is selectedGroup === activeGroupId?
    onGroupChange(id);  // here we send the new group_id to Shell to get acted upon...
  }

  const okToRender = groupObjArray.length > 0;
  if (okToRender) {
    return ( 
      <GroupListWrapperDiv>
        {console.log("~~~~~ GroupList(), inside return() for render")}
        {loaded ? '' : <LoadingDivLayer sidebar />} 
        {groupObjArray.map((group) => {
          const parentObj = parentsArray.find(parent => parent.id === group.id_guid);
          const isCollapsed = parentObj && parentObj.closed;
          return (
              <CollapsibleGroupRow
                groupObject={group} 
                isCollapsed={isCollapsed} // we are showing them all open at the moment
                onCaretChange={onCaretChange} // change this to be onCaretChange, so it all happens in <Shell>
                onGroupClick={selectGroup} 
                selectedGroup={selectedGroup} 
                isSelected={(groupsActive || activeGroupId.length > 20) && group.id_guid === selectedGroup} 
                id={group.id_guid + "_row"}
                // key={group.id_guid + "_row"}
                key={uuidv4()}
                parentsArray={parentsArray}
              />
          );
        })}
      </GroupListWrapperDiv>
    );
  }

};

export default GroupList;
