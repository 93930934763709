// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

import Login from './components/Login';
import Content from './components/styled/Content';

import { storageAvailable } from './helpers'; 

function App() {
  // const [loading, setLoading] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  // const isPhone = useMediaQuery({ query: '(max-width: 1224px)' });
  const deviceInfo = {
    isDesktopOrLaptop: isDesktopOrLaptop,
    isBigScreen: isBigScreen,
    isTabletOrMobile: isTabletOrMobile,
    isPortrait: isPortrait,
    isRetina: isRetina
  };

  const location_string = window.location.href;
  const location_string_parts = location_string.split('//'); // tld, /, sitePage, ?optional query string
  const sitePage = location_string_parts[1].split('/')[1]; // could be '' for StretchLab (old dashboard), 'shell' for new DB, 'athlete' for Athlete DL
  console.log('~~~~~ App(), sitePage is ' + sitePage);
  const athleteDeepLink = sitePage === 'athlete' ? location_string_parts[1].split('/')[2].split('?')[0] : ''; // could be 'workouts', or 'readiness'
  console.log('~~~~~ App(), athleteDeepLink is ' + athleteDeepLink);
  const coachDeepLink = sitePage === 'coach' ? location_string_parts[1].split('/')[2].split('?')[0] : ''; // could be 'test1', or 'test2'
  const deepLinkQueryString = athleteDeepLink || coachDeepLink ? location_string_parts[1].split('/')[2].split('?')[1] : '' ;
  console.log('~~~~~ App(), deepLinkQueryString is ' + deepLinkQueryString);
  const deepLinkToken = (deepLinkQueryString && deepLinkQueryString.length !== 0 && deepLinkQueryString.includes('token=')) ? deepLinkQueryString.split('token=')[1].split('&')[0] : "";
  console.log('~~~~~ App(), deepLinkToken is ' + deepLinkToken);
  const deepLink = {
    type: (athleteDeepLink && athleteDeepLink.length !== 0) ? 'athlete' : (coachDeepLink && coachDeepLink.length !== 0) ? 'coach' : '', // 'athlete' or 'coach'
    page: (athleteDeepLink && athleteDeepLink.length !== 0) ? athleteDeepLink : (coachDeepLink && coachDeepLink.length !== 0) ? coachDeepLink : '' // 'readiness', or 'workouts', or 'test1', or 'test2'
  };

  console.log('~~~~~ App(), deepLink.type is ' + deepLink.type + ', and deepLink.page is ' + deepLink);

  const logout_url = location_string.includes('physmodo.net/') ? location_string.split('physmodo.net/')[0] + "physmodo.net/" : "http://localhost:3000/"; 
  // const logout_url = location_string.includes('localhost') ? "localhost:3000" : 

  console.log('~~~~~ App.js, location_string is *' + location_string + '* and logout_url is *' + logout_url + '*');

  // deviceInfo.isTabletOrMobile

  // also have to change this in Login.js to test case where no localStorage
  // constlocalStorageExists = false; // use App.state as a backup if no localStorage?
  const localStorageExists = storageAvailable('localStorage');
  // const alreadyReloaded = localStorageExists && localStorage.getItem('alreadyReloaded');
  
  function storeToken(token_string) {
    localStorage.setItem('token', token_string);
  }

  function logout() { 
    console.log('~~~~~~~~~~~~ App() - logout!');
    if (localStorageExists) {
      localStorage.setItem('token', null);
      localStorage.setItem('alreadyReloaded', false);
      window.location.reload();
    } else {
      // setToken(null); // what does this accomplish? setToken is not defined as we would expect const [token, setToken] = useState(null);
      storeToken(null);
    }
    // code here to set location to root for domain/subdomain
    window.location.href = logout_url;
  }

  // this logic should be more sophisticated, and should use/try a token provided in query string before the one in storage...
  // maybe check for one in the query string in a first step, and if found then save in localStorage?

  let token = (deepLinkToken && deepLinkToken.length > 20) ? deepLinkToken : localStorage.getItem('token'); // should be null if user has not logged in (or after after last logout)
  if (deepLinkToken && deepLinkToken.length > 20) {
    localStorage.setItem('token', token); // store currentToken (which is deepLinkToken if there was one) in LocalStorage (overwrite)
  }

  // no way found yet to show a message only once per session if there is no localStorage,
  // so we are not giving the user a 'get a better browser' warning in that case :-(
  // if (!localStorageExists) {  
  //   [token, setToken] = useState();
  // }

  const [width, setWidth] = useState(window.innerWidth); // useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      console.log('~~~~~ App.js, handleWindowSizeChange - window.innerWidth is ' + window.innerWidth);
      setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  // const isMobile = width <= 670;
  // const isMobile = deviceInfo.isTabletOrMobile;
  // const isMobile = deviceInfo.isTabletOrMobile || width <= 768; // make this width 640 ???
  const isMobile = deviceInfo.isTabletOrMobile || width <= 670; // make this width 640 ???
  // if we want this to realy mean "is tall and skinny"...  we could invent something that considered:
  // - deviceInfo.isMobileOrTablet
  // - deviceInfo.isRetina (because 'skinny' in pixels, is twice as big if display is retina)
  // - deviceInfo.isPortrait (because a phone in landscape might benefit from desktop version of layout)

  // so mobile should *always* mean deviceInfo.isPortrait?
  // - or could it be mobile even in landscape if max-width is less than some number?

  
  console.log('~~~~~ App.js, isMobile is *' + isMobile + '*');

  console.log('~~~~~ ' + window.location.href);

  if (window.location.href.includes('/accept') || window.location.href.includes('/signup')) { // for pages outside of login wall...
    return (
      <Router>
        <div style={{ display: "flex", minHeight: "100%"}}>
          <Content deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} deepLink={deepLink} id="content_component" />
        </div>
      </Router>
    );
  } else {

    if(!token || token.length < 10) { // go to login if not logged-in << note that we need to have already handled token !!!
      return <Login setToken={storeToken} isMobile={isMobile} />
    } else {
      return (
        <Router>
          <div style={{ display: "flex", minHeight: "100%"}}>
            <Content deviceInfo={deviceInfo} isMobile={isMobile} logout={logout} deepLink={deepLink} id="content_component" />
          </div>
        </Router>
      );
    }
  }
}

export default App;
