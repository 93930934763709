// WorkoutEdit.js

import React, { useState, useCallback, useRef, useEffect } from 'react';

import { createWorkout, updateWorkout, getExercise } from '../../services';
import {
  ShellOvalBtn,
  ShellInputLabel,
  ShellFieldDisplayName,
  ShellTextInput,
  ShellTextarea,
  Icon
} from './title';
import { iconSrc, colors } from '../../constants';
import InlineLoader from './InlineLoader';
import PickList from './PickList';
// import OrderedItemList from './OrderedItemList';
import OrderedExerciseList from './OrderedExerciseList';
import program from './../../models/program';

// const modelInfo = program;
// const fields = modelInfo.displayFields;

// const defaultItem = {
//   console.log('~~~~~ WorkoutEdit.js, defaultItem()');

//   let emptyItem = {};

//   fields.map((field) => {
//     console.log('~~~~~ WorkoutEdit.js, defaultItem() item[' + field.accessor + '] will be *' + field.default + '*');
//     emptyItem[field.accessor] = field.default;
//     console.log('~~~~~ WorkoutEdit.js, defaultItem() emptyItem[' + field.accessor + '] is now *' + emptyItem[field.accessor] + '*');
//   });

//   return emptyItem;
//   // setFormItem(emptyItem);
// }

const emptyWorkout = { // TODO: change this to build emptyWorkout based on defaults from models/program
  id_guid: "",
  name_string: "",
  note_string: "",
  interval_integer: 1,
  interval_string: "a week",
  exercise_array: [],
  user_id_guid: ""
};


const WorkoutEdit = ({
  create, item, movement, onSaveClick, onDuplicate, onAddExerciseClick, onVideoClick, clearMovement
}) => {
  const newItem = create && (
    !item || 
    !item.name_string || 
    item.name_string === undefined || 
    item.name_string.length === 0);

  // const [formItem, setFormItem] = useState(newItem ? emptyWorkout : item);
  const formItem = useRef(newItem ? emptyWorkout : item);
  // const [orderedListArray, setOrderedListArray] = useState(newItem ? emptyWorkout : item);
  const [perWeekOrPerDay, setPerWeekOrPerDay] = useState(formItem && formItem.current && formItem.current.interval_string && formItem.current.interval_string.toLowerCase().includes("day") ? "A Day" : "A Week",);
  const [exerciseMovement, setExerciseMovement] = useState(movement !== undefined ? movement : null);
  const myId = localStorage.getItem('loggedInUser');
  const notEditable = item && item.user_id_guid && item.user_id_guid.length > 20 && item.user_id_guid !== myId; // does workoutObj have user_id_guid property?
  const publicRecordMsg = "This record is Public, and cannot be edited here";
  const copy_black_url = "url(" + iconSrc.copy_black + ")";
  const dropdown_arrow_url = "url(" + iconSrc.dropdown_arrow + ")";
  const noExercises = !formItem || !formItem.current || !formItem.current.exercise_array || formItem.current.exercise_array.length === 0;
  const exerciseZeroStateMessage = "Add a Movement from the List to Create an Exercise";
  const borderColor = colors.lightGrey;

  function noEditAlert(){
    alert(publicRecordMsg);
  }

  const visible = true;
  // const itemType = modelInfo.type ;
  const [refreshExercisesList, setRefreshExercisesList] = useState('');

  const [fetchingData, setFetchingData] = useState(false);


  
  useEffect(() => {
    console.log('~~~~~ WorkoutEdit.js, useEffect()');
    
    // // if (item === undefined || !item || !item.id_guid && !item.name_string) {
    // if ((item === undefined || !item) && create) {
    //   console.log('~~~~~ WorkoutEdit.js, useEffect() - early return, no item or no item.id_guid');
    //   // defaultItem();
    //   return;
    // }

    console.log('~~~~~ WorkoutEdit.js, useEffect() - going to set formItem, because movement prop changed');

    // setRefreshExercisesList(uuidv4());
    // delete Object.assign(newExercise, {["exercise_id_guid"]: newExercise["id_guid"] })["id_guid"];
    
    // setFormItem(item);
    if (movement !== undefined && movement !== null){
      let tempItem = formItem.current;
      let tempExerciseArray = tempItem && tempItem.exercise_array ? tempItem.exercise_array : [];
      let lastAddedMovement = tempExerciseArray && tempExerciseArray.length > 0 ? tempExerciseArray[tempExerciseArray.length - 1] : null;
      let tempIdGuid = uuidv4();

      let newExercise = {
        id_guid: tempIdGuid,
        exercise_id_guid: tempIdGuid,
        movement_id_guid: movement && movement.id_guid ? movement.id_guid : null,
        name_string: movement && movement.name_string ? movement.name_string : null,
        title_string: movement && movement.title_string ? movement.title_string : null,
        subtitle_string: movement && movement.subtitle_string ? movement.subtitle_string : null,
        thumbnail_url_string: movement && movement.thumbnail_url_string ? movement.thumbnail_url_string : null ,
        video_url_string: movement && movement.video_url_string ? movement.video_url_string : null,
        rank_integer: tempExerciseArray.length + 1,
        distance_integer: null,
        distance_string: null,
        duration_integer: null,
        duration_string: null,
        load_string: null,
        repetition_integer: 1,
        set_integer: 1,
        note_string: null
      };
  
      tempExerciseArray.push(newExercise);
  
      tempItem.exercise_array = tempExerciseArray;
  
      formItem.current = tempItem;

      clearMovement();

      setRefreshExercisesList(uuidv4()); // do we need this if we are about to change the movement prop?
    }
    /*
    // each exercise in the exercise_array will have:
    // id_guid, exercise_id_guid (a string, or null if workout has not been saved with this exercise in it yet)
    // movement_id_guid
    // title_string, name_string (same as comparable movement attributes)
    // subtitle_string (same as comparable movement subtitle_string)
    // thumbnail_url_string (same as comparable movement thumbnail_url_string)
    // video_url_string (same as comparable movement video_url_string)
    //
    // rank_integer       // default to exercise_array.length of exercise_array BEFORE adding this new exercise
    // distance_integer   // default to null
    // distance_string    // default to null
    // duration_integer   // default to null
    // duration_string    // default to null
    // load_string      ` // default to null
    // repetition_integer // default to 1
    // set_integer        // default to 1
    // note_string        // default to null

    */
    
  }, [clearMovement, movement])


  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  // function checkItemChanged() { // compare current values for all item attributes in State, to values in item found in items (in State)
  //   // for Groups we would check itemName and itemNotes vsv
  //   // we would also want to check the associated_id_guids for as many associations as we allow editing of in this ViewEdit
  //   // we should probably save those guids in one or more collections in State somewhere...
  //   // then we could check the collections versus the collections on the appropriate item in the items array 
  //   // and if there are differences in the item attributes or associated items collection -> setEditingItem(true), 
    
  //   // in the functions that handle Submit, we would parse the missing or added guids and hit an api to remove or add, as required
  //   // IN ADDITION to submitting a new item or updating the existing item in the database...
  //   console.log('~~~~~ WorkoutEdit.js, checkItemChanged() 1');
    
  //   if (create) {
  //     console.log('~~~~~ WorkoutEdit.js, checkItemChanged() 2');
  //     return;
  //   }
  //   console.log('~~~~~ WorkoutEdit.js, checkItemChanged() 3');
    
  //   let changedFields = [];

  //   {fields.map((field) => {
  //       console.log('~~~~~ WorkoutEdit.js, checkItemChanged() 4, field.accessor');
  //       if (field.required) {
  //         console.log('~~~~~ WorkoutEdit.js, checkItemChanged() item[' + field.accessor + '] was *' + item[field.accessor] + '*');
  //         console.log('~~~~~ WorkoutEdit.js, checkItemChanged() formItem[' + field.accessor + '] is  now *' + formItem[field.accessor] + '*');
  //         if (item[field.accessor] !== formItem[field.accessor]) {
  //           console.log('~~~~~ WorkoutEdit.js, checkItemChanged() item has been changed!');
  //           changedFields.push(field.accessor);
  //         }
  //       }
  //     });

  //     if (changedFields.length > 0) {
  //       console.log('~~~~~ WorkoutEdit.js, checkItemChanged() item has been changed, ' + changedFields.length + ' fields were modified');
  //       // code here to make button show
  //       setEdit(true);
  //     } else {
  //       console.log('~~~~~ WorkoutEdit.js, checkItemChanged() item has not been changed, no field values were modified');
  //       // code here to make button hide
  //       setEdit(false);
  //     }
  //   }
  // }

  function handleUpdateArray(updatedArray) {
    console.log('~~~~~ WorkoutEdit.js, handleUpdateArray() - updated array is ' + updatedArray);
    console.log(updatedArray);

    formItem.current.exercise_array = updatedArray; // could this work?  might need a tempFormItem cloned from formItem?
    // setFormItem(formItem); // TODO: put this back? 4/10/24 updates

    // checkItemChanged();
    setRefreshExercisesList(uuidv4());
  }

  function handleAddExercise(exerciseObj) { // old fn used to add exercise from picklist
    const id_guid = exerciseObj.id_guid;
    let tempFormItem = structuredClone(formItem.current);
    let tempExerciseArray = tempFormItem && tempFormItem.exercise_array ? tempFormItem.exercise_array : [];
    // console.log('~~~~~ WorkoutEdit(), handleAddExercise() - tempExerciseArray.length before add is ' + tempExerciseArray.length);
    
    setFetchingData(true);
    getExercise(id_guid)
    .then(item => { // error handling here to annunciate failed fetch?
      let newExercise = item;
      //alert('added exercise with id_guid ' + newExercise && newExercise.id_guid ? newExercise.id_guid : 'unknown_id');
      if (newExercise && ((newExercise.id_guid && newExercise.id_guid.length > 20) || (newExercise.exercise_id_guid && newExercise.exercise_id_guid.length > 20))){
        // setAddedExerciseObj(newExercise); // TODO: use this for something?
      } else {
        alert('getExercise failed');
        newExercise = { id_guid: "fake", name_string: "Remove this - Exercise fetch failed :-(" };
      }
      console.log('~~~~~ WorkoutEdit(), handleAddExercise() - tempExerciseArray.length after add is ' + tempExerciseArray.length);
        
      // at this point newExercise has an id that is named "id_guid", and needs to be named "exercise_id_guid"
      delete Object.assign(newExercise, {["exercise_id_guid"]: newExercise["id_guid"] })["id_guid"];
      
      tempExerciseArray.push(newExercise);
      tempFormItem.exercise_array = tempExerciseArray;
      // setFormItem(tempFormItem); // TODO: put this back? 4/10/24 updates
      formItem.current = tempFormItem;

      // checkItemChanged();
      
      // also refresh OrderedItemList
      setRefreshExercisesList(uuidv4());
      setFetchingData(false);
    })
  }

  function handleUpdateExercise(exerciseObj) {
    const id_guid = exerciseObj.exercise_id_guid;
    let tempFormItem = structuredClone(formItem.current);
    let tempExerciseArray = tempFormItem && tempFormItem.exercise_array ? tempFormItem.exercise_array : [];
    // console.log('~~~~~ WorkoutEdit(), handleAddExercise() - tempExerciseArray.length before add is ' + tempExerciseArray.length);
    
    setFetchingData(true);
    getExercise(id_guid)
    .then(item => { // error handling here to annunciate failed fetch?
      let newExercise = item;
      //alert('added exercise with id_guid ' + newExercise && newExercise.id_guid ? newExercise.id_guid : 'unknown_id');
      if (newExercise && ((newExercise.id_guid && newExercise.id_guid.length > 20) || (newExercise.exercise_id_guid && newExercise.exercise_id_guid.length > 20))){
        // setAddedExerciseObj(newExercise); // TODO: use this for something?
      } else {
        alert('getExercise failed');
        newExercise = { id_guid: "fake", name_string: "Remove this - Exercise fetch failed :-(" };
      }
      console.log('~~~~~ WorkoutEdit(), handleAddExercise() - tempExerciseArray.length after add is ' + tempExerciseArray.length);
        
      // at this point newExercise has an id that is named "id_guid", and needs to be named "exercise_id_guid"
      delete Object.assign(newExercise, {["exercise_id_guid"]: newExercise["id_guid"] })["id_guid"];
      
      tempExerciseArray.push(newExercise);
      tempFormItem.exercise_array = tempExerciseArray;
      // setFormItem(tempFormItem); // TODO: put this back? 4/10/24 updates
      formItem.current = tempFormItem;

      // checkItemChanged();
      
      // also refresh OrderedItemList
      setRefreshExercisesList(uuidv4());
      setFetchingData(false);
    })
  }

  function exerciseFromMovement(movementObj) {

    // in this paradigm, an exercise is really a workout_exercise, and we save it as a movement_id plus workout-specific metadata:
    
    // we get from movement: 
    // id_guid, movement_id_guid // these two have the same valuue
    // title_string, name_string // as of 4/10/24 name_string is null
    // subtitle_string (typically null)
    // thumbnail_url_string
    // video_url_string
    // user_id_guid // - what is this in the context of a movement???

    // each exercise in the exercise_array will have:
    // id_guid, exercise_id_guid, workout_exercise_id_guid (a string, or null if workout has not been saved with this exercise in it yet)
    // movement_id_guid
    // title_string, name_string (same as comparable movement attributes)
    // subtitle_string (same as comparable movement subtitle_string)
    // thumbnail_url_string (same as comparable movement thumbnail_url_string)
    // video_url_string (same as comparable movement video_url_string)
    //
    // rank_integer       // default to exercise_array.length of exercise_array BEFORE adding this new exercise
    // distance_integer   // default to null
    // distance_string    // default to null
    // duration_integer   // default to null
    // duration_string    // default to null
    // load_string      ` // default to null
    // repetition_integer // default to 1
    // set_integer        // default to 1
    // note_string        // default to null

    // when we get a new movement to add to the exercise list, we should create a workout_exercise object with movement attributes,
    // and set the values of the exercise-specific attributes to the default values, and then append it to the array

    // const id_guid = exerciseObj.id_guid;
    // let tempFormItem = structuredClone(formItem);
    // let tempExerciseArray = tempFormItem && tempFormItem.exercise_array ? tempFormItem.exercise_array : [];
    // // console.log('~~~~~ WorkoutEdit(), handleAddExercise() - tempExerciseArray.length before add is ' + tempExerciseArray.length);
    
    // setFetchingData(true);
    // getExercise(id_guid)
    // .then(item => { // error handling here to annunciate failed fetch?
    //   let newExercise = item;
    //   //alert('added exercise with id_guid ' + newExercise && newExercise.id_guid ? newExercise.id_guid : 'unknown_id');
    //   if (newExercise && ((newExercise.id_guid && newExercise.id_guid.length > 20) || (newExercise.exercise_id_guid && newExercise.exercise_id_guid.length > 20))){
    //     // setAddedExerciseObj(newExercise); // TODO: use this for something?
    //   } else {
    //     alert('getExercise failed');
    //     newExercise = { id_guid: "fake", name_string: "Remove this - Exercise fetch failed :-(" };
    //   }
    //   console.log('~~~~~ WorkoutEdit(), handleAddExercise() - tempExerciseArray.length after add is ' + tempExerciseArray.length);
        
    //   // at this point newExercise has an id that is named "id_guid", and needs to be named "exercise_id_guid"
    //   delete Object.assign(newExercise, {["exercise_id_guid"]: newExercise["id_guid"] })["id_guid"];
      
    //   tempExerciseArray.push(newExercise);
    //   tempFormItem.exercise_array = tempExerciseArray;
    //   setFormItem(tempFormItem);

    //   // checkItemChanged();
      
    //   // also refresh OrderedItemList
    //   setRefreshExercisesList(uuidv4());
    //   setFetchingData(false);
    // })
  }

  function handleRemoveExercise(indexInt){
    console.log('~~~~~ WorkoutEdit(), removeExercise(), indexIntid to be removed is ' + indexInt);

    let tempFormItem = structuredClone(formItem.current);
    let tempExerciseArray = tempFormItem.exercise_array;
    console.log('~~~~~ WorkoutEdit(), handleRemoveExercise() - tempExerciseArray.length before remove is ' + tempExerciseArray.length);
    tempExerciseArray.splice(indexInt-1, 1);
    console.log('~~~~~ WorkoutEdit(), handleRemoveExercise() - tempExerciseArray.length after remove is ' + tempExerciseArray.length);
    // setFormItem(tempFormItem); // TODO: put this back, 4/10/24 updates

    formItem.current = tempFormItem;
    // also refresh OrderedItemList
    setRefreshExercisesList(uuidv4());
    // checkItemChanged();
  }
  
  function handleValueChange(event) {

    if (notEditable){
      alert('not Editable here');
      noEditAlert();
    } else {
      const accessor = event.target.id;
      const tvalue = event.target.value;
      let newItem = Object.assign({}, formItem.current); // this clones the current formItem as newItem

      console.log('~~~~~ WorkoutEdit.js, WorkoutEdit() - handleValueChange() attribute changing is *' + accessor + '* and value is *' + tvalue + '*');
      console.log('~~~~~ WorkoutEdit.js, WorkoutEdit() - handleValueChange() - newItem[' + accessor + '] is *' + newItem[accessor] + '*');

      newItem[accessor] = tvalue;

      console.log('~~~~~ WorkoutEdit.js, WorkoutEdit() - handleValueChange() - after edit, newItem[' + accessor + '] is *' + newItem[accessor] + '*');
      
      // setFormItem(newItem); // TODO: put this back, 4/10/24 updates
      formItem.current = newItem;
    
      // checkItemChanged();

      // console.log('~~~~~ WorkoutEdit.js, handleValueChange() - after checkItemChanged()');
    }
  }

  function handleCreateItemConfirmClick() {
    console.log('~~~~~ WorkoutEdit.js, handleCreateItemConfirmClick() - formItem.current.name_string is *' + formItem.current.name_string + '*');

    createWorkout(formItem.current) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
    .then(thisItem => { // item is going to be what is returned from the service method... 

      onSaveClick("workout", formItem.current); // TODO: figure out how to have Save of created new wokout switch to Edit mode of that same workout

      let alertMsg = 'New Workout with Name ' + thisItem.name_string + ' has been created and added to your list';

      setTimeout(function() {
        // onSaveClick("workout", formItem.current);
        // alert(alertMsg);
      }, 500);
    })
  }

  function handleUpdateItemConfirmClick() { // TODO: put this in Shell(), and add onWorkoutUpdate prop here
    console.log('~~~~~ WorkoutEdit.js - handleUpdateItemConfirmClick()');

    updateWorkout(formItem.current) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
    .then(thisItem => { // item is going to be what is returned from the service method... 

      onSaveClick("workout", formItem.current);

      let alertMsg = 'Updated Workout with Name ' + thisItem.name_string + ' and id_guid ' + thisItem.id_guid + ' has been updated in your list';

      setTimeout(function() {
        // onSaveClick("workout", formItem.current);// TODO: do we need to have this timeout here?
        // alert(alertMsg);
      }, 500);
    })
  }

  function onFieldChange(fieldName, newValue){
    console.log('~~~~~ WorkoutEdit(), onFieldChange() - new contents of fieldName *' + fieldName + '* input: ' + newValue);
    let newItem = Object.assign({}, formItem.current); // this clones the current formItem as newItem
    newItem[fieldName] = newValue;

    console.log('~~~~~ WorkoutEdit() - onFieldChange() - after edit, newItem[' + fieldName + '] is *' + newItem[fieldName] + '*');
    
    if (notEditable){
      noEditAlert();
    } else {
      // setFormItem(newItem); // TODO: put this back, 4/10/24 updates
      formItem.current = newItem;
    }
  }

  function handleSaveClick(){
    console.log('~~~~~ WorkoutEdit(), handleSaveClick() - id_guid of item to save is ' + formItem.current.id_guid);

    if (create){
      handleCreateItemConfirmClick();
    } else {
      handleUpdateItemConfirmClick();
    }
  }

  function handleAddExerciseClick(){
    // alert('WorkoutEdit(), handleAddExerciseClick()');
    console.log('~~~~~ WorkoutEdit(), handleAddExerciseClick()');
    onAddExerciseClick(create);
  }

  function onDuplicateClick(){
    // code here to populate a Create Workout form with the values from the current workout
    // except that the name/title will be the name/title of the current workout with "Duplicate" appended
    console.log('~~~~~ WorkoutEdit(), onDuplicateClick');
    // so what needs to happen here?
    // 1. the WorkoutEdit component needs to be set to 'create' mode (as opposed to the current 'edit' mode)
    // - this should happen 'outside' the WorkoutEdit component as the incoming 'create' prop will change
    //    so we will need a 'onDuplicate' event handler passed in from WorkoutSection
    // 2. the formItem needs to be updated to have a blank id_guid
    // 3. the formItem needs to be updated to append " Duplicate" after the Workout Name/title
    // 4. how will we get the pane header to update?  we need a function up in Shell...
    let futureFormItem = formItem.current;
    futureFormItem.id_guid = "";
    futureFormItem.name_string = formItem.current.name_string + " Duplicate";

    onDuplicate(futureFormItem);
  }

  function onPerWeekClick(){
    // code here to handle changing from per Week to per Day or vice-versa...
    let localString = perWeekOrPerDay;
    console.log('~~~~~ WorkoutEdit(), onPerWeekClick() - initial perWeekOrPerDay is *' + perWeekOrPerDay + '*');
    if (perWeekOrPerDay === "A Week") {
      console.log('~~~~~ WorkoutEdit(), onPerWeekClick() - perWeekOrPerDay was A Week');
      // perWeekOrPerDay = "A Day";
      setPerWeekOrPerDay("A Day");
      localString = "A Day";
    } else {
      console.log('~~~~~ WorkoutEdit(), onPerWeekClick() - perWeekOrPerDay was A Day');
      // perWeekOrPerDay = "A Week";
      setPerWeekOrPerDay("A Week");
      localString = "A Week";
    }
    console.log('~~~~~ WorkoutEdit(), onPerWeekClick() - new perWeekOrPerDay is *' + perWeekOrPerDay + '*');
    // code here to save neew value as part of workoutObject
    let tempFormItem = structuredClone(formItem.current);
    tempFormItem.interval_string = localString;
    // setFormItem(tempFormItem); // TODO: put this back, 4/10/24 updates
    formItem.current = tempFormItem;
    // also refresh OrderedItemList
    setRefreshExercisesList(uuidv4());
  }

  return (
    <div style={{ display: visible ? "flex" : "none", flexDirection: "column", alignItems: "left", width: "100%", paddingLeft: "40px", paddingTop: "30px" }}>
      <ShellInputLabel>
        <ShellFieldDisplayName style={{ width: "80px" }}>Name</ShellFieldDisplayName>
        <ShellTextInput 
          type="text" 
          defaultValue={(formItem && formItem.current && formItem.current.name_string) ? formItem.current. name_string : ''} 
          id="workout_name_string" 
          placeholder = "Enter a Name for this Workout"  
          name="name_string" 
          onChange={(e) => onFieldChange("name_string", e.target.value)}
          style={{ width: "420px" }}  
          disabled={notEditable}
          title={notEditable ? publicRecordMsg : "Enter a Name for this Workout"}
        />
        <ShellOvalBtn type="button" onClick={handleSaveClick} disabled={notEditable}>Save</ShellOvalBtn>
      </ShellInputLabel>
      
      <div className="IntervalAndDuplicate" 
        style={{ 
          display: "flex", 
          flexDirection: "row", 
          alignContent: "space-between" 
        }}>
        <ShellInputLabel>
          <ShellFieldDisplayName style={{ width: "80px" }}>
            Interval
          </ShellFieldDisplayName>
          <ShellTextInput 
            type="text" 
            defaultValue={(formItem && formItem.current && formItem.current.interval_integer) ? formItem.current.interval_integer : 1} 
            id="interval_integer" 
            // placeholder = "0.5 = twice per day, 2.0 = every two days"
            name="interval_integer" 
            onChange={(e) => onFieldChange("interval_integer", e.target.value)}
            style={{ width: "60px" }} 
            disabled={notEditable}
            title={notEditable ? publicRecordMsg : "Enter an integer Interval for this Workout"}
          />
        </ShellInputLabel>
        <ShellInputLabel>
          <ShellFieldDisplayName style={{ width: "1px", paddingLeft: "0px" }}>
            {/* Dropdown: per Week, per Day */}
          </ShellFieldDisplayName>
          <ShellOvalBtn type="button" onClick={onPerWeekClick} 
            style={{ 
              backgroundImage: dropdown_arrow_url,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "85px center",
              backgroundSize: "14px, 14px", 
              paddingLeft: "0px",
              width: "110px"
            }}
          >
            {perWeekOrPerDay}
          </ShellOvalBtn>

        </ShellInputLabel>
        { !create &&
          <ShellOvalBtn type="button" onClick={onDuplicateClick} 
            style={{ 
              backgroundImage: copy_black_url,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "10px center",
              backgroundSize: "16px, 16px", 
              paddingLeft: "35px",
              marginLeft: "100px"
            }}
          >
            Duplicate this Workout
          </ShellOvalBtn>
        }
      </div>
      
      <ShellInputLabel>
        <ShellFieldDisplayName style={{ width: "80px" }}>Notes</ShellFieldDisplayName>
        <ShellTextarea 
          // value={formItem.note_string ?? ""} 
          defaultValue={(formItem && formItem.current && formItem.current.note_string) ? formItem.current.note_string : ""} 
          id="workout_note_string" 
          name="note_string" 
          onChange={(e) => onFieldChange("note_string", e.target.value)} 
          style={{ width: "500px" }}
          disabled={notEditable}
          title={notEditable ? publicRecordMsg : "Enter a Note for this Workout"}
        />
      </ShellInputLabel>

      <ShellInputLabel style={{ display: "block", width: "100%" }}>
        <div style={{ display: "block", width: "100%" }}>
          <ShellFieldDisplayName style={{ width: "80px", marginRight: "15px" }}>Exercises</ShellFieldDisplayName>
            <ShellOvalBtn type="button" onClick={handleAddExerciseClick} disabled={notEditable} style={{ paddingTop: "3px", float: "right", marginRight: "90px" }}>
              <Icon src={iconSrc.plus} style={{ height: "22px", width: "22px", marginRight: "0px" }} />
            </ShellOvalBtn>
        </div>
       { !noExercises &&
        <div style={{ width: "100%", marginTop: "20px" }}>
          <OrderedExerciseList
            key={refreshExercisesList} 
            active={true} 
            mobile={false} 
            array={(formItem && formItem.current && formItem.current.exercise_array) ? formItem.current.exercise_array : []} 
            // array={orderedListArray && orderedListArray.length > 0 ? orderedListArray : []}
            updateArray={handleUpdateArray}
            onRemoveClick={handleRemoveExercise}
            onVideoClick={onVideoClick}
            disabled={notEditable}
          />
        </div>
       }
       { noExercises && 
        <div 
        style={{ 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "87%", 
          height: "200px",
          marginTop: "20px",
          border: "1px solid",
          borderColor: {borderColor},
          borderRadius: "10px",
          color: colors.lightGreyText  
        }}>
          {exerciseZeroStateMessage}
        </div>
       }


      </ShellInputLabel>
    </div>
  );

}

export default WorkoutEdit;
