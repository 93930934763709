// services/explore.js

const token = localStorage.getItem('token');
// const coachId = localStorage.getItem('loggedInUser');
const fizzy_data_url = "https://data.physmodo.net";

export function explore(like_string, group_id, user_id, workout_id, group_boolean, user_boolean, workout_boolean, inverse_boolean) {
  console.log('~~~~~~~~~~ explore(' + like_string + ', ' + group_id + ', group_boolean: ' + group_boolean + ', user_boolean: ' + user_boolean + ', workout_boolean: ' + workout_boolean);
  const group_string = group_boolean ? "&group_boolean=true" : "";
  const user_string = user_boolean ? "&user_boolean=true" : "";
  const workout_string = workout_boolean ? "&workout_boolean=true" : "";
  const inverse_string = inverse_boolean ? "&inverse_boolean=true" : "";
  const queryString = '?like_string=' + like_string + group_string + user_string + workout_string + inverse_string;  
  const groupExplore = group_id && group_id.length > 20; 
  const userExplore = user_id && user_id.length > 20;
  const workoutExplore = workout_id && workout_id.length > 20;                                                                                        // with group_id it is exploreGroup
  let urlString = 'explore';
  // let urlString = 'group/' + group_id + '/explore';
  if (groupExplore) {
    urlString = 'group/' + group_id + '/explore';
  }
  if (userExplore) {
    urlString = 'user/' + user_id + '/explore';
  }
  if (workoutExplore) {
    urlString = 'workout/' + workout_id + '/explore'; // TODO: is this right??
  }
  
  return fetch(fizzy_data_url + '/api/v202203/app/' + urlString + queryString, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}                                                                                           // with user_id it is exploreUser

export function exploreAll(like_string, group_boolean, user_boolean, workout_boolean) {
  // let queryString = '';
  // if (group_boolean || user_boolean || workout_boolean){
  //   queryString += "?";
  //   if (group_boolean) { queryString += "group_boolean=true"; }
  //   if (user_boolean){
  //     if (queryString !== "?") { queryString += "&"}
  //     queryString += "user_boolean=true"
  //   }
  //   if (workout_boolean){
  //     if (queryString !== "?") { queryString +="&"}
  //     queryString += "workout_boolean=true"
  //   }
  // }
  const group_string = group_boolean ? "&group_boolean=true" : "";
  const user_string = user_boolean ? "&user_boolean=true" : "";
  const workout_string = workout_boolean ? "&workout_boolean=true" : "";
  const queryString = '?like_string=' + like_string + group_string + user_string + workout_string;
  return fetch(fizzy_data_url + '/api/v202203/app/explore' + queryString, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function exploreGroup(like_string, group_id_guid, group_boolean, user_boolean, workout_boolean) { 
  const group_string = group_boolean ? "&group_boolean=true" : "";
  const user_string = user_boolean ? "&user_boolean=true" : "";
  const workout_string = workout_boolean ? "&workout_boolean=true" : "";
  const queryString = '?like_string=' + like_string + group_string + user_string + workout_string;  
  return fetch(fizzy_data_url + '/api/v202203/app/group/' + group_id_guid + '/explore' + queryString, {  
    method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
  )
  .then(data => data.json())
}

// export function exploreUser(like_string, user_id_guid, group_boolean, user_boolean, workout_boolean) { 
//   const group_string = group_boolean ? "&group_boolean=true" : "";
//   const user_string = user_boolean ? "&user_boolean=true" : "";
//   const workout_string = workout_boolean ? "&workout_boolean=true" : "";
//   const queryString = '?like_string=' + like_string + group_string + user_string + workout_string;  
//   return fetch(fizzy_data_url + '/api/v202203/app/user/' + user_id_guid + '/explore' + queryString, {
//       method: 'GET',
//       mode: 'cors',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': token
//       }
//     }
//   )
//   .then(data => data.json())
// }

// export function exploreWorkout(like_string, workout_id_guid, group_boolean, user_boolean) { 
//   const group_string = group_boolean ? "&group_boolean=true" : "";
//   const user_string = user_boolean ? "&user_boolean=true" : "";
//   const workout_string = "&workout_boolean=false";
//   const queryString = '?like_string=' + like_string + group_string + user_string + workout_string;  
//   return fetch(fizzy_data_url + '/api/v202203/app/workout/' + workout_id_guid + '/explore' + queryString, {
//       method: 'GET',
//       mode: 'cors',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': token
//       }
//     }
//   )
//   .then(data => data.json())
// }
