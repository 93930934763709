// associations.js

const token = localStorage.getItem('token');
// const coachId = localStorage.getItem('loggedInUser');
const fizzy_data_url = "https://data.physmodo.net";

// an array of itemType objects will be returned, each one of them will be associated with the assocType record assocId
// the last parameter is inverseBool, if true, the items returned will be all of itemType NOT associated w/assocId
export function getAssociatedItems(itemType, assocType, assocId, inverseBool) {
  console.log('~~~~~ services/associations.js, getAssociatedItems(' + itemType + ', ' + assocType + ', ' + assocId + ', ' + inverseBool + ')');
  // if (!assocId) {return { data: [] }} // TODO: handle case where assocId is null or undefined
  if (!assocId) {assocId = "not-a-valid-guid"} // TODO: handle case where assocId is null or undefined
  let apiUrl = '/api/v202203/app/user/' + assocId + '/group/'; // initially, get groups for an athlete
  const item = itemType.toLowerCase();
  const assoc = assocType.toLowerCase();
  
  switch (true) {
    case(item === "group" && assoc === "athlete") : // fetch all groups for this athlete?
      apiUrl = '/api/v202203/app/user/' + assocId + '/group'; // appropriate endpoint for getAllGroupsForAthlete
      console.log('~~~~~ services/associations, setting up to getAllGroupsForAthlete');
      break;
    case(item === "program" && assoc === "group") : // fetch all programs for this group
      apiUrl = '/api/v202203/app/group/' + assocId + '/program'; // appropriate endpoint for getAllProgramsForGroup
      console.log('~~~~~ services/associations, setting up to getAllProgramsForGroup');
      break;
    case(item === "athlete" && assoc === "group") : // fetch all athletes for this group
      apiUrl = '/api/v202203/app/group/' + assocId + '/user'; // appropriate endpoint for getAllAthletesForGroup
      console.log('~~~~~ services/associations, setting up to getAllAthletesForGroup');
      break;
    case(item === "program" && assoc === "athlete") : // fetch all programs for this athlete
      apiUrl = '/api/v202203/app/user/' + assocId + '/program'; // appropriate endpoint for getAllProgramsForAthlete
      console.log('~~~~~ services/associations, setting up to getAllProgramsForAthlete');
      break;
    case(item === "program" && assoc === "exercise") : // fetch all programs for this exercise
      apiUrl = '/api/v202203/app/exercise/' + assocId + '/program'; // appropriate endpoint for getAllProgramsForExercise
      console.log('~~~~~ services/associations, setting up to getAllProgramsForExercise');
      break;
    case(item === "exercise" && assoc === "program") : // fetch all exercises for this program
      apiUrl = '/api/v202203/app/program/' + assocId + '/exercise'; // appropriate endpoint for getAllExercisesForProgram
      console.log('~~~~~ services/associations, setting up to getAllExercisesForProgram');
      break;
    default: // this is the same as the first case above...
      apiUrl = '/api/v202203/app/user/' + assocId + '/group'; // appropriate endpoint for getAllGroupsForAthlete
      console.log('~~~~~ services/associations, setting up to getAllGroupsForAthlete');
  }

  if (inverseBool) { apiUrl = apiUrl + '?inverse_boolean=true'; }
  
  return fetch(fizzy_data_url + apiUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
  // .then(data => makeMembersFromUsers(data))
}

/*
// this is an example of response to a GET to '/api/v202203/app/user/' + assocId + '/group'
// - in this example the user (athlete8@physmodo.com) actually had a group associated...
{
	"data": [
		{
			"id_guid": "7db059b9-c855-4ccc-b607-9822d530731d",
			"name_string": "Team 4 Group!",
			"group_user_name_string": null,
			"group_user_first_name_string": null,
			"group_user_last_name_string": null
		}
	],
	"pagination": {
		"page_current": 1,
		"page_total": 1,
		"rows_current": 0,
		"rows_total": 1
	}
}
*/



export function addAssociatedItem(itemType, itemId, assocType, assocId) { // an array of assocType objects (or id_guids?) will be returned 
  console.log('~~~~~ services/associations.js, addAssociatedItem(' + itemType + ', ' + itemId + ', ' + assocType + ', ' + assocId + ')');
  // alert('~~~~~ services/associations.js, addAssociatedItem(' + itemType + ', ' + itemId + ', ' + assocType + ', ' + assocId + ')');
  // if (!itemId || !assocId) {return}
  if (!itemId) {itemId = "not-a-valid-guid"} // TODO: handle case where itemId is null or undefined
  if (!assocId) {assocId = "not-a-valid-guid"} // TODO: handle case where assocId is null or undefined
  
  // code here to parse params and find API endpoint, method, and body
  let apiUrl = '/api/v202203/app/user/' + assocId + '/group/' + itemId; // initially, add an Athlete-Group association
  const item = itemType.toLowerCase();
  const assoc = assocType.toLowerCase();
  
  switch (true) {
    case(item === "group" && (assoc === "athlete" || assoc === "user")) :
      apiUrl = '/api/v202203/app/group/' + itemId + '/user/' + assocId; // endpoint to add an Athlete-Group association
      console.log('~~~~~ services/associations, setting up to associate an Athlete and a Group'); // A
      break;
    case((item === "athlete" || item === "user") && assoc === "group") :
      apiUrl = '/api/v202203/app/user/' + itemId + '/group/' + assocId; // endpoint to add a Group-Athlete association
      console.log('~~~~~ services/associations, setting up to associate an Group and an Athlete'); // A!
      break;
    case(item === "group" && (assoc === "program" || assoc === "workout")) :
      apiUrl = '/api/v202203/app/group/' + itemId + '/workout/' + assocId; // endpoint to add a Program-Group association
      console.log('~~~~~ services/associations, setting up to associate a Program and a Group'); // B
      break;
    case((item === "program" || item === "workout") && assoc === "group") :
      apiUrl = '/api/v202203/app/workout/' + itemId + '/group/' + assocId; // endpoint to add a Group-Program association
      console.log('~~~~~ services/associations, setting up to associate a Group and a Program'); // B!
      break;
    case((item === "athlete" || item === "user") && (assoc === "program" || assoc === "workout")) :
      apiUrl = '/api/v202203/app/user/' + itemId + '/workout/' + assocId; // endpoint to add a Program-Athlete association
      console.log('~~~~~ services/associations, setting up to associate a Program and an Athlete'); // C
      break;
    case((item === "program" || item === "workout") && (assoc === "athlete" || assoc === "user")) :
      apiUrl = '/api/v202203/app/workout/' + itemId + '/user/' + assocId; // endpoint to add an Athlete-Program association
      console.log('~~~~~ services/associations, setting up to associate a Athlete and a Program'); // C!
      break;    
    case((item === "program" || item === "workout") && assoc === "exercise") :
      apiUrl = '/api/v202203/app/workout/' + itemId + '/exercise/' + assocId; // endpoint to add an Exercise-Program association
      console.log('~~~~~ services/associations, setting up to associate an Exercise and a Program'); // D
      break;
    case(item === "exercise" && (assoc === "program" || assoc === "workout")) :
      apiUrl = '/api/v202203/app/exercise/' + itemId + '/workout/' + assocId; // endpoint to add a Program-Exercise association
      console.log('~~~~~ services/associations, setting up to associate a Program and an Exercise'); // D!
      break;
    default: // this is the same as the first case above...
      apiUrl = '/api/v202203/app/group/' + itemId + '/user/' + assocId; // endpoint to add an Athlete-Group association
      console.log('~~~~~ services/associations, setting up to associate an Athlete and a Group'); // A (again)
  }
  
  return fetch(fizzy_data_url + apiUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
  }})
  .then(resp => {
      const respStatus = resp.status;

      return respStatus;
    }
  )
}


export function removeAssociatedItem(itemType, itemId, assocType, assocId) { // a status code will be returned with no body, should be 204 
 console.log('~~~~~ services/associations.js, removeAssociatedItem(' + itemType + ', ' + itemId + ', ' + assocType + ', ' + assocId + ')');
  // if (!itemId || !assocId) {return}
  if (!itemId) {itemId = "not-a-valid-guid"} // TODO: handle case where itemId is null or undefined
  if (!assocId) {assocId = "not-a-valid-guid"} // TODO: handle case where assocId is null or undefined
 //   // code here to parse params and find API endpoint, method, and body
  let apiUrl = '/api/v202203/app/user/' + assocId + '/group/' + itemId; // initially, remove an Athlete-Group association
  const item = itemType.toLowerCase();
  const assoc = assocType.toLowerCase();
  
  switch (true) {
    case(item === "group" && assoc === "user") :
      apiUrl = '/api/v202203/app/group/' + itemId + '/user/' + assocId; // endpoint to delete an Athlete-Group association
      console.log('~~~~~ services/associations, setting up to dis-associate an Athlete and a Group'); // A
      break;
    case(item === "user" && assoc === "group") :
      apiUrl = '/api/v202203/app/user/' + itemId + '/group/' + assocId; // endpoint to delete a Group-Athlete association
      console.log('~~~~~ services/associations, setting up to dis-associate an Group and an Athlete'); // A!
      break;
    case(item === "group" && assoc === "workout") :
      apiUrl = '/api/v202203/app/group/' + itemId + '/workout/' + assocId; // endpoint to delete a Program-Group association
      console.log('~~~~~ services/associations, setting up to dis-associate a Program and a Group'); // B
      break;
    case(item === "workout" && assoc === "group") :
      apiUrl = '/api/v202203/app/workout/' + itemId + '/group/' + assocId; // endpoint to delete a Group-Program association
      console.log('~~~~~ services/associations, setting up to dis-associate a Group and a Program'); // B!
      break;
    case(item === "user" && assoc === "workout") :
      apiUrl = '/api/v202203/app/user/' + itemId + '/workout/' + assocId; // endpoint to delete a Program-Athlete association
      console.log('~~~~~ services/associations, setting up to dis-associate a Program and an Athlete'); // C
      break;
    case(item === "workout" && assoc === "user") :
      apiUrl = '/api/v202203/app/workout/' + itemId + '/user/' + assocId; // endpoint to delete an Athlete-Program association
      console.log('~~~~~ services/associations, setting up to dis-associate a Program and an Athlete'); // C!
      break;    
    case(item === "workout" && assoc === "exercise") :
      apiUrl = '/api/v202203/app/workout/' + itemId + '/exercise/' + assocId; // endpoint to delete an Exercise-Program association
      console.log('~~~~~ services/associations, setting up to dis-associate an Exercise and a Program'); // D
      break;
    case(item === "exercise" && assoc === "workout") :
      apiUrl = '/api/v202203/app/exercise/' + itemId + '/workout/' + assocId; // endpoint to delete a Program-Exercise association
      console.log('~~~~~ services/associations, setting up to dis-associate a Workout and an Exercise'); // D!
      break;
    default: // this is the same as the first case above...
      apiUrl = '/api/v202203/app/group/' + itemId + '/user/' + assocId; // endpoint to delete an Athlete-Group association
      console.log('~~~~~ services/associations, setting up to dis-associate a User and a Group'); // A (again)
  }
  
  return fetch(fizzy_data_url + apiUrl, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
  }})
  .then(resp => {
      const respStatus = resp.status;

      return respStatus;
    }
  )
}


