// components/styled/WorkoutsHeader.js

import React from 'react';
import { useState, useRef, useEffect } from "react";

import styled from 'styled-components';
import { AreaTitle, BackLink } from './title';
import { iconSrc, colors } from '../../constants';


const WorkoutsHeaderDiv = styled.div`
  text-align: center;
`;

function WorkoutsHeader(props) {
  const config = props.config;
  // const isMobile = props.isMobile;
  const showWorkoutsLink = !config.includes("L");
  const showMovementsLink = !config.includes("M");
  const isMobile = props.isMobile;

  console.log('~~~~~ WorkoutsHeader.js, WorkoutsHeader() - isMobile is *' + isMobile + '*');
  return (
    <WorkoutsHeaderDiv>
      <AreaTitle style={{ display: "inline-block", width: "30%", textAlign: "left", fontSize: "smaller" }}>
        {/* <a onClick={(e) => {window.location.reload()}} style={{ cursor: "pointer", color: colors.accent, textDecoration: "underline" }}>Assigned Workouts</a> */}
        <BackLink onClick={(e) => {window.location.reload()}}>{ showWorkoutsLink ? "Assigned Workouts" : "" }</BackLink>
      </AreaTitle>
      <AreaTitle style={{ display: "inline-block", width: "36%", color: isMobile ? "red" : "black" }}>Your Upcoming Workouts</AreaTitle>
      <AreaTitle style={{ display: "inline-block", width: "30%", textAlign: "right", fontSize: "smaller" }}>
        {/* <a onClick={(e) => {window.location.reload()}}  style={{ cursor: "pointer", color: colors.accent, textDecoration: "underline" }}>Recommendations</a> */}
        {/* <BackLink onClick={(e) => {window.location.reload()}}>{ showMovementsLink ? "Recommendations" : "" }</BackLink> */}
      </AreaTitle>
    </WorkoutsHeaderDiv>
  );
}

export default WorkoutsHeader;


