// ShellSidebar.js

import React, { useState, useRef, useEffect } from 'react';
import packageJson from '../../../package.json';

import styled from "styled-components";
import ShellSidebarButton from './ShellSidebarButton';
import GroupList from './GroupList';
// import { SidebarBtnIcon, Icon, SidebarSearchBtnIcon, SidebarExerciseBtnIcon, SidebarWorkoutBtnIcon } from './title';
import { 
  colors,
  iconSrc,
  logoSrc,
  northsideLogoSrc,
  massageAddictLogoSrc
 } from '../../constants';
 import { storageAvailable } from '../../helpers';

const isCoach = localStorage.getItem('coach') === 'true' ? true : false;
const isAdmin = localStorage.getItem('administrator') === 'true' ? true : false;
const isAthlete = localStorage.getItem('athlete') === 'true' ? true : false;
const isEnterprise = localStorage.getItem('enterprise') === 'true' ? true : false;
let isExplorer = localStorage.getItem('explorerDashboard') === 'true' ? true : false;

const SideBarDiv = styled.div`
  padding: 0px;
  padding-top: 4vh;
  width: 17.7vw;
  min-height: 100vh;
  border-right: 1px solid #ffffff; /* TODO: decide if we should use colors.lightGreyDividers ? */
  background-color: blue;
`;

const SideBarInnerDiv = styled.div`
  text-align: center;
`;

const SideBarFooterDiv = styled.div`
  height: 7vw;
  width: 15vw;
  /* position: fixed; */
  bottom: 1vw;
  border-top: 1px solid #e9e9e9;
  margin: 0;
  margin-left: 0.8vw;
`;

const StyledBoxExp = styled.div`
  height: 300px;
  width: 252px;
`;

const StyledNavigationBar = styled.div`
  height: 300px;
  left: 0;
  position: fixed;
  top: 0;
  width: 252px;
`;

const StyledOverlap = styled.div`
  height: 300px;
  position: relative;
  width: 256px;
`;

const StyledGroup = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 276px;
  width: 256px;
`;

const StyledOverlapGroup = styled.div`
  border: 1px solid;
  border-color: #f5f5f5;
  height: 24px;
  /* position: relative; */
  width: 252px;
`;

const StyledTextWrapper = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 39px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 128px;
`;

const StyledDiv = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  transform: rotate(90deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledOverlapWrapper = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 253px;
  width: 256px;
`;

const StyledOverlapGroupWrapper = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 230px;
  width: 256px;
`;

const StyledGroup2 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 53px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 128px;
`;

const StyledTextWrapper2 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 36px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  transform: rotate(90deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledGroup3 = styled.div`
  height: 25px;
  left: 0;
  position: absolute;
  top: 137px;
  width: 256px;
`;

const StyledGroup4 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 68px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 128px;
`;

const StyledTextWrapper3 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 50px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledDivWrapper = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 184px;
  width: 256px;
`;

const StyledGroup5 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 81px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 128px;
`;

const StyledTextWrapper4 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 63px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  transform: rotate(90deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledGroup6 = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 161px;
  width: 256px;
`;

const StyledGroup7 = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 207px;
  width: 256px;
`;

const StyledGroup8 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 67px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 128px;
`;

const StyledTextWrapper5 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 49px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2px;
  transform: rotate(90deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledGroup9 = styled.div`
  height: 25px;
  left: 0;
  position: absolute;
  top: 114px;
  width: 256px;
`;

const StyledOverlap2 = styled.div`
  height: 25px;
  /* position: relative; */
  width: 252px;
`;

const StyledRectangle = styled.div`
  border: 1px solid;
  border-color: #f5f5f5;
  height: 24px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 252px;
`;

const StyledGroup10 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 54px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 128px;
`;

const StyledTextWrapper6 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 36px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledGroup11 = styled.div`
  height: 25px;
  left: 0;
  position: absolute;
  top: 91px;
  width: 256px;
`;

const StyledTextWrapper7 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 40px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 128px;
`;

const StyledTextWrapper8 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledTopGroup = styled.div`
  height: 25px;
  left: 0;
  position: absolute;
  top: 68px;
  width: 256px;
`;

const StyledTextWrapper9 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 26px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 128px;
`;

const StyledTextWrapper10 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 8px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  white-space: nowrap;
  width: 15px;
`;

const StyledExercises = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 254px;
`;

const StyledTextWrapper11 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 128px;
`;

const StyledExerciseDark = styled.img`
  height: 18px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 18px;
`;

const StyledWorkouts = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 254px;
`;

const StyledWorkoutDark = styled.img`
  height: 24px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 24px;
`;

const StyledExplore = styled.div`
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 254px;
`;

const StyledOverlap3 = styled.div`
  background-color: #ffffff;
  height: 24px;
  /* position: relative; */
  width: 252px;
`;

const StyledTextWrapper12 = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 26px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 223px;
`;

const StyledIconSearch = styled.img`
  height: 21px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 20px;
`;

// className="ShellSidebar"
// style={{ display: "flex", opacity: "1.00" }}

// logout={logout} 
// deviceInfo={deviceInfo} 
// key={refreshSB}

// activeGroupId={group.id_guid}
// group286={group286}
// selectedBtn={sidebarBtn}

// groupObjArray={groupObjArray} 
// groupTreesArray={groupTreesArray.current}

// onSidebarBtnClick={handleSidebarBtnClick} 
// onCaretChange={handleCaretChange}



// { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina }
function ShellSidebar({
  logout, deviceInfo, activeGroupId, group286, selectedBtn, onSidebarBtnClick, groupObjArray, groupTreesArray, parentsArray, onCaretChange, onLogoClick, topLogoSrc
}) {
  // const topLogoSrcIndex = useRef(0);
  const localStorageExists = storageAvailable("localStorage");
  const loggedInUserEmail = localStorage.getItem('loggedInUserEmail');
  
  var dashboardType = "Explorer";

  const [remove, setRemove] = useState(group286);
  
  function handleNavBtnClick(e) {
    const button_id = e.target.id;
    // alert('HEY! button_id: ' + button_id);
    onSidebarBtnClick(button_id);
  }

  function handleGroupClick(id_guid) {
    // alert('HEY! group_' + id_guid);
    onSidebarBtnClick("group_" + id_guid);
  }


  
  return (
    <SideBarDiv style={{ position: "relative", backgroundColor: colors.sidebarBg, paddingBottom: "0px" }}>
      {console.log("~~~~~ SideBarDiv(), inside return() for render")}
      <div style={{ width: "100%", margin: "auto", marginTop: "-10px", paddingBottom: "5px", height: "15px", fontSize: "smaller", textAlign: "center", color: "#bbbbbb" }}>{loggedInUserEmail}</div>
      <SideBarInnerDiv style={{ position: "relative", padding: "0px" }}>
        <div style={{ padding: "10px", paddingTop: "4px" }}>
          <div style={{ backgroundColor: "white", padding: "10px" }}>
            <img src={topLogoSrc} alt="logo" style={{width: "15vw" }} onClick={onLogoClick} title={loggedInUserEmail} />
          </div>
        </div>
        <ShellSidebarButton btnTitle="Explore" isSelected={selectedBtn === "ExploreBtn"} onButtonClick={handleNavBtnClick} icon={iconSrc.search} btnId="ExploreBtn" />
        {/* <ShellSidebarButton btnTitle="Exercises" isSelected={selectedBtn === "ExercisesBtn"} onButtonClick={handleNavBtnClick} icon={iconSrc.exercise} btnId="ExercisesBtn" /> */}
        <ShellSidebarButton btnTitle="Workouts" isSelected={selectedBtn === "WorkoutsBtn"} onButtonClick={handleNavBtnClick} icon={iconSrc.workout} btnId="WorkoutsBtn" />
        <ShellSidebarButton btnTitle="Groups" isSelected={false} icon={iconSrc.group} id="GroupBtn" />
        <GroupList
          groupsActive={selectedBtn.includes("group_")} 
          activeGroupId={activeGroupId} 
          group286={remove}
          groupObjArray={groupObjArray}
          groupTreesArray={groupTreesArray}
          onGroupChange={handleGroupClick} 
          onCaretChange={onCaretChange}
          parentsArray={parentsArray}
        />
      </SideBarInnerDiv>
      
      <SideBarFooterDiv style={{ position: "absolute", bottom: "bottom", backgroundColor: colors.sidebarBg, paddingTop: "0px", paddingBottom: "0px", marginBottom: "0px", height: "60px" }}>
        <div style={{ position: "relative", width: "100%", textAlign: "center", padding: "10px 0 0 0" }} >
          <img alt="Physmodo logo" src={logoSrc} style={{ width: "100px", marginBottom: "-20px" }}/>
        </div>
        
        <ShellSidebarButton linkTo={'/#'} btnTitle="" isSelected={false} icon={iconSrc.logout} id="LogoutBtn" onButtonClick={logout} />
        <p style={{ textAlign: "center", fontSize: "10px", padding: "0px", color: colors.lightGreyText, marginTop: "-10px" }}>
          {/* v{packageJson.version} <span id="d-type" onClick={toggleShell}> {dashboardType}</span> */}
          v{packageJson.version}
        </p> 
      </SideBarFooterDiv>
    </SideBarDiv>
  );
}

export default ShellSidebar;
