// UserAssessment.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// import { getMember } from '../services/members';
import { getAssessmentPageData } from '../../services/assessments';
import TopLineScores from './../styled/TopLineScores';
// import ShellBodyMap from './../styled/ShellBodyMap';
import BodyMap from './../styled/BodyMap'; // TODO: retrieve stash with ShellBodyMap & go back to using it...
import ShellPrimaryIssuesTable from './../styled/ShellPrimaryIssuesTable';
import ShellSubdomainScoresTable from './../styled/ShellSubdomainScoresTable';
import LoadingDivLayer  from './../styled/LoadingDivLayer';
import { EZSpacer, BackLink, ContentWrapper, ShellTabButton } from './../styled/title';
import './../Assessment.css';

const BodymapAndIssuesDiv = styled.div`
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center-top;
  justify-content: space-between;
  padding-left: 0px;
`;

const isStretchLab = localStorage.getItem('stretchlab') === 'true' ? true : false;

const UserAssessment = ({
  assessmentId, priorAssessmentId, onBackLinkClick, isMobile
}) => {
  const [issues, setIssues] = useState([]);
  const [scores, setScores] = useState([]);
  // const [member, setMember] = useState({});
  const [alreadyFetched, setalreadyFetched] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState("mobility");


  useEffect(() => {
    if(alreadyFetched) { // was (scores && scores[0])
      return;
    }
    getAssessmentPageData(assessmentId, priorAssessmentId)
      .then(item => {
        setIssues(item.issuesData);
        setScores(item.scoresData);
        setalreadyFetched(true); 
        setTimeout(() => { // TODO: is delay necessary?
          // console.log("Delayed for 2 seconds.");
          setLoaded(true);
        }, 1000)
      })

    // return () => mounted.current = false; // TODO: how is this useful?
  }, [scores, issues, alreadyFetched, assessmentId, priorAssessmentId])

  function setActiveTab(title) {
    console.log("~~~~~ setActiveTab() - title: " + title);
    setCurrentTab(title);
  }

  let rawDisplayDate = issues && issues[0] && issues[0].dateTime ?
    issues[0].dateTime :
    '05/17/2022 10:10:10';

  // const displayDate = format(new Date(rawDisplayDate), 'MM/dd/yyyy HH:mm:ss');
  const displayDate = rawDisplayDate; // TODO: pick a display style for dates & times!

  console.log('~~~~~ UserAssessment() - isMobile is ' + isMobile);

  return (
    <div style={{ width: "80%", padding: "0px", margin: "0px", marginTop: "20px" }}>
      {loaded ? '' : <LoadingDivLayer style={{ width: "95%" }} />}
      <ContentWrapper className="wrapper" loaded={loaded} style={{ padding: "0px" }}>
        <BackLink onClick={onBackLinkClick} style={{ padding: "0px", margin: "0px 0px 0px 15px", fontSize: "smaller" }}>&#8592; Back to Assessments List</BackLink>
        <div className="TitleAndToplineScoresDiv" style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "30px" }}>
          <h5 style={{ textAlign: "center" }}>Assessment Performed {displayDate}</h5>
          <TopLineScores isMobile={isMobile} data={scores} shell={true} style={{ display: "flex", flexDirection: "row", justifyContent: "center" }} />
          <div className="DomainsTabButtonDiv" style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", paddingTop: "20px" }}>
            <ShellTabButton isMobile={isMobile} tabTitle={"mobility"} onClick={() => setActiveTab("mobility")} currentTab={currentTab}>MOBILITY</ShellTabButton>
            <ShellTabButton isMobile={isMobile} tabTitle={"stability"} onClick={() => setActiveTab("stability")} currentTab={currentTab}>{isStretchLab ? "ACTIVATION" : "STABILITY"}</ShellTabButton>
            <ShellTabButton isMobile={isMobile} tabTitle={"posture"} onClick={() => setActiveTab("posture")} currentTab={currentTab}>POSTURE</ShellTabButton>
            <ShellTabButton isMobile={isMobile} tabTitle={"symmetry"} onClick={() => setActiveTab("symmetry")} currentTab={currentTab}>SYMMETRY</ShellTabButton>
          </div>
        </div>
        <BodymapAndIssuesDiv>
          <BodyMap isMobile={isMobile} data={issues} domain={currentTab} maxIssues={3} style={{ cursor: "auto", width: "40%", margin: "0px" }} />
          <ShellPrimaryIssuesTable isMobile={isMobile} data={issues} maxIssues={3} domain={currentTab} style={{ cursor: "auto", width: "40%" }} />
        </BodymapAndIssuesDiv>
        
        <ShellSubdomainScoresTable isMobile={isMobile} data={scores} domain={currentTab} />
        <EZSpacer height={"2vw"} />
      </ContentWrapper>
    </div>
  );
}

export default UserAssessment;