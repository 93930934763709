import { useSortable, defaultAnimateLayoutChanges } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Card from 'react-bootstrap/Card';
import { RedSpan, Thumbnail, EditIcon, AreaTitle, ScrollingListContainer, ListContentRow, ListContentBox } from './title';
import { iconSrc, colors } from '../../constants';

export const SortableItem = ({
  id, item, onRemoveClick
})  => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id: id
  });

  const style = {
    cursor: "grab",
    transition,
    transform: CSS.Transform.toString(transform)
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="item" id={"outerDiv_" + item.exercise_id_guid}>
      <Card body className="m-2" id={item.exercise_id_guid} key={item.note_string} style={{ width: "90%", height: "80px" }}>
        <div className="ExerciseMetaDiv" style={{ width: "65%", minHeight: "8vh", textAlign: "left", display: "inline-block", marginLeft: "-10px" }}>
          <b>{item.name_string}</b><br />
          {/* <span style={{ fontSize: "smaller" }}>{item.note_string}</span> */}
        </div>
        <div className="ExerciseThumbnailDiv" data-id="tnail" style={{ width: "30%", height: "70px", display: "inline-block" }}>
          <Thumbnail src={item.thumbnail_url_string ? item.thumbnail_url_string : item.movement_thumbnail_url_string}  style={{ height: "70px", marginTop: "-10px", marginBottom: "-20px" }}/>
        </div>
        <div style={{ display: "inline-block", textAlign: "right" }} >
          <EditIcon src={iconSrc.remove} title="Delete this Exercise from the Workout" onClick={(e) => {onRemoveClick(item.indexInt)}} />
        </div>
      </Card>
    </div>
  )
}
