// components/styled/OrderedItemList.js

import React, { useState, useMemo } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { SortableItem } from './SortableItem';
import { AreaTitle } from './title';


const OrderedItemList = ({
  array, updateArray, onRemoveClick, disabled
}) => {
  console.log('~~~~~ OrderedItemList() - array.length is *' + array.length + '*');
  const [items, setItems] = useState(array.map((ex, index) => ({ ...ex, indexInt: index + 1 })));
  const itemIds = useMemo(() => items.map((item) => item.indexInt), [items]); // itemIds is an array of Ints
  const exerciseArray = items.map(({indexInt, ...keepAttrs}) => keepAttrs); // exerciseArray loses indexInt attribute
  let orderedArray = []
  let outputArray = [];

  console.log('~~~~~ OrderedItemList() - items.length is *' + items.length + '*');
  console.log(items);
  console.log('~~~~~ OrderedItemList() - itemIds.length is *' + itemIds.length + '*');
  console.log(itemIds);
  console.log('~~~~~ OrderedItemList() - exerciseArray.length is *' + exerciseArray.length + '*');
  console.log(exerciseArray);

  const active = true; // TODO: remove this and the reference in the div on line 66

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  
  const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } }); 
  const sensors = useSensors(pointerSensor);
  
  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div style={{ display: active ? "block" : "none", width: "100%", textAlign: "center" }}>
        <AreaTitle style={{ display: "inline-block", fontSize: "smaller", marginTop: "-30px" }}>
          Drag Exercises to change their order.
        </AreaTitle>
        <SortableContext
          items={itemIds}
          strategy={verticalListSortingStrategy}
        >
          {items.map(item => <SortableItem key={(item.id_guid ? item.id_guid : item.exercise_id_guid ? item.exercise_id_guid : item.workout_exercise_id_guid).toString() + '_' + uuidv4()} id={item.indexInt} item={item} onRemoveClick={onRemoveClick} /> )}
        </SortableContext>
      </div>
    </DndContext>
  );

  function handleDragEnd(event) {
    const eCTgt = event.activatorEvent.srcElement; // this element is a div child of <Card />
    const cardTgtId = event.activatorEvent.srcElement.parentElement.id; // this returns the id of the <Card /> element
    
    const {active, over} = event;

    if (disabled){
      alert('This record is Public, and cannot be edited here');
      return;
    }
    if (active.id === over.id) {
      return;
    }
    setItems((items) => {
      const oldIndex = items.findIndex((item) => item.indexInt === active.id);
      const newIndex = items.findIndex((item) => item.indexInt === over.id);
      orderedArray = arrayMove( items, oldIndex, newIndex);
      return orderedArray;
    });

    setTimeout(() => {
      console.log('~~~~~ OrderedItemList() - after drag, orderedArray[0].name_string is *' + orderedArray[0].name_string + '*, and orderedArray[0].indexInt is *' + orderedArray[0].indexInt + '*');
      console.log('~~~~~ OrderedItemList() - orderedArray.length is *' + orderedArray.length + '*');
      console.log(orderedArray);
      outputArray = orderedArray.map(({indexInt, ...keepAttrs}) => keepAttrs); // orderedArray loses indexInt attribute
      console.log(outputArray);
      updateArray(outputArray);
    }, 500);
  }

}

export default OrderedItemList;