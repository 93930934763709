// UserReadinessSummary.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { iconSrc } from '../../constants';
// import { 
  // colors,
  // logoSrc,
  // iconSrc,
  // northsideLogoSrc,
  // overviewSrc,
  // athletesSrc,
  // groupsSrc,
  // programsSrc,
  // exercisesSrc,
  // movementsSrc
//  } from '../../constants';
 import { 
  ExploreHeaderCell,
  ExploreIcon,
  ExploreTableCell,
  ExploreTableHeader
} from './exploreStuff';
 import ExploreScoreCircle from './ExploreScoreCircle';


const FakeUserReadinessSummaryWrapper = styled.div`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  white-space: nowrap;
  width: 100%;
  height: 180px;
  padding: 0px;
  /* padding-top: 10px; */
  margin: auto;
  margin-top: -10px;
  margin-bottom: -20px;
  text-align: center;
  vertical-align: middle;
  /* background: url("Shell_assets/fakes/readiness.png");
  background-size: cover; */
  /* background: red; */
`;

/*
background: url("Shell_assets/fakes/explore_searchbar.png");// fake <ExploreBar> implemented in <Explore> & looks good
background: url("Shell_assets/fakes/group_create.png");     // fake <GroupCreate> implemented in <Shell> & looks good
background: url("Shell_assets/fakes/group_edit.png");       // fake <GroupEdit> implemented in <Shell> & looks good
background: url("Shell_assets/fakes/injury_bodymap.png");   //
background: url("Shell_assets/fakes/readiness.png");        // fake <UserReadinessSummary> implemented in <UserSection> & looks good
background: url("Shell_assets/fakes/soreness_bodymap.png"); //
background: url("Shell_assets/fakes/user.png");             // not really needed...
background: url("Shell_assets/fakes/user_accept.png");      // fake <UserAccept> implemented in <UserSection> & looks good
background: url("Shell_assets/fakes/user_invite.png");      // fake <UserInvite> implemented in <Shell> & looks good
*/


const UserReadinessSummary = ({
  // user is userObj for current user
  user
}) => {
  const email = user && user.email_string && user.email_string.length > 0 ? user.email_string : "no user email :-(";

  console.log('~~~~~ UserReadinessSummary() - for user *' + email + '*');

  const lastScore = user.readiness.lastScore;
  const lastScoreDelta = user.readiness.lastScoreDelta;
  const lastScoreColor = user.readiness.lastScoreColor;

  const readinessScore = user.readiness.readinessScore;
  const readinessColor = user.readiness.readinessColor;

  const sleepScore = user.readiness.sleepScore;
  const sleepColor = user.readiness.sleepColor;

  const hydrationScore = user.readiness.hydrationScore;
  const hydrationColor = user.readiness.hydrationColor;

  const stressScore = user.readiness.stressScore;
  const stressColor = user.readiness.stressColor;

  const sorenessScore = user.readiness.sorenessScore;
  const sorenessColor = user.readiness.sorenessColor;

  const injuryScore = user.readiness.injuryScore;
  const injuryColor = user.readiness.injuryColor;

  const complianceScore = user.readiness.complianceScore;
  const complianceColor = user.readiness.complianceColor;

  const diameter = "30px";
  // const userIdTemp = user.id_guid ? user.id_guid : user.user_id_guid; // until Nathan puts id_guid back as an attribute of user in inverse_boolean results


  return (

    <FakeUserReadinessSummaryWrapper>
      <div style={{ textAlign: "center", paddingTop: "15px", width: "65%", margin: "auto" }}>
        <table style={{ textAlign: "center", paddingTop: "20px", width: "80%", margin: "auto" }}>
          <ExploreTableHeader>
          <tr>
            <ExploreHeaderCell width="3%" style={{ borderRadius: "15px 0 0 15px" }}></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" align="center" title="Assessment - most recent score"><ExploreIcon src={iconSrc.assessment} /></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" align="center" title="Estimated Readiness"><ExploreIcon src={iconSrc.readiness} /></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" style={{ textAlign: "left" }} title="Sleep Quality"><ExploreIcon src={iconSrc.sleep} /></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" style={{ textAlign: "left" }} title="Hydration Level"><ExploreIcon src={iconSrc.hydration} /></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" style={{ textAlign: "left" }} title="Stress Level"><ExploreIcon src={iconSrc.stress} style={{ height: "34px", width: "34px" }} /></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" style={{ textAlign: "left" }} title="Soreness"><ExploreIcon src={iconSrc.soreness} style={{ height: "30px", width: "30px", marginLeft: "-10px" }} /></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" align="center" style={{ paddingRight: "9px" }} title="Injury"><ExploreIcon src={iconSrc.injury} style={{ height: "21px", width: "21px" }} /></ExploreHeaderCell>
            <ExploreHeaderCell width="9%" style={{ borderRadius: "0 15px 15px 0", textAlign: "left" }} title="Workout Compliance"><ExploreIcon src={iconSrc.compliance} /></ExploreHeaderCell>
          </tr>
          </ExploreTableHeader>
          <tbody>
            <tr><td colSpan="9" style={{ height: "10px" }}></td></tr>
            <tr>
              <td></td>
              <ExploreTableCell title="Most recent Assessment Score" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={lastScore} delta={lastScoreDelta} color={lastScoreColor} />
              </ExploreTableCell>
              <ExploreTableCell title="Most recent self-reported readiness Score, 5 is the best" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={readinessScore} color={readinessColor} />
              </ExploreTableCell>
              <ExploreTableCell title="Most recent self-reported sleep Score, 5 is the best" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={sleepScore} color={sleepColor} />
              </ExploreTableCell>
              <ExploreTableCell title="Most recent self-reported hydration Score, 5 is the best" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={hydrationScore} color={hydrationColor} />
              </ExploreTableCell>
              <ExploreTableCell title="Most recent self-reported stress Score, 5 is the worst" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={stressScore} color={stressColor} />
              </ExploreTableCell>
              <ExploreTableCell title="Most recent self-reported soreness Score, 5 is the worst" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={sorenessScore} color={sorenessColor} />
              </ExploreTableCell>
              <ExploreTableCell title="Most recent self-reported injury Score, 5 is the worst" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={injuryScore} color={injuryColor} />
              </ExploreTableCell>
              <ExploreTableCell title="Tabulated self-reported workout compliance Score, 5 is the best" style={{ border: "none" }}>
                <ExploreScoreCircle diameter={diameter} score={complianceScore} color={complianceColor} />
              </ExploreTableCell>
            </tr>
          </tbody>
        </table>

      </div>
    </FakeUserReadinessSummaryWrapper>

  );

}

export default UserReadinessSummary;