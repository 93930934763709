// Members.js // TODO: re-factor this to be Athletes.js

import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import SiteButton, { SiteDropdownButton } from './styled/SiteButton';

import { addUserSrc } from '../constants';

import '../App.css'
import { getKioskStats, addMemberLink, editMemberLinking, submitAthleteEmailList, getAthleteSignupLink, getGroups } from '../services';

import LoadingDivLayer  from './styled/LoadingDivLayer';
import BigStat from './styled/BigStat';
// import MembersTable from './styled/MembersTable';
import ItemsTable from './styled/ItemsTable';
import TypeAheadSearch from './styled/TypeAheadSearch';
import {
  PrettyInput,
  PrettyTextArea,
  PrettyInputLabel,
  Thumbnail
} from './styled/title';

import { Title, EZSpacer, ContentWrapper } from './styled/title';

function timeout(delay) { // TODO: put this in a file in /helpers, and import it into this file
  return new Promise( res => setTimeout(res, delay) ); // use as await timeout(1000); //for 1 sec delay
}

function sortMembersByRecentScans(membersArray) {
  console.log('~~~~~ Members, sortMembersByRecentScans() - membersArray.length is ' + membersArray.length);
  membersArray.sort(function(a,b){
    return b.lastAssessmentDate - a.lastAssessmentDate;
  });

  return membersArray;
}

function addMember(membersArray, memberName, memberEmail) {
  // this would add a new member based on info returned by the addMemberLinkage service call
  // initially we do not use this call because we are simply reloading the athletes page...
  
  membersArray.sort(function(a,b){
    return b.status - a.status;
  });

  return membersArray;
}

function Members() {
  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [memberStats, setMemberStats] = useState([]);
  const [stats, setKioskStats] = useState([]);
  const [loaded, setLoaded] = useState(false);
  
  const defaultFilterName = "All Athletes"; // get this from /models/[type] ?? athlete, in this case
  const defaultFilterType = "group"; // get this from /models/[type] ?? athlete, in this case
  const [filter, setFilter] = useState(checkAndRestoreFilter());
  const [filterType, setFilterType] = useState(defaultFilterType); // TODO: do we need this? can we pass in a constant as prop for ItemsTable?
  const [filterId, setFilterId] = useState();
  
  const mounted = useRef(true);
  const navigate = useNavigate();
  const isCoach = localStorage.getItem('coach') === 'true' ? true : false; // TODO: have different routes? '/athletes' vs '/members'
  const [itemType, setItemType] = useState(isCoach ? "athlete" : "member"); // TODO: do we need this? can we pass in a constant as prop for ItemsTable?
  
  const [showInvite, setShowInvite] = useState(false);
  const [athleteName, setAthleteName] = useState();
  const [athleteEmail, setAthleteEmail] = useState();
  const [athleteEmailList, setAthleteEmailList] = useState();
  const [athleteId, setAthleteId] = useState();
  const [athleteGroupsListString, setAthleteGroupsListString] = useState();
  const [athleteProgramsListString, setAthleteProgramsListString] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showGroupsConfirm, setShowGroupsConfirm] = useState(false);
  const [showProgramsConfirm, setShowProgramsConfirm] = useState(false);
  const [showAcceptConfirm, setShowAcceptConfirm] = useState(false);
  const [showSignupLink, setShowSignupLink] = useState(false);
  const handleCloseSignupLink = () => setShowSignupLink(false);

  const [signupUrl, setSignupUrl] = useState("https://staging.dashboard.physmodo.net/signup");
  const [qrCodeSrc, setQrCodeSrc] = useState("StagingSignup.svg");

  let signupLink = "https://staging.dashboard.physmodo.net/signup";
  let signupQrCodeUrl = "StagingSignup.svg";
 

  const itemInfo = require('./../models/' + itemType).default; // { attributes, listColumns, displayFields, orderMade }

  const handleCreateLinkClick = () => {
    console.log('~~~~~ handleCreateLinkClick()');
    // code here to hit the API and get the custom link and the src for the QR code
    getAthleteSignupLink()
    .then(item => { // item is going to be what is returned from the service method...
      const link_url = item.url_string;
      const qr_code_src = item.url_qr_code_base64_image_string; 
      console.log('~~~~~ Members.js, handleCreateLinkClick() - link_url: ' + link_url);
      console.log('~~~~~ Members.js, handleCreateLinkClick() - qr_code_src: ' + qr_code_src);
      // signupLink = link_url;
      // signupQrCodeUrl = qr_code_src;
      setSignupUrl(link_url);
      setQrCodeSrc(qr_code_src);
      setShowSignupLink(true);
    })
    // setShowSignupLink(true);
  }
  
  const handleInviteClick = () => { 
    setShowInvite(true); 
    console.log('~~~~~ handleInviteClick()');
  }
  const handleCloseInvite = () => setShowInvite(false); // maybe null out the two input fields here...

  const handleEditClick = (event) => { 
    event.stopPropagation();
    setAthlete(event);
    
    setShowDeleteConfirm(true); 
  }
  const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

  const handleAcceptClick = (event) => {
    event.stopPropagation();
    setAthlete(event);

    setShowAcceptConfirm(true);
  }
  const handleCloseAcceptConfirm = () => setShowAcceptConfirm(false);
  
  const handleGroupsClick = (event, athleteId, athleteGroupsString) => { 
    event.stopPropagation();
    // console.log('~~~~~ Members.js, Members() - handleGroupsClick() - athleteId is ' + athleteId + ', and athleteGroupsString is ' + athleteGroupsString);
    console.log('~~~~~ Members.js, Members() - handleGroupsClick() - athleteId is ' + athleteId);

    if (event.target.parentElement.parentElement.children[1].textContent.includes('Pending')) { // TODO: change this?
      alert('Some Athlete data will not be viewable until both Athlete and Coach have accepted their association');
      return;
    }

    setAthleteGroupsListString(groupsFromId(athleteId));
    setAthlete(event);
    
    setShowGroupsConfirm(true); 
  }
  const handleCloseGroupsConfirm = () => setShowGroupsConfirm(false);

  const handleProgramsClick = (event, athleteId) => { 
    event.stopPropagation();
    // console.log('~~~~~ Members.js, Members() - handleProgramsClick() - athleteId is ' + athleteId + ', and athleteProgramsString is ' + athleteProgramsString);
    console.log('~~~~~ Members.js, Members() - handleProgramsClick() - athleteId is ' + athleteId);

    if (event.target.parentElement.parentElement.children[2].textContent.includes('Pending')) {
      alert('Some Athlete data will not be viewable until both Athlete and Coach have accepted their association');
      return;
    }

    // setAthleteProgramsListString(athleteProgramsString);
    // setAthleteProgramsListString("we are re-factoring the athleteProgramsString process");
    setAthleteProgramsListString(programsFromId(athleteId));
    setAthlete(event);
    
    setShowProgramsConfirm(true); 
  }
  const handleCloseProgramsConfirm = () => setShowProgramsConfirm(false);

  let updatedFilteringInfo = false;
  
  checkAndRestoreFilteringInfo(updatedFilteringInfo);

  useEffect(() => {
    if(stats.thisWeekAssessmentsCount) {
      return;
    }
    getKioskStats()
    .then(item => {
      if(mounted.current) { // TODO: how is this useful?
        setKioskStats(item);
        setTimeout(function() {
          setLoaded(true); // arbitrary choice of this api call as longest
        }, 1500);
        
      }
    })
  }, [stats])

  let fetchingNow = false;
  useEffect(() => {
    if(groups.length > 0 || fetchingNow || !isCoach) {
      return;
    }
    fetchingNow = true;
    mounted.current = true; // TODO: how is this useful?

    getGroups()
    .then(itemsResp => {
      if(mounted.current) {
        let items = itemsResp.data;
        setGroups(items);
        // setLoaded(true); // arbitrary choice of this api call as longest
        // alert('~~~~~ Members.js - useEffect() - ' + items.length + ' Groups were returned');
        console.log('~~~~~ Members.js - useEffect() - ' + items.length + ' Groups were returned');
      }
    })
  }, [groups])

  function updateFilterName(filter) {
    console.log('~~~~~ Members.js, Members() - updateFilterName() - filter is ' + filter);
    localStorage.setItem('athleteFilter', filter);
    setFilter(filter);
  }

  function updateFilterType(filterType) {
    console.log('~~~~~ Members.js, Members() - updateFilterType() - filterType is ' + filterType);
    localStorage.setItem('athleteFilterType', filterType);
    setFilterType(filterType);
  }

  function updateFilterId(filterId) {
    console.log('~~~~~ Members.js, Members() - updateFilterId() - filterId is ' + filterId);
    localStorage.setItem('athleteFilterId', filterId);
    setFilterId(filterId);
  }

  function checkAndRestoreFilter() {
    console.log('~~~~~ Members.js, Members() - checkAndRestoreFilter() - localStorage.getItem("athleteFilter") is ' + localStorage.getItem("athleteFilter"));

    const lsFilter = localStorage.getItem("athleteFilter");
    if (lsFilter && lsFilter.length > 0) {
      return lsFilter
    } else { 
      return defaultFilterName;
    }
  }

  function checkAndRestoreFilterType() {
    console.log('~~~~~ Members.js, Members() - checkAndRestoreFilterType() - localStorage.getItem("athleteFilterType") is ' + localStorage.getItem("athleteFilterType"));

    const lsFilterType = localStorage.getItem("athleteFilterType");
    
    if (lsFilterType && lsFilterType.length > 0 && lsFilterType !== filterType) {
      setFilterType(lsFilterType);
    }
  }

  function checkAndRestoreFilterId() {
    console.log('~~~~~ Members.js, Members() - checkAndRestoreFilterId() - localStorage.getItem("athleteFilterId") is ' + localStorage.getItem("athleteFilterId"));

    const lsFilterId = localStorage.getItem("athleteFilterId");
    let filterIdToSet = '';
    
    if (lsFilterId && lsFilterId.length > 10) { // only set the state value if the ls value is different
      // how to ensure that a value that is not a valid guid, like "not-a-guid", does not get set, and an empty string gets set
       // in here we know that the lsFilter value is different from the State value
       // if the lsFilter value is a valid guid, we should use it
       // if the lsFilter value is empty, we know that the last filter action was to remove filtering
       // the issue is what to do if the filterId was set to "all" by the DropDownFilter, since that should be saved as "" (empty)
      
      // when should we set filterId to the value in lsFilterId?
      // - if we do it on every render, there will always be another render
      // - so, when do we need it?
      // - we need it when the lsValue exists, and is different than the value in State at the time of the render...
      // - in the case of filterId, specifically, we should consider "all" and "" equivalent (no filtering)

      if (lsFilterId !== filterId) {
        console.log('~~~~~ Members.js, Members() - checkAndRestoreFilterId() - lsFilterId !== filterId');

        setFilterId(lsFilterId); // now we pray the render does not happen so fast we loop because setFilterId is not fast enough
        // otherwise maybe we put something like alreadyDone in a timing loop, or in localStorage...
      }
    }
  }

  function checkAndRestoreFilteringInfo(alreadyDone) {
    console.log('~~~~~ Members.js, Members() - checkAndRestoreFilteringInfo()');

    if (alreadyDone) {
      return;
    }

    checkAndRestoreFilter();
    checkAndRestoreFilterType();
    checkAndRestoreFilterId();

    updatedFilteringInfo = true; // FAIL, because it is set to false on every render, and the state updates cause renders...
  }

  function updateStats(memberStats) {
    console.log('~~~~~ Members.js, updateStats() - memberStats.totalUsers is ' + memberStats.totalUsers);
    setMemberStats(memberStats);
  }

  function updateItems(itemsArray) {
    console.log('~~~~~ Members.js, updateItems() - items.length is ' + itemsArray.length);
    setItems(itemsArray);
  }

  function setAthlete(event) { // this assumes the row structure of the MembersTable, with the row element id being the user.id_guid
    console.log('~~~~~ Members.js, setAthlete()');
    let athlete_name = event.target.parentElement.parentElement.children[0].textContent; // is now name or email
    const athlete_email = event.target.parentElement.parentElement.children[0].title;
    // const athlete_email = event.target.parentElement.parentElement.children[1].textContent;
    const athlete_id = event.target.parentElement.parentElement.id;

    if (athlete_name.length <= 5) { athlete_name = athlete_email }

    console.log('~~~~~ Members.js, setAthlete() - athlete_name is *' + athlete_name + '*');
    console.log('~~~~~ Members.js, setAthlete() - athlete_email is *' + athlete_email + '*');
    console.log('~~~~~ Members.js, setAthlete() - athlete_id is *' + athlete_id + '*');
    setAthleteName(athlete_name); 
    setAthleteEmail(athlete_email);
    setAthleteId(athlete_id);
  }

  function athleteObjFromId(id_guid) {

    console.log("~~~~~ Members.js, athleteObjFromId() - id_guid = *" + id_guid + "*");
    
    const matchingObjsArray = items.filter((athlete) => {
      return athlete.id_guid === id_guid;
    });
  
    return matchingObjsArray[0];
  }

  function groupsFromId(id_guid) {  
    console.log("~~~~~ Members.js, Members() - groupsFromId() - id_guid = *" + id_guid + "*");
    
    const matchingObjsArray = items.filter((athlete) => {
      return athlete.id_guid === id_guid;
    });

    let groups_string = "No Groups yet!";
    if (matchingObjsArray[0] && matchingObjsArray[0].group_list_string && matchingObjsArray[0].group_list_string.length > 0) {
      groups_string = matchingObjsArray[0].group_list_string;
    }
  
    return groups_string;
  }

  function programsFromId(id_guid) {  
    console.log("~~~~~ Members.js, Members() - programsFromId() - id_guid = *" + id_guid + "*");
    
    const matchingObjsArray = items.filter((athlete) => {
      return athlete.id_guid === id_guid;
    });

    let programs_string = "No Workouts yet!";
    if (matchingObjsArray[0] && matchingObjsArray[0].program_list_string && matchingObjsArray[0].program_list_string.length > 0) {
      programs_string = matchingObjsArray[0].program_list_string;
    }

    return programs_string;
  }

  function handleItemClick(event, itemid) {
    if (event.target.parentElement.children[2].textContent.includes('Pending')) {
      alert('Some Athlete data will not be viewable until both Athlete and Coach have accepted their association');
      return;
    }
    navigate('/history?from=Members&memberid=' + itemid);
  }

  function handleAcceptConfirmClick(event, itemid) {
    console.log('~~~~~ Members.js, Members() - handleAcceptClick(), itemid = *' + itemid + '*');
    handleCloseAcceptConfirm();

      addMemberLink(athleteEmail, athleteName) // should return item.status where 200 = edit worked, 422 = bad request
      .then(resp => { // resp is going to be what is returned from the service method... (response from fetch())
        console.log('~~~~~ Member.js, Members() - handleAcceptConfirmClick(), addMemberLink() - resp.status: ' + resp.status);
        let alertMsg = '';

        setTimeout(function() {
          window.location.reload();
          if (alertMsg !== '') { alert(alertMsg); }
        }, 500);
    })
  }

  function handleDeleteConfirmClick(event) {
    removeAthlete(athleteId);
    handleCloseDeleteConfirm();
  }

  function handleGroupsConfirmClick(event) {
    navigate('/groups?from=Members&athleteId=' + athleteId + '&athleteEmail=' + athleteEmail + '&athleteName=' + athleteName);
    handleCloseGroupsConfirm();
  }

  function handleProgramsConfirmClick(event) {
    navigate('/programs?from=Members&athleteId=' + athleteId + '&athleteEmail=' + athleteEmail + '&athleteName=' + athleteName);
    handleCloseProgramsConfirm();
  }

  function handleInviteSubmit(event) {
    console.log('~~~~~ Members.js - handleInviteSubmit(() - ' + athleteEmailList);
    handleCloseInvite();
    
    submitAthleteEmailList(athleteEmailList)
    .then(item => { // item is going to be what is returned from the service method... 
      console.log('~~~~~ Members.js, handleInviteSubmit() - item: ' + item);
      const obj_str = JSON.stringify(item);
      console.log('~~~~~ Members.js, handleInviteSubmit() - obj_str: ' + obj_str);
 
      setTimeout(function() {
        window.location.reload();
      }, 500);
    })
  }

  function removeAthlete(athleteId) {
    
    console.log('removing the athlete with id_guid ' + athleteId);

    const someLinkageToken = "not-really-alinkage-token";
    const someCoachId = "not-really-a-coach-id"

    editMemberLinking(athleteId) // should return item.status where 404 = delete worked, 200 = edit worked, 422 = bad request
      .then(resp => { // item is going to be what is returned from the service method... 
        console.log('~~~~~ resp.status: ' + resp.status);
        let alertMsg = '';

        const newMembersArray = items.filter(athlete => athlete.id_guid !== athleteId);
        // alertMsg = 'Linkage to Athlete with id_guid ' + athleteId + ' has been removed, or Invitation canceled';
        if (items.length - newMembersArray.length === 0) {
          alertMsg = 'no athletes were removed!';
        }
        // setMembers(newMembersArray); // Not needed due to reload() below

        setTimeout(function() {
          window.location.reload();
          if (alertMsg !== '') { alert(alertMsg); }
        }, 500);
    })
  }

  let returnedItems = [];

  function handleFilterChoice(event) {
    console.log('~~~~~ Members, handleFilterChoice()');
    let filter_guid = event.target.id;
    const filter_name = event.target.textContent;
    
    console.log('~~~~~ Members, handleFilterChoice() - filter_guid is *' + filter_guid + '*, and filter_name is *' + filter_name +'*');

    // setFilter(filter_name);
    updateFilterName(filter_name); // this function updates State and also localStorge

    if (filter_name === "all") {
      filter_guid = '';
      console.log('~~~~~ Members, handleFilterChoice() - filter name is all and filter_guid is ' + filter_guid);
    }

    // setFilterId(filter_guid);
    updateFilterId(filter_guid); // this function updates State and also localStorge
  }

  const columnClick = {
    groupsLink: handleGroupsClick,
    programsLink: handleProgramsClick,
    editLink: handleEditClick,
    lastAssessmentDate: handleAcceptClick
  }

  const handleEvents = { // TODO: imagine how this will work internally when different tables have different events to handle
    itemClick: handleItemClick, // maybe include some sort of mapping in models/[type] ?
    columnClick: columnClick,
    itemsUpdate: updateItems,
    statsUpdate: updateStats
  }

  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper className="wrapper" loaded={loaded}>
        <BigStat 
          value={memberStats.totalUsers} 
          title={isCoach?'Athletes':'Members'}>
        </BigStat>
        <BigStat 
          value={memberStats.activeUsers} 
          title={isCoach?'Active Athletes':'Active Members'}
          subtitle='last 10 days'>
        </BigStat>
        <BigStat 
          value={stats.thisWeekAssessmentsCount} 
          title='Assessments This Week'> 
        </BigStat>
        
        <EZSpacer height={"5vh"} />

        <table>
          <tbody>
            <tr>
              <td><TypeAheadSearch data={items} style={{flexBasis: "auto"}} /></td> 
              <td style={{paddingLeft: "40px", paddingBottom: "15px", verticalAlign: "top" }}>
                { isCoach && 
                  <SiteButton icon={addUserSrc} title='Invite Athletes' onClick={handleInviteClick} />
                }
              </td>
              <td style={{paddingLeft: "40px", paddingBottom: "15px", verticalAlign: "top" }}>
                { isCoach && 
                  <SiteButton icon={addUserSrc} title='Generate Signup Link' onClick={handleCreateLinkClick} />
                }
              </td>
            </tr>
          </tbody>
        </table>
        <EZSpacer height={"4vh"} /> 
        
        <Title style={{ display: "inline-block", marginRight: "2.5vw" }}>{isCoach?'Athlete':'Member'} List By Most Recent Activity</Title> 

        <SiteDropdownButton id="dropdown-basic-button" title={"Filtering by " + filter} style={{ display: isCoach ? "inline-block" : "none" }} >
          <Dropdown.Item as="button" id="all" onClick={handleFilterChoice}>All Athletes</Dropdown.Item>
          {groups.map((group) => {
              return (
                <Dropdown.Item as="button" id={group.id_guid} onClick={handleFilterChoice}>{group.name_string}</Dropdown.Item>
              );
            })
          }
        </SiteDropdownButton>

        <EZSpacer height={"2vh"} /> 
        
        <ItemsTable 
          itemType={itemType}
          filterType={filterType}
          filterId={filterId}
          handleEvents={handleEvents}
          modelInfo={itemInfo}
        />

        <Modal
          show={showInvite}
          onHide={handleCloseInvite}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title style={{ paddingLeft: "30px", opacity: "0.6" }}>Invite Athletes</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "180px", paddingTop: "20px" }}>
            <PrettyInputLabel style={{ width: "400px", marginBottom: "0px", paddingLeft: "30px" }}>
              <PrettyTextArea type="textarea" placeholder="Paste or type a list of one or more Athlete emails, separated by commas or returns" style={{ width: "400px", height: "160px", fontSize: "16px" }} onBlur={e => setAthleteEmailList(e.target.value)} />
            </PrettyInputLabel>
          </Modal.Body>
          <Modal.Footer style={{borderTop: "none"}}>
            <Button variant="primary" style={{ width: "200px", height: "50px", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleInviteSubmit}>Invite Athletes</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showDeleteConfirm}
          onHide={handleCloseDeleteConfirm}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px" }}>
            <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Remove Linkage to  <br />{athleteName}?<br /><span style={{ fontSize: "smaller", opacity: "0.6" }} >{athleteEmail}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: "0.7" }}>
            This will remove the Linkage to view {athleteName}'s assessments, if they have accepted an invite, or will revoke the invite if not yet accepted.  {athleteName} will no longer appear in any of your lists.
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="primary" style={{ width: "20vw", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleDeleteConfirmClick}>Remove Linkage</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showGroupsConfirm}
          onHide={handleCloseGroupsConfirm}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px" }}>
            <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Edit Groups for <br />{athleteName}?<br /><span style={{ fontSize: "smaller", opacity: "0.6" }} >{athleteEmail}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: "0.7" }}>
          {athleteName} is currently included in the following Groups: <b>{athleteGroupsListString}</b>.  To change the Groups {athleteName} belongs to, click the Change Groups button below.
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="primary" style={{ width: "20vw", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleGroupsConfirmClick}>Change Groups</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showProgramsConfirm}
          onHide={handleCloseProgramsConfirm}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px" }}>
            <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Edit Workouts for <br />{athleteName}?<br /><span style={{ fontSize: "smaller", opacity: "0.6" }} >{athleteEmail}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: "0.7" }}>
          {athleteName} is currently assigned the following Workouts: <b>{athleteProgramsListString}</b>.  To change the Workouts assigned to {athleteName}, click the Change Workouts button below.
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="primary" style={{ width: "20vw", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleProgramsConfirmClick}>Change Workouts</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showAcceptConfirm}
          onHide={handleCloseAcceptConfirm}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px" }}>
            <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Accept Association with <br />{athleteName}?<br /><span style={{ fontSize: "smaller", opacity: "0.6" }} >{athleteEmail}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: "0.7" }}>
          If you Accept {athleteName} , they will appear in your Athletes list, and you will be able to view their Physmodo Assessment history, as well as assign them to Groups, or assign them individual Workouts.
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="primary" style={{ width: "20vw", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleAcceptConfirmClick}>Accept Association</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showSignupLink}
          onHide={handleCloseSignupLink}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px" }}>
            <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Athlete SignUp Link<br /><span style={{ fontSize: "smaller", opacity: "0.6" }} >{athleteEmail}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: "0.7", marginTop: "-20px" }}>
          <div style={{ textAlign: "center" }} >  
              {signupUrl}
              <br />
            
              <Thumbnail src={qrCodeSrc} style={{ width: "150px", height: "150px", marginTop: "20px" }}/>
            </div>
          </Modal.Body>

        </Modal>
      </ContentWrapper>
    </div>
  );
}

export default Members;