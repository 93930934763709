// MovementList.js

import React from 'react';
import { useState, useRef, useEffect } from "react";

import styled from 'styled-components';
import { RedSpan, Thumbnail, AreaTitle, ScrollingListContainer, ListContentRow, ListContentBox } from './title';
// import { iconSrc, colors } from '../../constants';

import { getAthletes, getGroups, getMovements, getExercises, getPrograms } from '../../services'; // TODO: improve???
// import { getItems } from '../../services';


const TableBody = ({ 
  tableData, 
  onRowClick, 
  loading
}) => {

  const noItemsMsg = "You have no Exercises yet...";
  let rowIndex = 0;
  
  return (
    <>
      <div style={{ display: loading ? "flex" : "none", flexDirection: "row" }}>
        <div style={{ width: "100%", padding: "20px" }}>
          <RedSpan>Loading Exercises...</RedSpan>
        </div>
      </div>
      <div style={{ display: (!loading  && tableData && tableData.length === 0) ? "flex" : "none", flexDirection: "row" }}>
        <div style={{ width: "100%", padding: "20px" }}>
          <RedSpan>{noItemsMsg}</RedSpan>
        </div>
      </div>
      <ScrollingListContainer>
      {tableData.map((data) => {
        const id_guid = data.id_guid;
        rowIndex++;
        console.log('~~~~~ MovementList.js, TableBody() - data.id_guid is *' + id_guid + '* and rowIndex is *' + rowIndex + '*');
        // TODO: give outer div below a maxHeight and have overflow scroll vertically if required...
        return(
          <ListContentRow style={{ display: loading ? "none" : "flex" }} key={id_guid + '_' + rowIndex} id={id_guid + '_' + rowIndex} onClick={(e, id_guid) => onRowClick(e, id_guid)}>
            <ListContentBox>
              <div style={{ width: "65%", minHeight: "8vh", textAlign: "left" }}>
                Movement Name: <b>{data.title_string}</b><br />
                Assigned By: <b>Physmodo Assessment Results</b><br />
              </div>
              <div style={{ width: "25%", minHeight: "8vh" }}>
                <Thumbnail src={data.thumbnail_url_string}  style={{ height: "80px", marginTop: "0px" }}/>
              </div>
            </ListContentBox>
          </ListContentRow>
        )
      })}
      </ScrollingListContainer>
    </>
  );
};

const fakeRecommendedArray = [
  {
		id_guid: "4426afe4-79c1-49f7-bad5-ec088dc7957d",
		created_datetime: "2023-06-19T00:27:37.422Z",
		updated_datetime: "2023-06-19T00:27:37.422Z",
		user_id_guid: "aba773bb-2b97-4dab-b7f2-77a45966a810",
		video_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWms9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5eb0142c287c2cc91c6b3a43acf26c81a78a09eb/90.mp4",
		thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdVFCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ff340b19c55d84a40560eaefd9b601cb19bd1e38/Shinbox%20Reach.png",
		title_string: "Shinbox Reach",
		subtitle_string: null,
		public_boolean: true
	},
	{
		id_guid: "65a7a016-0868-496d-adda-77cfe9a2661b",
		created_datetime: "2023-06-19T00:27:37.422Z",
		updated_datetime: "2023-06-19T00:27:37.422Z",
		user_id_guid: "aba773bb-2b97-4dab-b7f2-77a45966a810",
		video_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBQQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ced900de77d1499c90298cff1679b0908a01ff1e/153.mp4",
		thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--54e5caac5bec509e5184eba51830b04a7bb2f5a9/Pretzel.png",
		title_string: "Pretzel",
		subtitle_string: null,
		public_boolean: true
	},
	{
		id_guid: "2ea95311-a418-4541-93ec-3260bdafe9ad",
		created_datetime: "2023-06-19T00:27:37.422Z",
		updated_datetime: "2023-06-19T00:27:37.422Z",
		user_id_guid: "aba773bb-2b97-4dab-b7f2-77a45966a810",
		video_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBHQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f5d7cf89851e5e550c9d651532d8f8a4e17bdf6e/291.mp4",
		thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbVFCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4c13d29015c58f065467554c895eafd5aaebdbec/Happy%20Baby.png",
		title_string: "Happy Baby",
		subtitle_string: null,
		public_boolean: true
	},
	{
		id_guid: "75b057b7-668b-4a1a-8df3-bc3c8286862c",
		title_string: "Lateral Lunge",
		video_link_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBIUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f1573ae69c4cec030f0af9037673eeef6a00ba80/32.mp4",
		image_link_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbWdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3e5004ad7bd9cc4cadb75cc1def967ee0aa0d8f0/Lateral%20Lunge.png",
		description_text: "Reason: Hip Flexion Mobility ",
		video_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBIUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f1573ae69c4cec030f0af9037673eeef6a00ba80/32.mp4",
		thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbWdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3e5004ad7bd9cc4cadb75cc1def967ee0aa0d8f0/Lateral%20Lunge.png",
		subtitle_string: "Reason: Hip Flexion Mobility "
	},
	{
		id_guid: "785e7498-fe87-4812-8b0e-e75376aeba93",
		created_datetime: "2023-06-19T00:27:37.422Z",
		updated_datetime: "2023-06-19T00:27:37.422Z",
		user_id_guid: "aba773bb-2b97-4dab-b7f2-77a45966a810",
		video_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWkU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a0f30563f11f637d96de3c5499b3c0e8ab841c75/92.mp4",
		thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1982b781054d48e3b9b94b99b04b255e53b9b665/Pike%20Toe%20Touch.png",
		title_string: "Pike Toe Touch",
		subtitle_string: null,
		public_boolean: true
	}
];


function MovementList(props) {

  const [items, setItems] = useState([]);
  const [tableData, setTableData] = useState(fakeRecommendedArray);
  const [loading, setLoading] = useState(false);
  const [firstSortDone, setFirstSortDone] = useState(false);
  const mounted = useRef(true);
  const onMovementClick = props.onMovementClick;
  const active = props.active;
  
  function initialSort(itemsArray) { // the only sort we need is by date, ascending
    console.log('~~~~~ Recommended, Recommended() - initialSort() - itemsArray.length is ' + itemsArray.length);
    
    const sorted = itemsArray.sort(function(a,b){
      return a.workoutDate - b.workoutDate;
    });

    setTableData(sorted);

    return sorted;
  }

  let fetchingNow = false;

  // useEffect(() => {
  //   if(fetchingNow) { // Note that this getItems() call should only run if there is not already a fetch in progress
  //     console.log('~~~~~ WorkoutsCalendar, WorkoutsCalendar() - inside useEffect, fetchingNow true, skipping fetch!');
  //     return;
  //   }
  //   fetchingNow = true; // Now there is a fetch in progress
  //   setLoading(true); // we should use this to show a loading items message, or an activity indicator where the list will appear
  //   mounted.current = true;

  //   console.log('~~~~~ WorkoutsCalendar, WorkoutsCalendar() - inside useEffect, proceeding with getPrograms()');
  //   getPrograms() // should there be a filter option? maybe public or not?
  //   .then(itemsResp => {
  //     if(mounted.current) {
  //       let items = itemsResp.data;
  //       setItems(items);
  //       let sortedItems = items;
  //       if ([...items].length > 0 && !firstSortDone) {
  //         sortedItems = initialSort(items);
  //         setFirstSortDone(true);
  //       }
  //       setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
  //       // itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
  //       fetchingNow = false; // Now we have finished with the current fetch
  //       setLoading(false);
  //       console.log('~~~~~ ItemTable() - useEffect() - ' + items.length + ' Programs were returned');
  //     }
  //   })

  // }, [items])

  return (
    <div style={{ display: active ? "block" : "none", width: "100%", textAlign: "center" }}>
      <AreaTitle style={{ display: "inline-block", marginBottom: "-25px" }}>Recommended Movements</AreaTitle>
      <AreaTitle style={{ display: "inline-block", fontSize: "smaller", marginTop: "-30px" }}>(based on your recent Physmodo Assessment results)</AreaTitle>
      <TableBody 
        tableData={props.movements}
        onRowClick={onMovementClick} 
        loading={loading}
      />
    </div>
  );
}

export default MovementList;