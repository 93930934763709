// components/styled/SortableExerciseCard.js

import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { useSortable, defaultAnimateLayoutChanges } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import { ShellOvalBtn, Thumbnail, EditIcon, ShellInputLabel, ShellFieldDisplayName, ShellTextInput, ShellTextarea } from './title';
import { iconSrc, colors } from '../../constants';

const ExerciseCardInputRow = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: flex-start;
  margin: 0px;
  margin-bottom: 5px; 
  padding: 0px; 
  height: 24px;
`;

const ExerciseCardInputRowLeft = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
  width: 33%;
  margin: 0px;
  padding: 0px;
`;

const ExerciseCardInputRowRight = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: flex-start;
  width: 100%;
  margin: 0px; 
  margin-left: 20px;
  padding: 0px;
`;

/*
<div className="FormRow1" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", margin: "0px", padding: "0px", height: "24px" }}>
              <div className="ExerciseCardRowLeft" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "30%" }}>

                <ShellInputLabel>
                  <ShellFieldDisplayName style={{ margin: "0px", padding: "0px", paddingRight: "10px" }}>Sets</ShellFieldDisplayName>
                  <ShellTextInput 
                    type="text" 
                    id={"exercise_set_integer_" + exercise.exercise_id_guid}
                    key={"exercise_set_integer_" + exercise.exercise_id_guid}
                    name="set_integer"
                    placeholder = "Sets"
                    title={notEditable ? publicRecordMsg : "Enter Sets for this Exercise"}

                    defaultValue={(exercise && exercise.set_integer) ? exercise.set_integer : ''} 
                    onChange={(e) => handleFieldChange(e, "set_integer", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "40px", height: "24px", margin: "0px" }}
                    disabled={notEditable}
                  />
                </ShellInputLabel>
              </div>
              <div className="ExersiseCardRowRight" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", marginLeft: "30px" }}></div>
*/

export const SortableExerciseCard = ({
  id, item, onRemoveClick, onExerciseUpdate, onVideoClick
})  => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id: id
  });

  const style = {
    cursor: "grab",
    transition,
    transform: CSS.Transform.toString(transform)
  }

  const myId = localStorage.getItem('loggedInUser');
  const notEditable = item && item.user_id_guid && item.user_id_guid.length > 20 && item.user_id_guid !== myId;
  const publicRecordMsg = "This record is Public, and cannot be edited here";
  // const [exercise, setExercise] = useState(item && item.exercise_id_guid && item.exercise_id_guid.length > 20 ? item : null)
  let exercise = (item && item.exercise_id_guid && item.exercise_id_guid.length > 20) ? item : null;

  /*
    children of ExerciseCard:
    - top line:
    -- delete/remove button (upper rt corner, with icon)
    -- name_string/title_string text, in left third of card, above thumbnail (with play button overlay?)
    - main body of card, below top line:
    -- left third of body:
    --- thumbnail_image  
    - Sets integer input (text, or really long dropdown?)
    - Load string input (text)
    - Reps integer input (text, or really long dropdown?)
    - Duration integer input (text, or really long dropdown?)
    - Duration string input dropdown (no separate label)
    - Distance integer input (text, or really long dropdown?)
    - Distance string input dropdown (no separate label)
    - Notes string input Textarea

    if exercise_array = [exObj0, exObj1, ... , exObjn],
    exObj = {
      id_guid, 
      exercise_id_guid (a string, or null if workout has not been saved with this exercise in it yet)
      movement_id_guid
      title_string, name_string (same as comparable movement attributes)
      subtitle_string (same as comparable movement subtitle_string)
      thumbnail_url_string (same as comparable movement thumbnail_url_string)
      video_url_string (same as comparable movement video_url_string)
      rank_integer       // default to exercise_array.length of exercise_array BEFORE adding this new exercise
      distance_integer   // default to null
      distance_string    // default to null
      duration_integer   // default to null
      duration_string    // default to null
      load_string      ` // default to null
      repetition_integer // default to 1
      set_integer        // default to 1
      note_string        // default to null
    }

    these values will need to be updated in the objects of the array using input onChange() (or onBlur?) methods
  */

  let testCount = 0;

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer =
        setTimeout(() => func.apply(context, args), delay);
    }
  }

  function handleFieldChange(event, field, newValue){
    console.log('~~~~~ SortableExerciseCard(), onFieldChange() - event.type is *' + event.type + '*, field is *' + field + '*, and newValue is *' + newValue + '*');
    exercise[field] = field.includes('_integer') ? parseInt(newValue, 10) : newValue;
    // onExerciseUpdate(id_guid, field, newValue);
    // onExerciseUpdate(exercise); // updatedExercise

    // conditional treatment of newValue based on field:
    // if field contains '_integer'
    // - then exercise[field] = parseInt(newValue, 10);
    // - else exercise[field] = newValue;
    /*
        set_integer: workoutObj.set_integer, 
        repetition_integer: workoutObj.repetition_integer,
        distance_integer: workoutObj.distance_integer, 
        distance_string: workoutObj.distance_string.toLowerCase().includes("day") ? "day" : "week",
        duration_integer: workoutObj.duration_integer, 
        duration_string: workoutObj.duration_string.toLowerCase().includes("day") ? "day" : "week",
    */
  }

  function handleFieldBlur(){
    onExerciseUpdate(exercise);
  }

  function optimizedHandleFieldChange(event, id_guid, field, newValue){
    console.log('~~~~~ SortableExerciseCard(), optimizedHandleFieldChange() - event.type is *' + event.type + '*, id_guid is *' + id_guid + '*, field is *' + field + '*, and newValue is *' + newValue + '*');
    // console.log('~~~~~ SortableExerciseCard(), optimizedHandleFieldChange()');
    debounce(handleFieldChange, 500);
  }

  // console.log('~~~~~ SortableExerciseCard(), we want a breakpoint right after here...  testCount is now *' + testCount + '*');
  testCount += 1;
  // console.log('~~~~~ SortableExerciseCard(), should have been a breakpoint before here...  testCount is now *' + testCount + '*');

    // distance_integer   // default to null
    // distance_string    // default to null
    // duration_integer   // default to null
    // duration_string    // default to null
    // load_string      ` // default to null
    // repetition_integer // default to 1
    // set_integer        // default to 1
    // note_string        // default to null

  return (
    <div tabIndex="-1" key={"exercise_card_" + exercise.exercise_id_guid} ref={setNodeRef} style={style} className="item" id={"outerDiv_" + exercise.exercise_id_guid}>
      <Card tabIndex="-1" body className="m-2" id={exercise.exercise_id_guid} key={exercise.exercise_id_guid} style={{ display: "flex", width: "90%", height: "auto" }}>
        <button tabIndex="-1" className="CardDragHandle"  {...attributes} {...listeners} style={{ width: "100%", height: "40px", margin: "0px", marginTop: "-10px", marginBottom: "5px", backgroundColor: "#EEEEEE", color: "#888", fontStyle: "italic", border: "none" }}> Drag this to change order of Exercises </button>
        <div tabIndex="-1" className="CardTopLine" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div tabIndex="-1" className="CardNameDiv" style={{ width: "30%", fontWeight: "bold", textAlign: "left" }} title={exercise.exercise_id_guid}>{exercise.name_string}</div>
          <EditIcon tabIndex="-1" src={iconSrc.remove} title="Delete this Exercise from the Workout" onClick={(e) => {onRemoveClick(exercise.indexInt)}} style={{ height: "50%", marginTop: "-15px", marginRight: "-15px" }} />
        </div>
        <div tabIndex="-1" className="CardLowerSection" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div tabIndex="-1" className="ExerciseThumbnailDiv" data-id="tnail" style={{ width: "30%", height: "100%", display: "inline-block", cursor: 'pointer' }}>
            <Thumbnail onClick={() => {onVideoClick(exercise.video_url_string, exercise.name_string)}} tabIndex="-1" src={exercise.thumbnail_url_string ? exercise.thumbnail_url_string : exercise.movement_thumbnail_url_string}  style={{ height: "160px", marginTop: "-10px", marginBottom: "-20px" }}/>
          </div>
          <div className="ExerciseForm" style={{ width: "65%", height: "100%", display: "inline-block", marginTop: "10px" }}>
            <ExerciseCardInputRow key={"input_row_1_" + exercise.exercise_id_guid} >
              <ExerciseCardInputRowLeft>
                <ShellInputLabel tabIndex="0" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <ShellFieldDisplayName style={{ margin: "0px", padding: "0px", paddingRight: "10px" }}>Sets</ShellFieldDisplayName>
                  <ShellTextInput 
                    type="text" 
                    tabIndex="1"
                    id={"exercise_set_integer_" + exercise.exercise_id_guid}
                    key={"exercise_set_integer_" + exercise.exercise_id_guid}
                    name="set_integer"
                    placeholder = ""
                    title={notEditable ? publicRecordMsg : "Enter Sets for this Exercise"}

                    defaultValue={(exercise && exercise.set_integer) ? exercise.set_integer : ''} 
                    onChange={(e) => handleFieldChange(e, "set_integer", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "40px", height: "24px", margin: "0px" }}
                    disabled={notEditable}
                  />
                </ShellInputLabel>
              </ExerciseCardInputRowLeft>
              <ExerciseCardInputRowRight>
                <ShellInputLabel tabIndex="0" style={{ width: "100%" }}>
                  <ShellFieldDisplayName style={{ margin: "0px", padding: "0px", paddingRight: "10px" }}>Load</ShellFieldDisplayName>
                  <ShellTextInput 
                    type="text"
                    tabIndex="2" 
                    id={"exercise_load_string_" + exercise.exercise_id_guid}
                    key={"exercise_load_string_" + exercise.exercise_id_guid}
                    name="load_string"
                    placeholder = "Enter prescribed Load"
                    title={notEditable ? publicRecordMsg : "Enter prescribed Load for this Exercise"}

                    defaultValue={(exercise && exercise.load_string) ? exercise.load_string : ''} 
                    onChange={(e) => handleFieldChange(e, "load_string", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "100%", height: "24px", margin: "0px" }}
                    disabled={notEditable}
                  />
                </ShellInputLabel>

              </ExerciseCardInputRowRight>
            </ExerciseCardInputRow>

            <ExerciseCardInputRow key={"input_row_2_" + exercise.exercise_id_guid} >
              <ExerciseCardInputRowLeft>
                <ShellInputLabel style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <ShellFieldDisplayName style={{ margin: "0px", padding: "0px", paddingRight: "10px" }}>Reps</ShellFieldDisplayName>
                  <ShellTextInput 
                    type="text" 
                    tabIndex="3"
                    id={"exercise_repetition_integer_" + exercise.exercise_id_guid}
                    key={"exercise_repetition_integer_" + exercise.exercise_id_guid}
                    name="repetition_integer"
                    placeholder = ""
                    title={notEditable ? publicRecordMsg : "Enter Reps for this Exercise"}

                    defaultValue={(exercise && exercise.repetition_integer) ? exercise.repetition_integer : ''} 
                    onChange={(e) => handleFieldChange(e, "repetition_integer", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "40px", height: "24px", margin: "0px", padding: "0px", textAlign: "center" }}
                    disabled={notEditable}
                  />
                </ShellInputLabel>
              </ExerciseCardInputRowLeft>
              <ExerciseCardInputRowRight>
                <ShellInputLabel style={{ width: "100%" }}>
                  <ShellFieldDisplayName style={{ margin: "0px", padding: "0px", paddingRight: "10px" }}>Duration</ShellFieldDisplayName>
                  <ShellTextInput 
                    type="text" 
                    tabIndex="4"
                    id={"exercise_duration_integer_" + exercise.exercise_id_guid}
                    key={"exercise_duration_integer_" + exercise.exercise_id_guid}
                    name="duration_integer"
                    placeholder = "integer"
                    title={notEditable ? publicRecordMsg : "Enter prescribed Duration for this Exercise"}

                    defaultValue={(exercise && exercise.duration_integer) ? exercise.duration_integer : ''} 
                    onChange={(e) => handleFieldChange(e, "duration_integer", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "60px", height: "24px", margin: "0px", padding: "0px", textAlign: "center" }}
                    disabled={notEditable}
                  />
                  {/* <ShellTextInput 
                    type="text" 
                    id={"exercise_duration_string_" + exercise.exercise_id_guid}
                    key={"exercise_duration_string_" + exercise.exercise_id_guid}
                    name="duration_string"
                    placeholder = "units"
                    title={notEditable ? publicRecordMsg : "Enter prescribed Duration Units for this Exercise"}

                    defaultValue={(exercise && exercise.duration_string) ? exercise.duration_string : ''} 
                    onChange={(e) => handleFieldChange(e, "duration_string", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "100%", height: "24px", margin: "0px", marginLeft: "10px" }}
                    disabled={notEditable}
                  /> */}
                  <Form.Select 
                    style={{ 
                      height: "24px", 
                      margin: "0px", 
                      marginLeft: "10px",
                      // marginRight: "5px",
                      padding: "0px",
                      paddingLeft: "15px",
                      // textAlign: "center",
                      background: "#dddddd",
                      border: "1px solid black", 
                      borderRadius: "12px",
                      // color: "#888888"
                    }}
                    size="sm" 
                    aria-label="Default select example" 
                    id={"exercise_duration_string_" + exercise.exercise_id_guid}
                    key={"exercise_duration_string_" + exercise.exercise_id_guid}
                    name="duration_string"
                    placeholder = "time units"
                    title={notEditable ? publicRecordMsg : "Enter prescribed Duration Units for this Exercise"}

                    defaultValue={(exercise && exercise.duration_string) ? exercise.duration_string : ''} 
                    onChange={(e) => handleFieldChange(e, "duration_string", e.target.value)}
                    onBlur={handleFieldBlur}
                    disabled={notEditable}
                  >
                    <option style={{ verticalAlign: "top" }} >time units</option>
                    <option value="seconds">Seconds</option>
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                    <option value="days">Days</option>
                    <option value="weeks">Weeks</option>
                    <option value="months">Months</option>
                    <option value="years">Years</option>
                  </Form.Select>
                </ShellInputLabel>
              </ExerciseCardInputRowRight>
            </ExerciseCardInputRow>

            <ExerciseCardInputRow key={"input_row_3_" + exercise.exercise_id_guid} >
              <ExerciseCardInputRowLeft>
                <ShellInputLabel htmlFor={"exercise_note_string_" + exercise.exercise_id_guid} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <ShellFieldDisplayName style={{ margin: "0px", padding: "0px", paddingRight: "10px" }}>Notes</ShellFieldDisplayName>
                </ShellInputLabel>
              </ExerciseCardInputRowLeft>
              <ExerciseCardInputRowRight>
                <ShellInputLabel style={{ width: "100%" }}>
                  <ShellFieldDisplayName style={{ margin: "0px", padding: "0px", paddingRight: "10px" }}>Distance</ShellFieldDisplayName>
                  <ShellTextInput 
                    type="text" 
                    id={"exercise_distance_integer_" + exercise.exercise_id_guid}
                    key={"exercise_distance_integer_" + exercise.exercise_id_guid}
                    name="distance_integer"
                    placeholder = "integer"
                    title={notEditable ? publicRecordMsg : "Enter prescribed Distance for this Exercise"}

                    defaultValue={(exercise && exercise.distance_integer) ? exercise.distance_integer : ''} 
                    onChange={(e) => handleFieldChange(e, "distance_integer", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "60px", height: "24px", margin: "0px", padding: "0px", textAlign: "center" }}
                    disabled={notEditable}
                  />
                  {/* <ShellTextInput 
                    type="text" 
                    id={"exercise_distance_string_" + exercise.exercise_id_guid}
                    key={"exercise_distance_string_" + exercise.exercise_id_guid}
                    name="distance_string"
                    placeholder = "units"
                    title={notEditable ? publicRecordMsg : "Enter prescribed Distance Units for this Exercise"}

                    defaultValue={(exercise && exercise.distance_string) ? exercise.distance_string : ''} 
                    onChange={(e) => handleFieldChange(e, "distance_string", e.target.value)}
                    onBlur={handleFieldBlur}
                    style={{ width: "100%", height: "24px", margin: "0px", marginLeft: "10px" }}
                    disabled={notEditable}
                  /> */}
                  <Form.Select 
                    style={{ 
                      height: "24px", 
                      margin: "0px", 
                      marginLeft: "10px",
                      // marginRight: "5px",
                      padding: "0px",
                      paddingLeft: "15px",
                      // textAlign: "center",
                      background: "#dddddd",
                      border: "1px solid black", 
                      borderRadius: "12px",
                      // color: "#888888"
                    }}
                    size="sm" 
                    aria-label="Default select example" 
                    id={"exercise_distance_string_" + exercise.exercise_id_guid}
                    key={"exercise_distance_string_" + exercise.exercise_id_guid}
                    name="distance_string"
                    placeholder = "units"
                    title={notEditable ? publicRecordMsg : "Enter prescribed Distance Units for this Exercise"}

                    defaultValue={(exercise && exercise.distance_string) ? exercise.distance_string : ''} 
                    onChange={(e) => handleFieldChange(e, "distance_string", e.target.value)}
                    onBlur={handleFieldBlur}
                    disabled={notEditable}
                  >
                    <option style={{ verticalAlign: "top" }} >length units</option>
                    <option value="seconds">Feet</option>
                    <option value="minutes">Yards</option>
                    <option value="hours">Miles</option>
                    <option value="days">Meters</option>
                    <option value="weeks">Kilometers</option>
                  </Form.Select>
                </ShellInputLabel>
              </ExerciseCardInputRowRight>
            </ExerciseCardInputRow>

            <div className="FormRow4" style={{ margin: "0px", marginTop: "-10px", textAlign: "left" }}>
              <ShellTextarea 
                id={"exercise_note_string_" + exercise.exercise_id_guid}
                key={"exercise_note_string_" + exercise.exercise_id_guid}
                name="note_string"
                placeholder = "Enter a note"
                title={notEditable ? publicRecordMsg : "Enter a Note for this Exercise"}
                
                defaultValue={(exercise && exercise.note_string) ? exercise.note_string : ''} 
                onChange={(e) => handleFieldChange(e, "note_string", e.target.value)}
                onBlur={handleFieldBlur}
                style={{ width: "376px", height: "60px", margin: "0px", marginTop: "10px" }}
                disabled={notEditable}
              />
            </div>
          </div>
        </div>

      </Card>
    </div>
  )
}
/*
<div className="ExerciseMetaDiv" style={{ width: "65%", minHeight: "8vh", textAlign: "left", display: "inline-block", marginLeft: "-10px" }}>
<b>{item.name_string}</b><br />
<span style={{ fontSize: "smaller" }}>{item.note_string}</span>
</div>
<div className="ExerciseThumbnailDiv" data-id="tnail" style={{ width: "30%", height: "70px", display: "inline-block" }}>
<Thumbnail src={item.thumbnail_url_string ? item.thumbnail_url_string : item.movement_thumbnail_url_string}  style={{ height: "70px", marginTop: "-10px", marginBottom: "-20px" }}/>
</div>
<div style={{ display: "inline-block", textAlign: "right" }} >
<EditIcon src={iconSrc.remove} title="Delete this Exercise from the Workout" onClick={(e) => {onRemoveClick(item.indexInt)}} />
</div>
*/