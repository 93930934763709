// /services/programs.js

const token = localStorage.getItem('token');
// const coachId = localStorage.getItem('loggedInUser');
const fizzy_data_url = "https://data.physmodo.net";

export function getPrograms() {
  return fetch(fizzy_data_url + '/api/v202203/app/program/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function getAthletePrograms(athleteId) {
  return fetch(fizzy_data_url + '/api/v202203/app/user/' + athleteId + '/program/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}

export function createProgram(programObj) {
  console.log('~~~~~ services/programs.js, createProgram() - name_string is *' + programObj.name_string + '*');
  console.log('~~~~~ services/programs.js, createProgram() - note_string is *' + programObj.note_string + '*');
  console.log('~~~~~ services/programs.js, createProgram() - exercise_array is *' + programObj.exercise_array + '*');
  console.log(programObj.exercise_array);
  try {
    return fetch(fizzy_data_url + '/api/v202203/app/program/', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: programObj.name_string, 
        interval_day_decimal: programObj.interval_day_decimal,
        note_string: programObj.note_string ? programObj.note_string : "",
        exercise_array: programObj.exercise_array
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/programs, createProgram() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/programs.js, createProgram() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function updateProgram(programObj) {
  console.log('~~~~~ services/programs.js, updateProgram() - name_string is *' + programObj.name_string + '*');
  console.log('~~~~~ services/programs.js, updateProgram() - note_string is *' + programObj.note_string + '*');
  console.log('~~~~~ services/programs.js, createProgram() - exercise_array is *' + programObj.exercise_array + '*');
  console.log('~~~~~ services/programs.js, updateProgram() - id_guid is *' + programObj.id_guid + '*');
  // alert('~~~~~ services/programs.js, createProgram() - exercise_array is *' + programObj.exercise_array + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/program/' + programObj.id_guid, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }, 
      body: JSON.stringify({ 
        name_string: programObj.name_string,
        interval_day_decimal: programObj.interval_day_decimal, 
        note_string: programObj.note_string ? programObj.note_string : "",
        exercise_array: programObj.exercise_array
      })
    })
    .then(data => data.json())
    // .then(response => { if (response.status === 200 || response.status === 201) {
    //   console.log('~~~~~ services/members.js, updateProgram() - response status code was 200 or 201!');
    //   return response.data.json(); 
    // } else {
    //   console.log('~~~~~ services/members.js, updateProgram() - OOPS! response status code was ' + response.status + ' :-(');
    // }})
  }
  catch (e) {
    console.log(e)
  }
}

export function deleteProgram(id_guid) {
  console.log('~~~~~ services/programs.js, deleteProgram() - id_guid is *' + id_guid + '*');

  try {
    return fetch(fizzy_data_url + '/api/v202203/app/program/' + id_guid, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    // .then(data => data.json())
    .then(response => { if (response.status === 204) {
      console.log('~~~~~ services/programs.js, deleteProgram() - response status code was 200 or 201 or 204!');
      // return response.json(); 
      return response.status; 
    } else {
      console.log('~~~~~ services/programs.js, deleteProgram() - OOPS! response status code was ' + response.status + ' :-(');

    }
    return response.status;
  })
  }
  catch (e) {
    console.log(e)
  }
}

export function getWorkoutExercises(workoutId) {
  return fetch(fizzy_data_url + '/api/v202203/app/program/' + workoutId + '/program_exercise/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})
  .then(data => data.json())
}
