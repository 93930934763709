// components/styled/WorkoutList.js

import React from 'react';
import { useState, useRef, useEffect } from "react";

import styled from 'styled-components';
import { RedSpan, Thumbnail, AreaTitle, ScrollingListContainer, ListContentRow, ListContentBox } from './title';
// import { iconSrc, colors } from '../../constants';

import { getAthletes, getGroups, getMovements, getExercises, getPrograms } from '../../services'; // TODO: improve???
// import { getItems } from '../../services';




function WorkoutList(props) {

  const [items, setItems] = useState([]);
  const [tableData, setTableData] = useState(props.workouts);
  // const [loading, setLoading] = useState(false);
  const [firstSortDone, setFirstSortDone] = useState(false);
  const mounted = useRef(true);
  const onWorkoutClick = props.onWorkoutClick;
  const active = props.active;
  const isMobile = props.isMobile;
  const loading = props.loading;
  let fetchingNow = false;

  console.log('~~~~~ WorkoutList.js, WorkoutList() - isMobile is *' + isMobile + '*');

  // useEffect(() => {
  //   if(fetchingNow) { // Note that this getItems() call should only run if there is not already a fetch in progress
  //     console.log('~~~~~ WorkoutList, WorkoutList() - inside useEffect, fetchingNow true, skipping fetch!');
  //     return;
  //   }
  //   fetchingNow = true; // Now there is a fetch in progress
  //   setLoading(true); // we should use this to show a loading items message, or an activity indicator where the list will appear
  //   mounted.current = true;

    // console.log('~~~~~ WorkoutList, WorkoutList() - inside useEffect, proceeding with getPrograms()');
    // getPrograms() // should there be a filter option? maybe public or not?
    // .then(itemsResp => {
    //   if(mounted.current) {
    //     let items = itemsResp.data;
    //     setItems(items);
    //     let sortedItems = items;
    //     if ([...items].length > 0 && !firstSortDone) {
    //       sortedItems = initialSort(items);
    //       setFirstSortDone(true);
    //     }
    //     setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
    //     // itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
    //     fetchingNow = false; // Now we have finished with the current fetch
    //     setLoading(false);
    //     console.log('~~~~~ ItemTable() - useEffect() - ' + items.length + ' Programs were returned');
    //   }
    // })

  // }, [items])

  // setTableData(props.workouts);

  const ListBody = ({ 
    tableData, 
    onRowClick, 
    loading
  }) => {
  
    const noItemsMsg = "You have no Workouts assigned yet...";
    let rowIndex = 0;
    
    return (
      <>
        <div style={{ display: loading ? "flex" : "none", flexDirection: "row" }}>
          <div style={{ width: "100%", padding: "20px" }}>
            <RedSpan>Loading Workouts...</RedSpan>
          </div>
        </div>
        <div style={{ display: (!loading  && tableData && tableData.length === 0) ? "flex" : "none", flexDirection: "row" }}>
          <div style={{ width: "100%", padding: "20px" }}>
            <RedSpan>{noItemsMsg}</RedSpan>
          </div>
        </div>
        <ScrollingListContainer isMobile>
        {tableData.map((data) => {
          const id_guid = data.workout_id_guid;
          const name = data.name_string;
          const timesPerWeek = data.interval_day_decimal <= 1.0 ? "Every Day" :
            data.interval_day_decimal <= 2.0 ? "Every Other Day" :
            data.interval_day_decimal <= 4.0 ? "Twice a Week" :
            "Once a Week";
          rowIndex++;
          console.log('~~~~~ WorkoutList.js, TableBody() - data.id_guid is *' + id_guid + '* and rowIndex is *' + rowIndex + '*'); //  id={id_guid} <- from down there in ListContentRow... id="ListContentRow" 
          // TODO: give outer div below a maxHeight and have overflow scroll vertically if required...
          return(
            <ListContentRow data-name={name} id={id_guid + '_' + rowIndex} style={{ display: loading ? "none" : "flex" }} key={id_guid + '_' + rowIndex} onClick={(e, id_guid) => onRowClick(e, id_guid)}>
              <ListContentBox className="ListContentBox" id="ListContentBox">
                <div className="WorkoutMetaDiv" style={{ width: "79%", minHeight: "8vh", textAlign: "left", fontSize: isMobile ? "26px" : "13px" }}>
                  Name: <b>{data.name_string}</b><br />
                  Schedule: <b>{timesPerWeek}</b><br />
                  {/* Estimated Duration: <b>{data.durationEstimate}</b><br /> */}
                  Assigned By: <b>{data.group_name_string ? data.group_name_string : data.program_user_first_name_string + ' ' + data.program_user_last_name_string}</b><br />
                </div>
                <div className="WorkoutThumbnailDiv" data-id="tnail" style={{ width: "20%", minHeight: "8vh" }}>
                  <Thumbnail src={data.movement_thumbnail_url_string_array[0]}  style={{ height: isMobile ? "120px" : "80px", marginTop: "0px" }}/>
                </div>
              </ListContentBox>
            </ListContentRow>
          )
        })}
        </ScrollingListContainer>
      </>
    );
  };

  return (
    <div style={{ display: active ? "block" : "none", width: "100%", textAlign: "center", paddingTop: "30px" }}>
      <AreaTitle style={{ display: "inline-block", marginBottom: "-15px", fontSize: isMobile ? "26px" : "13px" }}>Assigned Workouts this Week</AreaTitle><br />
      {/* <AreaTitle style={{ display: "inline-block", fontSize: "smaller", marginTop: "-30px" }}>.</AreaTitle> */}

      <ListBody 
        tableData={props.workouts}
        onRowClick={onWorkoutClick} 
        loading={loading}
      />
    </div>
  );
}

export default WorkoutList;