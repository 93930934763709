// ShellScoreDisplay.js

import React from 'react';
import styled from 'styled-components'

import ScoreCircle from './ScoreCircle';
import { colors } from '../../constants';

const isPhysmodo = localStorage.getItem('physmodo') === 'true' ?
  true : false;

const TopName = styled.h3`
  ${ props => props.isMobile 
    ? '{ font-size: 3vw; }'
    : '{ font-size: 18px; }'
  } 
  /* font-size: 18px; */
  color: black;
  white-space: nowrap;
`;

const BottomName = styled.h4`
  ${ props => props.isMobile 
    ? '{ font-size: 2.5vw; }'
    : '{ font-size: 14px; }'
  } 
  /* font-size: 14px; */
  color: black;
  white-space: nowrap;
`;

const ScoreCircleDiv = styled.div`
  clear: both;
  float: left;
  padding-left: 15px;
  padding-top: 14px;
`;

const MeasurementDiv = styled.div`
  clear: both;
  padding: 0;
  margin-top: 0;
  color: ${colors.lightGreyText};
  ${ props => props.isMobile 
    ? '{ font-size: 2.4vw; }'
    : '{ font-size: 13px; }'
  } 
  /* font-size: 13px; */
  text-align: center;
`;

const ScoreTitlesDiv = styled.div`
  float: left;
  padding-left: 15px;
  padding-top: 22px;
`;
 
const ScoreDisplayDiv = styled.div` // twoColumns
  ${ props => props.twoColumns 
    ? '{ width: 45%; }'
    : '{ width: 95%; }'
  } 
  margin-left: 15px;
  padding-right: 0px;
  padding-bottom: 4vw; /* only needed if we are showing measurement */
  border-bottom: 1px solid #b6b6b6;
`;


const ShellScoreDisplay = ({  // this takes a single score and displays it, the parent component will map the totality of the 
  data, twoColumns, isMobile  // available scores & filter by domain. this component will receive a scoreObject
}) => {                   
  /* // the format of the score object ( data[index] )will be this:
    {
      "id": "e4bfd335-8c78-45a5-8c56-083bb7e81b11",
      "domain": "stability",
      "title": "Scap Right",
      "subtitle": "Scap Right words",
      "score": "45",
      "measurement": "126"
		}
  */

  /* 6vw if we are showing measurement, otherwise 5vw (or 4.5vw if we never have subtitles) */
  const scoreDisplayHeight = isMobile ? "11vw" : isPhysmodo ? "80px" : "74px";
  
  const score = parseInt(data.score, 10);
  const measurement = parseFloat(data.measurement).toFixed(2);
  const title = data.title;
  const subtitle = data.subtitle;
  const trend = data.trend;
  const showBasis = false; // TODO: remove this (or set to true) once we have demo accounts that do not have Physmodo emails

  return (
    <>
      <ScoreDisplayDiv twoColumns={twoColumns} style={{ height: scoreDisplayHeight}}>
        <ScoreCircleDiv>
          <ScoreCircle
            size={isMobile? window.innerWidth*0.07 : window.innerWidth*0.025}
            score={score}
            trend={trend}
            simple={true}
            bigNum={true}
            style={{ margin: 0, marginTop: "1vw" }}
          />
          { (isPhysmodo && showBasis) &&
            <MeasurementDiv>
              {measurement}
            </MeasurementDiv>
          }
        </ScoreCircleDiv>
        <ScoreTitlesDiv>
          <TopName isMobile={isMobile}>{title}</TopName>
          <BottomName isMobile={isMobile}>{subtitle}</BottomName>
        </ScoreTitlesDiv>
      </ScoreDisplayDiv>
    </>
  );
}

export default ShellScoreDisplay;
