// Accept.js

// import React, { useRef, useState } from 'react';
import React, { useState } from 'react';
import packageJson from '../../package.json';
import '../App.css'

import { Title, EZSpacer, ContentWrapper } from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import { northsideLogoSrc } from '../constants';
import { acceptCoachLink, acceptGroupLink } from '../services'; // 

// OLD: http://localhost:3000/accept?
// user_authentication_jwt_token_string=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9ndWlkIjoiMDQxMTQ1OTgtYzI3My00MDk1LTk1NDYtMmM1YzVhZjNmOWFlIiwiY3JlYXRlZF9kYXRldGltZSI6IjIwMjMtMDgtMTRUMDU6MTM6MTcuMjcxWiIsInVwZGF0ZWRfZGF0ZXRpbWUiOiIyMDIzLTA4LTE0VDA1OjEzOjE3LjI3MVoiLCJ1c2VyX2lkX2d1aWQiOiJmNDQwNzRkOS04YzlmLTQ5NjAtYTA2My1iZmM2YTQxNTUyNGEiLCJ2YWxpZF9ib29sZWFuIjp0cnVlLCJleHBpcmF0aW9uX2RhdGV0aW1lIjoiMjAyMy0wOS0xM1QwNToxMzoxNy4yNzFaIiwidXNlcl9hdXRob3JpemF0aW9uX3R5cGVfaWRfZ3VpZCI6ImE3OWY0MDhmLWNmNjQtNGNmMy04MWFjLTc0MDEzODg0YTkyYSIsImlhdCI6MTY5MTk4OTk5N30.GxDcCNmzBQXBtfrEgBHiitFsE0zT6l4BTf9RoibOHY4&
// user_association_id_guid=1755ec72-a433-4543-85e5-657a45434b6e&
// athlete_user_id_guid=f44074d9-8c9f-4960-a063-bfc6a415524a&
// user_name_string=John%20Physmodo
/*
user_authentication_jwt_token_string=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9ndWlkIjoiMDQxMTQ1OTgtYzI3My00MDk1LTk1NDYtMmM1YzVhZjNmOWFlIiwiY3JlYXRlZF9kYXRldGltZSI6IjIwMjMtMDgtMTRUMDU6MTM6MTcuMjcxWiIsInVwZGF0ZWRfZGF0ZXRpbWUiOiIyMDIzLTA4LTE0VDA1OjEzOjE3LjI3MVoiLCJ1c2VyX2lkX2d1aWQiOiJmNDQwNzRkOS04YzlmLTQ5NjAtYTA2My1iZmM2YTQxNTUyNGEiLCJ2YWxpZF9ib29sZWFuIjp0cnVlLCJleHBpcmF0aW9uX2RhdGV0aW1lIjoiMjAyMy0wOS0xM1QwNToxMzoxNy4yNzFaIiwidXNlcl9hdXRob3JpemF0aW9uX3R5cGVfaWRfZ3VpZCI6ImE3OWY0MDhmLWNmNjQtNGNmMy04MWFjLTc0MDEzODg0YTkyYSIsImlhdCI6MTY5MTk4OTk5N30.GxDcCNmzBQXBtfrEgBHiitFsE0zT6l4BTf9RoibOHY4&user_association_id_guid=1755ec72-a433-4543-85e5-657a45434b6e&athlete_user_id_guid=f44074d9-8c9f-4960-a063-bfc6a415524a&user_name_string=John%20Physmodo
*/

// NEW "Invited to Group" style - CORRECT AS OF 11/09/23: https://data.physmodo.net/email/accept.html?
// user_authentication_jwt_token_string=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9ndWlkIjoiNDZmYjZiOWYtNzEzYi00ZmEwLTgwMzYtNzVmZmU4ZWE1MzhkIiwiY3JlYXRlZF9kYXRldGltZSI6IjIwMjMtMTEtMDdUMjA6MDg6MTcuNzYxWiIsInVwZGF0ZWRfZGF0ZXRpbWUiOiIyMDIzLTExLTA3VDIwOjA4OjE3Ljc2MVoiLCJ1c2VyX2lkX2d1aWQiOiJjODNiMDFmYi00ODQwLTQxMjQtOGJmMS1mZjUzN2VkNzBkODciLCJ2YWxpZF9ib29sZWFuIjp0cnVlLCJleHBpcmF0aW9uX2RhdGV0aW1lIjoiMjAyMy0xMi0wN1QyMDowODoxNy43NjBaIiwidXNlcl9hdXRob3JpemF0aW9uX3R5cGVfaWRfZ3VpZCI6ImE3OWY0MDhmLWNmNjQtNGNmMy04MWFjLTc0MDEzODg0YTkyYSIsImlhdCI6MTY5OTM4NzY5N30.yGqGtQpu55q2lw8OhErKFWo6gJaCke6_bQnKuF4zwTw&
// group_id_guid=36dc9c28-d150-416b-a5be-0eddd65feeca&
// user_id_guid=c83b01fb-4840-4124-8bf1-ff537ed70d87&
// group_name_string=District%201%20(ISD)
/*
user_authentication_jwt_token_string=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9ndWlkIjoiNDZmYjZiOWYtNzEzYi00ZmEwLTgwMzYtNzVmZmU4ZWE1MzhkIiwiY3JlYXRlZF9kYXRldGltZSI6IjIwMjMtMTEtMDdUMjA6MDg6MTcuNzYxWiIsInVwZGF0ZWRfZGF0ZXRpbWUiOiIyMDIzLTExLTA3VDIwOjA4OjE3Ljc2MVoiLCJ1c2VyX2lkX2d1aWQiOiJjODNiMDFmYi00ODQwLTQxMjQtOGJmMS1mZjUzN2VkNzBkODciLCJ2YWxpZF9ib29sZWFuIjp0cnVlLCJleHBpcmF0aW9uX2RhdGV0aW1lIjoiMjAyMy0xMi0wN1QyMDowODoxNy43NjBaIiwidXNlcl9hdXRob3JpemF0aW9uX3R5cGVfaWRfZ3VpZCI6ImE3OWY0MDhmLWNmNjQtNGNmMy04MWFjLTc0MDEzODg0YTkyYSIsImlhdCI6MTY5OTM4NzY5N30.yGqGtQpu55q2lw8OhErKFWo6gJaCke6_bQnKuF4zwTw&group_id_guid=36dc9c28-d150-416b-a5be-0eddd65feeca&user_id_guid=c83b01fb-4840-4124-8bf1-ff537ed70d87&group_name_string=District%201%20(ISD)
*/

function Accept(props) {
  const [loaded, setLoaded ] = useState(false);
  // const mounted = useRef(true);
  const isMobile = props.isMobile;

  let errorMsg = "";
  let fullErrorMsg = "";
  let nextSteps = "\nPlease contact the coach directly to get a new invitation";
  let queryParams = "";
  let searchString = window.location.search;
  let coachName = "";
  let coachLastName = "";
  let user_authentication_jwt_token_string = "";
  let user_association_id_guid = "";
  let athlete_user_id_guid = "";
  let inviteToken = "";
  let group_id_guid = "";
  // let group_user_id_guid = "";
  let user_id_guid = "";
  let group_name_string = "";
  let user_name_string = "";

  // let tokenQueryParamPresent = false;
  let tokenQueryParamOk = false;

  // let user_association_id_guidQueryParamPresent = false;
  let user_association_id_guidQueryParamOk = false;

  // let athlete_user_id_guidQueryParamPresent = false;
  let athlete_user_id_guidQueryParamOk = false;

  // let user_name_stringQueryParamPresent = false;
  let user_name_stringQueryParamOk = false;

  let oldQueryStringParamsOk = false;


  // let group_id_guidQueryParamPresent = false;
  let group_id_guidQueryParamOk = false;

  // let user_id_guidQueryParamPresent = false;
  let user_id_guidQueryParamOk = false;

  // let group_name_stringQueryParamPresent = false;
  let group_name_stringQueryParamOk = false;

  let newQueryStringParamsOk = false;


  let oldAcceptAttempt = false;
  let newAcceptAttempt = false;
  
  let missingSpace = false;

  queryParams = new URLSearchParams(window.location.search);


  if (searchString && searchString.length > 2 && searchString.includes('user_authentication_jwt_token_string=') ){
    // tokenQueryParamPresent = true;
    user_authentication_jwt_token_string = queryParams.get("user_authentication_jwt_token_string");
    if (user_authentication_jwt_token_string && user_authentication_jwt_token_string.length > 100){
      tokenQueryParamOk = true;
    }
  }

  if (searchString && searchString.length > 2 && searchString.includes('user_association_id_guid=') ){
    // user_association_id_guidQueryParamPresent = true;
    user_association_id_guid = queryParams.get("user_association_id_guid");
    if (user_association_id_guid && user_association_id_guid.length > 30){
      user_association_id_guidQueryParamOk = true;
    }
  }

  if (searchString && searchString.length > 2 && searchString.includes('athlete_user_id_guid=') ){
    // athlete_user_id_guidQueryParamPresent = true;
    athlete_user_id_guid = queryParams.get("athlete_user_id_guid");
    if (athlete_user_id_guid && athlete_user_id_guid.length > 30){
      athlete_user_id_guidQueryParamOk = true;
    }
  }

  if (searchString && searchString.length > 2 && searchString.includes('user_name_string=') ){
    // user_name_stringQueryParamPresent = true;
    user_name_string = queryParams.get("user_name_string");
    if (user_name_string && user_name_string.length > 4){
      user_name_stringQueryParamOk = true;
    }
  }

  if (tokenQueryParamOk && user_association_id_guidQueryParamOk && athlete_user_id_guidQueryParamOk && user_name_stringQueryParamOk){
    oldQueryStringParamsOk = true;
  }


  if (searchString && searchString.length > 2 && searchString.includes('group_id_guid=') ){
    // group_id_guidQueryParamPresent = true;
    group_id_guid = queryParams.get("group_id_guid");
    if (group_id_guid && group_id_guid.length > 30){
      group_id_guidQueryParamOk = true;
    }
  }

  if (searchString && searchString.length > 2 && searchString.includes('user_id_guid=') ){
    // user_id_guidQueryParamPresent = true;
    user_id_guid = queryParams.get("user_id_guid");
    if (user_id_guid && user_id_guid.length > 30){
      user_id_guidQueryParamOk = true;
    }
  }

  if (searchString && searchString.length > 2 && searchString.includes('group_name_string=') ){
    // group_name_stringQueryParamPresent = true;
    group_name_string = queryParams.get("group_name_string");
    if (group_name_string && group_name_string.length > 4){
      group_name_stringQueryParamOk = true;
    }
  }

  if (tokenQueryParamOk && group_id_guidQueryParamOk && user_id_guidQueryParamOk && group_name_stringQueryParamOk){
    newQueryStringParamsOk = true;
  }

  if (user_association_id_guidQueryParamOk || athlete_user_id_guidQueryParamOk || user_name_stringQueryParamOk){
    oldAcceptAttempt = true;
  }

  if (group_id_guidQueryParamOk || user_id_guidQueryParamOk || group_name_stringQueryParamOk){
    newAcceptAttempt = true;
  }

  if ((oldAcceptAttempt && !oldQueryStringParamsOk) || (newAcceptAttempt && !newQueryStringParamsOk) || (!oldAcceptAttempt && !newAcceptAttempt)){
    // code to put up error message regarding malformed link
    errorMsg = "Unfortunately the link in your invitation was malformed and was missing some information.";
  } else {
    if (oldAcceptAttempt){
      
      acceptCoachLink(inviteToken, athlete_user_id_guid, user_association_id_guid) // token, athleteId, assocId
      .then(acceptResp => {
        setLoaded(true);
        let coach_name = (acceptResp && acceptResp.user_name_string && acceptResp.user_name_string.length > 5) ? acceptResp.user_name_string : "Coach";
        console.log('~~~~~ Accept.js, Accept() - Coach name is *' + coach_name + '*');
        // alert('Coach Invite Accepted! <br/> Now download the app.');
      });
  
      console.log('~~~~~ 6');
      
      if(coachName.length < 3 || missingSpace) {
        console.log('~~~~~ 7');
        errorMsg = "Unfortunately the link in your invitation was malformed and was missing your coach's information.";
      } else {
        console.log('~~~~~ 8');
        coachLastName = coachName.split(' ')[1];
        console.log('~~~~~ coachLastName: ' + coachLastName); // e.g. Menter
      }

    } else if (newAcceptAttempt){

      acceptGroupLink(user_id_guid, user_authentication_jwt_token_string, group_id_guid, group_name_string) // (userId, token, groupId, groupName)
      .then(acceptResp => {
        setLoaded(true);
        // alert('Group Invite Accepted! <br/> Now download the app.');
      });
      
    } else {
      // don't know how we got here..., presumably this is not an old attempt, and not a new attempt
      console.log('~~~~~ Accept() - we should not be able to get here .... shrug');
    }

    console.log('~~~~~ errorMsg: ' + errorMsg);

    if (errorMsg.length > 5) {
      fullErrorMsg = errorMsg + nextSteps;
    }
  
    console.log('~~~~~ fullErrorMsg: ' + fullErrorMsg);
  }

  const linkedToText = newAcceptAttempt ? "the Group " + group_name_string + "!" : "Coach " + coachName + "!";
  const monitoringBenefit = newAcceptAttempt ? group_name_string + " Staff and Coaches" : "Coach " + coachLastName;

  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper  loaded={loaded}>
        <EZSpacer height={"10vh"} />
        <div style={{ align: "center" }}> {/*  align-items-center */}
          <Title className="d-flex justify-content-center" style={{ opacity: "0.0", marginBottom: "5vh" }}>Accept Page Content</Title>
          <div className="d-flex justify-content-center" style={{ marginBottom: isMobile ? "18vh" : "3vh" }}>
            {/* <img src={logoSrc} alt="Physmodo logo" style={{ width: "24vw" }}/> */}
            <img src={northsideLogoSrc} alt="Northside logo" style={{ width: isMobile ? "80vw" : "34vw" }}/>
          </div>

          {errorMsg.length < 5 ? (
            <div style={{ width:  isMobile ? "95vw" : "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "7vh" }}>
              <p className="d-flex justify-content-center" style={{ fontSize: isMobile ? "30px" : "15px", opacity: 0.8, marginBottom: "4vh" }}>
                You have successfully linked your Physmodo assessment results to {linkedToText}
              </p>
              <p style={{ fontSize: isMobile ? "30px" : "15px", opacity: 0.751 }} className="d-flex justify-content-center">
                {monitoringBenefit} will be able to monitor your progress as you perform the Physmodo Movement Assessment during the year, 

                and provide you with specific guidance and programming to achieve your goals.
              </p>
            </div>
          ) : (
            <div style={{ width: "80vw", marginLeft: "auto", marginRight: "auto", marginTop: "7vh" }}>
              <p style={{ fontSize: isMobile ? "30px" : "15px", opacity: 0.8, marginBottom: "4vh" }} className="d-flex justify-content-center">
                {fullErrorMsg}
              </p>
            </div>
          )}

        </div>
        { isMobile &&
          <p style={{ textAlign: "center", width: "100vw", height: "7vw", fontSize: "1.6vw", padding: "3.0vw", opacity: "0.3" }}>
            Version: {packageJson.version}
          </p>
        } 
      </ContentWrapper>
    </div>
  );
}

export default Accept;