// ViewEditAssociatedItems.js

import React from 'react';
import { useState, useEffect, useRef } from "react";

import styled from 'styled-components';
import { RedSpan } from './title';
import { colors, iconSrc } from '../../constants';
import { getAssociatedItems, addAssociatedItem, removeAssociatedItem } from '../../services';
import { capitalize } from '../../helpers';

import SiteButton from './SiteButton';

const isCoach = localStorage.getItem('coach') === 'true' ? true : false;

const SortableTableRow = styled.tr`
  cursor: pointer;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-bottom: 1px solid #f0f0f0;
  &:hover { background: ghostwhite };
`;

const TableHead = ({ columns, handleSorting }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    console.log('~~~~~ ViewEditAssociatedItems, SortableTableRow - accessor = ' + accessor);
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    console.log('~~~~~ ViewEditAssociatedItems, SortableTableRow - sortOrder = ' + sortOrder);
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
   <thead>
    <tr style={{ borderBottom: "1px solid #e9e9e9", whiteSpace: "nowrap" }}>
     {columns.map(({ label, accessor, sortable, minWidth }) => {
      return (
        <th 
          key={accessor}
          title={sortable ? "Click to sort by this column" : ""}
          onClick={sortable ? () => handleSortingChange(accessor) : null}
          style={{
            fontSize: "1vw", 
            color: "#b4b4b4", 
            fontWeight: "400", 
            paddingRight: "0.5vw", 
            paddingLeft: "0.5vw", 
            minWidth: minWidth ? minWidth : "60px", 
            cursor: sortable ? "pointer" : "default",
            textAlign: accessor === "addRemove" ? "right" : "left"
          }}
        >
          {label}
        </th>
      );
     })}
    </tr>
   </thead>
  );
 };

 const TableBody = ({ tableData, columns, rowClick, adding, itemType, assocType, loaded }) => {
  const EditIcon = styled.img`
    display: inline-block;
    width: 1.6vw;
    height: 1.6vw;
    margin: 0 0 0 0 !important; // updated for 1.6vw svg images
    padding: 0 !important;
    opacity: 0.5;
    &:hover {
      opacity: 1.0;
    }
  `;

  const itemTypeDisplay = itemType !== "program" ? itemType : "workout";
  const assocTypeDisplay = assocType !== "program" ? assocType : "workout";

  // let idAccessor = itemType === "athlete" ? "user_id_guid" : "id_guid";
  let idAccessor = (itemType === "athlete" && adding) ? "user_id_guid" : "id_guid"; //TODO: why have && adding?

  if (itemType === "program") { idAccessor = adding ? "id_guid" : "program_id_guid"; }
  // let idAccessor = "user_id_guid"; // TODO: change this back after Nathan fixes/reverts the endpoint to what it was...
  // what about when program is the itemType?
  // we have program_id_guid, program_name_string, program_user_id_guid and program_public_boolean $#@!^&%$
  // what about when athlete is the itemType?
  // we have id_guid, first_name_string, last_name_string, email_string $#@!^&%$


  if (!tableData || tableData.length === 0) {
    let hidden = !loaded;
    return (
      <tbody>
        <tr>
          <td colSpan="2" align="center">
            { !loaded && 
              <RedSpan>
                Loading the list of {capitalize(itemTypeDisplay)}s to {adding ? "Add" : "Remove"}...
              </RedSpan>
            } 
            { loaded && 
              <span style={{ visibility: "visible", opacity: "0.6" }}>
                There are no {capitalize(itemTypeDisplay)}s to {adding ? "Add!" : "Remove!"}
              </span>
            } 
          </td>
        </tr>
      </tbody>)
  } else {
    return (
      <tbody>
        {tableData.map((data) => {

          return (
            <SortableTableRow key={data.id_guid}  onClick={function(event){rowClick(event, data[idAccessor])}}>
            {columns.map(({ accessor, align, color }) => {
              let tData = data[accessor] ? data[accessor] : "——";
              
              if (accessor === "user_association_athlete_user_name_string" && tData === "——") {
                let first_name = data.first_name_string;
                let last_name = data.last_name_string;
                if (first_name && first_name.length > 0 && last_name && last_name.length > 0) {
                  tData = first_name + " " + last_name;
                } else {
                  tData = data.email_string
                }
              }
              
              console.log('~~~~~ ViewEditAssociatedItems, tableBody() - for accessor ' + accessor + ', tData was ' + tData);
              
              let rowCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0.6vw 0vw 0.6vw 0.6vw" }}>{tData}</td>;
              if (accessor === 'addRemove' && data.id_guid !== "00000000-0000-0000-0000-000000000000") {
                rowCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0.5vw" }}>
                    <EditIcon src={adding ? iconSrc[itemType + 's'] : iconSrc['remove_active']} title={adding ? "Add the " + capitalize(itemTypeDisplay) + " to this " + capitalize(assocTypeDisplay) : "Remove the " + capitalize(itemTypeDisplay) + " from this " +  capitalize(assocTypeDisplay)} />
                </td>;
              }
              if (accessor === 'addRemove' && data.id_guid === "00000000-0000-0000-0000-000000000000") {
                rowCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0.5vw" }}></td>;
              }                 

              return rowCode;
            })}
            </SortableTableRow>
          );
        })}
        <tr>
          <td colSpan="2" align="center" style={{ opacity: "0.6" }}>
            Click on any {capitalize(itemTypeDisplay)} above to { adding ? "add" : "remove" }
          </td>
        </tr>
      </tbody>
    );
  }
 };                                                                                     // padding: 0.6vw 0vw 0.6vw 0.6vw !important; /* top right bottom left */ 

function ViewEditAssociatedItems(props) { // itemType, itemId, assocType, assocId
  const [tableData, setTableData] = useState([]); // will be useState([]);
  const [assocItems, setAssocItems] = useState([]);
  const [notAssocItems, setNotAssocItems] = useState([]);
  const [fetchedAssoc, setFetchedAssoc] = useState(false);
  const [fetchedNotAssoc, setFetchedNotAssoc] = useState(false);
  const [adding, setAdding] = useState(false);
  const [loaded, setLoaded ] = useState(false);
  const mounted = useRef(true);
  const [firstSort, setFirstSort] = useState(false);
  let sorted = [];

  const reload = props.reload;
  const itemType = props.itemType;    // the modelType of the 'parent' -ish item we are viewing (i.e. group)
  const itemTypeDisplay = itemType !== "program" ? itemType : "workout";
  const assocType = props.assocType;  // the modelType of the records that will be returned by this query s/b lowercase?
  const assocTypeDisplay = assocType !== "program" ? assocType : "workout";
  const assocId = props.assocId;      // the id_guid of the associated record we will be adding or removing
  // const nameAccessor = assocType === "athlete" ? "user_association_athlete_user_name_string" : "name_string";
  const onAssocModified = props.onAssocModified;
  // const active = props.active; 

  console.log('~~~~~ ViewEditAssociatedItems() - itemType is *' + itemType + '*, and assocType is *' + assocType + '*');

  let nameAccessor = "user_association_athlete_user_name_string";
  // if (itemType === "program") { nameAccessor = adding ? "program_name_string" : "program_name_string"; }
  if (itemType === "program") { nameAccessor = "name_string"; } // TODO: remove all this conditional logic when Nathan has used "name_string" everywhere...
  
  if (itemType === "group") { nameAccessor = "name_string"; }

  console.log('~~~~~ ViewEditAssociatedItems, itemType is *' + itemType + '*, and assocType is *' + assocType + '*');

  const columns = [ // these might be the same for every associated items table?
  // { label: "Name", accessor: assocType === "athlete" ? "user_association_athlete_user_name_string" : "name_string", align: "left", color: tealText, sortable: true, minWidth: "200px" },
    { label: "Name", accessor: nameAccessor, align: "left", color: colors.accent, sortable: true, minWidth: "200px" },
    { label: adding ? "Add" : "Remove", accessor: "addRemove", align: "right", color: colors.lightGreyText, sortable: false }
  ];

  const handleSorting = (sortField, sortOrder) => {

    if (sortField && assocItems && assocItems.length > 1) {
      sorted = assocItems.sort((a, b) => {
        let aToString = "";
        let bToString = "";
        if (a[sortField]) {
          aToString = a[sortField].toString().length > 0 ? a[sortField].toString().replace('.','') : "";
        }
        if (b[sortField]) {
          bToString = b[sortField].toString().length > 0 ? b[sortField].toString().replace('.','') : "";
        }

        return (
          aToString.localeCompare(bToString) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      console.log('~~~~~ ViewEditAssociatedItems, handleSorting() - sorted.length: ' + sorted.length);
      setTableData(sorted);
    }
  };

  useEffect(() => {
    setLoaded(false);
    mounted.current = true; // TODO: how is this useful?
    if(fetchedAssoc) { 
      return;
    }
    // an array of itemType objects will be returned, each one of them will be associated with the assocType record assocId
    // the last parameter is inverseBool, if true, the items returned will be all this Coach's itemType NOT associated w/assocId
    getAssociatedItems(itemType, assocType, assocId, false)
    .then(itemsResp => {
      setFetchedAssoc(true); 
      if(mounted.current) {
        let items = Array.isArray(itemsResp) ? itemsResp : itemsResp.data; // works for Athletes when finding associated Groups
        // let items = itemsResp; // works for Groups when finding associated Athletes

        if (items) {
          setAssocItems(items);
          setTimeout(function() {
            setLoaded(true); // arbitrary choice of this api call as longest
          }, 1000);
        }

        if (!items) {
          console.log('~~~~~ ViewEditAssociatedItems, useEffect() - items is currently undefined');
        } else {
          console.log('~~~~~ ViewEditAssociatedItems, useEffect() - ' + items.length + ' associated items were returned');
        
          return items[0]
        }
      }
    })
  }, [assocId, assocItems, assocType, fetchedAssoc, itemType])

  function getNotAssocItems(itemType, assocType, assocId) {
    console.log('~~~~~ ViewEditAssciatedItems, getNotAssocItems()');
    setLoaded(false);
  
    mounted.current = true; // TODO: how is this useful?
    if(fetchedNotAssoc) { 
      alert('~~~~~ returning!');
      return;
    }
    
    getAssociatedItems(itemType, assocType, assocId, true)
    .then(itemsResp => {

      if(mounted.current) {
        let items = itemsResp;
        
        if (items) {
          setNotAssocItems(items);
          setTimeout(function() { // TODO: evaluate whether this is needed here, and elsewhere...
            setLoaded(true);
          }, 500);
        }

        if (!items) {
          console.log('~~~~~ ViewEditAssociatedItems, getNotAssocItems() - items is currently undefined');
        } else {
          console.log('~~~~~ ViewEditAssociatedItems, getNotAssocItems() - ' + items.length + ' not-associated items were returned');
        
          return items[0]
        }
        
        alert('~~~~~ ViewEditAssociatedItems, useEffect() - ' + items.length + ' associated items were returned');
        console.log('~~~~~ ViewEditAssociatedItems, getNotAssocItems() - ' + items.length + ' notAssociated items were returned');
      }
    })
  }

  function handleAddAssociationClick(event) { // when 'Add a {itemType}' button is clicked
    console.log(`~~~~~ ViewEditAssociatedItems, handleAddAssociationClick() - `);
    setAdding(true);
    setFetchedNotAssoc(false);
    setLoaded(false);

    setTimeout(function() {
      getNotAssocItems(itemType, assocType, assocId);
    }, 500);
  }

  function handleRemoveAssociationClick(event) { // when 'Add a {itemType}' button is clicked
    console.log(`~~~~~ ViewEditAssociatedItems, handleDeleteAssociation() - `);
  
    setAdding(false);
  }

  function removeThisAssocClick(event, itemId) { // TODO: rename to removeThisAssocItemClick ?
    console.log('~~~~~ Removing ' + itemType + ' with Id_guid ' + itemId + ' from this ' + assocType);
    // alert('~~~~~ Removing ' + capitalize(itemType) + ' with Id_guid ' + itemId + ' from this ' + capitalize(assocType));
    let alertMsg = '';
    // console.log says itemType "athlete" and assocType "group"

    removeAssociatedItem(itemType, itemId, assocType, assocId)
    .then(respStatus => {

      if (respStatus === 204) {
        alertMsg = 'The associated ' + capitalize(itemTypeDisplay) + ' has been deleted and removed from your list';
      } else {
        alertMsg = 'There was a problem removing the ' + capitalize(itemTypeDisplay) + ' - Response Status Code was ' + respStatus;
      }
      console.log('~~~~~ ViewEditAssociatedItems, removeThisAssocClick() - response status code was ' + respStatus);
    })

    setTimeout(function() {
      if (reload) { 
        window.location.reload()
      } else {
        // code that updates the assocItems list in the modal, *without* resetting right pane to "Create" mode display
        console.log('~~~~~ ViewEditAssociatedItems, removeThisAssocClick() - in setTimeout() before setAdding(false)');
        // alert(alertMsg);
        console.log('~~~~~ ViewEditAssociatedItems, removehisAssocClick() - ' + alertMsg);
        onAssocModified();
      }
    }, 300);
  }
  
  function addThisAssocClick(event, itemId) {
    console.log('~~~~~ Adding ' + itemType + ' with Id_guid ' + itemId + ' to this ' + assocType);
    let alertMsg = '';
    // console.log says itemType "athlete" and assocType "group"

    addAssociatedItem(itemType, itemId, assocType, assocId)
    .then(respStatus => {

      if (respStatus === 201) {
        alertMsg = 'The ' + capitalize(itemTypeDisplay) + ' has been added and will appear in your list';
      } else {
        alertMsg = 'There was a problem adding the ' + capitalize(itemTypeDisplay) + ' - Response Status Code was ' + respStatus;
      }
      console.log('~~~~~ ViewEditAssociatedItems, addThisAssocClick() - addAssociatedItem() - response status code was ' + respStatus);
    })

    setTimeout(function() {
      if (reload) { 
        window.location.reload()
      } else {
        // code that updates the assocItems list in the modal, *without* resetting right pane to "Create" mode display
        console.log('~~~~~ ViewEditAssociatedItems, addThisAssocClick() - in setTimeout() before setAdding(false)');
        // alert(alertMsg);
        console.log('~~~~~ ViewEditAssociatedItems, addThisAssocClick() - ' + alertMsg);
        onAssocModified();
      }
    }, 300);
  }

  if (!firstSort) {
    setTimeout(function() {
      console.log('~~~~~ ViewEditAssociatedItems - first sorting!');
      handleSorting('name_string', 'asc');
      setFirstSort(true);
    }, 500);
  }

  return (
    <div>
      <table cellPadding={10} width="100%">
        <TableHead {...{ columns, handleSorting }} />
        <TableBody 
          columns={columns}  
          tableData={adding ? notAssocItems : assocItems}
          rowClick={adding ? addThisAssocClick : removeThisAssocClick} 
          adding={adding}
          itemType={itemType}     // this is the type of item the will be added or removed (the itemType for the 'associated' lists)
          assocType={assocType}   // this is the type of the 'parent' item, that the tab is probably named for
          loaded={loaded}
        />
      </table>
      <SiteButton 
        icon={iconSrc[itemType + 's_active']} // this assumes that ...?
        title={adding ? 'Remove ' + capitalize(itemTypeDisplay) : 'Add ' + capitalize(itemTypeDisplay)} 
        onClick={adding ? handleRemoveAssociationClick : handleAddAssociationClick}
        style={{ marginTop: "20px", width: "auto" }}
      />
    </div>
  );

}

export default ViewEditAssociatedItems;
