// UserInvite.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { 
  colors,
  logoSrc,
  iconSrc
 } from '../../constants';
 import { ExploreIcon, ShellOvalBtn } from './title';
 import { addMemberLink, editMemberLinking, submitUserEmailList, getAthleteSignupLink, getUserGroupSignupLink } from '../../services';
 import LoadingDivLayer  from './LoadingDivLayer';


const tabSectionItemsWidth = "90%";
const tabSectionItemsVerticalSpacing = "10px";

const FakeUserInviteWrapper = styled.div`
  /* cursor: pointer; */
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  white-space: nowrap;
  width: 80%;
  height: 470px;
  padding: 0px;
  padding-top: 10px;
  margin: auto;
  margin-top: -2px;
  text-align: center;
  vertical-align: middle;
  /* background: url("Shell_assets/fakes/user_invite.png");
  background-size: cover; */
  /* background: red; */
`;

const InviteRadioBtnsDiv = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: middle;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  width: 280px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
`;

const InviteRadioBtnLabel = styled.label`
  cursor: pointer;
  display: inline-block;
  width: 150px;
  height: 28px;
  padding: 7px;
`;

const RadioBtnLabelTextDiv = styled.div`
  display: inline-block;
  padding-left: 10px;
  /* padding-bottom: 15px; */
  margin-right: 30px;
  color: ${colors.exploreBlueBtn}; //"#5b79fd";
`;

const InviteRadioBtn = styled.input`
  width: 24px;
  height: 24px;
  margin: 0;
  /* margin-bottom: -10px; */
  padding: 0;
`;

const InviteTextarea = styled.textarea`
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  white-space: wrap;
  border: 1px solid #343434;
  border-radius: 12px;
  background-color: #eeeeee;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 7px;
`;

const InviteLinkAndQRCodeSectionDiv = styled.div`
  width: 100%;
  min-height: 30px;
  height: auto;
  padding: 0px;
  /* background: #eeefff; */
  /* border: 1px solid red; */
  margin-top: 70px;
`;

const InviteInstructionsAndCopyLinkDiv = styled.div`
  width: 75%;
  min-height: 120px;
  /* background: red; */
`;

const InviteInstructionsDiv = styled.div`
  width: 100%;
  min-height: 50px;
  /* background: #ccccFF; */
  text-wrap: wrap;
  text-align: left;
  padding: 5px;
  font-weight: 700;
`;

const InviteCopyLinkDiv = styled.div`
  width: 100%;
  min-height: 80px;
  /* background: green; */
  padding: 0px;
`;

const InviteCopyLinkBtn = styled.div`
  width: 25%;
  min-height: 30px;
  background: #eeeeee;
  border-radius: 15px;
  margin-top: 25px; 
  float: left;
`;

const InviteLinkTextDiv = styled.div`
  width: 75%;
  min-height: 80px;
  text-wrap: wrap;
  text-align: left;
  padding: 5px;
  padding-left:10px;
  /* background: pink; */
  float: right;
`; 

const InviteQRCodeImg = styled.img`
  width: 25%;
  /* height: 25%; */
  margin: 0px;
`;


const UserInvite = ({
  // do we need any input props here, for UserInvite context? a group_id? a user_id? anything?
  groupId, onInviteSubmit
}) => {
  const mounted = useRef(true);
  const [loaded, setLoaded] = useState(true); // defaults to true so always comes up loaded
  const [signupUrl, setSignupUrl] = useState('');
  const [qrCodeSrc, setQrCodeSrc] = useState('');
  const [showSignupLink, setShowSignupLink] = useState(true);
  const [athleteEmailList, setAthleteEmailList] = useState();
  const [staffRadio, setStaffRadio] = useState('');
  const [inviteOk, setInviteOk] = useState(false);


  function inviteUsers(term){
    console.log('~~~~~ UserInvite(), inviteUsers()');

    if (staffRadio === '') {
      alert("You must choose to Invite these Users either as Athletes or as Staff before proceeding");
    } else {
      setLoaded(false); // spins up loading/activity indicator
      submitUserEmailList(athleteEmailList, groupId, staffRadio === 'staff')
      .then(item => { // item is going to be what is returned from the service method... 
        console.log('~~~~~ UserInvite(), inviteUsers() - item: ' + item);
        const obj_str = JSON.stringify(item);
        console.log('~~~~~ UserInvite(), inviteUsers() - obj_str: ' + obj_str);
        onInviteSubmit(athleteEmailList, groupId, staffRadio === 'staff');
      })
    }
  }

  function copyLink(){
    navigator.clipboard.writeText(signupUrl).then(() => {
      alert('Signup Link URL copied to clipboard!');
    });
  }

  function updateRadioValue(){
    noRadioSelected();
    // var ele = document.getElementsByName('athlete_or_staff');
    // let radioValue = 'athlete';
    // for (let i = 0; i < ele.length; i++) {
    //   if (ele[i].checked){
    //     radioValue = ele[i].value;

    //     // if (ele[i].value === "staff"){
    //     //   setShowSignupLink(false);
    //     // } else {
    //     //   setShowSignupLink(true);
    //     // }
    //     // setTimeout(() => {
    //     // setStaffRadio(radioValue);
    //     // },300);
    //     // setStaffRadio(ele[i].value); 
    //     // staffRadio === "athlete"
    //   }
    // } 
  }

  function noRadioSelected(){
    let noStaffAthlete = /staff|athlete/.test("hello world");
    console.log('~~~~~ UserInvite(), noStaffAthlete = ' + noStaffAthlete);
  
    let yesAthlete = /staff|athlete/.test("athlete");
    console.log('~~~~~ UserInvite(), yesAthlete = ' + yesAthlete);
  
    // console.log('~~~~~ UserInvite(), ')
  
    let elementArray = document.getElementsByName('athlete_or_staff');
    let athleteChecked = elementArray[0].checked;
    let staffChecked = elementArray[1].checked;
    let inviteBlocked = !athleteChecked && !staffChecked;
    console.log('~~~~~ UserInvite(), elementArray = ' + elementArray);
    console.log('~~~~~ UserInvite(), elementArray.length = ' + elementArray.length);
    console.log('~~~~~ UserInvite(), elementArray[0] = ' + elementArray[0]);
    console.log('~~~~~ UserInvite(), athleteChecked = ' + athleteChecked);
    console.log('~~~~~ UserInvite(), staffChecked = ' + staffChecked);
    console.log('~~~~~ UserInvite(), inviteBlocked = ' + inviteBlocked);
  


    return false;
  }

  // setTimeout(() => {
  //   noRadioSelected();
  // }, 1000);

  // console.log('~~~~~ UserInvite(), noRadioSelected() = ' + noRadioSelected());


  // let noRadioSelected = document.getElementsByName('athlete_or_staff')[0].value.includes('staff') || document.getElementsByName('athlete_or_staff')[0].value.includes('staff') ;

  // let noRadioSelected = "/staff|athlete/".test(document.getElementsByName('athlete_or_staff')[0].value);
// var value = /hello|hi|howdy/.test(str);

  const intervalID = setInterval(() => {
    var ele = document.getElementsByName('athlete_or_staff');
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked){
        if (ele[i].value === "staff"){
          setShowSignupLink(false);
          setStaffRadio('staff');
        } else {
          setShowSignupLink(true);
          setStaffRadio('athlete');
        }
      }
    }    
  }, 300);

  useEffect(() => {
    mounted.current = true; // TODO: how is this useful?
    if(signupUrl.length !== 0) { 
      console.log('~~~~~ UserInvite(), useEffect() linkUrl - signupUrl.length !== 0');
      return;
    }

    getUserGroupSignupLink(groupId)
    .then(item => { // item is going to be what is returned from the service method...
      const link_url = item.url_string;
      const qr_code_src = item.url_qr_code_base64_image_string; 
      console.log('~~~~~ Members.js, handleCreateLinkClick() - link_url: ' + link_url);
      console.log('~~~~~ Members.js, handleCreateLinkClick() - qr_code_src: ' + qr_code_src);
      setSignupUrl(link_url);
      setQrCodeSrc(qr_code_src);
      setShowSignupLink(true); // what will we use this for?
      noRadioSelected();
    })
  }, [groupId, signupUrl.length])

  return (
    <>
      {loaded ? '' : <LoadingDivLayer  style={{ position: "relative", width: "100%", height: "100%" }}/>}
      <FakeUserInviteWrapper>
        <InviteRadioBtnsDiv>
          <InviteRadioBtnLabel htmlFor="athlete">
            <input type="radio" id="athlete" name="athlete_or_staff" value="athlete" onChange={updateRadioValue} />
            <RadioBtnLabelTextDiv>Invite as Athlete</RadioBtnLabelTextDiv> 
          </InviteRadioBtnLabel>
          <InviteRadioBtnLabel htmlFor="staff">
            <input type="radio" id="staff" name="athlete_or_staff" value="staff" onChange={updateRadioValue} />
            <RadioBtnLabelTextDiv>Invite as Staff</RadioBtnLabelTextDiv> 
          </InviteRadioBtnLabel>
        </InviteRadioBtnsDiv>

        <InviteTextarea 
          name="invite_textarea" 
          id="invite_textarea" 
          rows="8" 
          onBlur={e => setAthleteEmailList(e.target.value)}
          placeholder="Paste or type a list of one or more Athlete emails here, separated by commas or returns - &#10; &#013; then click 'Invite' and all the users will be emailed invitations to join this group!">
        </InviteTextarea>  


        <ShellOvalBtn style={{ display: "block", margin: "0px", width: "100px", float: "right", opacity: staffRadio === '' ? "0.250" : "1.0" }} onClick={() => inviteUsers()} title="Send eMail invitations to all the email addresses" disabled={noRadioSelected} >Invite</ShellOvalBtn>

        {showSignupLink &&
          <InviteLinkAndQRCodeSectionDiv style={{ cursor: "auto", clear: "both" }}>
            <InviteInstructionsAndCopyLinkDiv style={{ cursor: "auto", float: "left" }}>
              <InviteInstructionsDiv style={{ cursor: "auto" }}>
                Invite new users to signup using the provided link, QR code, or email invite. &nbsp; <span style={{ color: "#5b79fd" }} >NOTE: Staff users must be invited by email.</span>
              </InviteInstructionsDiv>
              <InviteCopyLinkDiv style={{ cursor: "auto" }}>
                {/* <InviteCopyLinkBtn style={{ cursor: "auto" }} onClick={copyLink} /> */}
                <ShellOvalBtn style={{ cursor: "pointer", margin: "0px", marginTop: "25px", width: "25%", float: "left" }} title="Copy SignUp Link URL" onClick={copyLink}>
                  {/* <ExploreIcon src={} /> */}
                  Copy Link
                </ShellOvalBtn>
                <InviteLinkTextDiv style={{ cursor: "auto" }}>
                  {signupUrl}
                </InviteLinkTextDiv>
              </InviteCopyLinkDiv>
            </InviteInstructionsAndCopyLinkDiv>
            <InviteQRCodeImg src={qrCodeSrc} style={{ cursor: "auto", float: "right" }} />
          </InviteLinkAndQRCodeSectionDiv>
        }
      </FakeUserInviteWrapper>
    </>
    

  );

}

export default UserInvite;