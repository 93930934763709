// ShellPrimaryIssuesTable.js

import React from 'react';
import styled from 'styled-components'

import ShellScoreDisplay from './ShellScoreDisplay';


const ShellPrimaryIssuesTableWrapper = styled.div`
  width: 50%;
`;

const ShellPrimaryIssuesTableElement = styled.table`
  width: 100%;
`;

const TableTitle = styled.h2`
  max-width: 100%;
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 30px;
  /* font-size: 14px; */
  ${ props => props.isMobile === true
    ? '{ font-size: 3vw; }'
    : '{ font-size: 14px; }'
  }
`;

const StyledTableRow = styled.tr`
  cursor: pointer;
  padding-top: 30px;
  padding-bottom: 30px;
  /* &:hover { background: ghostwhite }; */ /* no hover state here */
`;

const TableBody = ({ tableData, maxRows, domain, isMobile }) => {
  var domainData = tableData.filter((issue) => {
    return issue.domain === domain;
  });

  const noIssues = !tableData || !Array.isArray(tableData) || tableData.length === 0;

  console.log('~~~~~ ShellPrimaryIssuesTable.js, TableBody() - isMobile is ' + isMobile);

  return (
    <>
      {!noIssues &&
        <tbody>
          {domainData.slice(0, maxRows).map((data) => {
            return (
              <StyledTableRow key={data.id}>
                <td>
                  <ShellScoreDisplay data={data} key={"score-display-" + data.id} isMobile={isMobile} />
                </td>
              </StyledTableRow>
            )})
          }
        </tbody> 
      }
      {noIssues &&
        <tbody>
          <StyledTableRow key="no issues">
            <td>
              No {domain} issues were found! 
            </td>
          </StyledTableRow>
        </tbody> 
      }
    </>
  );
};

const ShellPrimaryIssuesTable = ({
  data, domain, maxIssues, isMobile
}) => {

  return (
    <ShellPrimaryIssuesTableWrapper>
      <TableTitle isMobile={isMobile}>PRIMARY ISSUES</TableTitle>
      <ShellPrimaryIssuesTableElement>
        <thead><tr><th></th><th></th></tr></thead>
        <TableBody tableData={data} domain={domain} maxRows={maxIssues} isMobile={isMobile} />
      </ShellPrimaryIssuesTableElement>
    </ShellPrimaryIssuesTableWrapper>
  );
}

export default ShellPrimaryIssuesTable;
