// Movements.js

import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { deleteMovement } from '../services';
import { iconSrc } from '../constants';

import {
  AreaTitle,
  SplitTabContentWrapper,
  TabSectionLeft,
  TabSectionRight,
  EZSpacer 
} from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import ItemsTable from './styled/ItemsTable';
// import ViewEditAssociatedItems from './styled/ViewEditAssociatedItems';
import SiteButton from './styled/SiteButton';
import ViewEditItem from './styled/ViewEditItem';

let formItemId = '';


function Movements() {
  const [items, setItems] = useState([]); // TODO: do we need this here? Or are items fetched inside of ItemsTable & ViewEditItem?
  const [itemId, setItemId] = useState([]);
  const [itemName, setItemName] = useState([]);
  const [itemNotes, setItemNotes] = useState([]);
  const [formItem, setFormItem] = useState({});

  const [filterType, setFilterType] = useState("exercise"); // TODO: do we need this? can we pass in a string as prop for ItemsTable?
  const [filterId, setFilterId] = useState();

  const [viewEditItem, setViewEditItem] = useState(true);
  const [editingItem, setEditingItem] = useState(false); // this means there might be a submittal, so there should be an 'Update' button
  const [newItem, setNewItem] = useState(true);         // this means there might be a submittal, so there should be a 'Create' button
  const [showRemoveItemConfirm, setShowRemoveItemConfirm] = useState(false);
  const [loaded, setLoaded ] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  

  const mounted = useRef(true);
  
  const urlPieces = window.location.toString().split("/");
  const lastPiece = urlPieces[urlPieces.length - 1];  // the last piece might have a queryString e.g groups?email=greg@physmodo
  const tabReference = lastPiece.split('?')[0]; // should be singular, so itemType below will remove last character
  const itemType = tabReference.charAt(0).toUpperCase() + tabReference.slice(1).substring(0, tabReference.length - 2);
  
  const noLongerAbleText = "to assign Athletes or Programs to it"; // TODO: have this assigned by itemType, somewhere...
  // example: "to see it in any of your lists" would work as a default case...

  console.log('~~~~~ Movements.js - itemType: ' + itemType);
  console.log('~~~~~ Movements.js - tabReference: ' + tabReference);

  const itemInfo = require('./../models/' + itemType.toLowerCase()).default; // { type, attributes, listColumns, displayFields, defaultSort }

  function setItemAttributes(itemid) {  // TODO: is there any point in making this type-agnostic? (ie parse from displayFields?)
    const matchingObjsArray = items.filter((item) => {
      return item.id_guid === itemid;
    });

    // replace this next code section with something that might key off off data in the models/[itemname].js file
    // ideally, if we are using separate useState variables for each attribute, we automaticaly declare appropriate
    // names and values for those State variables depending on what is conditionally imported in the models/[itemname].js file
    // -- alternately, could we set an Object as the value of a state var itemObj, and then use attribute names from the model
    // in the form itemObj[attributeName] to iterate though them as required? or itemObj[keys]...?

    if (itemid.length >5) { // there was a valid guid provided, not the word "new"
      console.log('~~~~~ Movements.js - setItemAttributes() for Item ' + matchingObjsArray[0].name_string);
      setItemId(matchingObjsArray[0].id_guid);
      setItemName(matchingObjsArray[0].name_string);
      setItemNotes(matchingObjsArray[0].note_string);
    } else {
      setItemId("");
      setItemName("");
      setItemNotes("");
    }
  }

  function itemFromId(id_guid) {
    console.log('~~~~~ Movements.js - itemFromId() for id_guid ' + id_guid);

    const matchingObjsArray = items.filter((item) => {
      return item.id_guid === id_guid;
    });

    console.log('~~~~~ Movements.js - itemFromId() for id_guid ' + id_guid + ', matchingObjsArray.length is ' + matchingObjsArray.length);

    // console.log('~~~~~ Movements.js - itemFromId() for id_guid ' + id_guid + ', video_url_string is ' + matchingObjsArray[0].video_url_string);

    if (matchingObjsArray.length > 0) {
      let formItemObj = matchingObjsArray[0];

      console.log('~~~~~ Movements.js - itemFromId() for id_guid ' + id_guid + ', matchingObjsArray[0].title_string is ' + formItemObj.title_string);
      
      setFormItem(formItemObj);
    }
  }

  function handleAddItemClick(event) {
    console.log('~~~~~ Movements.js - handleAddItemClick()');
    
    setItemAttributes("new"); // if no movementid is provided here, fields are set to blank
    setViewEditItem(true);
    setNewItem(true);
  }
  
  function handleRemoveClick(event, itemid) {
    event.stopPropagation();
    setItemAttributes(itemid); // need to set these here and use them in the Modals...

    console.log('~~~~~ Movements.js - handleRemoveClick() for Item ' + itemName);
    
    setShowRemoveItemConfirm(true); 
  }
  const handleCloseRemoveItemConfirm = () => setShowRemoveItemConfirm(false);

  function handleRowClick(event, itemid) {
    console.log('~~~~~ Movements.js - handleRowClick() - open Item View/Edit panel in right tab section for itemid ' + itemid);

    setItemAttributes(itemid);
    setViewEditItem(true);
    setNewItem(false);          // no longer in create item mode
    setEditingItem(false);      // not yet actually editing, since no data has been changed...
  }

  function sortItemsByName(itemsArray) {
    itemsArray.sort(function(a,b){
      return b.name_string - a.name_string;
    });
  
    return itemsArray;
  }

  function removeItem(event, itemId) { 
    console.log('~~~~~ Movements.js - removeItem() - remove Item ' + itemName);

    deleteMovement(itemId) // should return respStatus 204 = delete worked, 200 = edit worked, 422 = bad request
      .then(respStatus => {
        let alertMsg = '';

        if (respStatus === 204) {
          // alertMsg = itemType + ' ' + itemName + ', with id_guid ' + itemId + ' has been deleted and removed from your list';
          alertMsg = itemType + ' ' + itemName + ' has been deleted and removed from your list';
        } else {
          alertMsg = 'There was a problem deleting ' + itemType + ' ' + itemName + ', with id_guid ' + itemId + ', Response Status Code ' + respStatus;
        }
        
        setTimeout(function() {
          window.location.reload();
          alert(alertMsg);
        }, 500);
      })
  }

  function handleRemoveItemConfirmClick(event) {
    removeItem();
    handleCloseRemoveItemConfirm();
  }

  function handleAddAssociationClick(event) {
    alert('Not yet implemented :-(');
  }

  function handleItemClick(event) {
    console.log('~~~~~ Movements.js, Movements() - handleItemClick()');
    const parentTryId = event.target.parentElement.id;
    formItemId = parentTryId;
    setItemId(parentTryId); // might take some time because of lag updating State
    itemFromId(parentTryId); // this should set the appropriate item as formItem, which is a prop for ViewEditItem
    console.log('~~~~~ Movements.js - handleItemClick() - parentTryId is *' + parentTryId + '*');
    console.log('~~~~~ Movements.js - handleItemClick() - formItemId is *' + formItemId + '*');
    console.log('~~~~~ Movements.js - handleItemClick() - itemId is *' + itemId + '*');
    setViewEditItem(true);
    setNewItem(false);          // no longer in create item mode
    setEditingItem(false);      // not yet actually editing, since no data has been changed...
  }

  function handleEditClick(event) {
    console.log('~~~~~ Movements.js, Movements() - handleEditClick()');
    alert('~~~~~ Movements.js, Movements() - handleEditClick()');
  }

  function updateStats(statsObjet) {
    console.log('~~~~~ Movements.js, Movements() - updateStats()');
    alert('~~~~~ Movements.js, Movements() - updateStats()');
  }

  function updateItems(itemsArray) {
    console.log('~~~~~ Movements.js, Movements() - updateItems(), ' + itemsArray.length + ' items were passed to parent State');
    setItems(itemsArray);
    setLoaded(true);
    // alert('~~~~~ Movements.js, Movements() - updateItems(), ' + itemsArray.length + ' items were passed to parent State');
  }

  const columnClick = {
    removeLink: removeItem
  }
  
  const handleEvents = { // TODO: imagine how this will work internally when different tables have different events to handle
    itemClick: handleItemClick, // maybe include some sort of mapping in models/[type] ?
    editClick: handleEditClick, // how does the local loaded State get set?
    itemsUpdate: updateItems,
    statsUpdate: updateStats,
    columnClick: columnClick
  }

  console.log('~~~~~ Movements.js, Movements() - itemType is *' + itemType + '*, filterType is *' + filterType + '*, modelInfo.type is *' + itemInfo.type + '*');
  
  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <SplitTabContentWrapper className="wrapper" loaded={loaded}>
        <TabSectionLeft>
          <AreaTitle>Your {itemType}s</AreaTitle>
          <ItemsTable 
            itemType={itemType}
            filterType={filterType}
            filterId={filterId}
            handleEvents={handleEvents}
            modelInfo={itemInfo}
          />
          <EZSpacer height="20px" />
          <SiteButton 
            icon={iconSrc[itemType.toLowerCase() + 's_active']}
            title={'Add Another ' + itemType} 
            onClick={handleAddItemClick}    // find a way to key off of type...
          />
        </TabSectionLeft>

        <TabSectionRight>
          <ViewEditItem 
            visible={(!false || true)} // TODO: set this for Movements case...
            create={newItem} 
            edit={editingItem} 
            // type={itemType} // get from modelInfo?
            fields={itemInfo.displayFields} 
            itemType={itemType} // get from modelInfo?
            item={formItem}
            itemId={formItemId}
            // itemName={itemName}
            // itemNotes={itemNotes}
            modelInfo={itemInfo}
          /> 
        </TabSectionRight>
        
        <Modal
          show={showRemoveItemConfirm}
          onHide={handleCloseRemoveItemConfirm}
          backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton style={{ borderBottom: "none", paddingLeft: "80px" }}>
            <Modal.Title style={{ opacity: "0.8", fontSize: "18px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Remove {itemType}  <br /><b>{itemName}</b>?</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: "0.7" }}>
            This will remove the {itemType} <b>{itemName}</b> from your list, and you will no longer be able {noLongerAbleText}.
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="primary" style={{ width: "20vw", marginLeft: "auto", marginRight: "auto", marginTop: "0px", marginBottom: "30px" }} onClick={handleRemoveItemConfirmClick}>Remove {itemType}</Button>
          </Modal.Footer>
        </Modal>

      </SplitTabContentWrapper>
    </div>
  );
}

export default Movements;