// components/styled/OrderedExerciseList.js

import React, { useState, useMemo } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
// import { SortableItem } from './SortableItem';
import { SortableExerciseCard } from './SortableExerciseCard';
import { AreaTitle } from './title';


const OrderedExerciseList = ({
  array, updateArray, onRemoveClick, onVideoClick, disabled
}) => {
  console.log('~~~~~ OrderedItemList() - array.length is *' + array.length + '*');
  let arrayItems = array.map((ex, index) => ({ ...ex, indexInt: index + 1 }));
  console.log('~~~~~ OrderedItemList(), arrayItems.length is ' + arrayItems.length + ', and latest item.name_string is ' + arrayItems[arrayItems.length - 1].name_string);

  const [items, setItems] = useState(arrayItems);
  // // const [items, setItems] = useState(array.map((ex, index) => ({ ...ex, indexInt: index + 1 })));
  // const itemIds = useMemo(() => items.map((item) => item.indexInt), [items]); // itemIds is an array of Ints
  // const exerciseArray = items.map(({indexInt, ...keepAttrs}) => keepAttrs); // exerciseArray loses indexInt attribute
  
  const itemIds = useMemo(() => arrayItems.map((item) => item.indexInt), [arrayItems]); // itemIds is an array of Ints
  const exerciseArray = arrayItems.map(({indexInt, ...keepAttrs}) => keepAttrs); // exerciseArray loses indexInt attribute 
  
  
  let orderedArray = []
  let outputArray = [];

  // console.log('~~~~~ OrderedItemList() - items.length is *' + items.length + '*');
  // console.log(items);
  console.log('~~~~~ OrderedItemList() - items.length is *' + arrayItems.length + '*');
  console.log(arrayItems);
  console.log('~~~~~ OrderedItemList() - itemIds.length is *' + itemIds.length + '*');
  console.log(itemIds);
  console.log('~~~~~ OrderedItemList() - exerciseArray.length is *' + exerciseArray.length + '*');
  console.log(exerciseArray);

  const active = true; // TODO: remove this and the reference in the div on line 66 or so

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  // function listOrder(array){
  //   let listOrderString = "";

  //   array.map(ex => {

  //   })
  // }

  function handleExerciseUpdate(updatedExercise){
    // console.log('~~~~~ OrderedExerciseList(), handleExerciseUpdate() - updatedExercise.exercise_id_guid is *' + updatedExercise.exercise_id_guid + '*, and updatedExercise.note_string is *' + updatedExercise.note_string + '*');

    // code here to update the exercise_array element in arrayItems
    let exObjIndex = arrayItems.findIndex(exObj => exObj.exercise_id_guid === updatedExercise.exercise_id_guid);
    // if (exObjIndex === undefined || exObjIndex === -1 || !exObjIndex){
    if (exObjIndex === -1){
      alert('the referenced exercise with exercise_id_guid *' + updatedExercise.exercise_id_guid + '* was not found in arrayItems');
    } else {
      arrayItems[exObjIndex] = updatedExercise;
    }

    // let exObjIndex2 = items.findIndex(exObj => exObj.exercise_id_guid === id_guid);
    // if (exObjIndex2 === undefined || !exObjIndex2){
    //   alert('the referenced exercise with id_guid *' + id_guid + '* was not found in items');
    // } else {
    //   items[exObjIndex2][fieldName] = newValue;
    // }
    
    // then save exercise_array up the chain with updateArray() 
    updateArray(arrayItems);
  }
  
  
  function handleDragEnd(event) {
    const eCTgt = event.activatorEvent.srcElement; // this element is a div child of <Card />
    const cardTgtId = event.activatorEvent.srcElement.parentElement.id; // this returns the id of the <Card /> element
    
    const {active, over} = event;

    if (disabled){
      alert('This record is Public, and cannot be edited here');
      return;
    }
    if (active.id === over.id) {
      return;
    }
    // setItems((items) => {
    //   const oldIndex = items.findIndex((item) => item.indexInt === active.id);
    //   const newIndex = items.findIndex((item) => item.indexInt === over.id);
    //   orderedArray = arrayMove( items, oldIndex, newIndex);
    //   return orderedArray;
    // });

    const oldIndex = arrayItems.findIndex((item) => item.indexInt === active.id);
    const newIndex = arrayItems.findIndex((item) => item.indexInt === over.id);
    console.log('~~~~~ OrderedItemList(), handleDragEnd() - oldIndex is *' + oldIndex + '*, and newIndex is *' + newIndex + '*');
    orderedArray = arrayMove(arrayItems, oldIndex, newIndex);
    arrayItems = orderedArray;
    setItems(orderedArray);

    outputArray = orderedArray.map(({indexInt, ...keepAttrs}) => keepAttrs); // orderedArray loses indexInt attribute
    updateArray(outputArray);

/*
    setTimeout(() => {
      console.log('~~~~~ OrderedItemList(), handleDragEnd() - setTimeout() after drag, orderedArray[0].name_string is *' + orderedArray[0].name_string + '*, and orderedArray[0].indexInt is *' + orderedArray[0].indexInt + '*');
      console.log('~~~~~ OrderedItemList(), handleDragEnd() - setTimeout() - orderedArray.length is *' + orderedArray.length + '*');
      // console.log('~~~~~ OrderedItemList(), handleDragEnd() - setTimeout() - orderedArray: ');
      console.log(orderedArray);
      // console.log('~~~~~ OrderedItemList(), handleDragEnd() - setTimeout() - items.length is ' + items.length);
      // console.log('~~~~~ OrderedItemList(), handleDragEnd() - setTimeout() - items[1].name_string is ' + items[1].name_string);
      outputArray = orderedArray.map(({indexInt, ...keepAttrs}) => keepAttrs); // orderedArray loses indexInt attribute
      // outputArray = items.map(({indexInt, ...keepAttrs}) => keepAttrs); // orderedArray loses indexInt attribute
      // console.log('~~~~~ OrderedItemList(), handleDragEnd() - setTimeout() - outputArray: ');
      console.log(outputArray);
      // updateArray(outputArray);
      updateArray(outputArray);
    }, 500);
    */
  }
  
  const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } }); 
  const sensors = useSensors(pointerSensor);
  
  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div style={{ display: active ? "block" : "none", width: "100%", textAlign: "center" }}>
        <AreaTitle style={{ display: "inline-block", fontSize: "smaller", marginTop: "-30px" }}>
          Drag Exercises to change their order.
        </AreaTitle>
        <SortableContext
          items={itemIds}
          strategy={verticalListSortingStrategy}
        >
          {items.map(item => <SortableExerciseCard 
            key={(item.id_guid ? item.id_guid : 
            item.exercise_id_guid ? item.exercise_id_guid : 
            'no_id_guid_').toString() + '_' + uuidv4()} 
            id={item.indexInt} 
            item={item} 
            onRemoveClick={onRemoveClick} 
            onExerciseUpdate={handleExerciseUpdate}
            onVideoClick={onVideoClick}
          /> )}
          {/* {arrayItems.map(item => <SortableItem key={(item.id_guid ? item.id_guid : item.exercise_id_guid ? item.exercise_id_guid : 'no_id_guid_').toString() + '_' + uuidv4()} id={item.indexInt} item={item} onRemoveClick={onRemoveClick} /> )} */}
        </SortableContext>
      </div>
    </DndContext>
  );

}

export default OrderedExerciseList;