// TopLineScores.js

import React from 'react';

import styled from 'styled-components';
import ScoreCircle from './ScoreCircle';

const TopLineScoresDiv = styled.div`
  cursor: auto;
  ${ props => props.isMobile === true /*  // TODO: set this to always be display: flex ? check other places it is used  */
    ? '{ display: flex }'
    : '{ display: flex }'
  }
  ${ props => props.isMobile === true  /*  // TODO: set this to always be justify-content: center ? check other places it is used  */
    ? '{ justify-content: center }' 
    : '{ justify-content: center }'
  }
`;

const ScoreCircleAndTitleDiv = styled.div`
  display: inline-block;
  ${ props => props.shell === true
    ? '{ width: 17% }'
    : '{ width: 6vw }'
  }
  min-width: 80px;
  ${ props => props.shell === true
    ? '{ margin-right: 0px }'
    : '{ margin-right: 1.5vw }'
  }
  text-align: center;
`;

const ScoreTitle = styled.h6`
  text-align: center;
  ${ props => props.isMobile === true
    ? '{ font-size: 3vw }'
    : ''
  }
`;

// return one scorecircle with a title below it
const ScoreCircleAndTitle = ({
    score, // object with properties score, trend, title
    size, // size of circle
    shell, // boolean
    isMobile // boolean
 }) => {
  console.log('~~~~~ TopLineScores(), ScoreCircleAndTitle() - isMobile is ' + isMobile);
  return (
    <ScoreCircleAndTitleDiv shell={shell} isMobile={isMobile}>
      <ScoreCircle score={score.score} trend={score.trend} size={size} style={{ margin: "auto", marginTop: "20px", marginBottom: "20px" }} />
      <ScoreTitle isMobile={isMobile}>{score.title}</ScoreTitle>
    </ScoreCircleAndTitleDiv>
  )
}


const TopLineScores = ({
 data, shell, isMobile
}) => {
  const mobileSize = window.innerWidth*0.15;
  const desktopSize = window.innerWidth*0.045;
  const shellSize = "80";
  const circleSize = isMobile ? mobileSize : shell ? shellSize : desktopSize; 

  console.log('~~~~~ ToplineScores() - shell is ' + shell + ', isMobile is ' + isMobile + ', circleSize is ' + circleSize);

  if (data && Array.isArray(data) && data.length > 4) {
    const scores = data;
    const compositeScoresArray = scores.filter((score) => {
      return score.title === "Composite";
    });
    // console.log("~~~~~ TopLineScores() - compositeScoresArray.length: " + compositeScoresArray.length);
    const compositeScore = compositeScoresArray[0];
    
    const mobilityScoresArray = scores.filter((score) => {
      return score.title === "Mobility";
    });
    const mobilityScore = mobilityScoresArray[0];
  
    const stabilityScoresArray = scores.filter((score) => {
      return score.title === "Stability" || score.title === "Activation";
    });
    const stabilityScore = stabilityScoresArray[0];
  
    const postureScoresArray = scores.filter((score) => {
      return score.title === "Posture";
    });
    const postureScore = postureScoresArray[0];
  
    const symmetryScoresArray = scores.filter((score) => {
      return score.title === "Symmetry";
    });
    const symmetryScore = symmetryScoresArray[0];
  
    return (
      <TopLineScoresDiv isMobile={isMobile}>
        <ScoreCircleAndTitle score={compositeScore} size={circleSize} shell={shell} isMobile={isMobile} />
        <ScoreCircleAndTitle score={mobilityScore} size={circleSize} shell={shell} isMobile={isMobile} />
        <ScoreCircleAndTitle score={stabilityScore} size={circleSize} shell={shell} isMobile={isMobile} />
        <ScoreCircleAndTitle score={postureScore} size={circleSize} shell={shell} isMobile={isMobile} />
        <ScoreCircleAndTitle score={symmetryScore} size={circleSize} shell={shell} isMobile={isMobile} />
      </TopLineScoresDiv>
    );
  } else {
    return (
      <div />
    );
  }
}

export default TopLineScores;

