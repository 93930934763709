// CollapsibleGroupRow.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { 
  colors,
  logoSrc,
  northsideLogoSrc,
  overviewSrc,
  athletesSrc,
  groupsSrc,
  programsSrc,
  exercisesSrc,
  movementsSrc
 } from '../../constants';
import { getGroup } from '../../services';

const StyledTextWrapper = styled.div`
  cursor: pointer;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  min-width: 128px;
  text-align: left;
  ${ props => props.selected === true
    ? '{ background-color: #FFFFFF }'
    : '{ background-color: #eeeeee }' // use colors.sidebarBg ?
  }
`;

const StyledCaret = styled.div`
  cursor: pointer;
  display: inline-block;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 0px;
  letter-spacing: 0;
  line-height: 20px;
  width: 24px;
  margin-right: 5px;
  ${ props => props.showChildren === true
    ? '{ transform: rotate(-180deg) }'
    : '{ transform: rotate(90deg) }'
  }
  ${ props => props.hasChildren === true
    ? '{ color: #000000 }'
    : '{ color: #0000FF }'
  }
  content: '⌃ ';
  white-space: nowrap;
  width: 15px;
`;

/*
// was like this, when carets pointed right
  ${ props => props.showChildren == true
    ? '{ transform: rotate(-180deg) }'
    : '{ transform: rotate(90deg) }'
  }
*/

  /* ${ props => props.isCollapsed == true
    ? '{ display: none }'
    : '{ display: block }'
  } */

const ChildrenWrapper = styled.div`

  /* display: none; */
  padding-left: 15px;
  white-space: nowrap;
`;

const RowWrapper = styled.div`
  /* display: none; */
  padding-left: 0px;
  white-space: nowrap;
`;


const fakeGroupObj = 		{
  "id_guid": "6109953e-a454-4320-9d57-77773118adeb",
  "name_string": "Fake Child Group",
  "note_string": "Fake Child Group notes",
  "parent_group_id_guid": "8149d7a6-b183-4b43-b404-5628308b7777",
  "user_id_guid": "aba773bb-2b97-4dab-b7f2-77a45966a810",
  "has_children_boolean": false,
  "level_integer": 2
};



const CollapsibleGroupRow = ({ // groupObject.has_children_boolean
    groupObject,        // a groupObject, including id_guid string, name_string, has_children_boolean, closed boolean
    parentsArray,       // an array of GroupObjectInfo, including id_guid string, name_string, closed boolean, kids [id]
    selectedGroup,      // id_guid
    isSelected,         // might not need this... ?
    isCollapsed,        // a tree-friendly groupObjArray should have isCollapsed in it
    onGroupClick,       // a method invoked by clicking/tapping the Group
    onCaretChange       // a method invoked by clicking/tapping the caret for a Group
  }) => {
    const myKidsCount = useRef(0);
    // const isCollapsedNow = useRef(true); // was true for hopeful final config...
    let myKidsCt = 0;
    // const hasChildren = groupObject.group_array.length > 0;
    // const selected = (selectedGroup && selectedGroup.length > 20) && groupObject.id_guid == selectedGroup;
    const hasChildren = groupObject.has_children_boolean; // or should GroupObject have children_id_guid_array?
    const [showKids, setShowKids] = useState(!isCollapsed);
    // const [selected, setSelected] = useState(groupObject.id_guid == selectedGroup);
    // const [selected, setSelected] = useState(groupObject.id_guid == selectedGroup);
    // this should be stored outside the row, in the list
    const [selected, setSelected] = useState(isSelected);
    const indentInt = 20 + groupObject.level_integer * 10;    // might not need this later?
    const padLeft = indentInt.toString() + "px";              // might not need this later?

    let haveKids = false;
    let myKids = [];
    let myRecordObj = null;

    // if (myKidsCount.current === 0 && parentsArray && parentsArray.length > 0) {
    if (myKidsCt === 0 && parentsArray && parentsArray.length > 0) {
      myRecordObj = parentsArray.find(obj => { // find returns the first matching element
        console.log('~~~~~ CGR() - found our id in the parentsArray');
        return obj.id === groupObject.id_guid // NOTE: the parentsArray does not use id_guid
      });
      if (myRecordObj && myRecordObj.kids.length > 0){
        console.log('~~~~~ CGR() - myRecordObj.name is ' + myRecordObj.name + ', and myRecordObj.kids.length is ' + myRecordObj.kids.length);

        // myKidsCount.current = myRecordObj.kids.length;
        myKidsCt = myRecordObj.kids.length;
        myKids = myRecordObj.kids; // [ { id String, name String, closed Bool, kids Array [id] } ]
        haveKids = true;

        // console.log('~~~~~ CGR() - myKidsCount.current is now ' + myKidsCount.current);
        console.log('~~~~~ CGR() - myKidsCt is now ' + myKidsCt);
        console.log('~~~~~ CGR() - myKids.length is now ' + myKids.length);
        console.log('~~~~~ CGR() - myKids[0].name_string is now ' + myKids[0].name_string);
      } else {
        console.log('~~~~~ CGR() - no valid record for us in parentsArray, or kids.length === 0 for this Group');
      }
    }
    console.log('~~~~~ CGR() - after parentsArray processing, this name_string is ' + groupObject.name_string + ', and myKids.length is ' + myKids.length);
  
    useEffect(() => {
      // if (myKidsCount.current > 0){ // only do this if myKids has not yet been set
      if (myKidsCt > 0){ // only do this if myKids has not yet been set
        return;
      }

    if (parentsArray && parentsArray.length > 0) {
      myRecordObj = parentsArray.find(obj => { // find returns the first matching element
        console.log('~~~~~ CGR() - found our id in the parentsArray');
        return obj.id === groupObject.id_guid // NOTE: the parentsArray does not use id_guid
      });
      if (myRecordObj && myRecordObj.kids.length > 0){
        console.log('~~~~~ CGR() - myRecordObj.kids.length is ' + myRecordObj.kids.length);

        // myKidsCount.current = myRecordObj.kids.length;
        myKidsCt = myRecordObj.kids.length;
        myKids = myRecordObj.kids; // [ { id String, name String, closed Bool, kids Array [id] } ]
        haveKids = true;


        // console.log('~~~~~ CGR() - myKidsCount.current is now ' + myKidsCount.current);
        console.log('~~~~~ CGR() - myKidsCt is now ' + myKidsCt);
        console.log('~~~~~ CGR() - myKids.length is now ' + myKids.length);
        console.log('~~~~~ CGR() - myKids[0].name_string is now ' + myKids[0].name_string);
        haveKids = true;
      } else {
        console.log('~~~~~ CGR() - no valid record for us in parentsArray, or kids.length === 0');
      }
    }
    
    }, [groupObject]); // was parentsArray


    function toggleChildren(e){
      e.stopPropagation();
      let key = e.target.parentElement.id;
      console.log('~~~~~ CollapsibleGroupRow(), toggleChildren() - caret clicked/tapped for row ' + key);
      if (showKids){
        setShowKids(false);
        onCaretChange(key, false);
      } else {
        setShowKids(true);
        onCaretChange(key, true);
      }
    }
    
    function groupRowClick(e){
      let key = e.currentTarget.id;
      console.log('~~~~~ CollapsibleGroupRow(), groupRowClick() - %s row clicked/tapped', key);
      // if(selectedGroup != key){ // TODO: leave this but have selectedGroup 'zeroed-out' when clicking away from GroupList in Sidebar
        console.log('~~~~~ CollapsibleGroupRow(), groupRowClick() - %s row clicked/tapped, selected Group was %s', key, selectedGroup);
        onGroupClick(key);
        // code to deselect all the 
        setSelected(groupObject.id_guid === selectedGroup); 
      // }
    
    }

    function uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    }

    // console.log('~~~~~ CollapsibleGroupRow.js, CollapsibleGroupRow() - padLeft = ' + padLeft);
    return (
      <RowWrapper id={groupObject.id_guid + '_row_wrapper'} title={groupObject.id_guid}>
        {console.log('~~~~~ CollapsibleGroupRow() - inside return() for render!')}
        <StyledTextWrapper onClick={groupRowClick} id={groupObject.id_guid} selected={isSelected} style={{ paddingLeft: padLeft }}>
          {/* {hasChildren && <StyledCaret showChildren={showKids} onClick={toggleChildren} />} */}
          {hasChildren && <StyledCaret showChildren={showKids} hasChildren={true} onClick={toggleChildren}>⌃ </StyledCaret>}
          {!hasChildren && <StyledCaret>  </StyledCaret>}
          
          {groupObject.name_string}
        </StyledTextWrapper>
        {/* can this be right? do we need to create ChildWrapper no matter what?  Or can we render it conditionally? */}  
        {(haveKids && showKids) && 
          <ChildrenWrapper id={groupObject.id_guid + "_children"} style={{ display: showKids ? "block" : "none" }}>
            {myKids.map(groupObj => {
              console.log('~~~~~ CGR(), return(), myKids.map() - groupObj.name_string: ' + groupObj.name_string);
              const parentObj = parentsArray.find(parent => parent.id === groupObj.id_guid);
              const collapsed = parentObj && parentObj.closed;
              return(
                <CollapsibleGroupRow 
                  groupObject={groupObj}          // should include .id_guid, .name_string, .has_children_boolean, .level_integer?, .kids [obj]
                  parentsArray={parentsArray}
                  // showChildren={false}            // default starting value
                  isCollapsed={collapsed}
                  // isSelected={false}              // default starting value   // ={(groupsActive || activeGroupId.length > 20) && group.id_guid == selectedGroup}
                  onCaretChange={onCaretChange}   // always the same
                  onGroupClick={onGroupClick}     // always the same
                  selectedGroup={selectedGroup} 
                  isSelected={groupObj.id_guid === selectedGroup} 
                  id={groupObj.id_guid + "_row"}          // could be made from groupObject.id_guid
                  key={groupObj.id_guid + "_row"}         // could be made from groupObject.id_guid
                  // title={groupObj.id_guid} // TODO: remove this, eventually...
                />
              )
            })}
          </ChildrenWrapper>
        }

      </RowWrapper>
    );  
  }

  export default CollapsibleGroupRow;
