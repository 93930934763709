// components/stled/MyAssessment.js

import React, { useState } from 'react';

import TopLineScores from './../styled/TopLineScores';
// import ShellBodyMap from './../styled/ShellBodyMap';
import BodyMap from './../styled/BodyMap'; // TODO: retrieve stash with ShellBodyMap & go back to using it...

import ShellPrimaryIssuesTable from './../styled/ShellPrimaryIssuesTable';
import ShellSubdomainScoresTable from './../styled/ShellSubdomainScoresTable';
// import MobileLoadingDivLayer  from './../styled/MobileLoadingDivLayer';
import { EZSpacer, BackLink, ContentWrapper, ShellTabButton, MobileVersionDiv } from './../styled/title';
import styled from 'styled-components';
import { iconSrc } from '../../constants';
// import './../Assessment.css';

const BodymapAndIssuesDiv = styled.div`
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center-top;
  justify-content: space-between;
  padding-left: 0px;
`;

const isStretchLab = localStorage.getItem('stretchlab') === 'true' ? true : false;

function shortDateTime(dateTime) {
  console.log('~~~~~ MyAssessment(), shortDateTime() - dateTime is ' + dateTime);
  let dtDate = dateTime;
  if (typeof dateTime === "string") {
    console.log('~~~~~ MyAssessment(), shortDateTime() - dateTime was a string!');
    dtDate = new Date(dateTime);
  }

  const dayOfMonth = dtDate.getDate();
  const month = (dtDate.getMonth() + 1).toString();
  const fullYear = dtDate.getFullYear().toString();
  const shortYear = typeof fullYear === "string" ? fullYear.substring(2) : "fullYear not a string!";
  const shortDate = month + "/" + dayOfMonth + "/" + shortYear;

  const hours = dtDate.getHours().toString();
  const minutes = dtDate.getMinutes().toString();
  const shortTime = hours + ":" + minutes;

  return shortDate + " " + shortTime;
}

const MyAssessment = ({
  data, isMobile, goToHistory
}) => {
  const [issues, setIssues] = useState(data.issuesData);
  const [scores, setScores] = useState(data.scoresData);
  const [metadata, setMetadata] = useState(data.metaData);
  // const [member, setMember] = useState({});
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState("mobility");

  let fetching = false;

  const left_arrow_url = "url(" + iconSrc.chevron_left_black_filled + ")";

  function setActiveTab(title) {
    console.log("~~~~~ setActiveTab() - title: " + title);
    setCurrentTab(title);
  }

  let rawDisplayDate = issues && issues[0] && issues[0].dateTime ?
    issues[0].dateTime :
    new Date('05/17/2022 10:10:10');

  const displayDate = shortDateTime(rawDisplayDate);

  return (

    <div style={{ 
      width: "100vw", 
      display: "flex", 
      flexDirection: "column", 
      justifyContent: "center", 
      paddingBottom: "15vh", 
      paddingTop: "0vw",
      // marginBottom: "10vh" 
    }}>
      <h5 style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", padding: "2vw", fontSize: "3vw" }}>Performed {displayDate}</h5>
      <TopLineScores data={scores} shell={true} isMobile={isMobile} style={{ width: "100vw", padding: "0px", margin: "auto" }} />
      <div style={{ width: "100vw", display: "flex", flexDirection: "row", justifyContent: "space-around", paddingBottom: "0vw", paddingTop: "4vw" }}>
        <ShellTabButton isMobile={isMobile} tabTitle={"mobility"} onClick={() => setActiveTab("mobility")} currentTab={currentTab}>MOBILITY</ShellTabButton>
        <ShellTabButton isMobile={isMobile} tabTitle={"stability"} onClick={() => setActiveTab("stability")} currentTab={currentTab}>{isStretchLab ? "ACTIVATION" : "STABILITY"}</ShellTabButton>
        <ShellTabButton isMobile={isMobile} tabTitle={"posture"} onClick={() => setActiveTab("posture")} currentTab={currentTab}>POSTURE</ShellTabButton>
        <ShellTabButton isMobile={isMobile} tabTitle={"symmetry"} onClick={() => setActiveTab("symmetry")} currentTab={currentTab}>SYMMETRY</ShellTabButton>
      </div>
      <div style={{ width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center", padding: "3vw", paddingTop: "0vw" }}>
        <BodymapAndIssuesDiv>
          <BodyMap isMobile={isMobile} data={issues} domain={currentTab} maxIssues={3} style={{ cursor: "auto", width: "40vw", margin: "0px" }} />
          <ShellPrimaryIssuesTable isMobile={isMobile} data={issues} maxIssues={3} domain={currentTab} style={{ cursor: "auto", width: "40vw" }} />
        </BodymapAndIssuesDiv>
        <ShellSubdomainScoresTable data={scores} domain={currentTab} isMobile={isMobile} />
      </div>
      
      <div style={{ width: "100vw", display: "flex", justifyContent: "center" }}>
        <button 
          onClick={goToHistory} 
          style={{
            cursor: "pointer",
            marginTop: "1vh",
            // marginBottom: "5vh",
            width: "80vw",
            height: "7vw",
            border: "0.5vw solid",
            borderRadius: "3.5vw",
            backgroundImage: left_arrow_url,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "20px center",
            backgroundSize: "4vw 4vw",
            fontSize: "4vw",
            fontWeight: "bold",
            color: "#000",
            opacity: "0.5"
          }}
        >
          HISTORY
        </button>
      </div>
    </div>

  );
}

export default MyAssessment;