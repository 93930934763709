import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typeahead } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

const isCoach = localStorage.getItem('coach') === 'true' ? true : false;

const SearchInputDiv = styled.div`
  width: 34vw; 
  height: 4.5vw; 
  border: none;
  padding: 0;
  padding-left: 5vw;
  padding-right: 1vw;
  padding-top: 0.5vw;
  font-size: 1.2vw;
  background: #f7f7f7;
  background-image: url('search_black_20pct.png');
  background-size: 2.2vw 2.2vw;
  background-position: top 0.7vw left 1.2vw;
  background-repeat: no-repeat;
  border: none;
  border:solid 1px #ccc;
  border-radius: 1.0vw;
  margin-top: 1vw;
  margin-bottom: 2vw;
`;

function TypeAheadSearch(props) {
  const {
    data
  } = props;

  const navigate = useNavigate();

  function handleChange(selectedOptions) {
    console.log(selectedOptions);
    navigate('/history?from=Members&memberid=' + selectedOptions[0].id_guid);
  }

  
  return (
    <>
      <SearchInputDiv>
        <Typeahead
          id="bootstrap-test"
          placeholder={isCoach?'Search for an Athlete by email address':'Search for a Member by email address'}
          onChange={handleChange}
          labelKey="email"
          minLength={1}
          options={data}
          inputProps={{
            style: {
              'background': 'none',
              'border': 'none',
              'height': '3vw',
              'fontSize': '1.2vw',
              'outline': 'none'
            }
          }}
        />
       </SearchInputDiv>
    </>
  );
}


export default TypeAheadSearch;