// InlineLoader.js

import React from 'react';
import { loadingSrc, accentColor } from '../../constants';

import styled from 'styled-components';

const LoadingDiv = styled.div`
  display: inline-block;
  padding: 0px;
  padding-bottom: 5px;
  margin-left: 30px;
  margin-top: -7px;


`;

// ${ props => props.active !== false
//   ? '{ display: inline-block }'
//   : '{ display: none }'
// }


const InlineLoader = ({
  active
}) => {
  console.log('~~~~~ InLineLoader() - active is ' + !!active);
  return (
    <LoadingDiv style={{ display: active ? "inline-block" : "none", paddingTop: "0px" }} >
      <img src={loadingSrc} style={{ width: "24px", height: "24px" }} />
    </LoadingDiv>
  );
}

export default InlineLoader;