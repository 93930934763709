// ShellSidebarButton.js

import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import { SidebarBtnIcon } from './title';
// import { 
//   colors,
//   iconSrc,
//   logoSrc,
//   northsideLogoSrc,
//   overviewSrc,
//   athletesSrc,
//   groupsSrc,
//   programsSrc,
//   exercisesSrc,
//   movementsSrc
// } from '../../constants';


const ButtonWrapper = styled.div`
  cursor: pointer;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
  padding-left: 15px;
  white-space: nowrap;
  min-width: 128px;
  text-align: left;
  ${ props => props.selected === true
    ? '{ background-color: #FFFFFF }'
    : '{ background-color: #eeeeee }' // use colors.sidebarBg ?
  }
`;

const ShellSidebarButton = ({
    btnTitle, isSelected, onButtonClick, icon, btnId
  }) => {
    const something = 5;
    const [isLucky, setIsLucky] = useState(false);
  
    function yellOut() {
      alert('HEY!');
    }
  
    return (
      <ButtonWrapper selected={isSelected} onClick={onButtonClick} id={btnId} style={{ zIndex: "10000" }}>
        <SidebarBtnIcon src={icon} />
        {btnTitle}
      </ButtonWrapper>
    ); 
}

export default ShellSidebarButton;