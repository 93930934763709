// ItemsTable.js

import React from 'react';
import { useState, useRef, useEffect } from "react";

import styled from 'styled-components';
import { RedSpan, Thumbnail, EditIcon } from './title';
import { iconSrc, colors } from '../../constants';

import { getAthletes, getGroups, getMovements, getExercises, getPrograms } from '../../services'; // TODO: improve???
// import { getItems } from '../../services';

const SortableTableRow = styled.tr`
  cursor: pointer;
  padding-top: 2vw;
  padding-bottom: 2vw;
  border-bottom: 1px solid #f0f0f0;
  &:hover { background: ghostwhite };
`;

const TableHead = ({ columns, handleSorting }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    // console.log('~~~~~ ItemsTable - handleSortingChange(), accessor = ' + accessor);
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    // console.log('~~~~~ ItemsTable - handleSortingChange(), sortOrder = ' + sortOrder);
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
   <thead style={{ position: "sticky", top: "0px", zIndex: 1 }}>
    <tr style={{ borderBottom: "1px solid #e9e9e9", whiteSpace: "nowrap", background: colors.background }}>
     {columns.map(({ label, accessor, sortable, minWidth }) => {
      return (
        <th 
          key={accessor}
          title={sortable ? "Click to sort by this column" : ""}
          onClick={sortable ? () => handleSortingChange(accessor) : null}
          style={{
            fontSize: "1vw", 
            color: "#b4b4b4", 
            fontWeight: "400", 
            paddingRight: "0.5vw", 
            paddingLeft: "1.7vw", 
            minWidth: minWidth ? minWidth : "60px", 
            cursor: sortable ? "pointer" : "default",
            position: "sticky",
            top: "0px",
            zIndex: 1
          }}
        >
          {label}
        </th>
      );
     })}
    </tr>
   </thead>
  );
};

const TableBody = ({ 
  tableData, 
  columns, 
  modelInfo,
  onRowClick, 
  columnClick,
  loading, 
  filtering
}) => {

  // const EditIcon = styled.img`
  //   display: inline-block;
  //   width: 1.6vw;
  //   height: 1.6vw;
  //   margin: 0 0 0 0 !important; // updated for 1.6vw svg images
  //   padding: 0 !important;
  //   opacity: 0.5;
  //   &:hover {
  //     opacity: 1.0;
  //   }
  // `;

  const noItemsMsg = modelInfo.strings.noItemsMsg; // TODO: accommodate possiblity of filtering by empty Group
  const noFilteredItemsMsg = modelInfo.strings.noFilteredItemsMsg; // TODO: accommodate possiblity of filtering by empty Group

  return (
   <tbody>
      <SortableTableRow style={{ display: loading ? "table-row" : "none" }}>
        <td colSpan={columns.length} align="center"><RedSpan>Loading {modelInfo.type}s...</RedSpan></td>
      </SortableTableRow>
      <SortableTableRow style={{ display: (!loading && tableData && tableData.length === 0) ? "table-row" : "none" }}>
        <td colSpan={columns.length} align="center"><RedSpan>{filtering ? noFilteredItemsMsg : noItemsMsg}</RedSpan></td>
      </SortableTableRow>
    {tableData.map((data) => {
      // the const below is automatically set to true if no accessor is set for enabling the itemClick functionality
      const itemClickEnabled = !modelInfo.itemClickEnabledBooleanAccessor || data[modelInfo.itemClickEnabledBooleanAccessor];
      const itemClickDisabledMessage = modelInfo.itemClickDisabledMessage;
     return (
        <SortableTableRow  style={{ display: loading ? "none" : "table-row" }} key={data.id_guid} id={data.id_guid} onClick={function(event){
          itemClickEnabled ? 
          onRowClick(event, data.id_guid) : 
          alert(itemClickDisabledMessage); // or noop, or a prettyMessage in a dialog or flashMessage style ?
        }}>
          {columns.map(({ type, accessor, align, color, title, replaceDataIf, replaceDataWith, iconSrc, minWidth, maxWidth }) => {
            let tData = data[accessor] ? data[accessor] : "——";
            if (accessor === "full_name_string") {
              title = data.email_string;
              if (tData === "——") {
                let first_name = data.first_name_string;
                let last_name = data.last_name_string;
                if (first_name && first_name.length > 0 && last_name && last_name.length > 0) {
                  tData = first_name + " " + last_name;
                } else {
                  tData = data.email_string
                }
              }
            }
            let cellCode = <td key={accessor} title={title} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw", minWidth: minWidth ? minWidth : "30px", maxWidth: maxWidth ? maxWidth : "auto", overflow: maxWidth ? "hidden" : "auto" }}>{tData}</td>;

            if (replaceDataIf && tData === replaceDataIf) { // will replace any shortcut string with different string content
              cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}><RedSpan>{replaceDataWith}</RedSpan></td>;
            }

            if (type === "tnail") { // this should insert a 200px wide thubnail found at the URL in tData, or a 'fake_tnail' image...
              cellCode = <td key={accessor} align={align} style={{ padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}><Thumbnail src={tData} /></td>;
            }

            if (type === "tnail-array") { // this should insert a 200px wide thubnail found at the URL in tData, or a 'fake_tnail' image...
              cellCode = <td key={accessor} align={align} style={{ padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}><Thumbnail src={tData[0]} /></td>;
            }

            if (type === "check") { // this should insert a checkmark icon if the associated boolean is true
              cellCode = <td key={accessor} align={align} style={{ padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}>
                <EditIcon src={tData ? iconSrc : ''} title={title} />
              </td>;
            }

            if (type === "check") { // this should insert a checkmark icon if the associated boolean is true
              cellCode = <td key={accessor} align={align} style={{ padding: "0", paddingTop: "1.2vw", paddingBottom: "1.2vw"}}>
                <EditIcon src={tData ? iconSrc : ''} title={title} />
              </td>;
            }

            if (accessor === "lastAssessmentDate" && tData.includes("Coach")) { // make link for "Pending Coach Accept"
              cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}} onClick={(event) => { event.stopPropagation(); columnClick[accessor](event, data.id_guid) }} >
              <span style={{ cursor: "pointer", textDecoration: "underline", color: colors.accent }}>Pending Coach Accept</span>
            </td>;
            }

            if (accessor.includes("Link")) { // works for any clickable icon that should launch a method...
              if (itemClickEnabled && iconSrc || (accessor === 'editLink')) {                      // alert('Column was ' + accessor + ', and data.id_guid was ' + data.id_guid) // TODO: correct onClick below to not fire when rendering
                cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}} onClick={(event) => { event.stopPropagation(); columnClick[accessor](event, data.id_guid) }} >
                  <EditIcon src={iconSrc} title={title} /> 
                </td>;
              } else {
                cellCode = <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw"}}></td>;
              }
            }

            return cellCode;
          })}
        </SortableTableRow>
     );
    })}
   </tbody>
  );

};

// props: itemType, filterType, filterId, handleClick, editClick, groupsClick, programsClick
// eventually prop handleClicks: { default : fn, edit: fn, groups: fn, programs: fn [, ... more] } // handleClicks.edit, etc.

function ItemsTable(props) {
  const [items, setItems] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [firstSortDone, setFirstSortDone] = useState(false);
  let sorted = [];
  const filterId = props.filterId;
  const modelInfo = props.modelInfo;
  function noop(){}
  const itemClick = props.handleEvents.itemClick;
  const columnClick = props.handleEvents.columnClick;

  const itemsUpdate = props.handleEvents.itemsUpdate;
  const statsUpdate = props.handleEvents.statsUpdate ? props.handleEvents.statsUpdate : noop; // some tabs will not feature stats...
  const columns = props.modelInfo.listColumns;
  const newItem = props.newItem;

  const mounted = useRef(true);
  const isCoach = localStorage.getItem('coach') === 'true' ? true : false;

  const handleSorting = (sortField, sortOrder) => {

    console.log('~~~~~ ItemsTable.js, ItemsTable() - handleSorting() items.length: ' + items.length);
    console.log('~~~~~ ItemsTable.js, ItemsTable() - handleSorting() sortField: ' + sortField + ', sortOrder: ' + sortOrder);

    if (sortField !== undefined) {
      console.log('~~~~~ ItemsTable, handleSorting() - sortField is *' + sortField);
    }

    if (sortOrder !== undefined) {
      console.log('~~~~~ ItemsTable, handleSorting() - sortOrder is *' + sortOrder);
    }

    // console.log('~~~~~ ItemsTable, handleSorting() - items[0].full_name_string is *' + items[0].full_name_string);

    if (sortField) {
      let aToString = "";
      let bToString = "";
      sorted = [...items].sort((a, b) => { // TODO: why not use tableData?
        if (a[sortField]) {
          aToString = a[sortField].toString().length > 0 ? a[sortField].toString().replace('.','') : "";
        }
        if (b[sortField]) {
          bToString = b[sortField].toString().length > 0 ? b[sortField].toString().replace('.','') : "";
        }
        return (
          aToString.localeCompare(bToString) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  function initialSort(itemsArray) {
    console.log('~~~~~ ItemsTable, ItemsTable() - initialSort() - itemsArray.length is ' + itemsArray.length);
    // itemsArray.sort(function(a,b){
    //   return b.lastAssessmentDate - a.lastAssessmentDate;
    // });
    const sortField = modelInfo.defaultSort.field;
    const sortOrder = modelInfo.defaultSort.order;

    console.log('~~~~~ ItemsTable.js, ItemsTable() - initialSort() itemsArray.length: ' + itemsArray.length);
    console.log('~~~~~ ItemsTable.js, ItemsTable() - initialSort() sortField: ' + sortField + ', sortOrder: ' + sortOrder);

    if (sortField !== undefined) {
      console.log('~~~~~ ItemsTable, initialSort() - sortField is *' + sortField + '*');
    }

    if (sortOrder !== undefined) {
      console.log('~~~~~ ItemsTable, initialSort() - sortOrder is *' + sortOrder + '*');
    }

    if (sortField) {
      let aToString = "";
      let bToString = "";
      sorted = [...itemsArray].sort((a, b) => { // TODO: why not use tableData?
        if (a[sortField]) {
          aToString = a[sortField].toString().length > 0 ? a[sortField].toString().replace('.','') : "";
        }
        if (b[sortField]) {
          bToString = b[sortField].toString().length > 0 ? b[sortField].toString().replace('.','') : "";
        }
        return (
          aToString.localeCompare(bToString) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  
     return sorted;
  }

  let fetchingNow = false;

  useEffect(() => {
    if(fetchingNow) { // Note that this getItems() call should only run if there is not already a fetch in progress
      console.log('~~~~~ ItemsTable, ItemsTable() - inside useEffect, fetchingNow true, skipping fetch!');
      return;
    }
    fetchingNow = true; // Now there is a fetch in progress
    setLoading(true); // we should use this to show a loading items message, or an activity indicator where the list will appear
    mounted.current = true;

    console.log('~~~~~ ItemsTable, ItemsTable() - inside useEffect, proceeding with itemsFetch');

    if (modelInfo.type === "athlete") {
      console.log('~~~~~ ItemsTable, ItemsTable() - inside useEffect, proceeding with getAthletes()');
      getAthletes(filterId) // TODO: make this itemType-agnostic?  services/items.js, getItems(itemType, parameters) { switch statement }
      .then(itemsResp => {
        if(mounted.current) {            // TODO: also make sure this works with itemsResp of the form [] or { data: [], pagination: {} }
          let items = itemsResp.items; // TODO: how to make this itemType-agnostic?
          let itemStats = itemsResp.itemStats // might be undefined, if items fetch does not return stats attribute
          setItems(items);
          let sortedItems = items;
          if ([...items].length > 0 && !firstSortDone) {
            sortedItems = initialSort(items);
            setFirstSortDone(true);
          }
          setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
          // statsUpdate(itemsResp.memberStats); // this passes back the updated memberStats to the parent Component
          statsUpdate(itemStats); // this passes back the updated memberStats to the parent Component
          itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
          fetchingNow = false; // Now we have finished with the current fetch
          setLoading(false);
        }
      })
    } else if (modelInfo.type === "group") {
      console.log('~~~~~ ItemsTable, ItemsTable() - inside useEffect, proceeding with getGroups()');
      getGroups()
      .then(itemsResp => {
        if(mounted.current) {
          let items = itemsResp.data;
          setItems(items);
          let sortedItems = items;
          if ([...items].length > 0 && !firstSortDone) {
            sortedItems = initialSort(items);
            setFirstSortDone(true);
          }
          setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
          // statsUpdate(itemsResp.memberStats); // this passes back the updated memberStats to the parent Component
          // statsUpdate(itemStats); // this passes back the updated memberStats to the parent Component
          itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
          fetchingNow = false; // Now we have finished with the current fetch
          setLoading(false);
          console.log('~~~~~ ItemTable() - useEffect() - ' + items.length + ' Groups were returned');
        }
      })
    } else if (modelInfo.type === "movement") {
      console.log('~~~~~ ItemsTable, ItemsTable() - inside useEffect, proceeding with getMovements()');
      getMovements() // should there be a filter option? maybe public or not?
      .then(itemsResp => {
        if(mounted.current) {
          let items = itemsResp.data;
          setItems(items);
          let sortedItems = items;
          if ([...items].length > 0 && !firstSortDone) {
            sortedItems = initialSort(items);
            setFirstSortDone(true);
          }
          setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
          // statsUpdate(itemsResp.memberStats); // this passes back the updated memberStats to the parent Component
          // statsUpdate(itemStats); // this passes back the updated memberStats to the parent Component
          itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
          fetchingNow = false; // Now we have finished with the current fetch
          setLoading(false);
          console.log('~~~~~ ItemTable() - useEffect() - ' + items.length + ' Movements were returned');
        }
      })
    } else if (modelInfo.type === "exercise") {
      console.log('~~~~~ ItemsTable, ItemsTable() - inside useEffect, proceeding with getExercises()');
      getExercises() // should there be a filter option? maybe public or not?
      .then(itemsResp => {
        if(mounted.current) {
          let items = itemsResp.data;
          setItems(items);
          let sortedItems = items;
          if ([...items].length > 0 && !firstSortDone) {
            sortedItems = initialSort(items);
            setFirstSortDone(true);
          }
          setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
          // statsUpdate(itemsResp.memberStats); // this passes back the updated memberStats to the parent Component
          // statsUpdate(itemStats); // this passes back the updated memberStats to the parent Component
          itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
          fetchingNow = false; // Now we have finished with the current fetch
          setLoading(false);
          console.log('~~~~~ ItemTable() - useEffect() - ' + items.length + ' Exercises were returned');
        }
      })
    } else if (modelInfo.type === "program") {
      console.log('~~~~~ ItemsTable, ItemsTable() - inside useEffect, proceeding with getPrograms()');
      getPrograms() // should there be a filter option? maybe public or not?
      .then(itemsResp => {
        if(mounted.current) {
          let items = itemsResp.data;
          setItems(items);
          let sortedItems = items;
          if ([...items].length > 0 && !firstSortDone) {
            sortedItems = initialSort(items);
            setFirstSortDone(true);
          }
          setTableData(sortedItems); // TODO: >> Note that this will not be immediate, due to use of State
          // statsUpdate(itemsResp.memberStats); // this passes back the updated memberStats to the parent Component
          // statsUpdate(itemStats); // this passes back the updated memberStats to the parent Component
          itemsUpdate(sortedItems); // this passes back the updated and sorted Items to the parent Component 
          fetchingNow = false; // Now we have finished with the current fetch
          setLoading(false);
          console.log('~~~~~ ItemTable() - useEffect() - ' + items.length + ' Programs were returned');
        }
      })
    }
  }, [filterId])

  return (
    <div style={{ height: newItem ? "80vh" : "60vh", overflow: "scroll" }}>
      <table cellPadding={10} width={"96%"}>
        <TableHead {...{ columns, handleSorting }} />
        <TableBody 
          modelInfo={modelInfo} 
          columns={columns}  
          tableData={tableData}
          onRowClick={itemClick} 
          columnClick={props.handleEvents.columnClick}
          loading={loading}
          filtering={props.filterId && props.filterId.length > 20}
        />
      </table>
    </div>
  );
}

export default ItemsTable;
