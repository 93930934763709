// components/styled/Workout.js

import React from 'react';
import { useState, useRef, useEffect } from "react";

import styled from 'styled-components';
import { RedSpan, Thumbnail, AreaTitle, ScrollingListContainer, ListContentRow, ListContentBox } from './title';
// import { iconSrc, colors } from '../../constants';

import { getAthletes, getGroups, getMovements, getExercises, getPrograms } from '../../services'; // TODO: improve???
// import { getItems } from '../../services';




// const fakeExercisesArray = [
//   {
//     id_guid: "c7f86337-7109-4ee9-ba4f-29f600f3a2a1",
//     exercise_id_guid: "58bd6a4c-7d0b-4a70-b317-dceba4510f1a",
//     program_id_guid: "4838140d-32a2-4b9e-8b2b-2467a19b0f43",
//     rank_integer: 0,
//     name_string: "Lying Knee Grabs",
//     note_string: "note_string1",
//     exercise_note_string: "This is a note",
//     duration_seconds_integer: 90,
//     repetition_integer: 3,
//     title_string: "Lying Knee Grabs",
// 		subtitle_string: null,
//     video_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f21f2d89dff656d83854846176665aff63579023/137.mp4",
// 		thumbnail_url_string: "https://movr-assess.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--613624a94a6a96ada00263daf72efb1f4b7eda6e/Lying%20Knee%20Grabs.png"
//   }
// ]


function Workout(props) {

  const [items, setItems] = useState(props.exercises);
  const [tableData, setTableData] = useState(props.exercises);
  // const [loading, setLoading] = useState(false);
  const [firstSortDone, setFirstSortDone] = useState(false);
  const mounted = useRef(true);
  const onExerciseClick = props.onExerciseClick;
  const active = props.active;
  const id_guid = props.id_guid;
  const name = props.name;
  const loading= props.loading; // should this somehow require automated workout to have loaded it's exercises?
  const isMobile = props.isMobile;
  
  function initialSort(itemsArray) { // the only sort we need is by date, ascending
    console.log('~~~~~ Workout, Workout() - initialSort() - itemsArray.length is ' + itemsArray.length);
    
    // const sorted = itemsArray.sort(function(a,b){
    //   return a.exerciseOrder - b.exerciseOrder;
    // });

    const sorted = itemsArray;

    setTableData(sorted);

    return sorted;
  }

  let fetchingNow = false;

  // const noItemsMsg = "There are no Exercises to show...";
  const noItemsMsg = "Loading Exercises..."; // TODO: figure out why loading state changes before Automated Workout populates exercises

  const ListBody = ({ 
    tableData, 
    onRowClick, 
    loading
  }) => {
  
    let rowIndex = 0;
    
    return (
      <>
        <div id="workout_component" style={{ display: loading ? "flex" : "none", flexDirection: "row" }}>
          <div style={{ width: "100%", padding: "20px" }}>
            <RedSpan>Loading Exercises...</RedSpan>
          </div>
        </div>
        <div style={{ display: (!loading  && tableData && tableData.length === 0) ? "flex" : "none", flexDirection: "row" }}>
          <div style={{ width: "100%", padding: "20px" }}>
            <RedSpan>{noItemsMsg}</RedSpan>
          </div>
        </div>
        <ScrollingListContainer isMobile={isMobile}>
        {tableData.map((data) => {
          const id_guid = data.exercise_id_guid;
          const duration = data.duration_seconds_integer;
          const repetitions = data.repetition_integer;
          const hasDuration = duration && duration > 0;
          const hasRepetitions = repetitions && repetitions > 0;
          rowIndex++;
          // console.log('~~~~~ Workout.js, TableBody() - data.id_guid is *' + id_guid + '* and rowIndex is *' + rowIndex + '*');
          // TODO: give outer div below a maxHeight and have overflow scroll vertically if required...
          return(
            <ListContentRow style={{ display: loading ? "none" : "flex" }} key={id_guid + '_' + rowIndex} id={id_guid + '_' + rowIndex} onClick={(e, id_guid) => onRowClick(e, id_guid)}>
              <ListContentBox>
                <div style={{ width: "79%", minHeight: "8vh", textAlign: "left", fontSize: isMobile ? "26px" : "13px" }}>
                  Perform Order: <b>{rowIndex}</b><br /> {/* data.rank_integer */}
                  Exercise Name: <b>{data.name_string}</b><br />
                  {hasDuration && <>Duration, in seconds: <b>{duration}</b><br /></>}
                  {hasRepetitions && <>Repetitions: <b>{repetitions}</b><br /></>}
                </div>
                <div style={{ width: "20%", minHeight: "8vh" }}>
                  <Thumbnail src={data.thumbnail_url_string}  style={{ height: isMobile ? "120px" : "80px", marginTop: "0px" }}/>
                </div>
              </ListContentBox>
            </ListContentRow>
          )
        })}
        </ScrollingListContainer>
      </>
    );
  };

  return (
    <div style={{ display: active ? "block" : "none", width: "100%", textAlign: "center" }}>
      <AreaTitle style={{ display: "inline-block", marginBottom: "-15px", fontSize: isMobile ? "26px" : "13px" }}>Exercises for {name} {!name.includes("Workout") ? "Workout" : ""}</AreaTitle><br />
      {/* <AreaTitle style={{ display: "inline-block", fontSize: "smaller", marginTop: "-30px", color: "white" }}>.</AreaTitle> */}

      <ListBody 
        tableData={tableData}
        onRowClick={onExerciseClick} 
        loading={loading}
      />
    </div>
  );
}

export default Workout;